/*------------------------------------*\
    #Video
\*------------------------------------*/
.video {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    height         : 560px;

    .btn--video {
        width           : 128px;
        height          : 128px;
        background-color: var(--global--color-white);
        position        : absolute;
        top             : 50%;
        left            : 50%;
        transform       : translate(-50%, -50%);
        box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
        z-index         : 5;

        @media #{$maxSmall} {
            width : 68px;
            height: 68px;
        }
    }

    &.video-2 {
        height       : 853px;
        margin-bottom: 63px;
        ;

        @media #{$maxSmall} {
            height: 560px;
        }
    }
}




/* Custom, iPhone Retina */