/*------------------------------------*\
    #Mixins
\*------------------------------------*/
/*-----------------------------------------------------------------------------------

	Theme Name: Medisch
	Theme URI: http://themeforest.net/user/zytheme
	Description: Medisch is a pixel perfect creative medical HTML5 tempalte based on bootstrap 5, designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author: zytheme
	Author URI: http://themeforest.net/user/zytheme
	Version: 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

	1.  Global Styles
	2.  Typography
	3.	Color
	4.	Align
	5.	Grid
	6.	Blockquotes
	7.	Background
	8.	Breadcrumbs
	9.	Buttons
	10. Forms
	11. Icons
	12. List
	13. Tabs
	14. Accordions
	15. Pagination
	16. Alerts
	17. Backtop
	18. Page Loading
	19. Header
	20. Menu
	21. Module
	22. Topbar
	23. Page Title
	24. Carousel
	25. Heading
	26. Hero
	27. Blog
	28. Shop
	29. Team
	30. Timetable
	31. Skills
	32. About
	33. Features
	34. Sidebar
	35. Pricing
	36. Donations
	37. Processes
	38. Contact
	39. Clients
	40. Testimonial
	41. Video
	42. Action
	43. 404
	44. Services
	45. Action
	46. Departments
	47. Footer

/*
WARNING! DO NOT EDIT THIS FILE!

To make it easy to update your theme, you should not edit the styles in this file. Instead use 
the custom.css file to add your styles. You can copy a style from this file and paste it in 
custom.css and it will override the style in this file. You have been warned! :)
*/
/*------------------------------------*\
    #CSS VARIBALES
\*------------------------------------*/
:root {
  --global--color-primary: #fed000;
  --global--color-primary-darken: #263967;
  --global--color-primary-light: #e6f8fb;
  --global--color-secondary: #1c7bfd;
  --global--color-heading: #222;
  --global--color-heading-darken: #1b1a1a;
  --global--color-heading-light: #282828;
  --global--color-body: #9b9b9b;
  --global--color-white: #ffffff;
  --global--color-border: #e6eaef;
  --global--color-gray: #f9f9f9;
  --global--color-gray-darken: #9aaabb;
  --global--color-gray-light: #e6e8eb;
  --global--font-body: "Roboto", sans-serif;
  --global--font-heading: "Kodchasan", sans-serif;
  --global--font-secondary: "Heebo", sans-serif;
}

/*------------------------------------*\
    #RESET
\*------------------------------------*/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a,
dl,
dt,
dd,
ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

*,
:active,
:focus {
  outline: none !important;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}

::selection {
  text-shadow: none;
}

::-moz-selection {
  text-shadow: none;
}

::-webkit-selection {
  text-shadow: none;
}

.form-control {
  box-shadow: none;
}

button {
  background-color: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
}

textarea {
  resize: none;
}

/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/
body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: var(--global--color-white);
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 400;
  color: var(--global--color-body);
  margin: 0;
}

.wrapper {
  background-color: var(--global--color-white);
}
.wrapper.sidearea-active .sidearea-overlay::before {
  visibility: visible;
  opacity: 1;
  right: 0;
}

::selection {
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
  text-shadow: none;
}

a {
  color: var(--global--color-primary);
  transition: all 300ms ease-in-out;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

textarea {
  resize: none;
}

.fullscreen {
  height: 100vh !important;
}

section {
  background-color: var(--global--color-white);
  padding: 120px 0;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  section {
    padding: 70px 0;
  }
}

hr {
  background-color: #eaeaea;
  border-color: #eaeaea;
  margin: 0;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .fullscreen {
    height: auto !important;
  }
}
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/* Heading Text */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--global--color-heading);
  font-family: var(--global--font-heading);
  font-weight: 700;
  margin: 0 0 28px;
  line-height: 1;
}

.h1,
h1 {
  font-size: 52px;
}

.h2,
h2 {
  font-size: 42px;
}

.h3,
h3 {
  font-size: 38px;
}

.h4,
h4 {
  font-size: 28px;
}

.h5,
h5 {
  font-size: 24px;
}

.h6,
h6 {
  font-size: 18px;
}

p {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.lead {
  font-size: 16px;
  line-height: 1.8;
}

/* Aligning Text */
.text--left {
  text-align: left !important;
}

.text--right {
  text-align: right !important;
}

.text--center {
  text-align: center !important;
}

.text--just {
  text-align: justify !important;
}

.align--top {
  vertical-align: top;
}

.align--bottom {
  vertical-align: bottom;
}

.align--middle {
  vertical-align: middle;
}

.align--baseline {
  vertical-align: baseline;
}

/* Weight Text */
.bold {
  font-weight: bold !important;
}

.regular {
  font-weight: normal !important;
}

.medium {
  font-weight: 500 !important;
}

.italic {
  font-style: normal;
}

.break-word {
  word-wrap: break-word;
}

.no-wrap {
  white-space: nowrap;
}

/* Text Color */
.text-white {
  color: var(--global--color-white) !important;
}

.text-gray {
  color: var(--global--color-gray) !important;
}

.text-black {
  color: var(--global--color-heading);
}

.text-theme {
  color: var(--global--color-primary) !important;
}

.text--capitalize {
  text-transform: capitalize !important;
}

.text--uppercase {
  text-transform: uppercase !important;
}

.font-heading {
  font-family: var(--global--font-heading);
}

.font-heading {
  font-family: var(--global--font-heading);
}

.font-18 {
  font-size: 24px;
}

.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 20px;
}

.font-40 {
  font-size: 40px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  text-center-xs {
    text-align: center !important;
  }
}
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  text-center-sm {
    text-align: center !important;
  }
}
.higlighted-style1 {
  background-color: var(--global--color-primary);
  color: var(--global--color-white);
  padding: 5px 0;
}

.higlighted-style2 {
  background-color: var(--global--color-heading);
  color: var(--global--color-white);
  padding: 5px 0;
}

.higlighted-style3 {
  background-color: var(--global--color-gray);
  color: var(--global--color-primary);
  padding: 5px 0;
}

/*------------------------------------*\
    #COLORS
\*------------------------------------*/
.color-heading {
  color: var(--global--color-heading) !important;
}

.color-theme {
  color: var(--global--color-primary) !important;
}

.color-white {
  color: var(--global--color-white) !important;
}

.color-gray {
  color: var(--global--color-gray) !important;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@-ms-keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@-o-keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@-webkit-keyframes Bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@-moz-keyframes Bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@-ms-keyframes Bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@-o-keyframes Bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes Bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
/*------------------------------------*\
    #Align
\*------------------------------------*/
address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 20px;
}

/* All margin */
.m-0 {
  margin: 0 !important;
}

.m-xs {
  margin: 10px;
}

.m-sm {
  margin: 20px;
}

.m-md {
  margin: 40px;
}

.m-lg {
  margin: 80px;
}

/* top margin */
.mt-0 {
  margin-top: 0;
}

.mt-xs {
  margin-top: 10px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-150 {
  margin-top: 150px;
}

/* bottom margin */
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 50px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

/* right margin */
.mr-0 {
  margin-right: 0;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-150 {
  margin-right: 150px;
}

/* left margin */
.ml-0 {
  margin-left: 0;
}

.ml-xs {
  margin-left: 10px;
}

.ml-sm {
  margin-left: 20px;
}

.ml-md {
  margin-left: 40px;
}

.ml-lg {
  margin-left: 80px;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-150 {
  margin-left: 150px;
}

/* All padding */
.p-0 {
  padding: 0 !important;
}

.p-xs {
  padding: 10px;
}

.p-sm {
  padding: 20px;
}

.p-md {
  padding: 40px;
}

.p-lg {
  padding: 80px;
}

/* top padding */
.pt-0 {
  padding-top: 0 !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

/* bottom padding */
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

/* right padding */
.pr-0 {
  padding-right: 0;
}

.pr-xs {
  padding-right: 10px;
}

.pr-sm {
  padding-right: 20px;
}

.pr-md {
  padding-right: 40px;
}

.pr-lg {
  padding-right: 80px;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-150 {
  padding-right: 150px;
}

/* left padding */
.pl-0 {
  padding-left: 0;
}

.pl-30 {
  padding-left: 30px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-150 {
  padding-left: 150px;
}

/* Postions */
.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

/* Zindex*/
.zindex-1 {
  z-index: 1;
}

.zindex-2 {
  z-index: 2;
}

.zindex-3 {
  z-index: 3;
}

/* Borders */
.border-all {
  border: 1px solid var(--global--color-primary);
}

.border-top {
  border-top: 1px solid var(--global--color-primary);
}

.border-bottom {
  border-bottom: 1px solid var(--global--color-primary);
}

.border-right {
  border-right: 1px solid var(--global--color-primary);
}

.border-left {
  border-left: 1px solid var(--global--color-primary);
}

/* Display */
.inline {
  display: inline;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hide {
  display: none;
}

.flex {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .text-center-xs {
    text-align: center !important;
  }
  .pull-none-xs {
    float: none !important;
    text-align: center !important;
  }
  .mb-15-xs {
    margin-bottom: 15px;
  }
  .mb-30-xs {
    margin-bottom: 30px !important;
  }
  .mb-40-xs {
    margin-bottom: 40px !important;
  }
  .mb-50-xs {
    margin-bottom: 50px;
  }
  .mb-60-xs {
    margin-bottom: 60px !important;
  }
  .p-none-xs {
    padding-right: 0;
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-center-sm {
    text-align: center !important;
  }
  .mb-0-sm {
    margin-bottom: 0;
  }
  .mb-15-sm {
    margin-bottom: 15px;
  }
  .mb-30-sm {
    margin-bottom: 30px !important;
  }
  .mb-40-sm {
    margin-bottom: 40px;
  }
  .mb-50-sm {
    margin-bottom: 50px;
  }
  .mb-60-sm {
    margin-bottom: 60px;
  }
  .pb-15-sm {
    padding-bottom: 15px;
  }
  .pb-30-sm {
    padding-bottom: 30px;
  }
  .pb-50-sm {
    padding-bottom: 50px;
  }
  .pb-60-sm {
    padding-bottom: 60px;
  }
  .p-none-sm {
    padding-right: 0;
    padding-left: 0;
  }
  .pull-none-sm {
    float: none !important;
    text-align: center !important;
  }
}
.border-b {
  border-bottom: 1px solid var(--global--color-gray);
}

.mt--80 {
  margin-top: -80px;
}

.p-0 {
  padding: 0;
}

.pt-200 {
  padding-top: 200px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-55 {
  padding-top: 55px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-225 {
  padding-top: 225px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-260 {
  padding-top: 260px;
}

.pt-265 {
  padding-top: 265px;
}

.pt-300 {
  padding-top: 300px;
}

.pt-355 {
  padding-top: 355px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-200 {
  padding-bottom: 200px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mt--7 {
  margin-top: -7px;
}

.overflow-visible {
  overflow: visible;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

/*------------------------------------*\
    #Grid
\*------------------------------------*/
.row-no-padding [class*=col-] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.col-lg-5ths,
.col-md-5ths,
.col-sm-5ths,
.col-xs-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}
.col-content {
  padding: 120px 100px 110px 120px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .col-content {
    padding: 125px 18px 0 !important;
  }
}
/* Postion Helpers */
.pos-fixed {
  position: fixed;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.pos-static {
  position: static;
}

.pos-top {
  top: 0;
}

.pos-bottom {
  bottom: 0;
}

.pos-right {
  right: 0;
}

.pos-left {
  left: 0;
}

.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.height-700 {
  height: 700px !important;
}

.height-500 {
  height: 500px !important;
}

.height-800 {
  height: 800px !important;
}

@media only screen and (min-width: 992px) {
  .hidden-lg,
  .hidden-md {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .col-lg-5ths {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/*------------------------------------*\
    #NOTE
\*------------------------------------*/
@media screen and (max-width:991.98px) {
  .note {
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
.note p {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 15px;
  color: var(--global--color-body);
  line-height: 25px;
  margin-bottom: 0;
}
.note p a {
  position: relative;
  font-weight: 700;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
}
.note p a:hover i {
  transform: translateX(5px);
}
.note p a i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background: var(--global--color-white);
  border-radius: 50%;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  font-size: 9px;
}
.note.note-light p {
  color: #f4f4f4;
}
.note.note-light p a {
  color: var(--global--color-primary);
}
.note.note-light p a i {
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
}
.note.note-line p a {
  position: relative;
}
.note.note-line p a:hover::before {
  width: 80%;
}
.note.note-line p a::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  height: 2px;
  width: 100%;
}
.note.note-light-2 p {
  color: #c6c6c6;
}
.note.note-light-2 p a {
  color: var(--global--color-primary);
}
.note.note-light-2 p a::before {
  background-color: var(--global--color-primary);
}
.note.note-light-2 p a i {
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
}
.note.note-light-3 p {
  color: #f9f9f9;
}
.note.note-light-3 p a {
  color: var(--global--color-heading);
}
.note.note-light-3 p a::before {
  background-color: var(--global--color-heading);
}
.note.note-light-3 p a i {
  background-color: var(--global--color-heading);
  color: var(--global--color-primary);
}
.note.note-light-4 p {
  color: #f9f9f9;
  font-weight: 400;
}
.note.note-light-4 p a {
  color: var(--global--color-primary);
  font-weight: 700;
}
.note.note-light-4 p a::before {
  background-color: var(--global--color-primary);
}
.note.note-light-4 p a i {
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
}

/*------------------------------------*\
    #Blockquotes
\*------------------------------------*/
.blockquote,
blockquote {
  font-size: 20px;
  font-family: var(--global--font-heading);
  color: var(--global--color-body);
  font-style: normal;
  line-height: 28px;
  font-weight: 500;
  padding: 30px 0 30px 30px;
  position: relative;
  border-left: 6px solid var(--global--color-heading);
}
.blockquote .quote-author,
blockquote .quote-author {
  font-family: var(--global--font-heading);
  display: block;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  color: var(--global--color-heading);
  margin-top: 18px;
}
.blockquote .author,
blockquote .author {
  font-family: var(--global--font-body);
  display: block;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  line-height: 1;
  color: var(--global--color-heading);
  margin-top: 18px;
}

.blockquote-1 {
  border-left: 6px solid var(--global--color-heading);
}

.blockquote-2 {
  position: relative;
  border-left: none;
}
.blockquote-2::before, .blockquote-2::after {
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  position: absolute;
  color: var(--global--color-primary);
  line-height: 1.8;
  font-weight: 600;
}
.blockquote-2::before {
  content: "\f10d";
  top: 0;
  left: 0;
}
.blockquote-2::after {
  content: "\f10e";
  right: 0;
  bottom: 0;
}

.blockquote-3 {
  background-color: var(--global--color-heading);
}
.blockquote-3 .author {
  color: var(--global--color-body);
}

/*------------------------------------*\
    #BACKGROUNDS
\*------------------------------------*/
.bg-theme {
  background-color: var(--global--color-primary) !important;
}

.bg-gray {
  background-color: #f1f5fa !important;
}

.bg-dark-gray {
  background-color: #3a3a3a;
}

.bg-dark {
  background-color: #222222 !important;
}

/* Background Image */
.bg-section {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.bg-parallax {
  background-attachment: fixed;
}

.bg-overlay:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.bg-overlay-2:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-light:before {
  background-color: var(--global--color-white);
  opacity: 0.8;
}

.bg-overlay-dark:before {
  background-color: rgba(27, 26, 26, 0.149);
}

.bg-overlay-dark-2:before {
  background-image: linear-gradient(90deg, rgba(37, 55, 69, 0) 0%, rgba(22, 22, 22, 0.04) 3%, rgb(22, 22, 22) 80%);
}

.bg-overlay-dark-3:before {
  background-image: linear-gradient(180deg, rgba(37, 55, 69, 0) 0%, rgba(22, 22, 22, 0.84) 0%, rgb(22, 22, 22) 80%);
}

.bg-overlay-dark-4:before {
  background: linear-gradient(rgba(0, 0, 0, 0.877) 0%, rgba(0, 0, 0, 0.85) 6.74%, rgba(0, 0, 0, 0.744) 44.68%, rgba(23, 23, 23, 0.177) 100%);
}

.bg-overlay-dark-5:before {
  background: linear-gradient(rgba(0, 0, 0, 0.877) 0%, rgba(0, 0, 0, 0.85) 6.74%, rgba(0, 0, 0, 0.744) 44.68%, rgba(23, 23, 23, 0.277) 100%);
}

.bg-overlay-theme-dark:before {
  background: linear-gradient(rgba(29, 101, 185, 0) 0%, rgba(6, 52, 100, 0) 0%, rgba(6, 52, 100, 0) 16.43%, #063464 97.66%, #063464 100%);
}
.bg-overlay-theme-dark::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 52, 100, 0.95);
  z-index: 0;
}

.bg-overlay-dark-slider:before {
  background: linear-gradient(rgba(0, 0, 0, 0.3058823529) 0%, rgba(39, 38, 38, 0.5450980392) 6.74%, rgba(96, 96, 96, 0) 74.68%, rgba(71, 71, 71, 0.177) 100%);
}

.bg-overlay-dark-slider-2:before {
  background: linear-gradient(rgba(0, 0, 0, 0.877) 0%, rgba(0, 0, 0, 0.95) 6.74%, rgba(0, 0, 0, 0.744) 44.68%, rgba(23, 23, 23, 0.177) 100%);
}

.bg-overlay-dark-slider-3:before {
  background: linear-gradient(rgba(0, 0, 0, 0.877) 0%, rgba(0, 0, 0, 0.85) 6.74%, rgba(0, 0, 0, 0.744) 44.68%, rgba(23, 23, 23, 0.177) 100%);
}

.bg-overlay-video:before {
  background-image: linear-gradient(180deg, rgba(6, 23, 51, 0) 0%, rgba(29, 42, 77, 0) 62%, rgb(29, 42, 77) 97%);
}

/* Background Video */
.bg-video {
  position: relative;
}
.bg-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: rotateZ(0);
}
.bg-video h2 {
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  z-index: 5;
}

.no-shadow {
  box-shadow: none !important;
}

.bg-animate {
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: animatedBackground 30s linear infinite;
}

@-webkit-keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
/* Elements Background */
.el-bg {
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-bg h2 {
  color: var(--global--color-white);
}

/*------------------------------------*\
    #BREADCRUMB
\*------------------------------------*/
/* Breadcrumb Style 1 */
.breadcrumb > li {
  display: inline-flex;
  align-items: center;
}
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--global--font-body);
  position: relative;
  z-index: 2;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  border: none;
  width: 170px;
  height: 50px;
  transition: 0.3s ease-in-out;
  border-radius: 3px;
  color: var(--global--color-white);
  padding: 0 25px;
}
.btn.no-shadow {
  box-shadow: none;
}
.btn:focus, .btn.active, .btn:active {
  box-shadow: none;
  outline: none;
}
.btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
  border-radius: inherit;
}
.btn i {
  margin-left: 10px;
  font-size: 10px;
  transition: 0.3s ease-in-out;
}
.btn:hover::before {
  transform: scaleX(1);
  transform-origin: left center;
}

/* Button Primary */
.btn--primary {
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
}
.btn--primary i {
  color: var(--global--color-heading);
}
.btn--primary::before {
  background-color: var(--global--color-heading);
}
.btn--primary:active, .btn--primary:focus, .btn--primary:hover {
  color: var(--global--color-white);
}
.btn--primary:active i, .btn--primary:focus i, .btn--primary:hover i {
  color: var(--global--color-white);
}
.btn--primary.btn--inversed::before {
  background-color: var(--global--color-white);
}
.btn--primary.btn--inversed:active, .btn--primary.btn--inversed:focus, .btn--primary.btn--inversed:hover {
  color: var(--global--color-heading);
}
.btn--primary.btn--inversed:active i, .btn--primary.btn--inversed:focus i, .btn--primary.btn--inversed:hover i {
  color: var(--global--color-heading);
}

/* Button Secondary*/
.btn--secondary {
  background-color: var(--global--color-heading);
  color: var(--global--color-white);
}
.btn--secondary i {
  color: var(--global--color-white);
}
.btn--secondary::before {
  background-color: var(--global--color-primary);
}
.btn--secondary:active, .btn--secondary:focus, .btn--secondary:hover {
  color: var(--global--color-heading);
}
.btn--secondary:active i, .btn--secondary:focus i, .btn--secondary:hover i {
  color: var(--global--color-heading);
}
.btn--secondary.btn--inversed::before {
  background-color: var(--global--color-heading-darken);
}
.btn--secondary.btn--inversed:active, .btn--secondary.btn--inversed:focus, .btn--secondary.btn--inversed:hover {
  color: var(--global--color-white);
}
.btn--secondary.btn--inversed:active i, .btn--secondary.btn--inversed:focus i, .btn--secondary.btn--inversed:hover i {
  color: var(--global--color-heading);
}

/* Button White */
.btn--white {
  background-color: var(--global--color-white);
  color: var(--global--color-heading);
}
.btn--white::before {
  background-color: var(--global--color-heading);
}
.btn--white i {
  color: var(--global--color-heading);
}
.btn--white:active, .btn--white:focus, .btn--white:hover {
  color: var(--global--color-white);
}
.btn--white:active i, .btn--white:focus i, .btn--white:hover i {
  color: var(--global--color-white);
}

/* Button Transparent */
.btn--transparent {
  background-color: transparent;
  color: var(--global--color-white);
  border: 2px solid transparent;
}
.btn--transparent::before {
  content: unset;
}
.btn--transparent:active, .btn--transparent:focus, .btn--transparent:hover {
  color: var(--global--color-white);
}
.btn--transparent.btn--primary {
  border-color: var(--global--color-primary);
  color: var(--global--color-primary);
}
.btn--transparent.btn--primary:active, .btn--transparent.btn--primary:focus, .btn--transparent.btn--primary:hover {
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
}
.btn--transparent.btn--white {
  border-color: var(--global--color-white);
}
.btn--transparent.btn--white i {
  color: var(--global--color-white);
}
.btn--transparent.btn--white:active, .btn--transparent.btn--white:focus, .btn--transparent.btn--white:hover {
  color: var(--global--color-heading);
  border-color: var(--global--color-primary);
  background-color: var(--global--color-primary);
}
.btn--transparent.btn--white:active i, .btn--transparent.btn--white:focus i, .btn--transparent.btn--white:hover i {
  color: var(--global--color-heading-darken);
}

/* Buttons Custom */
.btn--danger {
  background-color: #dd3333;
  color: var(--global--color-white);
}
.btn--danger:active, .btn--danger:focus, .btn--danger:hover {
  background-color: var(--global--color-secondary);
  color: var(--global--color-white);
  border-color: var(--global--color-secondary);
}

.btn--success {
  background-color: #81d742;
  color: var(--global--color-white);
}
.btn--success:active, .btn--success:focus, .btn--success:hover {
  background-color: var(--global--color-secondary);
  color: var(--global--color-white);
  border-color: var(--global--color-secondary);
}

.btn--prime {
  background-color: #1e73be;
  color: var(--global--color-white);
}
.btn--prime:active, .btn--prime:focus, .btn--prime:hover {
  background-color: var(--global--color-secondary);
  color: var(--global--color-white);
  border-color: var(--global--color-secondary);
}

.btn--purble {
  background-color: #8224e3;
  color: var(--global--color-white);
}
.btn--purble:active, .btn--purble:focus, .btn--purble:hover {
  background-color: var(--global--color-secondary);
  color: var(--global--color-white);
  border-color: var(--global--color-secondary);
}

/* Button Fluid */
.btn--block {
  width: 100%;
}

/*Button Auto */
.btn--auto {
  width: auto;
  padding: 0 20px;
}

/* Button Rounded */
.btn--rounded {
  border-radius: 50px;
}

/* Buttons Shadow */
.btn-shadow {
  box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
}

.btn-shadow-hover:hover, .btn-shadow-hover:active, .btn-shadow-hover:focus {
  box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
}

/* Button Bordered */
.btn--bordered {
  background-color: var(--global--color-white);
  color: var(--global--color-white);
  border: 2px solid transparent;
}
.btn--bordered::before {
  content: unset;
}
.btn--bordered.btn--primary {
  color: var(--global--color-primary);
  border-color: var(--global--color-primary);
}
.btn--bordered.btn--primary:active, .btn--bordered.btn--primary:focus, .btn--bordered.btn--primary:hover {
  color: var(--global--color-white);
  background-color: var(--global--color-primary);
  border-color: var(--global--color-primary);
}
.btn--bordered.btn--secondary {
  color: var(--global--color-heading);
  border-color: #bcbcbc;
}
.btn--bordered.btn--secondary:active, .btn--bordered.btn--secondary:focus, .btn--bordered.btn--secondary:hover {
  color: var(--global--color-white);
  background-color: var(--global--color-heading);
  border-color: var(--global--color-heading);
}
.btn--bordered.btn--secondary-dark {
  color: var(--global--color-heading);
  border-color: var(--global--color-heading);
}
.btn--bordered.btn--secondary-dark i {
  color: var(--global--color-heading);
}
.btn--bordered.btn--secondary-dark:active, .btn--bordered.btn--secondary-dark:focus, .btn--bordered.btn--secondary-dark:hover {
  color: var(--global--color-white);
  background-color: var(--global--color-heading);
  border-color: var(--global--color-heading);
}
.btn--bordered.btn--secondary-dark:active i, .btn--bordered.btn--secondary-dark:focus i, .btn--bordered.btn--secondary-dark:hover i {
  color: var(--global--color-white);
}
.btn--bordered.btn--white {
  color: var(--global--color-white);
  border-color: var(--global--color-white);
  background: transparent;
}
.btn--bordered.btn--white:active, .btn--bordered.btn--white:focus, .btn--bordered.btn--white:hover {
  color: var(--global--color-primary);
  background-color: var(--global--color-white);
  border-color: var(--global--color-white);
}

.btn--slide :last-of-type {
  margin-left: -6px;
}

.btn--slide-wide {
  width: 270px;
}
@media screen and (max-width: 360px) {
  .btn--slide-wide {
    width: 240px;
  }
}

.btn--video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid var(--global--color-white);
}
.btn--video i {
  color: var(--global--color-white);
}

.btn--video-text {
  display: flex;
  align-items: center;
}
.btn--video-text .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: var(--global--color-white);
  border-radius: 50%;
  margin-right: 30px;
  position: relative;
}
.btn--video-text .icon-wrapper::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 1px solid var(--global--color-white);
  border-radius: 100%;
  transform: scale(1);
  opacity: 1;
  animation: pulse 1s ease-out 0s infinite;
  z-index: -1;
}
.btn--video-text span {
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 700;
  color: var(--global--color-white);
  text-transform: capitalize;
}

/* Buttons Sizes */
.btn-small {
  width: 170px;
  height: 41px;
  line-height: 41px;
}

.btn-super {
  width: 170px;
  height: 61px;
  line-height: 61px;
}

/*------------------------------------*\
    #FORMS
\*------------------------------------*/
form {
  position: relative;
}
form .col-12 {
  position: relative;
}

.form-control {
  color: var(--global--color-heading-light);
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  background-color: transparent;
  margin-bottom: 0;
  border: 0;
  padding: 0px;
  position: relative;
  box-shadow: none;
  padding-top: 14px;
  transition: all 0.3s ease-in-out;
}
.form-control:focus {
  border-color: var(--global--color-heading-light);
  box-shadow: none;
}
.form-control::-webkit-input-placeholder {
  color: var(--global--color-heading-light);
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}
.form-control:-moz-placeholder {
  color: var(--global--color-heading-light);
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}
.form-control:-ms-input-placeholder {
  color: var(--global--color-heading-light);
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}
.form-control.nice-select {
  width: 100%;
  display: flex;
  align-items: center;
}
.form-control.nice-select::after {
  width: 7px;
  height: 7px;
  border-color: #616161;
  right: 10px;
}
.form-control.nice-select.open .list {
  width: 100%;
}
.form-control.nice-select .current {
  text-transform: capitalize;
}
.form-control.nice-select .list li {
  text-transform: capitalize;
}
.form-control + .error {
  position: absolute;
  bottom: 11px;
  left: 17px;
  color: red;
}
.form-control.error {
  border-color: red;
}

.form-label {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  color: #616161;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 28px;
  transition: 0.3s ease-in-out;
  z-index: 5;
}

.form-label-inline {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 14px;
  color: var(--global--color-heading-light);
  margin-bottom: 13px;
}

.input-group,
.select-holder {
  position: relative;
  margin-bottom: 20px;
  height: 68px;
  padding: 20px;
  padding-left: 28px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: 2px solid #eaeaea;
}
.input-group:hover,
.select-holder:hover {
  border-color: var(--global--color-heading-light);
}
.input-group:hover .form-label,
.select-holder:hover .form-label {
  transform: translateY(-20px);
  font-size: 12px;
}
.input-group:hover .form-control,
.select-holder:hover .form-control {
  opacity: 1;
  visibility: visible;
}
.input-group .form-control,
.select-holder .form-control {
  opacity: 0;
  visibility: hidden;
}
.input-group .form-control + .error,
.select-holder .form-control + .error {
  bottom: -21px;
}

.input-group.textarea-holder {
  height: 160px;
}
.input-group.textarea-holder .form-label {
  top: 32px;
}
.input-group.textarea-holder textarea.form-control {
  height: 117px;
}

.select-holder {
  position: relative;
  display: flex;
  margin-bottom: 20px;
}
.select-holder:hover .form-control .current {
  opacity: 1;
  visibility: visible;
}
.select-holder .form-control {
  opacity: 1;
  visibility: visible;
  padding-top: 20px;
}
.select-holder .form-control .current {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}

.custom-radio-group-wrapper {
  margin-top: 17px;
}
.custom-radio-group-wrapper .custom-radio-group {
  display: flex;
  flex-wrap: wrap;
}
.custom-radio-group-wrapper .custom-radio-group .custom-control {
  position: relative;
  min-height: 0;
  margin-right: 20px;
  margin-bottom: 16px;
}
.custom-radio-group-wrapper .custom-radio-group .custom-control .custom-control-input {
  opacity: 0;
}
.custom-radio-group-wrapper .custom-radio-group .custom-control .custom-control-input:checked ~ label::before {
  border-color: var(--global--color-heading);
}
.custom-radio-group-wrapper .custom-radio-group .custom-control .custom-control-input:checked ~ label::after {
  background-color: var(--global--color-primary);
  opacity: 1;
  transform: scale(1);
}
.custom-radio-group-wrapper .custom-radio-group .custom-control label {
  font-size: 14px;
  font-family: var(--global--font-body);
  font-weight: 400;
  color: #616161;
  margin-bottom: 0;
  line-height: 1;
  text-transform: capitalize;
  cursor: pointer;
  padding-left: 10px;
}
.custom-radio-group-wrapper .custom-radio-group .custom-control label::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-radius: 3px;
  border: 2px solid #eaeaea;
  transition: 0.3s ease-in-out;
  width: 18px;
  height: 18px;
}
.custom-radio-group-wrapper .custom-radio-group .custom-control label::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 3px;
  left: 6px;
  top: 7px;
  background-color: transparent;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transform: scale(0);
}

textarea.form-control {
  height: 155px;
  padding-top: 18px;
}

.form-select {
  position: relative;
}
.form-select i {
  position: absolute;
  font-size: 19px;
  font-weight: 400;
  line-height: 49px;
  right: 20px;
  top: 0;
  z-index: 1;
  pointer-events: none;
  cursor: pointer;
  color: var(--global--color-body);
}
.form-select select {
  line-height: 1;
  color: var(--global--color-body);
  cursor: pointer;
  appearance: none;
}

/* Input Checkbox */
.input-checkbox {
  margin-bottom: 30px;
}
.input-checkbox p {
  display: block;
  position: relative;
  font-size: 13px;
  padding-left: 30px;
  line-height: 20px;
  text-align: left;
  margin-bottom: 0;
}

.label-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
  z-index: 2;
}
.label-checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.label-checkbox .check-indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 17px;
  width: 17px;
  background: transparent;
  border: 1px solid #e1e3e3;
  border-radius: 2px;
}
.label-checkbox .check-indicator:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid var(--global--color-primary);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.label-checkbox input:checked ~ .check-indicator {
  background: var(--global--color-white);
}
.label-checkbox input:checked ~ .check-indicator:after {
  display: block;
}

/*------------------------------------*\
    #Lists
\*------------------------------------*/
ul.list-icons {
  padding: 0;
  font-size: 14px;
  list-style-type: none;
}
ul.list-icons li {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  position: relative;
}
ul.list-icons li i {
  font-size: 14px;
  color: var(--global--color-heading);
  margin-right: 10px;
}

@media screen and (max-width:991.98px) {
  .advantages-list-wrapper {
    text-align: center;
  }
}
@media screen and (max-width:991.98px) and (max-width:991.98px) {
  .advantages-list-wrapper .advantages-list {
    display: inline-grid;
  }
}

.advantages-list {
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
.advantages-list li {
  display: flex;
  align-items: center;
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 15px;
  line-height: 36px;
  color: var(--global--color-body);
  text-transform: capitalize;
  position: relative;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .advantages-list li {
    font-size: 14px;
  }
}
.advantages-list li i {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: var(--global--color-primary);
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 10px;
  color: var(--global--color-white);
}
.advantages-list.advantages-list-white li {
  color: #f9f9f9;
}
.advantages-list.advantages-list-white li i {
  color: var(--global--color-heading);
}
.advantages-list.advantages-list-white-2 li {
  color: #9b9b9b;
}
.advantages-list.advantages-list-white-2 li i {
  color: var(--global--color-white);
}
.advantages-list.advantages-list-2 li {
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 17px;
  border-bottom: 1px solid #404040;
}
.advantages-list.advantages-list-2 li:first-child {
  padding-top: 0;
}
.advantages-list.advantages-list-2 li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.advantages-list.advantages-list-2 li i {
  width: 26px;
  height: 26px;
  margin-right: 20px;
}
.advantages-list.advantages-list-2.advantages-list-dark li {
  color: var(--global--color-heading-light);
  border-color: #eaeaea;
}
.advantages-list.advantages-list-2.advantages-list-dark li i {
  color: var(--global--color-heading);
}
.advantages-list.advantages-list-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
}
@media screen and (max-width:991.98px) {
  .advantages-list.advantages-list-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width:767.98px) {
  .advantages-list.advantages-list-3 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.advantages-list.advantages-list-3 li {
  background-color: #f4f4f4;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out;
  font-size: 16px;
  color: var(--global--color-heading-light);
  padding: 24px;
}
.advantages-list.advantages-list-3 li:hover {
  background-color: var(--global--color-white);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.19);
  border-color: #282828;
}
.advantages-list.advantages-list-3 li i {
  width: 36px;
  height: 36px;
  margin-right: 15px;
  color: var(--global--color-heading);
}
.advantages-list.advantages-list-4 {
  padding-top: 40px;
  border-top: 2px solid #404040;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
@media screen and (max-width:767.98px) {
  .advantages-list.advantages-list-4 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.advantages-list.advantages-list-4 li {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media screen and (max-width:767.98px) {
  .advantages-list.advantages-list-4 li {
    flex-direction: row;
  }
}
.advantages-list.advantages-list-4 li:last-child .list-body {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.advantages-list.advantages-list-4 li i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: var(--global--color-primary);
  flex-shrink: 0;
  border-radius: 50%;
  color: var(--global--color-heading);
  margin-bottom: 23px;
}
@media screen and (max-width: 424.98px) {
  .advantages-list.advantages-list-4 li i {
    width: 40px;
    height: 40px;
  }
}
.advantages-list.advantages-list-4 li .list-body h3 {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: var(--global--color-white);
  margin-bottom: 16px;
}
.advantages-list.advantages-list-4 li .list-body p {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #f9f9f9;
  margin-bottom: 0;
}
.advantages-list.advantages-list-5 li {
  font-size: 14px;
  font-weight: 400;
  color: #f9f9f9;
}
.advantages-list.advantages-list-5 li i {
  width: auto;
  height: auto;
  margin-right: 10px;
  color: var(--global--color-heading-light);
  background-color: transparent;
}
.advantages-list.advantages-list-6 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
}
@media screen and (max-width:991.98px) {
  .advantages-list.advantages-list-6 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width:767.98px) {
  .advantages-list.advantages-list-6 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.advantages-list.advantages-list-6 li {
  background-color: #f4f4f4;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out;
  font-size: 16px;
  color: var(--global--color-heading-light);
  padding: 24px;
}
.advantages-list.advantages-list-6 li:hover {
  background-color: var(--global--color-white);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.19);
  border-color: #282828;
}
.advantages-list.advantages-list-6 li i {
  width: 36px;
  height: 36px;
  margin-right: 15px;
  color: var(--global--color-heading);
}

/*------------------------------------*\
    #Tabs
\*------------------------------------*/
.tabs .tab-content > .tab-pane {
  font-family: var(--global--font-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.tabs-1 .nav-pills .nav-link.active,
.tabs-1 .nav-pills .show > .nav-link {
  color: var(--global--color-primary);
  border-color: var(--global--color-primary);
}
.tabs-1 .nav-pills {
  display: flex;
  width: 100%;
  justify-content: center;
}
.tabs-1 .nav-pills .nav-link {
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: var(--global--color-heading);
  font-family: var(--global--font-heading);
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  padding-top: 0;
  border-radius: 0;
  transition: 0.3s ease-in-out;
}
.tabs-1 .nav-pills .nav-link:hover {
  color: var(--global--color-primary);
  border-color: var(--global--color-primary);
}

.tabs-2 .nav-pills .nav-link.active,
.tabs-2 .nav-pills .show > .nav-link {
  color: var(--global--color-primary);
}
.tabs-2 .nav-pills .nav-link.active i,
.tabs-2 .nav-pills .show > .nav-link i {
  color: var(--global--color-white);
  background-color: var(--global--color-primary);
}
.tabs-2 .nav-pills {
  display: flex;
  width: 100%;
  justify-content: center;
}
.tabs-2 .nav-pills .nav-link {
  border: 0;
  background-color: transparent;
  color: var(--global--color-heading);
  font-family: var(--global--font-heading);
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  padding-top: 0;
  border-radius: 0;
  transition: 0.3s ease-in-out;
}
.tabs-2 .nav-pills .nav-link i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  font-size: 25px;
  margin-bottom: 15px;
}
.tabs-2 .nav-pills .nav-link:hover {
  color: var(--global--color-primary);
}
.tabs-2 .nav-pills .nav-link:hover i {
  color: var(--global--color-white);
  background-color: var(--global--color-primary);
}

/*------------------------------------*\
    #Accordion
\*------------------------------------*/
/* Accordion Base */
.accordion .accordion-holder {
  border-top: 6px solid var(--global--color-primary);
}
.accordion .card,
.accordion .card:first-of-type,
.accordion .card:not(:first-of-type):not(:last-of-type) {
  padding: 21px 0px 18px;
  box-shadow: none;
  border: 0;
  border-bottom: 2px solid #eaeaea;
  margin-bottom: 0px;
  border-radius: 0px;
  transition: 0.3s ease-in-out;
  text-align: left;
}
.accordion .card.active-acc .card-heading .card-link,
.accordion .card:first-of-type.active-acc .card-heading .card-link,
.accordion .card:not(:first-of-type):not(:last-of-type).active-acc .card-heading .card-link {
  color: var(--global--color-heading);
}
.accordion .card .card-heading,
.accordion .card:first-of-type .card-heading,
.accordion .card:not(:first-of-type):not(:last-of-type) .card-heading {
  padding-bottom: 4px;
}
.accordion .card .card-heading .card-link,
.accordion .card:first-of-type .card-heading .card-link,
.accordion .card:not(:first-of-type):not(:last-of-type) .card-heading .card-link {
  font-family: var(--global--font-body);
  font-size: 19px;
  font-weight: 700;
  color: var(--global--color-heading);
  text-transform: capitalize;
  position: relative;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
}
.accordion .card .card-heading .card-link:hover,
.accordion .card:first-of-type .card-heading .card-link:hover,
.accordion .card:not(:first-of-type):not(:last-of-type) .card-heading .card-link:hover {
  color: var(--global--color-heading);
}
.accordion .card .card-heading .card-link::before,
.accordion .card:first-of-type .card-heading .card-link::before,
.accordion .card:not(:first-of-type):not(:last-of-type) .card-heading .card-link::before {
  /* symbol for "opening" cards */
  content: "\f078";
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background: var(--global--color-secondary);
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 11px;
  margin-right: 20px;
  color: var(--global--color-heading);
  background-color: var(--global--color-primary);
}
.accordion .card .card-heading .card-link.collapsed::before,
.accordion .card:first-of-type .card-heading .card-link.collapsed::before,
.accordion .card:not(:first-of-type):not(:last-of-type) .card-heading .card-link.collapsed::before {
  /* symbol for "collapsed" cards */
  transform: rotate(-90deg);
  color: var(--global--color-white);
  background-color: var(--global--color-heading);
}
.accordion .card .card-body,
.accordion .card:first-of-type .card-body,
.accordion .card:not(:first-of-type):not(:last-of-type) .card-body {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  padding: 10px 40px 1px;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
}

/*------------------------------------*\
    #PAGINATION
\*------------------------------------*/
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 0;
}
.pagination li {
  margin-right: 10px;
  display: inline-block;
}
.pagination li:last-child {
  margin-right: 0px;
}
.pagination li a,
.pagination li span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--global--color-white);
  color: var(--global--color-heading);
  font-family: var(--global--font-body);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  width: 58px;
  height: 58px;
  border-radius: 3px;
  border: 2px solid #bcbcbc;
  transition: 300ms ease-in-out;
}
.pagination li a:hover,
.pagination li span:hover {
  background-color: var(--global--color-primary);
  border-color: var(--global--color-primary);
}
.pagination li a i,
.pagination li span i {
  font-size: 11px;
}
.pagination li a.current,
.pagination li span.current {
  background-color: var(--global--color-primary);
  border-color: var(--global--color-primary);
}

/*------------------------------------*\
    #ALERT MESSAGE
\*------------------------------------*/
.alerts {
  background-color: var(--global--color-gray);
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 30px;
}
.alerts .alert-icon {
  width: 46px;
  height: 46px;
  line-height: 46px;
  background-color: var(--global--color-primary);
  border-radius: 2px 1px 1px 2px;
  text-align: center;
  position: absolute;
}
.alerts .alert-icon i {
  color: var(--global--color-white);
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}
.alerts .alert-content {
  padding-left: 68px;
}
.alerts .alert-content h4 {
  color: var(--global--color-primary);
  font-size: 15px;
  font-weight: 800;
  line-height: 32px;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: -6px;
}
.alerts .alert-content p {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.alerts.alert-warning h4 {
  color: #8a6d3b;
}
.alerts.alert-warning .alert-icon {
  background-color: #fcf8e3;
}
.alerts.alert-warning .alert-icon i {
  color: #8a6d3b;
}
.alerts.alert-danger h4 {
  color: #a94442;
}
.alerts.alert-danger .alert-icon {
  background-color: #f2dede;
}
.alerts.alert-danger .alert-icon i {
  color: #a94442;
}

.alert {
  font-size: 16px;
  font-family: var(--global--font-body);
}

/* Subscribe Alert */
.subscribe-alert {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  margin: 0;
  cursor: pointer;
  transform: translate3d(0, 200%, 0);
  animation: alertfadein 0.35s ease 0.5s both;
}
.subscribe-alert .alert {
  margin: 0 auto;
  font-size: 18px;
  height: 60px;
  line-height: 60px;
  border: none;
  border-radius: 0;
  padding: 0 30px;
  color: var(--global--color-white);
}
.subscribe-alert .alert:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.25s ease;
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
}
.subscribe-alert .alert.alert-danger {
  background-color: #cc0000;
}
.subscribe-alert .alert.alert-success {
  background-color: #198c19;
}

@keyframes alertfadein {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: none;
  }
}
/* Contact Alert */
#contact-result .alert {
  margin: 10px auto;
  font-size: 12px;
}

/*------------------------------------*\
    #BACK TO TOP
\*------------------------------------*/
#back-to-top {
  z-index: 999;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 300ms ease-in-out;
}
#back-to-top span {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 14px;
  color: var(--global--color-white);
  text-transform: capitalize;
  margin-right: 20px;
  transition: 0.3s ease-in-out;
}
#back-to-top:hover::before {
  width: 100%;
  height: 100%;
}

/*------------------------------------*\
    #POPUP
\*------------------------------------*/
.mfp-bg {
  z-index: 1543;
}

.mfp-wrap {
  z-index: 1642;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}

/*------------------------------------*\
    #Page Loading
\*------------------------------------*/
.preloader {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 99999;
  background-color: var(--global--color-white);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preloader .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}
.preloader .dot1,
.preloader .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--global--color-heading);
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.preloader .dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/*------------------------------------*\
    #Header
\*------------------------------------*/
.header {
  height: 80px;
}
.header .navbar-brand {
  padding: 0;
  margin: 0;
  margin-right: 80px;
  display: flex;
  align-items: center;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .header .navbar-brand {
    margin-right: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .header .navbar-brand {
    margin-right: 30px;
  }
}
@media screen and (min-width:992px) {
  .header .navbar-brand {
    height: 110px;
  }
}
@media screen and (max-width:991.98px) {
  .header .navbar-brand {
    height: 80px;
  }
}
.header .navbar-brand .logo {
  max-width: 100%;
  width: 150px;
  height: 40px;
}
.header .navbar-brand .logo.logo-mobile {
  width: auto;
  height: auto;
}
.header.header-light {
  background-color: var(--global--color-white);
}
.header.header-light .navbar {
  background-color: var(--global--color-white);
}
.header.header-light .navbar .navbar-brand .logo-dark {
  display: inline-block;
}
@media screen and (max-width:767.98px) {
  .header.header-light .navbar .navbar-brand .logo-dark {
    display: none;
  }
}
.header.header-light .navbar .navbar-brand .logo-light {
  display: none;
}
.header.header-light .navbar .navbar-brand .logo-mobile {
  display: none;
}
@media screen and (max-width:767.98px) {
  .header.header-light .navbar .navbar-brand .logo-mobile {
    display: inline-block;
  }
}
@media screen and (max-width:767.98px) {
  .header.header-light .navbar .navbar-brand .logo-mobile.logo-mobile-white {
    display: none;
  }
}
.header.header-light .navbar .navbar-nav > li > a {
  color: var(--global--color-heading);
}
.header.header-light .navbar .navbar-nav > li > a::before {
  background-color: var(--global--color-primary);
}
.header.header-white {
  background-color: var(--global--color-white);
  height: 110px;
}
@media screen and (max-width:991.98px) {
  .header.header-white {
    height: 80px;
  }
}
.header.header-white .navbar {
  background-color: var(--global--color-white);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.header.header-white .navbar .navbar-brand .logo-dark {
  display: inline-block;
}
@media screen and (max-width:767.98px) {
  .header.header-white .navbar .navbar-brand .logo-dark {
    display: none;
  }
}
.header.header-white .navbar .navbar-brand .logo-light {
  display: none;
}
.header.header-white .navbar .navbar-brand .logo-mobile {
  display: none;
}
@media screen and (max-width:767.98px) {
  .header.header-white .navbar .navbar-brand .logo-mobile {
    display: inline-block;
  }
}
@media screen and (max-width:767.98px) {
  .header.header-white .navbar .navbar-brand .logo-mobile.logo-mobile-white {
    display: none;
  }
}
.header.header-white .navbar .navbar-nav > li > a {
  color: var(--global--color-heading);
}
.header.header-white .navbar .navbar-nav > li > a::before {
  background-color: var(--global--color-primary);
}
.header.header-white .navbar .module.module-cart {
  border-color: #eaeaea;
}
.header.header-transparent {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
@media screen and (min-width:992px) {
  .header.header-transparent {
    height: 110px;
  }
}
.header.header-transparent .navbar {
  background-color: transparent;
}
.header.header-transparent .navbar .navbar-brand .logo-dark {
  display: none;
}
.header.header-transparent .navbar .navbar-brand .logo-light {
  display: inline-block;
}
@media screen and (max-width:767.98px) {
  .header.header-transparent .navbar .navbar-brand .logo-light {
    display: none;
  }
}
.header.header-transparent .navbar .navbar-brand .logo-mobile {
  display: none;
}
@media screen and (max-width:767.98px) {
  .header.header-transparent .navbar .navbar-brand .logo-mobile {
    display: inline-block;
  }
}
@media screen and (max-width:767.98px) {
  .header.header-transparent .navbar .navbar-brand .logo-mobile.logo-mobile-dark {
    display: none;
  }
}
@media screen and (min-width:992px) {
  .header.header-transparent .navbar .navbar-nav > li > a {
    color: var(--global--color-white);
  }
}
.header.header-transparent .navbar .navbar-nav > li > a::before {
  background: var(--global--color-primary);
}
.header.header-transparent .navbar .navbar-nav > li.has-dropdown > a::after {
  color: var(--global--color-white);
}
.header.header-transparent .navbar .module .module-icon i,
.header.header-transparent .navbar .module .module-icon svg {
  color: var(--global--color-white);
}
.header.header-transparent .navbar .module .module-icon i:hover,
.header.header-transparent .navbar .module .module-icon svg:hover {
  color: var(--global--color-primary);
}
.header.header-transparent .navbar .module.module-phone .module-body a {
  color: var(--global--color-white);
}
.header.header-transparent .navbar .module.module-phone:hover .module-icon svg {
  color: var(--global--color-primary);
}
.header.header-transparent .navbar .module.module-phone:hover .module-body a {
  color: var(--global--color-primary);
}
.header.header-transparent .navbar .module-holder-phone .navbar-toggler .navbar-toggler-icon {
  background-color: var(--global--color-white);
}
.header.header-transparent .navbar .module-holder-phone .navbar-toggler .navbar-toggler-icon::before, .header.header-transparent .navbar .module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
  background-color: var(--global--color-white);
}
.header.header-transparent .navbar .module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-color: transparent;
}
.header.header-transparent .navbar.navbar-fixed .navbar-brand .logo-dark {
  display: none;
}
@media screen and (min-width:768px) {
  .header.header-transparent .navbar.navbar-fixed .navbar-brand .logo-dark {
    display: inline-block;
  }
}
@media screen and (max-width:767.98px) {
  .header.header-transparent .navbar.navbar-fixed .navbar-brand .logo-mobile.logo-mobile-white {
    display: none;
  }
  .header.header-transparent .navbar.navbar-fixed .navbar-brand .logo-mobile.logo-mobile-dark {
    display: inline-block;
  }
}
.header.header-transparent .navbar.navbar-fixed .navbar-nav > li.has-dropdown > a::after {
  color: var(--global--color-heading);
}
.header.header-transparent .navbar.navbar-fixed .navbar-nav > li.has-dropdown.active > a::after, .header.header-transparent .navbar.navbar-fixed .navbar-nav > li.has-dropdown:hover > a::after {
  color: var(--global--color-primary);
}
.header.header-transparent .navbar.navbar-fixed .module .module-icon i,
.header.header-transparent .navbar.navbar-fixed .module .module-icon svg {
  color: var(--global--color-heading);
}
.header.header-transparent .navbar.navbar-fixed .module .module-icon i:hover,
.header.header-transparent .navbar.navbar-fixed .module .module-icon svg:hover {
  color: var(--global--color-primary);
}
.header.header-transparent .navbar.navbar-fixed .module.module-phone .module-body a {
  color: var(--global--color-heading);
}
.header.header-transparent .navbar.navbar-fixed .module.module-phone:hover .module-icon svg {
  color: var(--global--color-primary);
}
.header.header-transparent .navbar.navbar-fixed .module.module-phone:hover .module-body a {
  color: var(--global--color-primary);
}
.header.header-transparent .navbar.navbar-fixed .module-holder-phone .navbar-toggler .navbar-toggler-icon {
  background-color: var(--global--color-heading);
}
.header.header-transparent .navbar.navbar-fixed .module-holder-phone .navbar-toggler .navbar-toggler-icon::before, .header.header-transparent .navbar.navbar-fixed .module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
  background-color: var(--global--color-heading);
}
.header.header-transparent .navbar.navbar-fixed .module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-color: transparent;
}
.header.header-transparent-2 {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
@media screen and (min-width:992px) {
  .header.header-transparent-2 {
    height: 110px;
  }
}
.header.header-transparent-2 .navbar {
  background-color: transparent;
  padding-left: 0;
}
@media screen and (max-width:991.98px) {
  .header.header-transparent-2 .navbar {
    padding-right: 0;
    position: relative;
  }
}
.header.header-transparent-2 .navbar .navbar-brand {
  width: 230px;
  background-color: var(--global--color-white);
  justify-content: center;
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .header.header-transparent-2 .navbar .navbar-brand {
    width: 200px;
  }
}
@media screen and (max-width:991.98px) {
  .header.header-transparent-2 .navbar .navbar-brand {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width:767.98px) {
  .header.header-transparent-2 .navbar .navbar-brand {
    width: 80px;
  }
}
.header.header-transparent-2 .navbar .navbar-brand .logo-dark {
  display: none;
}
@media screen and (min-width:768px) {
  .header.header-transparent-2 .navbar .navbar-brand .logo-dark {
    display: inline-block;
  }
}
.header.header-transparent-2 .navbar .navbar-brand .logo-light {
  display: none;
}
.header.header-transparent-2 .navbar .navbar-brand .logo-mobile {
  display: none;
}
@media screen and (max-width:767.98px) {
  .header.header-transparent-2 .navbar .navbar-brand .logo-mobile {
    display: inline-block;
  }
}
.header.header-transparent-2 .navbar .navbar-brand .logo-mobile.logo-mobile-white {
  display: none;
}
@media screen and (max-width:767.98px) {
  .header.header-transparent-2 .navbar .navbar-brand .logo-mobile.logo-mobile-dark {
    display: block;
  }
}
@media screen and (min-width:992px) {
  .header.header-transparent-2 .navbar .navbar-nav > li {
    margin-right: 20px;
  }
}
@media screen and (min-width: 1400px) {
  .header.header-transparent-2 .navbar .navbar-nav > li {
    margin-right: 20px;
  }
}
@media screen and (min-width:992px) {
  .header.header-transparent-2 .navbar .navbar-nav > li > a {
    color: var(--global--color-white);
  }
}
.header.header-transparent-2 .navbar .navbar-nav > li > a::before {
  bottom: auto;
  top: 0;
  background: var(--global--color-primary);
}
.header.header-transparent-2 .navbar .navbar-nav > li.has-dropdown > a::after {
  color: var(--global--color-white);
}
.header.header-transparent-2 .navbar .module .module-icon i,
.header.header-transparent-2 .navbar .module .module-icon svg {
  color: var(--global--color-white);
}
.header.header-transparent-2 .navbar .module .module-icon i:hover,
.header.header-transparent-2 .navbar .module .module-icon svg:hover {
  color: var(--global--color-primary);
}
.header.header-transparent-2 .navbar .module.module-phone .module-body a {
  color: var(--global--color-white);
}
.header.header-transparent-2 .navbar .module.module-phone:hover .module-icon svg {
  color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar .module.module-phone:hover .module-body a {
  color: var(--global--color-white);
}
@media screen and (max-width:991.98px) {
  .header.header-transparent-2 .navbar .module-holder-phone {
    padding-right: 40px;
  }
}
@media screen and (max-width:767.98px) {
  .header.header-transparent-2 .navbar .module-holder-phone {
    padding-right: 20px;
  }
}
.header.header-transparent-2 .navbar .module-holder-phone .navbar-toggler .navbar-toggler-icon {
  background-color: var(--global--color-white);
}
.header.header-transparent-2 .navbar .module-holder-phone .navbar-toggler .navbar-toggler-icon::before, .header.header-transparent-2 .navbar .module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
  background-color: var(--global--color-white);
}
.header.header-transparent-2 .navbar .module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-color: transparent;
}
@media screen and (max-width: 400px) {
  .header.header-transparent-2 .navbar .module-holder-phone .module-search,
  .header.header-transparent-2 .navbar .module-holder-phone .module-cart {
    display: none;
  }
}
.header.header-transparent-2 .navbar.navbar-fixed .navbar-nav > li.has-dropdown > a::after {
  color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar.navbar-fixed .navbar-nav > li.has-dropdown.active > a::after, .header.header-transparent-2 .navbar.navbar-fixed .navbar-nav > li.has-dropdown:hover > a::after {
  color: var(--global--color-primary);
}
.header.header-transparent-2 .navbar.navbar-fixed .module .module-icon i,
.header.header-transparent-2 .navbar.navbar-fixed .module .module-icon svg {
  color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar.navbar-fixed .module .module-icon i:hover,
.header.header-transparent-2 .navbar.navbar-fixed .module .module-icon svg:hover {
  color: var(--global--color-primary);
}
.header.header-transparent-2 .navbar.navbar-fixed .module.module-phone .module-icon {
  border-color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar.navbar-fixed .module.module-phone .module-body a {
  color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar.navbar-fixed .module.module-phone:hover .module-icon {
  background-color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar.navbar-fixed .module.module-phone:hover .module-icon svg {
  color: var(--global--color-white);
}
.header.header-transparent-2 .navbar.navbar-fixed .module.module-phone:hover .module-body a {
  color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar.navbar-fixed .module-holder-phone .navbar-toggler .navbar-toggler-icon {
  background-color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar.navbar-fixed .module-holder-phone .navbar-toggler .navbar-toggler-icon::before, .header.header-transparent-2 .navbar.navbar-fixed .module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
  background-color: var(--global--color-heading);
}
.header.header-transparent-2 .navbar.navbar-fixed .module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-color: transparent;
}
.header.header-topbar {
  height: 80px;
}
@media screen and (min-width:992px) {
  .header.header-topbar {
    height: 160px;
  }
}
@media screen and (max-width:991.98px) {
  .header.header-topbar .top-bar {
    display: none;
  }
}
.header.header-topbar.header-shadow .navbar {
  box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11);
}
@media screen and (min-width:1200px) {
  .header.header-topbar .navbar .navbar-nav > li {
    margin-right: 35px;
  }
  .header.header-topbar .navbar .navbar-nav > li:last-child {
    margin-right: 0;
  }
}
.header.header-topbar .navbar .navbar-nav > li.has-dropdown > a::after {
  color: var(--global--color-heading);
}
.header.header-topbar .navbar .navbar-nav > li.has-dropdown.active > a::after, .header.header-topbar .navbar .navbar-nav > li.has-dropdown:hover > a::after {
  color: var(--global--color-primary);
}
.header.header-topbar .navbar .module-holder .module-search {
  margin-right: 30px;
}
@media screen and (min-width:992px) {
  .header.header-topbar .navbar .module-holder .module-contact {
    margin-right: 30px;
  }
}
.header.header-topbar .navbar .module-holder .module-cart {
  padding-right: 0;
  margin-right: 0;
}
.header.header-topbar .navbar .module-holder .module-cart .module-cart-warp {
  left: -300px;
}

/*------------------------------------*\
    #Menu
\*------------------------------------*/
.header .navbar {
  margin-bottom: 0;
  border-radius: 0;
  border: none;
  z-index: 7777;
  padding: 0;
  transition: all 300ms ease-in-out;
  padding: 0 40px;
}
@media screen and (min-width:992px) {
  .header .navbar {
    min-height: 110px;
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .header .navbar {
    padding: 0px 20px;
  }
}
.header .navbar.navbar-bordered {
  border-bottom: 1px solid rgba(234, 234, 234, 0.149);
}
.header .navbar .navbar-nav > li {
  margin-right: 30px;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1400px) {
  .header .navbar .navbar-nav > li {
    margin-right: 15px;
  }
}
.header .navbar .navbar-nav > li:last-child {
  margin-right: 0;
}
.header .navbar .navbar-nav > li > a {
  font-family: var(--global--font-body);
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  color: var(--global--color-heading);
  margin-bottom: 0px;
  text-transform: capitalize;
  line-height: 110px;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
}
.header .navbar .navbar-nav > li > a::before {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 4px;
  background: var(--global--color-primary);
  content: "";
  transition: transform 1s cubic-bezier(0.2, 1, 0.3, 1);
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
}
.header .navbar .navbar-nav > li.has-dropdown > a {
  position: relative;
}
.header .navbar .navbar-nav > li.has-dropdown > a::after {
  border: 0;
  content: "\f078";
  position: relative;
  margin-left: 7px;
  font-size: 11px;
  font-family: "Font Awesome 5 Free";
  color: var(--global--font-heading);
  transition: 0.3s ease-in-out;
}
.header .navbar .navbar-nav > li.has-dropdown.active > a::after, .header .navbar .navbar-nav > li.has-dropdown:hover > a::after {
  color: var(--global--color-primary);
}
.header .navbar .navbar-nav > li.single-link.active > a, .header .navbar .navbar-nav > li.single-link:hover > a {
  color: var(--global--color-primary);
}
@media screen and (min-width:1200px) {
  .header .navbar .navbar-nav > li.single-link.active > a::before, .header .navbar .navbar-nav > li.single-link:hover > a::before {
    transform: scale3d(1, 1, 1);
    transform-origin: 0 50%;
    background-color: var(--global--color-primary);
  }
}
.header .navbar .navbar-nav > li.active > .dropdown-toggle, .header .navbar .navbar-nav > li:hover .dropdown-toggle {
  color: var(--global--color-primary);
}
.header .navbar .navbar-nav > li.active > .dropdown-toggle::before, .header .navbar .navbar-nav > li:hover .dropdown-toggle::before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0 50%;
}
.header .navbar .navbar-nav > li.active > .dropdown-toggle::after, .header .navbar .navbar-nav > li:hover .dropdown-toggle::after {
  color: var(--global--color-primary);
}
.header .navbar.navbar-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: var(--global--color-white);
  animation: fadeInDown 1s ease-in-out;
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
}
@media screen and (max-width:991.98px) {
  .header .navbar.navbar-fixed {
    border-color: var(--global--color-white);
  }
}
.header .navbar.navbar-fixed.navbar-bordered {
  border-bottom: 0;
}
@media screen and (min-width:992px) {
  .header .navbar.navbar-fixed .navbar-brand .logo-dark {
    display: inline-block;
  }
}
.header .navbar.navbar-fixed .navbar-brand .logo-light {
  display: none;
}
.header .navbar.navbar-fixed .navbar-nav > li > a {
  color: var(--global--color-heading);
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.navbar-nav > li.active > ul {
  margin-top: 0;
}

li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu > a:before,
ul.dropdown-menu > li.has-dropdown > a:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  content: "\f105";
  font-size: 13px;
  position: absolute;
  right: 25px;
}

/* Menu Levels */
.dropdown-menu {
  background-color: transparent;
  position: relative;
  width: 100%;
  border: none;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}

@media screen and (min-width:992px) {
  .navbar.navbar-dark {
    background-color: var(--global--color-heading);
  }
}
.navbar.navbar-dark.navbar-fixed .module-social a {
  color: var(--global--color-white);
}
.navbar.navbar-dark.navbar-fixed .module-social a:hover {
  color: var(--global--color-primary);
}
.navbar.navbar-dark .module .module-icon i {
  color: var(--global--color-white);
}
@media screen and (min-width:992px) {
  .navbar.navbar-dark .navbar-nav > li > a {
    color: var(--global--color-white);
  }
  .navbar.navbar-dark .navbar-nav > li > a:hover {
    color: var(--global--color-primary);
  }
  .navbar.navbar-dark .navbar-nav > li.active > a, .navbar.navbar-dark .navbar-nav > li:hover > a {
    color: var(--global--color-white);
  }
}

/*------------------------------------*\
    #Menu
\*------------------------------------*/
/* Small Devices, Tablets */
@media only screen and (max-width:991.98px) {
  .header .navbar {
    padding: 0 40px;
  }
  /* Navabar */
  /* Header Modifers */
}
@media only screen and (max-width:991.98px) and (max-width:767.98px) {
  .header .navbar {
    padding: 0 20px;
  }
}
@media only screen and (max-width:991.98px) {
  .header .navbar .navbar-brand {
    margin-right: 0;
  }
  .header .navbar .navbar-brand .logo-light {
    display: none;
  }
  .header .navbar .navbar-brand .logo-phone {
    width: auto;
  }
  .header .navbar .navbar-collapse {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    max-height: calc(100vh - 80px);
    background-color: var(--global--color-white);
    padding: 13px 30px 30px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s linear;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .header .navbar .navbar-collapse::-webkit-scrollbar {
    height: 7px;
    width: 4px;
  }
  .header .navbar .navbar-collapse::-webkit-scrollbar-thumb {
    margin-right: 4px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    cursor: pointer;
  }
  .header .navbar .navbar-collapse.show {
    visibility: visible;
    opacity: 1;
  }
  .header .navbar.navbar-bordered .navbar-collapse {
    top: calc(100% + 1px);
  }
  .header .navbar {
    /* Mega Menu Content */
  }
  .header .navbar .navbar-nav > li {
    margin-right: 0px;
    border-bottom: 1px solid rgba(234, 234, 234, 0.5);
  }
  .header .navbar .navbar-nav > li > a, .header .navbar .navbar-nav > li.active > a {
    line-height: 45px;
    color: var(--global--color-heading);
    display: block;
    z-index: 5;
  }
  .header .navbar .navbar-nav > li > a::before, .header .navbar .navbar-nav > li > a::after, .header .navbar .navbar-nav > li.active > a::before, .header .navbar .navbar-nav > li.active > a::after {
    display: none;
  }
  .header .navbar .menu-item::before {
    display: none;
  }
  .header .navbar .has-dropdown > .dropdown-menu {
    transition: 0.3s ease-in-out;
  }
  .header .navbar .has-dropdown::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    content: "\f054";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 13px;
    color: var(--global--color-heading);
    transition: 0.3s ease-in-out;
  }
  .header .navbar .has-dropdown:hover::after, .header .navbar .has-dropdown.active::after {
    color: var(--global--color-primary);
  }
  .header .navbar .has-dropdown.show::after {
    transform: rotate(90deg);
  }
  .header .navbar .dropdown-menu.show {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    border-radius: 0;
  }
  .header .navbar .dropdown-menu li {
    line-height: 26px;
  }
  .header .navbar .dropdown-menu li.current a {
    background-color: var(--global--color-primary);
    color: var(--global--color-heading);
  }
  .header .navbar .dropdown-menu li a {
    color: var(--global--color-heading);
    font-size: 15px;
    font-weight: 500;
    display: block;
    padding: 4px 15px;
    margin-bottom: 5px;
    text-transform: capitalize;
    border-radius: 3px;
  }
  .header .navbar .dropdown-menu li a:hover {
    background-color: var(--global--color-primary);
    color: var(--global--color-heading);
  }
  .header .navbar .show > .dropdown-menu {
    display: block;
  }
  .header .navbar .dropdown-submenu.show .dropdown-menu {
    display: block;
  }
  .header .navbar .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
  }
  .header .navbar li.has-dropdown.mega-dropdown > .dropdown-menu {
    padding-left: 15px;
  }
}
@media only screen and (max-width:991.98px) and (max-width:767.98px) {
  .header .navbar li.has-dropdown.mega-dropdown > .dropdown-menu .row div {
    margin-bottom: 20px;
  }
  .header .navbar li.has-dropdown.mega-dropdown > .dropdown-menu .row div:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (max-width:991.98px) {
  .header .navbar li.has-dropdown.mega-dropdown > .dropdown-menu .row .menu-head {
    font-size: 14px;
    font-family: var(--global--font-heading);
    font-weight: 700;
    color: var(--global--font-heading);
    text-transform: capitalize;
    margin-bottom: 15px;
  }
  .navbar.navbar-light .navbar-nav > li > a {
    color: var(--global--color-heading);
  }
  .navbar-light:not(.navbar-fixed) .navbar-toggler {
    color: var(--global--color-white);
    border-color: var(--global--color-white);
  }
  .navbar-light:not(.navbar-fixed) .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,225, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .navbar-dark .navbar-toggler,
  .navbar-light.navbar-fixed .navbar-toggler {
    color: #222;
    border-color: #222;
  }
  .navbar-dark .navbar-toggler-icon,
  .navbar-light.navbar-fixed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(34,34,34, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}
/*------------------------------------*\
    #Menu
\*------------------------------------*/
@media only screen and (min-width:992px) {
  .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
  /* Menu with Dropdown */
  .navbar-nav {
    /* Mega Menu Content */
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu {
    box-shadow: 0px 5px 41.5px rgba(40, 40, 40, 0.11);
    transition: all 300ms linear;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0px;
    right: auto;
    transform-origin: left top;
    transform: translateY(20px);
    display: block;
    width: 235px;
    padding: 24px 40px 25px;
    background-color: var(--global--color-white);
    border-radius: 0px;
    box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu > li {
    padding-right: 0;
    position: relative;
    vertical-align: top;
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu li a {
    transition: 0.3s ease-in-out;
    position: relative;
    font-family: var(--global--font-body);
    color: var(--global--color-body);
    padding: 10px 0px 9px 0;
    display: block;
    font-size: 14px;
    font-weight: 400;
    transition: all 300ms ease-in-out;
    text-transform: capitalize;
    border-radius: 0;
    border-bottom: 1px solid #eaeaea;
    position: relative;
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu li a::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 1px;
    width: 0px;
    transition: 0.3s ease;
    background-color: var(--global--color-heading);
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:active, .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:focus, .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:hover {
    color: var(--global--color-heading);
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:active::after, .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:focus::after, .navbar-nav > li.has-dropdown > ul.dropdown-menu li a:hover::after {
    width: 100%;
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu li:last-child a {
    border-bottom: 0;
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu li:last-child a::after {
    content: unset;
  }
  .navbar-nav > li.has-dropdown > ul.dropdown-menu li.current > a {
    border-color: var(--global--color-heading);
    color: var(--global--color-heading);
  }
  .navbar-nav > li.has-dropdown:hover > ul.dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 100;
    display: block;
  }
  .navbar-nav li ul.dropdown-menu.left {
    left: -650px !important;
    margin-left: 0 !important;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu {
    background-color: var(--global--color-white);
    padding: 0;
    width: 480px;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu li {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row {
    background-color: var(--global--color-white);
    margin-right: 0;
    margin-left: 0;
    padding: 38px 23px 25px;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row .menu-head {
    font-size: 16px;
    font-family: var(--global--font-heading);
    font-weight: 700;
    color: var(--global--color-heading-light);
    text-transform: capitalize;
    margin-bottom: 13px;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul {
    margin: 0;
    padding: 0;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a {
    transition: 0.3s ease-in-out;
    position: relative;
    font-family: var(--global--font-body);
    color: var(--global--color-body);
    padding: 10px 0px 9px 0;
    display: block;
    font-size: 14px;
    font-weight: 400;
    transition: all 300ms ease-in-out;
    text-transform: capitalize;
    border-radius: 0;
    border-bottom: 1px solid #eaeaea;
    position: relative;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 1px;
    width: 0px;
    transition: 0.3s ease;
    background-color: var(--global--color-heading);
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a:active, .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a:focus, .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a:hover {
    color: var(--global--color-heading);
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a:active::after, .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a:focus::after, .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li a:hover::after {
    width: 100%;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li:last-child a {
    border-bottom: 0;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li:last-child a::after {
    content: unset;
  }
  .navbar-nav li.has-dropdown.mega-dropdown > .dropdown-menu .row ul li.current > a {
    border-color: var(--global--color-heading);
    color: var(--global--color-heading);
  }
  .navbar-nav.ms-auto.me-auto li.has-dropdown.mega-dropdown {
    position: absolute;
  }
  .navbar-nav.ms-auto:not(.me-auto) li.has-dropdown.mega-dropdown {
    position: relative;
  }
}
/*------------------------------------*\
    #MODULE
\*------------------------------------*/
.module-holder {
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 110px;
}
@media screen and (max-width:991.98px) {
  .module-holder {
    margin-top: 30px;
    margin-left: 0;
    height: auto;
  }
}
@media screen and (max-width:991.98px) {
  .module-holder div:not(.module-contact) {
    display: none;
  }
}

.module-holder-phone {
  display: none;
}
@media screen and (max-width:991.98px) {
  .module-holder-phone {
    display: flex;
    align-items: center;
    height: 80px;
    margin-top: 0;
  }
}
.module-holder-phone .navbar-toggler {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  transition: 0.3s ease-in-out;
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
  border-radius: 0;
}
.module-holder-phone .navbar-toggler:focus {
  box-shadow: none;
}
@media screen and (min-width:1200px) {
  .module-holder-phone .navbar-toggler {
    display: none;
  }
}
.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-color: transparent;
  transform: rotate(180deg);
}
.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before, .module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
  top: 0;
}
.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
  transform: rotate(45deg);
}
.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
  transform: rotate(-45deg);
}
.module-holder-phone .navbar-toggler .navbar-toggler-icon {
  background-color: var(--global--color-heading);
  width: 100%;
  height: 2px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.module-holder-phone .navbar-toggler .navbar-toggler-icon::before, .module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
}
.module-holder-phone .navbar-toggler .navbar-toggler-icon::before {
  top: -10px;
}
.module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
  top: 10px;
}
.module-holder-phone .module-language {
  display: inline-flex;
  margin-right: 15px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .module-holder-phone .module-language {
    top: 140%;
  }
}
@media screen and (min-width:992px) {
  .module-holder-phone .module-language {
    display: none;
  }
}
.module-holder-phone .module-language .selected img {
  margin-right: 0;
}
.module-holder-phone .module-language .selected span,
.module-holder-phone .module-language .selected i {
  display: none;
}
.module-holder-phone .module-language .selected .lang-list {
  left: -45px;
}
.module-holder-phone .module-search {
  display: inline-flex;
  border-bottom: 0;
  padding: 0;
  margin-right: 20px;
}
.module-holder-phone .module-cart {
  display: inline-flex;
}

.module {
  display: inline-block;
  position: relative;
}
.module span.title {
  display: none;
}
.module .module-icon {
  cursor: pointer;
}
.module .module-icon i {
  font-size: 14px;
  line-height: 24px;
  color: var(--global--color-white);
}
.module:last-of-type .module-icon {
  margin-right: 0;
}
.module .module-label {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  background-color: var(--global--color-primary);
  color: var(--global--color-white);
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  position: absolute;
  top: -4px;
  left: 10px;
}

/* Module Fullscreen */
.module-fullscreen {
  left: 0;
  top: 0;
  right: 0;
  z-index: 9999;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: var(--global--color-white);
  position: fixed;
}
.module-fullscreen .module-cancel {
  top: 40px;
  right: 40px;
  border-radius: 8px 8px 0 8px;
  position: absolute;
  width: 50px;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--global--color-white);
  background-color: var(--global--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
.module-fullscreen.module-active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

/* Module Search */
.module-search {
  position: relative;
  margin-right: 20px;
  display: flex;
  align-items: center;
  height: 80px;
}
@media screen and (min-width:992px) {
  .module-search {
    height: 110px;
  }
}
.module-search .module-icon:hover i,
.module-search .module-icon:hover span {
  color: var(--global--color-primary);
}
.module-search .module-icon i {
  transition: 0.3s ease-in-out;
  color: var(--global--color-heading);
}

.module-search-warp {
  left: 0;
  top: 0;
  right: 0;
  z-index: 9999;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: var(--global--color-white);
  position: fixed;
}
.module-search-warp.search-warp-active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.module-search-warp .module-cancel {
  top: 40px;
  right: 40px;
  border-radius: 3px;
  position: absolute;
  width: 64px;
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--global--color-white);
  background-color: var(--global--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width:767.98px) {
  .module-search-warp .module-cancel {
    top: 30px;
    right: 20px;
    font-size: 14px;
    width: 50px;
    height: 50px;
  }
}
.module-search-warp .form-search {
  margin-bottom: 0;
}
.module-search-warp .form-search button::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "\e906";
  color: var(--global--color-heading);
  font-family: "icomoon";
  font-size: 28px;
  font-weight: 400;
  line-height: 70px;
  z-index: 999;
  cursor: pointer;
  transform: translateY(-2px);
}
@media screen and (max-width:767.98px) {
  .module-search-warp .form-search button::before {
    font-size: 16px;
  }
}
.module-search-warp .form-control {
  position: relative;
  font-family: var(--global--font-heading);
  font-size: 35px;
  font-weight: 500;
  line-height: 70px;
  height: 70px;
  color: var(--global--color-body);
  border: none;
  background-color: transparent;
  width: 100%;
  border-bottom: 2px solid #eaeaea;
  padding: 17px 30px 25px 46px;
  margin-bottom: 0;
  transition: 400ms linear;
  text-transform: capitalize;
  border-radius: 0;
}
@media screen and (max-width:767.98px) {
  .module-search-warp .form-control {
    font-size: 18px;
    padding-left: 30px;
    padding-bottom: 17px;
  }
}
.module-search-warp .form-control:focus, .module-search-warp .form-control:active {
  border-color: var(--global--color-primary);
}
.module-search-warp .form-control::-webkit-input-placeholder {
  color: var(--global--color-body);
  font-family: var(--global--font-heading);
  font-size: 35px;
  font-weight: 500;
}
@media screen and (max-width:767.98px) {
  .module-search-warp .form-control::-webkit-input-placeholder {
    font-size: 18px;
  }
}
.module-search-warp .form-control::-moz-placeholder {
  color: var(--global--color-body);
  font-family: var(--global--font-heading);
  font-size: 35px;
  font-weight: 500;
}
@media screen and (max-width:767.98px) {
  .module-search-warp .form-control::-moz-placeholder {
    font-size: 18px;
  }
}
.module-search-warp .form-control::-moz-placeholder {
  color: var(--global--color-body);
  font-family: var(--global--font-heading);
  font-size: 35px;
  font-weight: 500;
}
@media screen and (max-width:767.98px) {
  .module-search-warp .form-control::-moz-placeholder {
    font-size: 18px;
  }
}
.module-search-warp .form-control:-ms-input-placeholder {
  color: var(--global--color-body);
  font-family: var(--global--font-heading);
  font-size: 35px;
  font-weight: 500;
}
@media screen and (max-width:767.98px) {
  .module-search-warp .form-control:-ms-input-placeholder {
    font-size: 18px;
  }
}
.module-search-warp .btn {
  background-color: transparent;
  border-radius: 0;
  width: 35px;
  line-height: 57px;
  color: var(--global--color-white);
  font-size: 28px;
  font-weight: 400;
  text-align: right;
  margin-left: 0;
  padding: 0;
}

/* Module SideNav */
.module-sidenav .module-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 80px;
  background: var(--global--color-primary);
  border-right: 1px solid #404040;
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .module-sidenav .module-icon {
    width: 60px;
  }
}
@media screen and (min-width:992px) {
  .module-sidenav .module-icon {
    height: 110px;
  }
}
@media screen and (max-width:991.98px) {
  .module-sidenav .module-icon {
    width: 80px;
  }
}

/* Module Cart */
.module-cart {
  height: 80px;
  display: flex;
  align-items: center;
  padding-right: 40px;
  margin-right: 40px;
}
@media (min-width: 992px) and (max-width: 1400px) {
  .module-cart {
    margin-right: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width:992px) {
  .module-cart {
    height: 110px;
    border-right: 1px solid rgba(234, 234, 234, 0.149);
  }
}
@media screen and (max-width:991.98px) {
  .module-cart {
    height: 80px;
    margin-right: 30px;
    padding-right: 0;
  }
}
.module-cart .module-icon {
  position: relative;
}
.module-cart .module-icon i {
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  font-size: 16px;
  position: relative;
  cursor: pointer;
}
.module-cart .module-cart-warp {
  position: absolute;
  box-shadow: 0px 5px 83px 0px rgba(3, 43, 86, 0.13);
  top: 100%;
  left: -150px;
  width: 290px;
  background-color: var(--global--color-white);
  padding: 40px;
  border-radius: 0 0 6px 6px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(40px);
  transition: 500ms;
}
@media screen and (max-width:991.98px) {
  .module-cart .module-cart-warp {
    left: -250px;
  }
}
@media screen and (max-width:767.98px) {
  .module-cart .module-cart-warp {
    left: -160px;
    padding: 30px;
  }
}
@media screen and (min-width:768px) {
  .module-cart .module-cart-warp {
    width: 330px;
  }
}
.module-cart .module-cart-warp .cart-overview {
  margin-bottom: 0;
  width: 100%;
}
.module-cart .module-cart-warp .cart-overview li {
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.module-cart .module-cart-warp .cart-overview li:last-child {
  margin-bottom: 25px;
}
.module-cart .module-cart-warp .cart-overview li .cart-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--global--color-white);
  font-size: 7px;
  flex-shrink: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--global--color-heading);
  margin-left: 15px;
}
.module-cart .module-cart-warp .cart-overview li .cart-cancel:hover {
  background-color: var(--global--color-primary);
}
.module-cart .module-cart-warp .cart-overview li img {
  border-radius: 4px;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 15px;
}
.module-cart .module-cart-warp .cart-overview li .product-meta {
  flex-grow: 1;
}
.module-cart .module-cart-warp .cart-overview li .product-meta h5 {
  color: var(--global--color-heading);
  font-family: var(--global--font-heading);
  font-size: 15px;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 8px;
}
.module-cart .module-cart-warp .cart-overview li .product-meta p {
  font-family: var(--global--font-body);
  color: var(--global--color-primary);
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
.module-cart .module-cart-warp span {
  font-family: var(--global--font-heading);
  color: #9aaabb;
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 24px;
}
.module-cart .module-cart-warp span .total-price {
  font-style: normal;
}
.module-cart .module-cart-warp .cart--control .btn {
  height: 60px;
  width: 100%;
}
.module-cart.module-active .module-cart-warp {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Module Contact */
.module-contact {
  display: flex;
  align-items: center;
}
@media screen and (max-width:1199.98px) {
  .module-contact {
    flex-direction: column;
    width: 100%;
  }
}
.module-contact .btn {
  width: 162px;
  height: 50px;
  padding: 0 10px;
}
@media screen and (max-width:1199.98px) {
  .module-contact .btn {
    display: flex;
    width: 100%;
  }
}

/* Module Phone */
.module-phone {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 40px;
}
@media (min-width: 992px) and (max-width: 1400px) {
  .module-phone {
    margin-right: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1150px) {
  .module-phone {
    display: none;
  }
}
.module-phone .module-icon {
  margin-right: 10px;
}
.module-phone .module-icon svg {
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  display: inline-block;
}
.module-phone .module-body {
  flex-shrink: 0;
}
.module-phone .module-body span {
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  color: var(--global--color-gray-darken);
  text-transform: capitalize;
}
.module-phone .module-body a {
  display: flex;
  align-items: center;
  color: var(--global--color-heading);
  font-family: var(--global--font-body);
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}
.module-phone .module-body a:hover {
  color: var(--global--color-primary);
}
.module-phone:hover .module-icon svg {
  color: var(--global--color-primary);
}
.module-phone:hover .module-body a {
  color: var(--global--color-primary);
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .module-phone.module-phone-2 {
    display: none;
  }
}
.module-phone.module-phone-2 .module-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 53px;
  height: 53px;
  background: transparent;
  border: 2px solid var(--global--color-white);
  border-radius: 50%;
  margin-right: 15px;
  transition: 0.3s ease-in-out;
}
.module-phone.module-phone-2:hover .module-icon {
  background-color: var(--global--color-white);
}
.module-phone.module-phone-2:hover .module-icon svg {
  color: var(--global--color-heading);
}

/* Module Language */
.module-language {
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 100%;
}
.module-language.module-active .lang-list, .module-language:hover .lang-list {
  opacity: 1;
  visibility: visible;
}
.module-language .selected {
  display: flex;
  align-items: center;
}
.module-language .selected img {
  margin-right: 10px;
}
.module-language .selected span {
  font-family: var(--global--font-body);
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  color: var(--global--color-body);
  margin-bottom: 0px;
  text-transform: capitalize;
  margin-right: 5px;
  cursor: pointer;
}
.module-language .selected i {
  font-size: 11px;
  color: var(--global--color-body);
}
.module-language .lang-list {
  background-color: var(--global--color-white);
  color: var(--global--color-heading);
  position: absolute;
  top: 100%;
  padding: 6px 22px;
  box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11);
  z-index: 5555;
  left: -22px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
  border-radius: 0 0 8px 8px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .module-language .lang-list {
    top: 140%;
  }
}
.module-language .lang-list ul {
  margin-bottom: 0;
}
.module-language .lang-list ul li {
  display: flex;
  align-items: center;
}
.module-language .lang-list ul li:last-of-type a {
  border-bottom: none;
}
.module-language .lang-list ul li:last-of-type a::after {
  content: none;
}
.module-language .lang-list ul li img {
  margin-right: 10px;
}
.module-language .lang-list ul li a {
  font-size: 14px;
  display: block;
  color: var(--global--color-body);
  text-transform: capitalize;
  border-bottom: 1px solid #eaeaea;
  padding: 8px 0px;
  cursor: pointer;
  transition: 0.3s ease;
  position: relative;
}
.module-language .lang-list ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 0px;
  transition: 0.3s ease;
  background-color: transparent;
}
.module-language .lang-list ul li a:hover {
  color: var(--global--color-primary);
}
.module-language .lang-list ul li a:hover::after {
  width: 100%;
  background-color: var(--global--color-primary);
}

/* Fixed Nav */
.navbar-fixed .module-language span,
.navbar-fixed .module-language i {
  color: var(--global--color-heading);
}

/* Module Social */
.module-social {
  line-height: 50px;
  height: 50px;
  display: flex;
  align-items: center;
}
.module-social a {
  margin-right: 10px;
}
.module-social a:last-child {
  margin-right: 0;
}
.module-social a i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: var(--global--color-primary);
  color: var(--global--color-heading);
  font-size: 12px;
  border-radius: 50%;
  transition: 300ms ease-in-out;
}
.module-social a i:hover {
  transform: translateY(-5px);
}
.module-social.module-social-2 {
  line-height: 70px;
  height: 70px;
  border-right: 2px solid #292929;
  margin-right: 0;
}
.module-social.module-social-2:last-child {
  margin-right: 0;
}
.module-social.module-social-2 a {
  color: var(--global--color-white);
  background-color: transparent;
  margin-right: 20px;
  width: auto;
  height: auto;
  border-radius: 0;
}
.module-social.module-social-2 a:hover {
  color: var(--global--color-primary);
  transform: scale(1);
}

/*------------------------------------*\
    #TopBar
\*------------------------------------*/
.top-bar {
  padding: 0 40px;
  display: flex;
  height: 50px;
  position: relative;
  z-index: 8888;
  background-color: var(--global--color-heading-light);
}
@media (min-width: 992px) and (max-width: 1400px) {
  .top-bar {
    padding: 0px 20px;
  }
}
.top-bar .block-left {
  display: flex;
  align-items: center;
  flex-basis: 50%;
}
@media screen and (min-width: 1350px) {
  .top-bar .block-left {
    flex-basis: 60%;
  }
}
.top-bar .block-right {
  display: flex;
  align-items: center;
  flex-basis: 50%;
}
@media screen and (min-width: 1350px) {
  .top-bar .block-right {
    flex-basis: 40%;
  }
}
@media screen and (min-width:992px) {
  .top-bar .block-right {
    justify-content: flex-end;
  }
}
@media (min-width: 992px) and (max-width: 1185px) {
  .top-bar .block-right {
    margin-bottom: 0px;
  }
}
.top-bar .block-right .module-language {
  margin-left: 30px;
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .top-bar .block-right .module-language {
    margin-left: 15px;
  }
}
.top-bar .block-right .module-social {
  margin-left: 30px;
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .top-bar .block-right .module-social {
    margin-left: 15px;
  }
}
.top-bar .block-right .top-bar-links-wrapper .top-bar-link {
  display: inline-flex;
  align-items: center;
  height: 100%;
  font-family: var(--global--font-body);
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  color: var(--global--color-white);
  margin-bottom: 0px;
  margin-right: 20px;
  text-transform: capitalize;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .top-bar .block-right .top-bar-links-wrapper .top-bar-link {
    margin-right: 10px;
  }
}
.top-bar .block-right .top-bar-links-wrapper .top-bar-link:last-child {
  margin-right: 0;
}
.top-bar .block-right .top-bar-links-wrapper .top-bar-link:hover {
  color: var(--global--color-primary);
}
.top-bar .top-contact {
  display: flex;
}
.top-bar .top-contact .contact-info {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .top-bar .top-contact .contact-info {
    margin-right: 15px;
  }
}
.top-bar .top-contact .contact-info:last-child {
  margin-right: 0;
}
@media (min-width: 992px) and (max-width: 1349.98px) {
  .top-bar .top-contact .contact-info:last-child {
    display: none;
  }
}
.top-bar .top-contact .contact-info svg {
  color: var(--global--color-primary);
  margin-right: 10px;
}
.top-bar .top-contact .contact-info a,
.top-bar .top-contact .contact-info p {
  font-family: var(--global--font-body);
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  color: var(--global--color-body);
  margin-bottom: 0px;
  text-transform: capitalize;
}
.top-bar .top-contact .contact-info a:hover {
  color: var(--global--color-primary);
}

/*------------------------------------*\
    #Page Title
\*------------------------------------*/
.page-title {
  padding-top: 0;
  padding-bottom: 0;
}
.page-title .page-title-wrap {
  display: flex;
  align-items: center;
  height: 465px;
  padding-top: 80px;
}
@media screen and (min-width:992px) {
  .page-title .page-title-wrap {
    padding-top: 110px;
  }
}
.page-title .title {
  margin-bottom: 32px;
}
@media screen and (max-width:991.98px) {
  .page-title .title {
    text-align: center;
  }
}
.page-title .title .title-sub-heading {
  font-family: var(--global--font-secondary);
  font-size: 30px;
  line-height: 1;
  font-weight: 400;
  color: var(--global--color-primary);
  margin-bottom: 20px;
  text-transform: capitalize;
}
@media screen and (max-width:991.98px) {
  .page-title .title .title-sub-heading {
    line-height: 1.4;
  }
}
.page-title .title .title-heading {
  font-family: var(--global--font-heading);
  font-size: 75px;
  line-height: 1;
  font-weight: 700;
  color: var(--global--color-white);
  margin-bottom: 0px;
  text-transform: capitalize;
}
@media screen and (max-width:767.98px) {
  .page-title .title .title-heading {
    font-size: 32px;
    line-height: 1.4;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .page-title .title .title-heading {
    font-size: 32px;
    line-height: 1.4;
  }
}
.page-title .title .title-desc {
  color: var(--global--color-white);
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 30px;
  font-weight: 700;
}
.page-title.page-title-1 {
  position: relative;
  margin-bottom: 0;
}
.page-title.page-title-1 .title .title-heading {
  margin-bottom: 0;
}
.page-title.page-title-2 {
  position: relative;
  margin-bottom: 0;
}
.page-title.page-title-2 .title .title-heading {
  margin-bottom: 0;
}

.breadcrumb-wrapper {
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
}
@media screen and (max-width:767.98px) {
  .breadcrumb-wrapper {
    height: auto;
    padding: 10px 0;
  }
}

.breadcrumb {
  margin-bottom: 0;
}
@media screen and (max-width:991.98px) {
  .breadcrumb {
    justify-content: center;
  }
}
.breadcrumb .breadcrumb-item {
  font-family: var(--global--font-body);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: var(--global--color-body);
  margin-bottom: 0px;
  background: transparent;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
}
@media screen and (max-width:767.98px) {
  .breadcrumb .breadcrumb-item {
    line-height: 2.4;
  }
}
.breadcrumb .breadcrumb-item .breadcrumb-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: var(--global--color-primary);
  border-radius: 3px;
  margin-right: 10px;
}
.breadcrumb .breadcrumb-item a {
  font-family: var(--global--font-body);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 700;
  color: var(--global--color-heading);
  margin-bottom: 0px;
  background: transparent;
}
@media screen and (max-width:767.98px) {
  .breadcrumb .breadcrumb-item a {
    line-height: 2.4;
  }
}
.breadcrumb .breadcrumb-item a:hover {
  color: var(--global--color-primary);
}
.breadcrumb .breadcrumb-item.active {
  color: var(--global--color-body);
}
.breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 11px;
  padding: 0 10px;
  color: var(--global--color-heading);
}
.breadcrumb.breadcrumb-light {
  color: var(--global--color-white);
}
.breadcrumb.breadcrumb-light a {
  color: var(--global--color-white);
}
.breadcrumb.breadcrumb-light .active {
  color: var(--global--color-white);
}
.breadcrumb.breadcrumb-light .breadcrumb-item + .breadcrumb-item::before {
  color: var(--global--color-white);
}

/*------------------------------------*\
    #Carousel
\*------------------------------------*/
.owl-nav.disabled {
  display: none;
}

.carousel-dots {
  position: relative;
}
.carousel-dots button.owl-dot,
.carousel-dots button.owl-dot:focus {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}
.carousel-dots .owl-dots {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 5;
}
.carousel-dots .owl-dots .owl-dot {
  margin-right: 10px;
  display: inline-flex;
}
.carousel-dots .owl-dots .owl-dot:last-child {
  margin-right: 0;
}
.carousel-dots .owl-dots .owl-dot.active span {
  background-color: var(--global--color-primary);
}
.carousel-dots .owl-dots .owl-dot span {
  display: inline-block;
  width: 50px;
  height: 6px;
  border-radius: 2px;
  transition: 0.3s ease-in-out;
  margin-right: 0;
  position: relative;
  background-color: #b4b4b4;
}
@media screen and (max-width:767.98px) {
  .carousel-dots .owl-dots .owl-dot span {
    width: 35px;
  }
}
.carousel-dots.carousel-dots-white .owl-dots .owl-dot span {
  background-color: var(--global--color-white);
}
.carousel-dots.carousel-dots-white .owl-dots .owl-dot.active span {
  background-color: var(--global--color-primary);
}
.carousel-dots.carousel-dots-white-2 .owl-dots .owl-dot span {
  background-color: var(--global--color-white);
}
.carousel-dots.carousel-dots-white-2 .owl-dots .owl-dot.active span {
  background-color: var(--global--color-heading);
}

.carousel-navs .owl-nav {
  display: flex;
  position: relative;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .carousel-navs .owl-nav {
    justify-content: center;
    margin-bottom: 30px;
  }
}
.carousel-navs .owl-nav .owl-next,
.carousel-navs .owl-nav .owl-prev {
  position: relative;
  overflow: hidden;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: var(--global--color-white) !important;
  transition: 0.3s ease-in-out;
}
.carousel-navs .owl-nav .owl-next:hover,
.carousel-navs .owl-nav .owl-prev:hover {
  background-color: var(--global--color-primary) !important;
}
.carousel-navs .owl-nav .owl-next:before,
.carousel-navs .owl-nav .owl-prev:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--global--color-heading);
  font-size: 14px;
  transition: 0.3s ease-in-out;
}
.carousel-navs .owl-nav .owl-next:after,
.carousel-navs .owl-nav .owl-prev:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--global--color-heading);
  font-size: 20px;
  transition: 0.3s ease-in-out;
}
.carousel-navs .owl-nav .owl-prev {
  margin-right: 20px;
}
.carousel-navs .owl-nav .owl-prev:before {
  content: "\f060";
}
.carousel-navs .owl-nav .owl-prev::after {
  content: "\f30a";
  transform: translateX(100%);
}
.carousel-navs .owl-nav .owl-prev:hover::before {
  transform: translateX(-100%);
}
.carousel-navs .owl-nav .owl-prev:hover::after {
  transform: translateX(0);
}
.carousel-navs .owl-nav .owl-next:before {
  content: "\f061";
}
.carousel-navs .owl-nav .owl-next::after {
  content: "\f30b";
  transform: translateX(-100%);
}
.carousel-navs .owl-nav .owl-next:hover::before {
  transform: translateX(100%);
}
.carousel-navs .owl-nav .owl-next:hover::after {
  transform: translateX(0);
}

.carousel-navs-2 .owl-nav {
  display: flex;
}
@media screen and (min-width:992px) {
  .carousel-navs-2 .owl-nav {
    position: absolute;
    bottom: 30px;
    right: 50px;
    z-index: 6;
  }
}
@media screen and (max-width:991.98px) {
  .carousel-navs-2 .owl-nav {
    justify-content: center;
  }
}
.carousel-navs-2 .owl-nav .owl-next,
.carousel-navs-2 .owl-nav .owl-prev {
  position: relative;
  overflow: hidden;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 4px solid transparent;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width:991.98px) {
  .carousel-navs-2 .owl-nav .owl-next,
  .carousel-navs-2 .owl-nav .owl-prev {
    width: 50px;
    height: 50px;
  }
}
.carousel-navs-2 .owl-nav .owl-next:hover,
.carousel-navs-2 .owl-nav .owl-prev:hover {
  border-color: rgba(9, 29, 62, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
}
.carousel-navs-2 .owl-nav .owl-next:before,
.carousel-navs-2 .owl-nav .owl-prev:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--global--color-white);
  font-size: 12px;
  transition: 0.3s ease-in-out;
}
.carousel-navs-2 .owl-nav .owl-next:after,
.carousel-navs-2 .owl-nav .owl-prev:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--global--color-white);
  font-size: 16px;
  transition: 0.3s ease-in-out;
}
.carousel-navs-2 .owl-nav .owl-prev {
  margin-left: -10px;
}
@media screen and (max-width:991.98px) {
  .carousel-navs-2 .owl-nav .owl-prev {
    margin-left: 0px;
  }
}
.carousel-navs-2 .owl-nav .owl-prev:before {
  content: "\f060";
}
.carousel-navs-2 .owl-nav .owl-prev::after {
  content: "\f30a";
  transform: translateX(100%);
}
.carousel-navs-2 .owl-nav .owl-prev:hover::before {
  transform: translateX(-100%);
}
.carousel-navs-2 .owl-nav .owl-prev:hover::after {
  transform: translateX(0);
}
.carousel-navs-2 .owl-nav .owl-next {
  margin-right: -10px;
}
@media screen and (max-width:991.98px) {
  .carousel-navs-2 .owl-nav .owl-next {
    margin-right: 0px;
  }
}
.carousel-navs-2 .owl-nav .owl-next:before {
  content: "\f061";
}
.carousel-navs-2 .owl-nav .owl-next::after {
  content: "\f30b";
  transform: translateX(-100%);
}
.carousel-navs-2 .owl-nav .owl-next:hover::before {
  transform: translateX(100%);
}
.carousel-navs-2 .owl-nav .owl-next:hover::after {
  transform: translateX(0);
}

.carousel-navs-rtl .owl-nav {
  display: flex;
}
@media screen and (min-width:992px) {
  .carousel-navs-rtl .owl-nav {
    position: absolute;
    bottom: 30px;
    right: 50px;
    z-index: 10;
  }
}
@media screen and (max-width:991.98px) {
  .carousel-navs-rtl .owl-nav {
    justify-content: center;
    margin-top: 30px;
  }
}
.carousel-navs-rtl .owl-nav .owl-next,
.carousel-navs-rtl .owl-nav .owl-prev {
  position: relative;
  overflow: hidden;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 4px solid transparent;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width:991.98px) {
  .carousel-navs-rtl .owl-nav .owl-next,
  .carousel-navs-rtl .owl-nav .owl-prev {
    width: 50px;
    height: 50px;
  }
}
.carousel-navs-rtl .owl-nav .owl-next:hover,
.carousel-navs-rtl .owl-nav .owl-prev:hover {
  border-color: rgba(9, 29, 62, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
}
.carousel-navs-rtl .owl-nav .owl-next:before,
.carousel-navs-rtl .owl-nav .owl-prev:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--global--color-white);
  font-size: 12px;
  transition: 0.3s ease-in-out;
}
.carousel-navs-rtl .owl-nav .owl-next:after,
.carousel-navs-rtl .owl-nav .owl-prev:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--global--color-white);
  font-size: 16px;
  transition: 0.3s ease-in-out;
}
.carousel-navs-rtl .owl-nav .owl-next {
  margin-right: -10px;
}
@media screen and (max-width:991.98px) {
  .carousel-navs-rtl .owl-nav .owl-next {
    margin-right: 0px;
  }
}
.carousel-navs-rtl .owl-nav .owl-next:before {
  content: "\f060";
}
.carousel-navs-rtl .owl-nav .owl-next::after {
  content: "\f30a";
  transform: translateX(100%);
}
.carousel-navs-rtl .owl-nav .owl-next:hover::before {
  transform: translateX(-100%);
}
.carousel-navs-rtl .owl-nav .owl-next:hover::after {
  transform: translateX(0);
}
.carousel-navs-rtl .owl-nav .owl-prev {
  margin-left: -10px;
}
@media screen and (max-width:991.98px) {
  .carousel-navs-rtl .owl-nav .owl-prev {
    margin-left: 0px;
  }
}
.carousel-navs-rtl .owl-nav .owl-prev:before {
  content: "\f061";
}
.carousel-navs-rtl .owl-nav .owl-prev::after {
  content: "\f30b";
  transform: translateX(-100%);
}
.carousel-navs-rtl .owl-nav .owl-prev:hover::before {
  transform: translateX(100%);
}
.carousel-navs-rtl .owl-nav .owl-prev:hover::after {
  transform: translateX(0);
}

.carousel-navs-slider {
  position: relative;
}
.carousel-navs-slider .owl-nav span {
  font-size: 0;
}
@media screen and (min-width:992px) {
  .carousel-navs-slider .owl-nav {
    position: absolute;
    top: -127px;
    right: 0;
  }
}
.carousel-navs-slider .owl-next:hover::before,
.carousel-navs-slider .owl-prev:hover::before {
  color: var(--global--color-primary);
}
.carousel-navs-slider .owl-next:before,
.carousel-navs-slider .owl-prev:before {
  font-family: "carousel";
  font-size: 43px;
  font-weight: 600;
  color: #687596;
  transition: 0.3s ease-in-out;
}
.carousel-navs-slider .owl-prev {
  margin-right: 50px;
}
.carousel-navs-slider .owl-prev:before {
  content: "\e990";
}
.carousel-navs-slider .owl-next:before {
  content: "\e991";
}

.carousel-navs-slider .owl-dots {
  position: absolute;
  bottom: 40px;
  width: 100%;
}

/*------------------------------------*\
    #Heading
\*------------------------------------*/
.heading {
  margin-bottom: 48px;
}
@media screen and (max-width:991.98px) {
  .heading {
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width:767.98px) {
  .heading {
    max-width: unset;
  }
}
.heading .heading-subtitle {
  font-family: var(--global--font-body);
  text-transform: capitalize;
  color: var(--global--color-body);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 14px;
}
@media screen and (max-width:991.98px) {
  .heading .heading-subtitle {
    line-height: 1.5;
  }
}
.heading .heading-title {
  font-family: var(--global--font-heading);
  text-transform: capitalize;
  font-size: 37px;
  line-height: 54px;
  margin-bottom: 0px;
  font-weight: 700;
  color: var(--global--color-heading-darken);
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading .heading-desc {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 32px;
}
.heading .paragraph {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 17px;
  line-height: 27px;
  color: #1e4873;
  margin-bottom: 0px;
}
.heading.heading-light .heading-subtitle {
  color: var(--global--color-primary);
}
.heading.heading-light .heading-title {
  color: var(--global--color-white);
}
.heading.heading-light .heading-desc {
  color: #c6c6c6;
}
.heading.heading-light .paragraph {
  color: #c6c6c6;
}
.heading.heading-2 {
  margin-bottom: 70px;
}
@media screen and (min-width:992px) {
  .heading.heading-2 {
    margin-bottom: 0px;
  }
}
.heading.heading-2 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 31px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-2 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-2 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-2 .heading-desc {
  margin-bottom: 25px;
}
.heading.heading-2 .heading-desc:last-of-type {
  margin-bottom: 32px;
}
.heading.heading-3 {
  margin-bottom: 70px;
}
.heading.heading-3 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 31px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-3 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-3 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-3 .heading-desc {
  font-size: 18px;
  margin-bottom: 0px;
}
.heading.heading-5 {
  margin-bottom: 0;
}
@media screen and (min-width:992px) {
  .heading.heading-5 {
    transform: translateY(-6px);
    margin-bottom: -6px;
  }
}
.heading.heading-5 .heading-desc {
  margin-bottom: 24px;
}
.heading.heading-6 {
  margin-bottom: 104px;
}
.heading.heading-6 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 23px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-6 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-6 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-6 .heading-desc {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 29px;
  font-weight: 700;
}
.heading.heading-7 {
  margin-bottom: 0;
}
.heading.heading-7 .heading-title {
  margin-bottom: 21px;
}
.heading.heading-7 .heading-desc {
  margin-bottom: 34px;
}
.heading.heading-8 {
  margin-bottom: 0;
}
.heading.heading-8 .heading-title {
  margin-bottom: 20px;
}
.heading.heading-8 .heading-desc {
  margin-bottom: 42px;
}
.heading.heading-9 {
  margin-bottom: 0;
}
.heading.heading-9 .heading-title {
  margin-bottom: 20px;
}
.heading.heading-9 .heading-desc {
  margin-bottom: 37px;
}
.heading.heading-9 {
  margin-bottom: 0;
}
.heading.heading-9 .heading-title {
  margin-bottom: 20px;
}
.heading.heading-9 .heading-desc {
  margin-bottom: 37px;
}
.heading.heading-11 {
  margin-bottom: 60px;
}
.heading.heading-11 .heading-title {
  margin-bottom: 23px;
}
@media screen and (min-width:992px) {
  .heading.heading-11 .heading-title {
    transform: translateY(-10px);
    margin-bottom: -10px;
  }
}
.heading.heading-12 {
  margin-bottom: 32px;
}
.heading.heading-12 .heading-title {
  font-size: 24px;
  line-height: 1.4;
}
.heading.heading-13 {
  margin-bottom: 0px;
}
.heading.heading-13 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 23px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-13 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-13 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-13 .heading-desc {
  font-size: 16px;
  font-weight: 700;
}
.heading.heading-13 .heading-desc:last-of-type {
  font-size: 15px;
  margin-bottom: 33px;
}
.heading.heading-15 {
  margin-bottom: 63px;
}
.heading.heading-15 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 24px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-15 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-15 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-15 .heading-desc {
  margin-bottom: 0px;
}
.heading.heading-16 .heading-title {
  margin-bottom: 34px;
}
.heading.heading-18 {
  margin-bottom: 62px;
}
.heading.heading-18 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 22px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-18 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-18 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-19 {
  margin-bottom: 0;
}
@media screen and (min-width:992px) {
  .heading.heading-19 {
    padding-top: 117px;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-19 {
    margin-bottom: 70px;
  }
}
.heading.heading-19 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 32px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-19 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-19 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-19 .heading-desc {
  margin-bottom: 25px;
}
.heading.heading-19 .heading-desc:last-of-type {
  margin-bottom: 32px;
}
.heading.heading-20 {
  margin-bottom: 83px;
}
.heading.heading-20 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 59px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-20 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-20 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-20 .block-desc {
  position: relative;
  padding-top: 28px;
}
@media screen and (max-width:991.98px) {
  .heading.heading-20 .block-desc {
    padding-bottom: 43px;
  }
}
.heading.heading-20 .block-desc::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 290px;
  height: 4px;
  background-color: var(--global--color-primary);
}
@media screen and (max-width:991.98px) {
  .heading.heading-20 .block-desc::before {
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
  }
}
.heading.heading-20 .block-desc .heading-desc {
  color: #f9f9f9;
  line-height: 28px;
  margin-bottom: 27px;
}
.heading.heading-20 .block-desc .heading-desc:last-of-type {
  margin-bottom: 33px;
}
.heading.heading-20 .block-desc .heading-desc:last-child {
  margin-bottom: 0;
}
.heading.heading-20 .block-desc .btn {
  display: inline-flex;
  width: 218px;
  height: 68px;
}
@media screen and (min-width:992px) {
  .heading.heading-21 {
    transform: translateY(-8px);
    margin-bottom: -8px;
  }
}
.heading.heading-22 {
  margin-bottom: 0px;
}
@media screen and (min-width:992px) {
  .heading.heading-22 {
    transform: translateY(-14px);
    margin-bottom: -14px;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-22 {
    margin-bottom: 62px;
  }
}
.heading.heading-22 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 31px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-22 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-22 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-22 .heading-desc {
  margin-bottom: 26px;
}
.heading.heading-23 {
  margin-bottom: 0;
}
@media screen and (min-width:992px) {
  .heading.heading-23 {
    margin-top: 287px;
  }
}
.heading.heading-23 .heading-title {
  color: var(--global--color-white);
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 27px;
}
.heading.heading-23 .heading-desc {
  margin-bottom: 24px;
}
.heading.heading-23 .advantages-list {
  margin-bottom: 33px;
}
.heading.heading-23 .btn {
  display: inline-flex;
  width: 228px;
  height: 68px;
}
.heading.heading-25 {
  margin-bottom: 0px;
}
.heading.heading-25 .heading-title {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 33px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-25 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media screen and (max-width:991.98px) {
  .heading.heading-25 .heading-title {
    font-size: 30px;
    line-height: 1.4;
  }
}
.heading.heading-25 .heading-desc {
  margin-bottom: 26px;
  font-weight: 700;
  color: #f9f9f9;
}
.heading.heading-25 .heading-desc:last-of-type {
  font-weight: 400;
  margin-bottom: 33px;
}
.heading.heading-26 {
  margin-bottom: 62px;
}
.heading.heading-26 .heading-title {
  margin-bottom: 20px;
}
.heading.heading-26 .heading-desc {
  font-size: 18px;
  line-height: 29px;
  font-weight: 700;
}
@media screen and (max-width:991.98px) {
  .heading.heading-27 {
    margin-bottom: 70px;
  }
}
.heading.heading-27 .heading-title {
  color: var(--global--color-white);
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 27px;
}
.heading.heading-27 .heading-desc {
  margin-bottom: 24px;
}
.heading.heading-27 .advantages-list {
  margin-bottom: 33px;
}
.heading.heading-27 .btn {
  display: inline-flex;
  width: 228px;
  height: 68px;
}
.heading.heading-28 {
  margin-bottom: 53px;
}
.heading.heading-28 .heading-title {
  margin-bottom: 21px;
}
.heading.heading-29 {
  margin-bottom: 62px;
}
.heading.heading-29 .heading-title {
  font-size: 22px;
  line-height: 33px;
  color: var(--global--color-heading-darken);
  margin-bottom: 24px;
}
.heading.heading-29 .heading-desc {
  font-size: 16px;
  color: var(--global--color-heading-light);
}
.heading.heading-31 {
  margin-bottom: 0;
}
.heading.heading-31 .heading-title {
  font-size: 22px;
  line-height: 33px;
  color: var(--global--color-heading-darken);
  margin-bottom: 23px;
}
.heading.heading-31 .heading-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: var(--global--color-heading-light);
  margin-bottom: 0;
}
.heading.heading-32 {
  margin-bottom: 0;
}
.heading.heading-32 .heading-subtitle {
  font-size: 17px;
  color: var(--global--color-heading-light);
}
.heading.heading-32 .heading-subtitle {
  margin-bottom: 11px;
}
.heading.heading-32 .heading-title {
  font-size: 75px;
  line-height: 1.1;
  text-transform: capitalize;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .heading.heading-32 .heading-title {
    font-size: 35px;
    line-height: 1.4;
  }
}
@media screen and (max-width:767.98px) {
  .heading.heading-32 .heading-title {
    font-size: 32px;
    line-height: 1.4;
  }
}

/*------------------------------------*\
	#Slider
\*------------------------------------*/
.slider {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}
.slider .slide {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  height: 900px;
}
@media screen and (max-width:991.98px) {
  .slider .slide {
    height: auto;
    min-height: 120vh;
  }
}
.slider .slide .slide-content {
  position: relative;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .slider .slide .slide-content {
    text-align: center;
  }
}
.slider .slide .slide-content .slide-subtitle {
  font-family: var(--global--font-body);
  text-transform: capitalize;
  color: #f9f9f9;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 8px;
}
.slider .slide .slide-content .slide-title {
  font-family: var(--global--font-heading);
  color: var(--global--color-white);
  font-weight: 700;
  font-size: 75px;
  line-height: 1.1;
  margin-bottom: 22px;
  text-transform: capitalize;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .slider .slide .slide-content .slide-title {
    font-size: 35px;
    line-height: 1.4;
  }
}
@media screen and (max-width:767.98px) {
  .slider .slide .slide-content .slide-title {
    font-size: 32px;
    line-height: 1.4;
  }
}
.slider .slide .slide-content .slide-desc {
  font-family: var(--global--font-body);
  color: #f9f9f9;
  font-size: 17px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 32px;
}
.slider .slide .slide-content .slide-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .slider .slide .slide-content .slide-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .slider .slide .slide-content .slide-action {
    flex-direction: column;
  }
}
.slider .slide .slide-content .slide-action .btn {
  width: 184px;
  height: 68px;
  margin-right: 30px;
}
@media screen and (max-width:767.98px) {
  .slider .slide .slide-content .slide-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 200px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .slider .slide .slide-content .slide-action .btn {
    width: 200px;
    margin-right: 15px;
  }
}
.slider .slide .slide-content .slide-action .btn:last-child {
  margin-right: 0;
}
@media screen and (max-width:767.98px) {
  .slider .slide .slide-content .slide-action .btn:last-child {
    margin-bottom: 0;
  }
}
.slider .slide .slide-content .slide-list {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .slider .slide .slide-content .slide-list {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .slider .slide .slide-content .slide-list {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
.slider .slide .slide-content .slide-list .icon {
  position: relative;
  margin-right: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 3px;
  width: 110px;
  height: 135px;
  background-color: var(--global--color-white);
  transition: 0.3s ease-in-out;
}
.slider .slide .slide-content .slide-list .icon:last-child {
  margin-right: 0;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .slider .slide .slide-content .slide-list .icon {
    margin-right: 15px;
  }
}
@media screen and (max-width:767.98px) {
  .slider .slide .slide-content .slide-list .icon {
    margin-right: 0;
    margin: 0 15px 25px;
  }
  .slider .slide .slide-content .slide-list .icon:last-child {
    margin-right: 15px;
  }
}
.slider .slide .slide-content .slide-list .icon:hover {
  background-color: var(--global--color-primary);
  animation: Bounce 0.8s ease-in-out;
}
.slider .slide .slide-content .slide-list .icon a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 5;
}
.slider .slide .slide-content .slide-list .icon i {
  display: inline-block;
  font-size: 48px;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  margin-bottom: 13px;
}
.slider .slide .slide-content .slide-list .icon span {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 15px;
  color: var(--global--color-heading);
  text-transform: capitalize;
}
.slider .carousel-navs-slider .owl-nav {
  right: auto;
  top: auto;
  position: initial;
}
@media screen and (max-width:991.98px) {
  .slider .carousel-navs-slider .owl-nav {
    display: none;
  }
}
.slider .carousel-navs-slider .owl-nav span {
  font-size: 0;
}
.slider .carousel-navs-slider .owl-nav .owl-next:before,
.slider .carousel-navs-slider .owl-nav .owl-prev:before {
  color: rgba(255, 255, 255, 0.5);
  font-family: "carousel" !important;
  font-size: 44px;
  transition: 0.3s ease-in-out;
}
.slider .carousel-navs-slider .owl-nav .owl-prev:before {
  content: "\e990";
}
.slider .carousel-navs-slider .owl-nav .owl-next:before {
  content: "\e991";
}
.slider .carousel-navs-slider .owl-nav .owl-next,
.slider .carousel-navs-slider .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.slider .carousel-navs-slider .owl-nav .owl-next:hover::before,
.slider .carousel-navs-slider .owl-nav .owl-prev:hover::before {
  color: white;
}
.slider .carousel-navs-slider .owl-nav .owl-next {
  right: 40px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .slider .carousel-navs-slider .owl-nav .owl-next {
    right: 20px;
  }
}
.slider .carousel-navs-slider .owl-nav .owl-prev {
  margin-right: 0;
  left: 40px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .slider .carousel-navs-slider .owl-nav .owl-prev {
    left: 20px;
  }
}
@media screen and (max-width:991.98px) {
  .slider.slider-1 .slide {
    padding-bottom: 135px;
    height: 130vh;
  }
}
@media screen and (max-width: 400px) {
  .slider.slider-1 .slide {
    height: 140vh;
  }
}
@media screen and (min-width:992px) {
  .slider.slider-1 .slide {
    padding-top: 111px;
  }
}
@media screen and (min-width:992px) {
  .slider.slider-1 .slide .slide-content .slide-desc {
    max-width: 90%;
  }
}
@media screen and (max-width:991.98px) {
  .slider.slider-1 .slide .slide-content .slide-action {
    margin-bottom: 40px;
  }
}
.slider.slider-1 .slide .slide-content .slide-action .btn:first-child {
  width: 240px;
}
.slider.slider-1 .slide .slide-content .slide-action .btn:last-child {
  padding: 0 20px;
}
.slider.slider-1 .carousel-navs-2 .owl-nav {
  position: absolute;
  right: 490px;
  bottom: 60px;
}
@media screen and (min-width:992px) {
  .slider.slider-2 .slide {
    padding-top: 110px;
  }
}
@media screen and (max-width:767.98px) {
  .slider.slider-2 .slide {
    height: 130vh;
  }
}
.slider.slider-2 .slide .slide-content .slide-action .btn:first-child {
  width: 260px;
}
.slider.slider-2 .slide .slide-content .slide-action .btn:last-child {
  width: 196px;
}
@media screen and (min-width:992px) {
  .slider.slider-3 .slide {
    height: 740px;
    padding-bottom: 110px;
  }
}

.hero {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  height: 680px;
  position: relative;
  padding-top: 110px;
}
@media screen and (max-width:991.98px) {
  .hero {
    height: 80vh;
    padding-top: 80px;
  }
}
@media screen and (max-width:767.98px) {
  .hero {
    height: 90vh;
  }
}
@media screen and (max-width:991.98px) {
  .hero .hero-content {
    text-align: center;
  }
}
.hero .hero-content .hero-subtitle {
  font-family: var(--global--font-secondary);
  text-transform: capitalize;
  color: var(--global--color-primary);
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 18px;
}
@media screen and (max-width:991.98px) {
  .hero .hero-content .hero-subtitle {
    line-height: 1.5;
  }
}
.hero .hero-content .hero-title {
  font-family: var(--global--font-heading);
  color: var(--global--color-white);
  font-weight: 700;
  font-size: 75px;
  line-height: 1;
  margin-bottom: 27px;
  text-transform: capitalize;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .hero .hero-content .hero-title {
    font-size: 35px;
    line-height: 1.4;
  }
}
@media screen and (max-width:767.98px) {
  .hero .hero-content .hero-title {
    font-size: 32px;
    line-height: 1.4;
  }
}
.hero .hero-content .hero-desc {
  font-family: var(--global--font-body);
  color: #f9f9f9;
  font-size: 17px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 32px;
}
.hero .hero-content .hero-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .hero .hero-content .hero-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .hero .hero-content .hero-action {
    flex-direction: column;
  }
}
.hero .hero-content .hero-action .btn {
  width: 218px;
  height: 68px;
  margin-right: 30px;
}
.hero .hero-content .hero-action .btn:last-child {
  width: 176px;
  margin-right: 0;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .hero .hero-content .hero-action .btn {
    width: 225px;
    margin-right: 15px;
  }
}
@media screen and (max-width:767.98px) {
  .hero .hero-content .hero-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 225px;
  }
  .hero .hero-content .hero-action .btn:last-child {
    margin-bottom: 0;
  }
}
.hero .hero-content .hero-action .contact-num {
  display: flex;
  align-items: center;
}
.hero .hero-content .hero-action .contact-num .contact-icon-wrapper {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: transparent;
  border: 2px solid var(--global--color-primary);
  border-radius: 50%;
  margin-right: 10px;
}
.hero .hero-content .hero-action .contact-num .contact-icon-wrapper .contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: var(--global--color-white);
  border-radius: 50%;
}
.hero .hero-content .hero-action .contact-num .contact-icon-wrapper .contact-icon svg {
  color: var(--global--color-primary);
}
.hero .hero-content .hero-action .contact-num .contact-body a {
  display: block;
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 17px;
  color: var(--global--color-white);
  margin-bottom: 10px;
  line-height: 1;
}
.hero .hero-content .hero-action .contact-num .contact-body .job-title {
  font-family: var(--global--color-secondary);
  font-weight: 400;
  font-size: 13px;
  color: #f4f4f4;
  margin-bottom: 0;
  line-height: 1;
}
.hero .hero-content .hero-list {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .hero .hero-content .hero-list {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .hero .hero-content .hero-list {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
.hero .hero-content .hero-list .icon {
  position: relative;
  margin-right: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 3px;
  width: 110px;
  height: 135px;
  background-color: var(--global--color-white);
  transition: 0.3s ease-in-out;
}
.hero .hero-content .hero-list .icon:last-child {
  margin-right: 0;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .hero .hero-content .hero-list .icon {
    margin-right: 15px;
  }
}
@media screen and (max-width:767.98px) {
  .hero .hero-content .hero-list .icon {
    margin-right: 0;
    margin: 0 15px 25px;
  }
  .hero .hero-content .hero-list .icon:last-child {
    margin-right: 15px;
  }
}
.hero .hero-content .hero-list .icon:hover {
  background-color: var(--global--color-primary);
  animation: Bounce 0.8s ease-in-out;
}
.hero .hero-content .hero-list .icon a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 5;
}
.hero .hero-content .hero-list .icon i {
  display: inline-block;
  font-size: 48px;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  margin-bottom: 13px;
}
.hero .hero-content .hero-list .icon span {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 15px;
  color: var(--global--color-heading);
  text-transform: capitalize;
}
.hero.hero-1 .hero-content .hero-action .btn:first-child {
  width: 220px;
}
@media screen and (min-width:992px) {
  .hero.hero-1 .hero-content .hero-action .btn:first-child {
    margin-right: 40px;
  }
}
.hero.hero-3 .hero-content .hero-action .btn {
  width: 244px;
}
.hero.hero-4 .hero-content .hero-action .btn {
  width: 260px;
}
.hero.hero-5 .hero-content .hero-action .btn {
  width: 228px;
}
@media screen and (min-width:992px) {
  .hero.hero-6 .hero-content .hero-desc {
    max-width: 80%;
  }
}
.hero.hero-6 .hero-content .hero-action .btn {
  width: 240px;
}
.hero.hero-7 .hero-content .hero-action .btn {
  width: 184px;
}
.hero.hero-7 .hero-content .hero-action .btn:last-child {
  width: 154px;
}
.hero.hero-8 .hero-content .hero-action .btn {
  width: 218px;
}
.hero.hero-8 .hero-content .hero-action .btn:last-child {
  width: 184px;
}
.hero.hero-9 .hero-content .hero-action .btn {
  width: 190px;
}
.hero.hero-9 .hero-content .hero-action .btn:last-child {
  width: 172px;
}
@media screen and (min-width:992px) {
  .hero.hero-10 {
    height: 832px;
  }
}
@media screen and (max-width:991.98px) {
  .hero.hero-10 {
    height: 100vh;
  }
}
@media screen and (max-width:767.98px) {
  .hero.hero-10 {
    height: 120vh;
  }
}
@media screen and (min-width:992px) {
  .hero.hero-11 {
    height: 765px;
  }
}
@media screen and (max-width:991.98px) {
  .hero.hero-11 {
    height: 85vh;
  }
}
@media screen and (max-width:767.98px) {
  .hero.hero-11 {
    height: 100vh;
  }
}
.hero.hero-12 {
  height: 572px;
}
.hero.hero-12 .hero-content .hero-desc {
  margin-bottom: 0;
}
.hero.hero-13 {
  height: 550px;
}
.hero.hero-13 .hero-content .hero-desc {
  margin-bottom: 0;
}
.hero.hero-14 {
  height: 600px;
}
@media screen and (max-width:767.98px) {
  .hero.hero-14 {
    height: 100vh;
  }
}
.hero.hero-14 .hero-content .hero-action {
  justify-content: center;
}
.hero.hero-14 .hero-content .hero-action .btn:first-child {
  width: 218px;
}
.hero.hero-14 .hero-content .hero-action .btn:last-child {
  width: 176px;
}
.hero.hero-15 .hero-content .hero-action .btn:first-child {
  width: 190px;
}
.hero.hero-15 .hero-content .hero-action .btn:last-child {
  width: 172px;
}
.hero.hero-16 .hero-content .hero-action .btn {
  width: 260px;
}

/*------------------------------------*\
    #Awards
\*------------------------------------*/
.awards {
  padding-top: 116px;
  padding-bottom: 121px;
}
@media screen and (max-width:991.98px) {
  .awards {
    padding-top: 70px;
    padding-bottom: 61px;
  }
}
@media screen and (max-width:991.98px) {
  .awards .award {
    max-width: 370px;
    margin: 0 auto;
  }
}
.awards .award:hover .award-img {
  background-color: var(--global--color-primary);
}
.awards .award .award-img {
  padding: 45px 85px;
  background-color: #f4f4f4;
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .awards .award .award-img {
    padding-left: 45px;
    padding-right: 45px;
  }
}
@media screen and (max-width:991.98px) {
  .awards .award .award-img {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.awards .award .award-img img {
  max-width: 100%;
}
.awards .award .award-content {
  padding: 29px 0 0;
}
@media screen and (max-width:991.98px) {
  .awards .award .award-content {
    text-align: center;
  }
}
.awards .award .award-content .award-title {
  margin-bottom: 19px;
}
.awards .award .award-content .award-title a {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: var(--global--color-heading-light);
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
}
.awards .award .award-content .award-title a:hover {
  color: var(--global--color-primary);
}
.awards .award .award-content .award-desc {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--global--color-body);
  margin-bottom: 0;
}

/*------------------------------------*\
#Highlights
\*------------------------------------*/
.highlights.highlights-1 {
  padding-top: 28px;
}
@media screen and (min-width:992px) {
  .highlights.highlights-1 .carousel-navs .owl-nav {
    position: absolute;
    right: 0;
    top: -103px;
  }
}
.highlights.highlights-1 .note {
  position: relative;
  z-index: 5;
}
@media screen and (min-width:992px) {
  .highlights.highlights-1 .note {
    margin-top: 4px;
  }
}

.highlight-panel {
  background-color: var(--global-color-white);
  transition: 0.3s ease-in-out;
  position: relative;
  margin-bottom: 40px;
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
}
@media screen and (max-width:991.98px) {
  .highlight-panel {
    max-width: 370px;
    margin: 0 auto 40px;
  }
}
@media screen and (max-width:767.98px) {
  .highlight-panel {
    margin: 0 auto 30px;
  }
}
.highlight-panel:hover {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.highlight-panel:hover .highlight-img::before {
  opacity: 1;
  visibility: visible;
}
.highlight-panel:hover .highlight-img .highlight-more {
  opacity: 1;
  visibility: visible;
}
.highlight-panel .highlight-img {
  position: relative;
}
.highlight-panel .highlight-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(225deg, rgba(0, 0, 0, 0.2) 55%, rgba(0, 0, 0, 0.35) 100%);
  z-index: 2;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.highlight-panel .highlight-img img {
  width: 100%;
  height: 192px;
  object-fit: cover;
}
.highlight-panel .highlight-img .highlight-more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: transparent;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
}
.highlight-panel .highlight-img .highlight-more i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: var(--global--color-white);
  border-radius: 50%;
  color: var(--global--color-heading);
  font-size: 11px;
  transition: 0.3s ease-in-out;
}
.highlight-panel .highlight-content {
  display: flex;
  flex-direction: column;
  padding: 33px 40px 33px;
  background-color: var(--global--color-white);
}
@media (min-width:992px) and (max-width:1199.98px) {
  .highlight-panel .highlight-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .highlight-panel .highlight-content {
    text-align: center;
  }
}
.highlight-panel .highlight-content .highlight-body .highlight-name {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  position: relative;
  margin-bottom: 16px;
}
.highlight-panel .highlight-content .highlight-body .highlight-name a {
  color: var(--global--color-heading-light);
}
.highlight-panel .highlight-content .highlight-body .highlight-name a:hover {
  color: var(--global--color-primary);
}
.highlight-panel .highlight-content .highlight-body .highlight-desc {
  color: var(--global--color-body);
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 0px;
  transition: 0.3s ease-in-out;
}

/*------------------------------------*\
    #Reviews
\*------------------------------------*/
.reviews {
  padding-top: 0;
  padding-bottom: 0;
  overflow: visible;
  z-index: 2;
}
.reviews .social-rates-wrapper {
  background-color: #f9f9f9;
}
.reviews .social-rates-wrapper .social-rates {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}
@media screen and (max-width:767.98px) {
  .reviews .social-rates-wrapper .social-rates {
    flex-direction: column;
  }
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper {
  border-right: 2px solid #eaeaea;
  position: relative;
  padding: 0 35px;
  max-width: 280px;
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper:last-child {
  border-right: 0;
}
@media screen and (max-width:991.98px) {
  .reviews .social-rates-wrapper .social-rates .rate-panel-wrapper {
    padding: 0 15px;
  }
}
@media screen and (max-width:767.98px) {
  .reviews .social-rates-wrapper .social-rates .rate-panel-wrapper {
    padding: 25px 0;
    border-right: 0;
    border-bottom: 2px solid #eaeaea;
  }
  .reviews .social-rates-wrapper .social-rates .rate-panel-wrapper:first-child {
    padding-top: 0;
  }
  .reviews .social-rates-wrapper .social-rates .rate-panel-wrapper:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel {
  display: flex;
  align-items: center;
  position: relative;
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel img {
  max-width: 100%;
  margin-right: 13px;
}
@media screen and (max-width:767.98px) {
  .reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel img {
    margin-right: 20px;
  }
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel .rating-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel .rate-body .rate-title {
  font-family: var(--global--font-body);
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #616161;
  margin-bottom: 9px;
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel .rate-body .rating {
  display: flex;
  align-items: center;
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel .rate-body .rating i {
  font-size: 12px;
  color: var(--global--color-body);
  margin-right: 5px;
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel .rate-body .rating i:last-child {
  margin-right: 0;
}
.reviews .social-rates-wrapper .social-rates .rate-panel-wrapper .rate-panel .rate-body .rating i.active {
  color: var(--global--color-primary);
}
.reviews .clients-reviews {
  padding-top: 90px;
  padding-bottom: 127px;
}
@media screen and (max-width:991.98px) {
  .reviews .clients-reviews {
    padding-top: 70px;
    padding-bottom: 67px;
  }
}
.reviews .clients-reviews .review-panel {
  display: flex;
  padding-top: 50px;
  padding-bottom: 47px;
  border-bottom: 2px solid #eaeaea;
}
.reviews .clients-reviews .review-panel:first-child {
  padding-top: 0;
}
.reviews .clients-reviews .review-panel:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
@media screen and (max-width:767.98px) {
  .reviews .clients-reviews .review-panel {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
.reviews .clients-reviews .review-panel .review-icon {
  margin-right: 40px;
  width: 65px;
  flex-shrink: 0;
}
@media screen and (max-width:767.98px) {
  .reviews .clients-reviews .review-panel .review-icon {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
  }
}
.reviews .clients-reviews .review-panel .review-icon img {
  max-width: 100%;
}
@media screen and (min-width:768px) {
  .reviews .clients-reviews .review-panel .review-body {
    transform: translateY(-8px);
    margin-bottom: -8px;
  }
}
.reviews .clients-reviews .review-panel .review-body p {
  font-family: var(--global--font-heading);
  font-size: 21px;
  line-height: 32px;
  font-weight: 700;
  color: var(--global--color-heading-light);
  margin-bottom: 19px;
}
.reviews .clients-reviews .review-panel .review-body .review-content {
  display: flex;
  align-items: center;
}
@media screen and (max-width:767.98px) {
  .reviews .clients-reviews .review-panel .review-body .review-content {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
.reviews .clients-reviews .review-panel .review-body .review-content .rating {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
@media screen and (max-width:767.98px) {
  .reviews .clients-reviews .review-panel .review-body .review-content .rating {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.reviews .clients-reviews .review-panel .review-body .review-content .rating i {
  font-size: 12px;
  color: var(--global--color-body);
  margin-right: 5px;
}
.reviews .clients-reviews .review-panel .review-body .review-content .rating i:last-child {
  margin-right: 0;
}
.reviews .clients-reviews .review-panel .review-body .review-content .rating i.active {
  color: var(--global--color-primary);
}
.reviews .clients-reviews .review-panel .review-body .review-content .review-meta {
  display: flex;
  align-items: center;
}
.reviews .clients-reviews .review-panel .review-body .review-content .review-meta .review-author {
  display: flex;
  align-items: center;
  font-family: var(--global--font-body);
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  color: var(--global--color-heading);
  margin-bottom: 0px;
  margin-right: 6px;
  text-transform: capitalize;
}
.reviews .clients-reviews .review-panel .review-body .review-content .review-meta .review-author::after {
  content: ",";
}
.reviews .clients-reviews .review-panel .review-body .review-content .review-meta .review-title {
  font-family: var(--global--font-body);
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  color: var(--global--color-body);
  margin-bottom: 0px;
  text-transform: capitalize;
}

/*------------------------------------*\
    #CASE STUDIES
\*------------------------------------*/
.case-studies {
  padding-top: 123px;
  padding-bottom: 104px;
  /* Gallery Filter */
}
@media screen and (max-width:991.98px) {
  .case-studies {
    padding-top: 63px;
    padding-bottom: 64px;
  }
}
.case-studies .case-studies-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .case-studies .case-studies-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .case-studies .case-studies-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.case-studies .case-studies-action .btn {
  width: 218px;
  height: 68px;
  margin-right: 30px;
}
@media screen and (max-width:767.98px) {
  .case-studies .case-studies-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 200px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .case-studies .case-studies-action .btn {
    width: 200px;
    margin-right: 15px;
  }
}
.case-studies .case-studies-action .contact-num {
  display: flex;
  align-items: center;
}
.case-studies .case-studies-action .contact-num .contact-icon-wrapper {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: transparent;
  border: 2px solid var(--global--color-primary);
  border-radius: 50%;
  margin-right: 10px;
}
.case-studies .case-studies-action .contact-num .contact-icon-wrapper .contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: var(--global--color-heading);
  border-radius: 50%;
}
.case-studies .case-studies-action .contact-num .contact-icon-wrapper .contact-icon svg {
  color: var(--global--color-primary);
}
.case-studies .case-studies-action .contact-num .contact-body a {
  display: block;
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 17px;
  color: var(--global--color-heading-light);
  margin-bottom: 10px;
  line-height: 1;
}
.case-studies .case-studies-action .contact-num .contact-body .job-title {
  font-family: var(--global--color-secondary);
  font-weight: 400;
  font-size: 13px;
  color: var(--global--color-body);
  margin-bottom: 0;
  line-height: 1;
}
.case-studies .carousel .owl-stage-outer {
  overflow: visible;
}
.case-studies .carousel .owl-stage-outer .owl-item {
  opacity: 0;
}
.case-studies .carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}
.case-studies .gallery-filters {
  text-align: center;
  margin-bottom: 20px;
}
.case-studies .gallery-filters ul {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.case-studies .gallery-filters ul li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
}
.case-studies .gallery-filters ul li:last-child {
  margin-right: 0;
}
.case-studies .gallery-filters ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background-color: transparent;
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  color: var(--global--color-heading);
  text-transform: capitalize;
  padding: 0px 15px;
  background-color: transparent;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}
.case-studies .gallery-filters ul li a.active-filter {
  background-color: var(--global--color-primary);
}
.case-studies.case-studies-1 .divider {
  height: 1px;
  background-color: rgb(234, 234, 234);
  margin-top: 70px;
  margin-bottom: 62px;
}
.case-studies.case-studies-1 .advantages-list {
  margin-bottom: 32px;
}
@media screen and (max-width:767.98px) {
  .case-studies.case-studies-1 .advantages-list {
    max-width: 550px;
    margin: 0 auto 32px;
  }
}
.case-studies.case-studies-2 {
  padding-top: 136px;
  padding-bottom: 0px;
  overflow: visible;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .case-studies.case-studies-2 {
    padding-top: 63px;
    padding-bottom: 0px;
  }
}
.case-studies.case-studies-2 .case-entry {
  margin-bottom: 32px;
}
@media screen and (min-width:992px) {
  .case-studies.case-studies-2 .carousel .owl-nav {
    position: absolute;
    top: 0;
    right: 50px;
    transform: translateY(-50%);
  }
}
@media screen and (max-width:991.98px) {
  .case-studies.case-studies-2 .carousel .owl-nav {
    display: none;
  }
}
.case-studies.case-studies-2 .carousel .owl-nav .owl-prev,
.case-studies.case-studies-2 .carousel .owl-nav .owl-next {
  border: 10px solid #000;
  width: 78px;
  height: 78px;
}
.case-studies.case-studies-2 .carousel .owl-nav .owl-prev {
  margin-right: 0;
}
@media screen and (min-width:992px) {
  .case-studies.case-studies-2 .carousel .owl-dots {
    justify-content: flex-end;
    position: absolute;
    bottom: -13px;
    width: 100%;
  }
}
@media screen and (max-width:991.98px) {
  .case-studies.case-studies-2 .carousel .owl-dots {
    margin-bottom: 32px;
  }
}
.case-studies.case-studies-2 .note {
  margin-bottom: 122px;
}
@media screen and (max-width:991.98px) {
  .case-studies.case-studies-2 .note {
    margin-bottom: 72px;
  }
}
@media screen and (min-width:992px) {
  .case-studies.case-studies-2 .testimonial-1 {
    transform: translateY(130px);
    margin-top: -130px;
  }
}
.case-studies.case-studies-3 {
  padding-top: 128px;
  padding-bottom: 110px;
}
@media screen and (max-width:991.98px) {
  .case-studies.case-studies-3 {
    padding-top: 63px;
    padding-bottom: 70px;
  }
}
.case-studies.case-studies-3 .btn {
  margin-top: 10px;
  width: 218px;
  height: 68px;
  display: inline-flex;
}
.case-studies.case-studies-4 {
  padding-top: 100px;
  padding-bottom: 110px;
}
@media screen and (max-width:991.98px) {
  .case-studies.case-studies-4 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.case-entry {
  margin-bottom: 40px;
  position: relative;
  background-color: var(--global--color-white);
  transition: 0.3s ease-in-out;
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
}
@media screen and (max-width:991.98px) {
  .case-entry {
    max-width: 370px;
    margin: 0 auto 40px;
    text-align: center;
  }
}
.case-entry:hover {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.case-entry:hover .entry-img .entry-img-holder img {
  transform: scale3d(1, 1, 1);
}
.case-entry:hover .entry-content .entry-body .entry-more a {
  color: var(--global--color-white);
  background-color: var(--global--color-heading);
  border-color: var(--global--color-heading);
}
.case-entry:hover .entry-content .entry-body .entry-more a i {
  color: var(--global--color-white);
}
.case-entry .entry-img {
  position: relative;
}
.case-entry .entry-img .entry-img-holder {
  overflow: hidden;
}
.case-entry .entry-img .entry-img-holder img {
  transition: transform 0.35s ease-in-out;
  width: 100%;
  transform: scale3d(1.05, 1.05, 1);
  height: 267px;
  object-fit: cover;
}
.case-entry .entry-img a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 4;
}
.case-entry .entry-content {
  padding: 32px 40px 40px;
  position: relative;
  z-index: 6;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .case-entry .entry-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .case-entry .entry-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.case-entry .entry-content .entry-category {
  position: absolute;
  left: 40px;
  top: -40px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0px;
  text-transform: capitalize;
  height: 40px;
  padding: 0 15px;
  background-color: var(--global--color-primary);
  border-radius: 3px 3px 0 0;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .case-entry .entry-content .entry-category {
    left: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .case-entry .entry-content .entry-category {
    left: 50%;
    transform: translateX(-50%);
  }
}
.case-entry .entry-content .entry-category a {
  position: relative;
  color: var(--global--color-heading);
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.case-entry .entry-content .entry-category a::after {
  content: ", ";
  position: relative;
  margin-right: 4px;
}
.case-entry .entry-content .entry-category a:last-of-type::after {
  content: "";
}
.case-entry .entry-content .entry-category a:hover {
  color: var(--global--color-white);
}
.case-entry .entry-content .entry-body .entry-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 16px;
}
.case-entry .entry-content .entry-body .entry-title a {
  color: var(--global--color-heading);
}
.case-entry .entry-content .entry-body .entry-title a:hover {
  color: var(--global--color-primary);
}
.case-entry .entry-content .entry-body .entry-bio {
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  color: var(--global--color-body);
  margin-bottom: 23px;
}
.case-entry .entry-content .entry-body .entry-more a {
  display: inline-flex;
  border-color: #bcbcbc;
  height: 58px;
  width: 170px;
  padding: 0 15px;
  position: relative;
  transition: all 300ms ease-in-out;
  color: var(--global--color-heading);
}
.case-entry .entry-content .entry-body .entry-more a::before {
  content: unset;
}
.case-entry .entry-content .entry-body .entry-more a i {
  color: var(--global--color-heading);
}
.case-entry.case-entry-2 {
  background-color: transparent;
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
  height: 487px;
}
.case-entry.case-entry-2:hover .entry-content .entry-body {
  transform: translateY(0);
}
.case-entry.case-entry-2:hover .entry-content .entry-body .entry-more {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.case-entry.case-entry-2 .entry-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}
.case-entry.case-entry-2 .entry-img .entry-img-holder::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 55%, rgba(0, 0, 0, 0.35) 100%);
  z-index: 2;
  transition: 0.3s ease-in-out;
}
.case-entry.case-entry-2 .entry-img .entry-img-holder img {
  height: 487px;
}
.case-entry.case-entry-2 .entry-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 50px 43px;
}
@media screen and (max-width:991.98px) {
  .case-entry.case-entry-2 .entry-content {
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.case-entry.case-entry-2 .entry-content .entry-category {
  width: max-content;
  position: relative;
  left: auto;
  top: auto;
}
@media screen and (max-width:991.98px) {
  .case-entry.case-entry-2 .entry-content .entry-category {
    transform: translateX(0);
  }
}
.case-entry.case-entry-2 .entry-content .entry-body {
  transform: translateY(40px);
  transition: 0.3s ease-in-out;
}
@media screen and (min-width:1200px) {
  .case-entry.case-entry-2 .entry-content .entry-body .entry-title {
    max-width: 70%;
  }
}
.case-entry.case-entry-2 .entry-content .entry-body .entry-title a {
  color: var(--global--color-white);
}
.case-entry.case-entry-2 .entry-content .entry-body .entry-bio {
  color: #f9f9f9;
  margin-bottom: 17px;
}
@media screen and (min-width:1200px) {
  .case-entry.case-entry-2 .entry-content .entry-body .entry-bio {
    max-width: 80%;
  }
}
.case-entry.case-entry-2 .entry-content .entry-body .entry-more {
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
}
.case-entry.case-entry-2 .entry-content .entry-body .entry-more .btn {
  background-color: transparent;
  padding: 0;
  width: auto;
  height: auto;
  color: var(--global--color-white);
  border: 0;
}
.case-entry.case-entry-2 .entry-content .entry-body .entry-more .btn:hover i {
  transform: translateX(5px);
}
.case-entry.case-entry-2 .entry-content .entry-body .entry-more .btn i {
  color: var(--global--color-white);
}
.case-entry.case-entry-3 {
  background-color: transparent;
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
  height: 420px;
}
.case-entry.case-entry-3:hover {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.case-entry.case-entry-3:hover .entry-img .entry-img-holder::before {
  opacity: 1;
  visibility: visible;
}
.case-entry.case-entry-3:hover .entry-img .entry-more {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}
.case-entry.case-entry-3:hover .entry-content {
  background-color: transparent;
}
.case-entry.case-entry-3:hover .entry-content .entry-category a {
  color: var(--global--color-primary);
}
.case-entry.case-entry-3:hover .entry-content .entry-body .entry-title a {
  color: var(--global--color-white);
}
.case-entry.case-entry-3 .entry-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}
.case-entry.case-entry-3 .entry-img .entry-img-holder::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 55%, rgba(0, 0, 0, 0.85) 100%);
  z-index: 2;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.case-entry.case-entry-3 .entry-img .entry-img-holder img {
  height: 420px;
}
.case-entry.case-entry-3 .entry-img .entry-more {
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: transparent;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  z-index: 5;
}
.case-entry.case-entry-3 .entry-img .entry-more i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: var(--global--color-white);
  border-radius: 50%;
  color: var(--global--color-heading);
  font-size: 12px;
  transition: 0.3s ease-in-out;
}
.case-entry.case-entry-3 .entry-content {
  background-color: var(--global--color-white);
  z-index: 5;
  display: flex;
  flex-direction: column;
  padding: 32px 40px 33px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width:1199.98px) {
  .case-entry.case-entry-3 .entry-content {
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.case-entry.case-entry-3 .entry-content .entry-category {
  display: flex;
  width: max-content;
  position: relative;
  left: auto;
  top: auto;
  padding: 0;
  background-color: transparent;
  height: auto;
  width: auto;
  margin-bottom: 5px;
}
@media screen and (min-width:992px) {
  .case-entry.case-entry-3 .entry-content .entry-category {
    justify-content: flex-start;
    width: 100%;
  }
}
@media screen and (max-width:991.98px) {
  .case-entry.case-entry-3 .entry-content .entry-category {
    justify-content: center;
    transform: translateX(0);
  }
}
.case-entry.case-entry-3 .entry-content .entry-category a {
  color: var(--global--color-heading);
}
.case-entry.case-entry-3 .entry-content .entry-body {
  transition: 0.3s ease-in-out;
}
.case-entry.case-entry-3 .entry-content .entry-body .entry-title {
  margin-bottom: 0;
}
.case-entry.case-entry-3 .entry-content .entry-body .entry-title a {
  color: var(--global--color-heading);
}

.case-study-head {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  height: 660px;
  position: relative;
  padding-top: 110px;
}
@media screen and (max-width:991.98px) {
  .case-study-head {
    height: 80vh;
    padding-top: 80px;
  }
}
@media screen and (max-width:767.98px) {
  .case-study-head {
    height: 100vh;
  }
}
@media screen and (max-width:991.98px) {
  .case-study-head .case-study-head-content {
    text-align: center;
  }
}
.case-study-head .case-study-head-content .case-head-category {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0px;
  text-transform: capitalize;
  height: 30px;
  padding: 0 10px;
  background-color: var(--global--color-primary);
  border-radius: 3px;
  margin-bottom: 20px;
}
.case-study-head .case-study-head-content .case-head-category a {
  position: relative;
  color: var(--global--color-heading);
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.case-study-head .case-study-head-content .case-head-category a::after {
  content: ", ";
  position: relative;
  margin-right: 4px;
}
.case-study-head .case-study-head-content .case-head-category a:last-of-type::after {
  content: "";
}
.case-study-head .case-study-head-content .case-head-title {
  font-family: var(--global--font-heading);
  color: var(--global--color-white);
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 24px;
  text-transform: capitalize;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .case-study-head .case-study-head-content .case-head-title {
    font-size: 35px;
    line-height: 1.4;
  }
}
@media screen and (max-width:767.98px) {
  .case-study-head .case-study-head-content .case-head-title {
    font-size: 32px;
    line-height: 1.4;
  }
}
.case-study-head .case-study-head-content .case-head-desc {
  font-family: var(--global--font-body);
  color: #f9f9f9;
  font-size: 17px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 0px;
}
.case-study-head .case-study-head-content .case-head-scroll-down {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width:991.98px) {
  .case-study-head .case-study-head-content .case-head-scroll-down {
    bottom: 25px;
  }
}
.case-study-head .case-study-head-content .case-head-scroll-down a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
.case-study-head .case-study-head-content .case-head-scroll-down a:hover {
  transform: scale(0.9);
}

.case-study-body {
  padding-top: 124px;
  padding-bottom: 125px;
}
@media screen and (max-width:991.98px) {
  .case-study-body {
    padding-top: 64px;
    padding-bottom: 65px;
  }
}
.case-study-body .entry-infos {
  margin-bottom: 43px;
}
@media screen and (max-width:991.98px) {
  .case-study-body .entry-infos {
    text-align: center;
  }
}
.case-study-body .entry-infos .entry-title {
  font-family: var(--global--font-heading);
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: var(--global--color-heading-darken);
  margin-bottom: 18px;
  text-transform: capitalize;
}
.case-study-body .entry-infos .entry-desc {
  font-family: var(--global--font-body);
  font-size: 17px;
  line-height: 27px;
  font-weight: 400;
  color: var(--global--color-body);
  margin-bottom: 26px;
}
.case-study-body .entry-infos .entry-desc:last-child {
  margin-bottom: 0;
}
.case-study-body .entry-infos .advantages-list {
  margin-top: 2px;
}
.case-study-body .entry-carousel {
  margin-bottom: 64px;
}
.case-study-body .entry-carousel img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-bottom: 40px;
}

/*------------------------------------*\
    #Projects Gallery
\*------------------------------------*/
.projects-gallery {
  padding: 110px 0;
}
@media screen and (max-width:991.98px) {
  .projects-gallery {
    padding: 70px 0;
  }
}
.projects-gallery .project-panel {
  position: relative;
  margin-bottom: 40px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width:991.98px) {
  .projects-gallery .project-panel {
    max-width: 370px;
    margin: 0 auto 40px;
  }
}
.projects-gallery .project-panel:hover .project-img::before {
  opacity: 1;
  visibility: visible;
}
.projects-gallery .project-panel:hover .project-img .project-zoom {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}
.projects-gallery .project-panel .project-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 55%, rgba(0, 0, 0, 0.35) 100%);
  z-index: 2;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.projects-gallery .project-panel .project-img img {
  border-radius: 6px;
  object-fit: cover;
  height: 267px;
  width: 100%;
}
.projects-gallery .project-panel .project-img .project-zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out;
}
.projects-gallery .project-panel .project-img .project-zoom i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: var(--global--color-white);
  border-radius: 50%;
  color: var(--global--color-heading);
  font-size: 12px;
  transition: 0.3s ease-in-out;
}
.projects-gallery .project-panel .project-img .project-zoom a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.projects-gallery .btn {
  display: inline-flex;
  width: 150px;
  height: 68px;
}

/*------------------------------------*\
#CTA
\*------------------------------------*/
.cta {
  padding-top: 130px;
  padding-bottom: 110px;
  position: relative;
  overflow: visible;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .cta {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.cta .cta-wrapper {
  position: relative;
}
.cta .advantages-list {
  margin-bottom: 32px;
}
.cta .cta-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .cta .cta-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .cta .cta-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.cta .cta-action .btn {
  width: 228px;
  height: 68px;
  margin-right: 30px;
  padding: 0 15px;
}
@media screen and (max-width:767.98px) {
  .cta .cta-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 220px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .cta .cta-action .btn {
    width: 220px;
    margin-right: 15px;
  }
}
.cta .cta-action .btn:last-child {
  margin-right: 0;
}
@media screen and (max-width:767.98px) {
  .cta .cta-action .btn:last-child {
    margin-bottom: 0;
  }
}
.cta .cta-img-holder {
  display: flex;
  justify-content: center;
}
@media screen and (min-width:992px) {
  .cta .cta-img-holder {
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 50%;
    z-index: 7;
  }
}
.cta .cta-img-holder .cta-img {
  position: relative;
}
@media screen and (max-width:991.98px) {
  .cta .cta-img-holder .cta-img {
    margin: 0 auto 50px;
  }
}
.cta .cta-img-holder .cta-img img {
  width: 100%;
  border-radius: 0px;
  height: 529px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
@media screen and (max-width:991.98px) {
  .cta .cta-img-holder .cta-img img {
    max-width: 550px;
    height: 550px;
    margin: 0 auto;
  }
}
@media screen and (max-width:767.98px) {
  .cta .cta-img-holder .cta-img img {
    max-width: 100%;
  }
}
.cta .cta-img-holder .cta-img .edge-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  width: 110px;
  height: 110px;
}
.cta .cta-img-holder .cta-img .cta-banner-holder {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--global--color-primary);
  max-width: 250px;
  z-index: 5;
  padding: 33px 40px 12px;
}
.cta .cta-img-holder .cta-img .cta-banner-holder .banner-title {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: var(--global--color-white);
  margin-bottom: 19px;
}
.cta .cta-img-holder .cta-img .cta-banner-holder .banner-desc {
  margin-bottom: 35px;
  color: #f9f9f9;
  font-size: 14px;
  line-height: 22px;
}
.cta .cta-img-holder .cta-img .cta-banner-holder .btn {
  width: 100%;
  margin-bottom: 14px;
}
.cta .cta-img-holder .cta-img .cta-banner-holder .banner-note {
  font-size: 13px;
  line-height: 24px;
  color: var(--global--color-heading);
  margin-bottom: 0;
}
.cta.cta-2 {
  padding: 0;
  position: relative;
  background-color: var(--global--color-primary);
}
@media screen and (min-width:992px) {
  .cta.cta-2 {
    transform: translateY(109px);
    margin-top: -109px;
    background-color: transparent;
  }
}
.cta.cta-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--global--color-primary);
  height: 100%;
  width: 50%;
}
.cta.cta-2 .cta-wrapper {
  background-color: var(--global--color-primary);
  padding-top: 122px;
  padding-bottom: 120px;
  position: relative;
  clip-path: polygon(90% 0, 100% 18%, 100% 100%, 0 100%, 0 0);
}
@media screen and (max-width:991.98px) {
  .cta.cta-2 .cta-wrapper {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.cta.cta-2 .cta-wrapper .cta-newsletter {
  margin-top: 46px;
}
@media screen and (max-width:991.98px) {
  .cta.cta-2 .cta-wrapper .cta-newsletter {
    margin-bottom: 40px;
  }
}
.cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
@media screen and (max-width:767.98px) {
  .cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter {
    flex-direction: column;
    justify-content: center;
  }
}
.cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter .form-control {
  border: none;
  box-shadow: none;
  color: #616161;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1;
  width: 290px;
  height: 68px;
  padding: 25px 25px 25px 40px;
  background-color: var(--global--color-white);
  border-radius: 3px;
  margin-bottom: 0;
  margin-right: 30px;
}
@media screen and (max-width:991.98px) {
  .cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter .form-control {
    padding-left: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter .form-control {
    margin-bottom: 25px;
    margin-right: 0px;
  }
}
.cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}
.cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter .form-control:-moz-placeholder {
  color: #616161;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}
.cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}
.cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter .btn {
  width: 214px;
  height: 68px;
}
.cta.cta-2 .cta-wrapper .cta-newsletter .form-newsletter .btn::before {
  background-color: var(--global--color-white);
}
.cta.cta-2 .cta-wrapper .cta-newsletter p {
  font-weight: 700;
  font-size: 15px;
  color: var(--global--color-heading-light);
  margin-bottom: 0;
}
.cta.cta-2 .cta-wrapper .video-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.cta.cta-2 .cta-wrapper .video-btn-wrapper .btn--video {
  background-color: var(--global--color-white);
  width: 128px;
  height: 128px;
}
@media screen and (max-width:767.98px) {
  .cta.cta-2 .cta-wrapper .video-btn-wrapper .btn--video {
    width: 80px;
    height: 80px;
  }
}

.call-to-action .cta-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .call-to-action .cta-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .call-to-action .cta-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.call-to-action .cta-action .btn {
  width: 260px;
  height: 68px;
  margin-right: 30px;
  padding: 0 15px;
}
@media screen and (max-width:767.98px) {
  .call-to-action .cta-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 220px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .call-to-action .cta-action .btn {
    width: 220px;
    margin-right: 15px;
  }
}
.call-to-action .cta-action .btn:last-child {
  margin-right: 0;
  width: 154px;
}
@media screen and (max-width:767.98px) {
  .call-to-action .cta-action .btn:last-child {
    margin-bottom: 0;
  }
}

.cta-banner {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #161616;
  max-width: 440px;
  z-index: 5;
  padding: 52px 50px 60px;
}
.cta-banner .cta-body {
  margin-bottom: 22px;
}
.cta-banner .cta-body .cta-name {
  font-size: 20px;
  line-height: 29px;
  color: var(--global--color-white);
  text-transform: capitalize;
  margin-bottom: 16px;
}
.cta-banner .cta-body .cta-desc {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 0;
}
@media screen and (min-width:992px) {
  .cta-banner .carousel-dots .owl-dots {
    justify-content: flex-start;
  }
}
.cta-banner .carousel-dots .owl-dots .owl-dot span {
  background-color: #505050;
  position: relative;
}
.cta-banner .carousel-dots .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  width: 0px;
  height: 6px;
  background-color: var(--global--color-primary);
  transition: 0.3s ease-in-out;
}
.cta-banner .carousel-dots .owl-dots .owl-dot.active span {
  background-color: #505050;
}
.cta-banner .carousel-dots .owl-dots .owl-dot.active span::before {
  width: 30px;
}

/*------------------------------------*\
#Counters
\*------------------------------------*/
.counters {
  padding-top: 260px;
  padding-bottom: 122px;
}
@media screen and (max-width:991.98px) {
  .counters {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.counters .counter-img-holder {
  display: flex;
  justify-content: center;
}
.counters .counter-img-holder .counter-img {
  position: relative;
}
@media screen and (max-width:991.98px) {
  .counters .counter-img-holder .counter-img {
    margin: 0 auto 0px;
  }
}
.counters .counter-img-holder .counter-img img {
  border-radius: 0px;
  height: 520px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
@media screen and (max-width:991.98px) {
  .counters .counter-img-holder .counter-img img {
    max-width: 550px;
    margin: 0 auto;
  }
}
@media screen and (max-width:767.98px) {
  .counters .counter-img-holder .counter-img img {
    max-width: 100%;
  }
}
.counters .counter-img-holder .counter-img .edge-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 110px;
  height: 110px;
}
.counters .counter-img-holder .counter-img .counters-holder {
  position: absolute;
  left: 40px;
  bottom: 0;
  background-color: var(--global--color-primary);
  max-width: 210px;
  padding: 32px 30px 40px;
  z-index: 5;
}
.counters .counter-img-holder .counter-img .counters-holder::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 40px;
  height: 30px;
  background-color: var(--global--color-primary);
  clip-path: polygon(100% 0, 0 0, 0 100%);
}
.counters .counter-img-holder .counter-img .counters-holder .counter .counter-num {
  font-family: var(--global--font-heading);
  font-weight: 600;
  font-size: 50px;
  color: var(--global--color-heading-darken);
  margin-bottom: 19px;
}
.counters .counter-img-holder .counter-img .counters-holder .counter .counter-name h3 {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  color: var(--global--color-heading-light);
  margin-bottom: 0;
}
.counters .counter-img-holder .counter-img .counters-holder .owl-dots {
  justify-content: flex-start;
  margin-top: 23px;
}
.counters.counters-1 .advantages-list {
  margin-bottom: 34px;
}
.counters.counters-2 {
  padding-top: 130px;
}
@media screen and (max-width:991.98px) {
  .counters.counters-2 {
    padding-top: 70px;
  }
}
.counters.counters-2 .advantages-list {
  margin-bottom: 34px;
}

/*------------------------------------*\
#How Works
\*------------------------------------*/
.how-works {
  padding-top: 0px;
  padding-bottom: 250px;
  position: relative;
  overflow: visible;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .how-works {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.how-works .how-works-wrapper {
  position: relative;
}
.how-works .how-works-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .how-works .how-works-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .how-works .how-works-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.how-works .how-works-action .btn {
  width: 240px;
  height: 68px;
  margin-right: 30px;
  padding: 0 15px;
}
@media screen and (max-width:767.98px) {
  .how-works .how-works-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 220px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .how-works .how-works-action .btn {
    width: 220px;
    margin-right: 15px;
  }
}
.how-works .how-works-action .btn:last-child {
  width: 238px;
  margin-right: 0;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .how-works .how-works-action .btn:last-child {
    width: 220px;
  }
}
@media screen and (max-width:767.98px) {
  .how-works .how-works-action .btn:last-child {
    margin-bottom: 0;
  }
}
.how-works .how-works-img-holder {
  display: flex;
  justify-content: center;
}
@media screen and (min-width:992px) {
  .how-works .how-works-img-holder {
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 41%;
    z-index: 7;
  }
}
.how-works .how-works-img-holder .how-works-img {
  position: relative;
}
@media screen and (max-width:991.98px) {
  .how-works .how-works-img-holder .how-works-img {
    margin: 0 auto 50px;
  }
}
.how-works .how-works-img-holder .how-works-img img {
  width: 100%;
  border-radius: 0px;
  height: 890px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
@media screen and (max-width:991.98px) {
  .how-works .how-works-img-holder .how-works-img img {
    max-width: 550px;
    height: 550px;
    margin: 0 auto;
  }
}
@media screen and (max-width:767.98px) {
  .how-works .how-works-img-holder .how-works-img img {
    max-width: 100%;
  }
}
.how-works .how-works-img-holder .how-works-img .edge-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  width: 110px;
  height: 110px;
}
.how-works .how-works-img-holder .how-works-img .btn--video {
  width: 128px;
  height: 128px;
  background-color: var(--global--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
  z-index: 5;
}
@media screen and (min-width:992px) {
  .how-works .work-steps-wrapper {
    transform: translate(-79px, 74px);
    margin-bottom: -100px;
    position: relative;
    z-index: 10;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .how-works .work-steps-wrapper {
    transform: translate(-107px, 83px);
  }
}
.how-works .work-steps-wrapper .work-step {
  display: flex;
}
@media screen and (max-width:767.98px) {
  .how-works .work-steps-wrapper .work-step {
    flex-direction: column;
  }
}
.how-works .work-steps-wrapper .work-step:first-child .step-num {
  border-top: 1px solid var(--global--color-primary);
}
.how-works .work-steps-wrapper .work-step:first-child .step-info {
  border-top: 1px solid #eaeaea;
}
.how-works .work-steps-wrapper .work-step .step-num {
  width: 150px;
  min-height: 221px;
  background-color: var(--global--color-primary);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eaeaea;
}
@media screen and (max-width:767.98px) {
  .how-works .work-steps-wrapper .work-step .step-num {
    width: 100%;
    border-bottom: 0;
    min-height: 70px;
  }
}
.how-works .work-steps-wrapper .work-step .step-num h3 {
  font-family: var(--global--font-heading);
  font-weight: 500;
  font-size: 60px;
  color: var(--global--color-white);
  margin-bottom: 0;
}
@media screen and (max-width:767.98px) {
  .how-works .work-steps-wrapper .work-step .step-num h3 {
    font-size: 40px;
  }
}
.how-works .work-steps-wrapper .work-step .step-info {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 118px;
  padding-right: 30px;
}
@media screen and (min-width:992px) {
  .how-works .work-steps-wrapper .work-step .step-info {
    height: 221px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .how-works .work-steps-wrapper .work-step .step-info {
    padding-left: 117px;
    padding-right: 0px;
  }
}
@media screen and (max-width:991.98px) {
  .how-works .work-steps-wrapper .work-step .step-info {
    padding: 40px;
  }
}
@media screen and (max-width:767.98px) {
  .how-works .work-steps-wrapper .work-step .step-info {
    padding: 40px 25px;
    text-align: center;
  }
}
.how-works .work-steps-wrapper .work-step .step-info .step-title {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  color: var(--global--color-heading-light);
  margin-bottom: 28px;
}
.how-works .work-steps-wrapper .work-step .step-info .step-desc {
  font-family: var(--global--font-body);
  font-size: 16px;
  line-height: 26px;
  color: var(--global--color-body);
  margin-bottom: 0;
}
.how-works.how-works-2 {
  padding-top: 130px;
}
@media screen and (max-width:991.98px) {
  .how-works.how-works-2 {
    padding-top: 70px;
  }
}
.how-works.how-works-3 {
  padding-top: 260px;
  z-index: 4;
}
@media screen and (max-width:991.98px) {
  .how-works.how-works-3 {
    padding-top: 70px;
  }
}

/*------------------------------------*\
# Solutions
\*------------------------------------*/
.solutions {
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
  overflow: visible;
  z-index: 5;
  background: #161616;
}
@media screen and (max-width:991.98px) {
  .solutions {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.solutions .solutions-wrapper {
  position: relative;
}
@media screen and (min-width:992px) {
  .solutions .solutions-wrapper {
    min-height: 830px;
    display: flex;
    align-items: center;
  }
}
.solutions .solutions-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width:991.98px) {
  .solutions .solutions-tags {
    justify-content: center;
    margin-bottom: 50px;
  }
}
.solutions .solutions-tags .btn {
  background-color: #eaeaea;
  color: var(--global--color-heading);
  height: 58px;
  width: auto;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 0 25px;
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .solutions .solutions-tags .btn {
    margin-right: 10px;
    padding: 0 20px;
  }
}
.solutions .solutions-tags .btn:hover {
  background-color: var(--global--color-primary);
  color: var(--global--color-white);
}
.solutions .solutions-img-holder {
  display: flex;
  justify-content: center;
}
@media screen and (min-width:992px) {
  .solutions .solutions-img-holder {
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 50%;
    z-index: 7;
  }
}
.solutions .solutions-img-holder .solutions-img {
  position: relative;
}
@media screen and (max-width:991.98px) {
  .solutions .solutions-img-holder .solutions-img {
    margin: 0 auto 0px;
  }
}
.solutions .solutions-img-holder .solutions-img img {
  width: 100%;
  border-radius: 0px;
  height: 940px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
@media screen and (max-width:991.98px) {
  .solutions .solutions-img-holder .solutions-img img {
    max-width: 550px;
    height: 650px;
    margin: 0 auto;
  }
}
@media screen and (max-width:767.98px) {
  .solutions .solutions-img-holder .solutions-img img {
    max-width: 100%;
  }
}
.solutions .solutions-img-holder .solutions-img .edge-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 5;
  width: 110px;
  height: 110px;
}
.solutions .solutions-img-holder .solutions-img .btn--video {
  width: 128px;
  height: 128px;
  background-color: var(--global--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
  z-index: 5;
}

/*------------------------------------*\
	#Blog Section
\*------------------------------------*/
.blog {
  padding: 127px 0 70px;
}
.blog .more-blogs {
  text-align: center;
}
.blog .more-blogs p {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 14px;
  color: var(--global--color-body);
  line-height: 33px;
  margin-bottom: 0;
}
.blog .more-blogs p a {
  position: relative;
  font-weight: 700;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
  padding-bottom: 6px;
}
.blog .more-blogs p a:hover::before {
  width: 80%;
}
.blog .more-blogs p a::before {
  content: "";
  transition: 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: var(--global--color-primary);
}
.blog .owl-carousel .owl-stage-outer {
  overflow: visible;
}
.blog .owl-carousel .owl-stage-outer .owl-item {
  opacity: 0;
}
.blog .owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}
.blog.blog-2 {
  padding-top: 114px;
  padding-bottom: 90px;
}
@media screen and (max-width:991.98px) {
  .blog.blog-2 {
    padding-top: 57px;
    padding-bottom: 22px;
  }
}

.blog-entry {
  margin-bottom: 33px;
  position: relative;
  background-color: var(--global--color-white);
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
}
@media screen and (max-width:991.98px) {
  .blog-entry {
    max-width: 370px;
    margin: 0 auto 33px;
    text-align: center;
  }
}
.blog-entry:hover {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.blog-entry:hover .entry-img .entry-img-holder img {
  transform: scale3d(1, 1, 1);
}
.blog-entry:hover .entry-img::before {
  opacity: 1;
  visibility: visible;
}
.blog-entry:hover .entry-content .entry-more {
  bottom: 0;
}
.blog-entry .entry-img {
  position: relative;
}
.blog-entry .entry-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 55%, rgba(0, 0, 0, 0.85) 100%);
  z-index: 2;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.blog-entry .entry-img .entry-img-holder {
  overflow: hidden;
}
.blog-entry .entry-img .entry-img-holder img {
  transition: transform 0.35s ease-in-out;
  width: 100%;
  transform: scale3d(1.05, 1.05, 1);
  height: 267px;
  object-fit: cover;
}
.blog-entry .entry-img a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 4;
}
.blog-entry .entry-category {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0px;
  text-transform: capitalize;
  height: 30px;
  padding: 0 10px;
  background-color: var(--global--color-primary);
  border-radius: 3px;
  margin-bottom: 24px;
}
.blog-entry .entry-category a {
  position: relative;
  color: var(--global--color-heading);
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.blog-entry .entry-category a::after {
  content: ", ";
  position: relative;
  margin-right: 4px;
}
.blog-entry .entry-category a:last-of-type::after {
  content: "";
}
.blog-entry .entry-category a:hover {
  color: var(--global--color-heading);
}
.blog-entry .entry-content {
  position: relative;
  z-index: 6;
  overflow: hidden;
}
.blog-entry .entry-content .entry-body {
  padding: 30px 40px 0px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .blog-entry .entry-content .entry-body {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .blog-entry .entry-content .entry-body {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.blog-entry .entry-content .entry-body .entry-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  text-transform: capitalize;
  margin-bottom: 16px;
}
.blog-entry .entry-content .entry-body .entry-title a {
  color: var(--global--color-heading);
}
.blog-entry .entry-content .entry-body .entry-title a:hover {
  color: var(--global--color-primary);
}
.blog-entry .entry-content .entry-body .entry-bio {
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  color: var(--global--color-body);
  margin-bottom: 33px;
}
.blog-entry .entry-content .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 67px;
  border-top: 1px solid #eaeaea;
}
.blog-entry .entry-content .entry-meta .entry-date {
  margin-bottom: 0px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-entry .entry-content .entry-meta .entry-date span {
  position: relative;
  color: #616161;
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
}
.blog-entry .entry-content .entry-meta .entry-date span::after {
  content: ", ";
  position: relative;
}
.blog-entry .entry-content .entry-meta .entry-date span:last-of-type::after {
  content: "";
}
.blog-entry .entry-content .entry-meta .entry-date span:hover {
  color: var(--global--color-primary);
}
.blog-entry .entry-content .entry-meta .divider {
  width: 1px;
  height: 67px;
  background-color: #eaeaea;
}
.blog-entry .entry-content .entry-meta .entry-author {
  text-transform: capitalize;
}
.blog-entry .entry-content .entry-meta .entry-author p {
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
  color: var(--global--color-heading);
}
.blog-entry .entry-content .entry-more {
  position: absolute;
  bottom: -61px;
  left: 0;
  right: 0;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.blog-entry .entry-content .entry-more a {
  display: flex;
  width: 100%;
  height: 67px;
  padding: 0 15px;
  position: relative;
  transition: all 300ms ease-in-out;
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
  border-radius: 0;
}
.blog-entry .entry-content .entry-more a::before {
  content: unset;
}
.blog-entry.blog-standard {
  box-shadow: none;
}
.blog-entry.blog-standard .entry-img::before {
  content: unset;
}
.blog-entry.blog-standard .entry-img .entry-img-holder img {
  height: 358px;
}
.blog-entry.blog-standard .entry-img .entry-category {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  z-index: 2;
  border-radius: 0 0 3px 0;
}
.blog-entry.blog-standard .entry-content {
  padding: 23px 40px 0px;
}
.blog-entry.blog-standard .entry-content .entry-body {
  padding: 0;
}
.blog-entry.blog-standard .entry-content .entry-body .entry-meta {
  height: auto;
  align-items: center;
  justify-content: flex-start;
  border-top: 0;
  margin-bottom: 6px;
}
@media screen and (max-width:991.98px) {
  .blog-entry.blog-standard .entry-content .entry-body .entry-meta {
    justify-content: center;
  }
}
.blog-entry.blog-standard .entry-content .entry-body .entry-meta .entry-date {
  margin-right: 20px;
}
.blog-entry.blog-standard .entry-content .entry-body .entry-title {
  font-size: 24px;
  line-height: 33px;
}
.blog-entry.blog-standard .entry-content .entry-body .entry-bio {
  margin-bottom: 18px;
}
.blog-entry.blog-standard .entry-content .entry-more {
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
}
.blog-entry.blog-standard .entry-content .entry-more a {
  width: auto;
  height: auto;
  padding: 0;
  background-color: transparent;
  display: inline-block;
}
.blog-entry.blog-thumb {
  box-shadow: none;
  display: flex;
  margin-bottom: 40px;
}
@media screen and (max-width:991.98px) {
  .blog-entry.blog-thumb {
    flex-direction: column;
  }
}
.blog-entry.blog-thumb .entry-img::before {
  content: unset;
}
.blog-entry.blog-thumb .entry-img .entry-category {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  z-index: 2;
  border-radius: 0 0 3px 0;
}
.blog-entry.blog-thumb .entry-img .entry-img-holder img {
  height: 120px;
  width: 180px;
}
@media screen and (max-width:991.98px) {
  .blog-entry.blog-thumb .entry-img .entry-img-holder img {
    width: 100%;
    height: 200px;
  }
}
.blog-entry.blog-thumb .entry-content {
  flex-grow: 1;
  padding-left: 40px;
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .blog-entry.blog-thumb .entry-content {
    padding-left: 0;
    padding-top: 32px;
  }
}
.blog-entry.blog-thumb .entry-content .entry-body {
  padding: 0;
}
.blog-entry.blog-thumb .entry-content .entry-body .entry-meta {
  height: auto;
  align-items: center;
  justify-content: flex-start;
  border-top: 0;
  margin-bottom: 6px;
}
@media screen and (max-width:991.98px) {
  .blog-entry.blog-thumb .entry-content .entry-body .entry-meta {
    justify-content: center;
  }
}
.blog-entry.blog-thumb .entry-content .entry-body .entry-meta .entry-date {
  margin-right: 20px;
}
.blog-entry.blog-thumb .entry-content .entry-body .entry-title {
  margin-bottom: 0;
}

/* Blog Grid */
.blog-grid.blog-grid-1 {
  padding: 128px 0 101px;
}
@media screen and (max-width:991.98px) {
  .blog-grid.blog-grid-1 {
    padding: 69px 0 61px;
  }
}
.blog-grid.blog-grid-2 {
  padding: 238px 0 101px;
}
@media screen and (max-width:991.98px) {
  .blog-grid.blog-grid-2 {
    padding: 69px 0 61px;
  }
}
.blog-grid.blog-grid-3 {
  padding: 110px 0;
}
@media screen and (max-width:991.98px) {
  .blog-grid.blog-grid-3 {
    padding: 70px 0;
  }
}
.blog-grid.blog-grid-3 .blog-entry {
  margin-bottom: 40px;
}

/* Blog Single */
.blog-single {
  padding-top: 70px;
  padding-bottom: 110px;
}
@media screen and (max-width:991.98px) {
  .blog-single {
    padding-bottom: 70px;
  }
}
.blog-single .blog-entry-wrapper {
  background-color: var(--global--color-white);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
  margin-bottom: 0;
}
@media screen and (max-width:991.98px) {
  .blog-single .blog-entry-wrapper {
    max-width: unset;
  }
}
.blog-single .blog-entry-wrapper:last-of-type {
  margin-bottom: 0px;
}
.blog-single .blog-entry-wrapper .entry-img img {
  border-radius: 0px;
  width: 100%;
  height: 562px;
  object-fit: cover;
}
.blog-single .blog-entry-wrapper .entry-holder {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #eaeaea;
  padding-top: 35px;
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-holder {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share,
.blog-single .blog-entry-wrapper .entry-holder .entry-tags {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-holder .entry-share,
  .blog-single .blog-entry-wrapper .entry-holder .entry-tags {
    align-items: center;
  }
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share span,
.blog-single .blog-entry-wrapper .entry-holder .entry-tags span {
  display: block;
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 14px;
  color: var(--global--color-heading-light);
  text-transform: capitalize;
  margin-bottom: 9px;
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share div,
.blog-single .blog-entry-wrapper .entry-holder .entry-tags div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share div {
  height: auto;
  line-height: normal;
  flex-wrap: nowrap;
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share div a {
  background-color: var(--global--color-white);
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  border: 2px solid #eaeaea;
  margin-right: 10px;
  margin-bottom: 10px;
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share div a:last-child {
  margin-right: 0;
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share div a i {
  color: var(--global--color-heading);
  font-size: 15px;
  transition: 0.3s ease-in-out;
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share div a:last-child {
  margin-right: 0;
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share div a:hover {
  background-color: var(--global--color-heading);
  border-color: var(--global--color-heading);
  transform: translateY(-5px);
}
.blog-single .blog-entry-wrapper .entry-holder .entry-share div a:hover i {
  color: var(--global--color-white);
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-holder .entry-tags {
    margin-bottom: 20px;
  }
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-holder .entry-tags div {
    justify-content: center;
  }
}
.blog-single .blog-entry-wrapper .entry-holder .entry-tags a {
  display: inline-flex;
  align-items: center;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: var(--global--font-body);
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  color: var(--global--color-heading);
  background-color: var(--global--color-primary);
  border-radius: 3px;
  padding: 0 14px;
}
.blog-single .blog-entry-wrapper .entry-holder .entry-tags a:hover {
  background-color: var(--global--color-heading);
  color: var(--global--color-white);
}
.blog-single .blog-entry-wrapper .entry-content {
  position: relative;
  padding: 38px 50px 40px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .blog-single .blog-entry-wrapper .entry-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .blog-single .blog-entry-wrapper .entry-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-content {
    text-align: center;
  }
}
.blog-single .blog-entry-wrapper .entry-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 100px);
  height: 4px;
  background-color: var(--global--color-primary);
}
@media (min-width:992px) and (max-width:1199.98px) {
  .blog-single .blog-entry-wrapper .entry-content::after {
    width: calc(100% - 50px);
  }
}
@media screen and (max-width:991.98px) {
  .blog-single .blog-entry-wrapper .entry-content::after {
    width: calc(100% - 50px);
  }
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 33px;
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper {
    flex-direction: column;
    justify-content: center;
  }
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-category {
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  height: 30px;
  padding: 0 10px;
  background-color: var(--global--color-primary);
  border-radius: 3px;
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-category {
    margin-bottom: 20px;
  }
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-category a {
  position: relative;
  color: var(--global--color-heading);
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-category a::after {
  content: ", ";
  position: relative;
  margin-right: 4px;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-category a:last-of-type::after {
  content: "";
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-category a:hover {
  color: var(--global--color-heading);
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta {
    margin-left: 0;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta div {
  margin-right: 20px;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta div:last-child {
  margin-right: 0;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-date {
  margin-bottom: 0px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-date span {
  position: relative;
  color: #616161;
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-date span::after {
  content: ", ";
  position: relative;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-date span:last-of-type::after {
  content: "";
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-author {
  text-transform: capitalize;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-author p {
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-author p a {
  color: var(--global--color-heading);
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-author p a:hover {
  color: var(--global--color-primary);
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-comments {
  text-transform: capitalize;
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  color: var(--global--color-body);
}
.blog-single .blog-entry-wrapper .entry-content .entry-meta-wrapper .entry-meta .entry-comments .num {
  color: var(--global--color-heading);
}
.blog-single .blog-entry-wrapper .entry-content .entry-title {
  margin-bottom: 19px;
}
.blog-single .blog-entry-wrapper .entry-content .entry-title h2 {
  font-family: var(--global--font-heading);
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 0;
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-content .entry-title h2 {
    font-size: 30px;
    line-height: 1.4;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .blog-single .blog-entry-wrapper .entry-content .entry-title h2 {
    font-size: 32px;
    line-height: 1.4;
  }
}
.blog-single .blog-entry-wrapper .entry-content .entry-images {
  margin-bottom: 44px;
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-content .entry-images {
    margin-bottom: 19px;
  }
}
.blog-single .blog-entry-wrapper .entry-content .entry-images img {
  width: 100%;
  object-fit: cover;
  height: 350px;
}
@media screen and (max-width:767.98px) {
  .blog-single .blog-entry-wrapper .entry-content .entry-images img {
    margin-bottom: 25px;
  }
}
.blog-single .blog-entry-wrapper .entry-content .entry-bio {
  margin-bottom: 32px;
}
.blog-single .blog-entry-wrapper .entry-content .entry-bio p {
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 27px;
  font-weight: 400;
  margin-bottom: 25px;
}
.blog-single .blog-entry-wrapper .entry-content .entry-bio p:last-child {
  margin-bottom: 0px;
}
.blog-single .nav-posts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
}
@media screen and (max-width:767.98px) {
  .blog-single .nav-posts {
    justify-content: center;
  }
}
.blog-single .nav-posts .btn {
  height: 60px;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  width: auto;
  font-size: 15px;
  font-weight: 700;
  padding: 0;
}
.blog-single .nav-posts .btn i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: var(--global--color-primary);
  color: var(--global--color-heading);
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  margin-left: 20px;
  font-size: 11px;
}
@media screen and (max-width:767.98px) {
  .blog-single .nav-posts .btn span {
    display: none;
  }
}
.blog-single .nav-posts .prev-post .btn i {
  background-color: var(--global--color-heading);
  color: var(--global--color-white);
  margin-left: 0;
  margin-right: 20px;
  transform: rotate(180deg);
}
.blog-single .entry-widget {
  margin-top: 62px;
}
.blog-single .entry-widget .entry-widget-title {
  position: relative;
  margin-bottom: 35px;
}
.blog-single .entry-widget .entry-widget-title h3 {
  font-family: var(--global--font-heading);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0;
  position: relative;
  text-transform: capitalize;
  color: var(--global--color-heading-darken);
}
.blog-single .entry-widget-bio {
  padding: 45px 45px 45px;
  border: 6px solid #eaeaea;
  position: relative;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-widget-bio {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .blog-single .entry-widget-bio {
    padding-right: 25px;
    padding-left: 25px;
  }
}
.blog-single .entry-widget-bio .entry-widget-content {
  position: relative;
  display: flex;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-widget-bio .entry-widget-content {
    flex-direction: column;
    align-items: center;
  }
}
.blog-single .entry-widget-bio .entry-widget-content img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-right: 30px;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-widget-bio .entry-widget-content img {
    margin-right: 0;
    margin-bottom: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc {
    text-align: center;
  }
}
@media screen and (min-width:768px) {
  .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc {
    transform: translateY(-5px);
    margin-bottom: -5px;
  }
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc h3 {
  font-family: var(--global--font-body);
  color: var(--global--color-heading);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc p {
  color: var(--global--color-body);
  font-size: 15px;
  font-family: var(--global--font-body);
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 13px;
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc .social-icons {
  display: flex;
  align-items: center;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc .social-icons {
    justify-content: center;
  }
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc .social-icons a {
  background-color: var(--global--color-white);
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  border: 2px solid #eaeaea;
  margin-right: 10px;
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc .social-icons a:last-child {
  margin-right: 0;
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc .social-icons a i {
  color: var(--global--color-primary);
  font-size: 15px;
  transition: 0.3s ease-in-out;
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc .social-icons a:last-child {
  margin-right: 0;
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc .social-icons a:hover {
  background-color: var(--global--color-heading);
  border-color: var(--global--color-heading);
  transform: translateY(-5px);
}
.blog-single .entry-widget-bio .entry-widget-content .entry-bio-desc .social-icons a:hover i {
  color: var(--global--color-white);
}
.blog-single .entry-comments .entry-widget-content .comments-list {
  width: 100%;
  list-style: none;
  overflow: hidden;
  padding-left: 0;
  margin-bottom: 0;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body {
  position: relative;
  margin-bottom: 34px;
  display: flex;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-comments .entry-widget-content .comments-list .comment-body {
    flex-direction: column;
  }
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body:last-child {
  margin-bottom: 0;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list {
  margin-top: 34px;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list li {
  position: relative;
  margin-bottom: 30px;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .replies-list li:last-child {
  margin-bottom: 0px;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .avatar {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  border-radius: 50%;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .avatar {
    margin-left: 20px;
    margin-bottom: 30px;
  }
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment {
  margin-left: 40px;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment {
    margin-left: 20px;
  }
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .comment-head {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .comment-head h3 {
  font-family: var(--global--font-body);
  color: var(--global--color-heading);
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 12px;
  line-height: 18px;
  margin-bottom: 0;
  margin-right: 20px;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .comment-head .date {
  color: #616161;
  font-family: var(--global--font-body);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .comment-content {
  background-color: #f4f4f4;
  padding: 32px 40px 32px;
  position: relative;
  margin-bottom: 16px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .comment-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .comment-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .comment-content::before {
  content: "";
  width: 20px;
  height: 15px;
  background-color: #f4f4f4;
  position: absolute;
  right: calc(100% - 1px);
  top: 0;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment .comment-content p {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0px;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment a.reply {
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--global--color-heading);
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  position: relative;
}
.blog-single .entry-comments .entry-widget-content .comments-list .comment-body .comment a.reply i {
  font-size: 10px;
  margin-right: 10px;
}
.blog-single .entry-add-comment {
  padding-top: 0;
  margin-top: 57px;
}
.blog-single .entry-add-comment .custom-radio-group .custom-control {
  margin-right: 0;
  margin-bottom: 39px;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-add-comment .custom-radio-group .custom-control {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-add-comment .custom-radio-group .custom-control label {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .blog-single .entry-add-comment .custom-radio-group .custom-control label::After {
    left: 6px;
    top: 4px;
  }
}
.blog-single .entry-add-comment .form-control {
  border: 2px solid #eaeaea;
  padding: 30px;
  height: 68px;
  border-radius: 3px;
  margin-bottom: 35px;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-add-comment .form-control {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.blog-single .entry-add-comment .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.blog-single .entry-add-comment .form-control:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.blog-single .entry-add-comment .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.blog-single .entry-add-comment textarea.form-control {
  height: 160px;
  margin-bottom: 21px;
}
.blog-single .entry-add-comment .btn {
  width: 240px;
  height: 78px;
}
@media screen and (max-width:767.98px) {
  .blog-single .entry-add-comment .btn {
    width: 100%;
    height: 68px;
  }
}
.blog-single .entry-add-comment .btn:hover i {
  background-color: var(--global--color-white);
  color: var(--global--color-heading);
}
.blog-single .entry-add-comment .btn i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: var(--global--color-heading);
  color: var(--global--color-white);
  border-radius: 50%;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  font-size: 9px;
}
@media screen and (max-width:991.98px) {
  .blog-single .sidebar-blog {
    margin-top: 70px;
  }
}

/*------------------------------------*\
    #Shop
\*------------------------------------*/
.shop {
  padding-top: 110px;
  padding-bottom: 110px;
}
@media screen and (max-width:991.98px) {
  .shop {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.shop.shop-1 .pagination {
  margin-top: 10px;
}
.shop.shop-2 {
  padding-top: 25px;
  padding-bottom: 70px;
}
@media screen and (max-width:991.98px) {
  .shop.shop-2 {
    padding-top: 20px;
    padding-bottom: 28px;
  }
}
.shop.shop-2 h2 {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--global--color-heading-darken);
  text-transform: capitalize;
  margin-bottom: 23px;
}
@media screen and (max-width:991.98px) {
  .shop.shop-2 h2 {
    text-align: center;
  }
}
.shop .pagination {
  margin-top: 10px;
}
@media screen and (min-width:992px) {
  .shop .sidebar-shop {
    transform: translateY(-8px);
  }
}

/* Product Item */
.product-item {
  margin-bottom: 40px;
  overflow: hidden;
}
@media screen and (max-width:991.98px) {
  .product-item {
    max-width: 370px;
    margin: 0 auto 40px;
  }
}
.product-item:hover .product-content {
  transform: translateY(0);
}
.product-item .product-img {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.product-item .product-img:hover img {
  transform: scale(1.1);
}
.product-item .product-img img {
  width: 100%;
  height: 290px;
  object-fit: cover;
  transition: 0.4s ease-in-out;
  transform: scale(1);
}
.product-item .product-img .badge {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #f9f9f9;
  border-radius: 3px;
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0px 15px;
  text-transform: capitalize;
}
.product-item .product-img .badge.badge-sale {
  background-color: #ff0000;
}
.product-item .product-img .badge.badge-new {
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
}
.product-item .product-content {
  transform: translateY(98px);
  margin-top: -98px;
  transition: all 0.3s ease-in-out;
  background-color: var(--global--color-white);
  position: relative;
  z-index: 5;
}
.product-item .product-content .product-body {
  padding-top: 21px;
  padding-bottom: 23px;
  text-align: center;
}
.product-item .product-content .product-body .product-title {
  margin-bottom: 6px;
}
.product-item .product-content .product-body .product-title a {
  text-transform: capitalize;
  font-family: var(--global--font-heading);
  font-size: 19px;
  line-height: 32px;
  font-weight: 700;
  color: var(--global--color-heading);
}
.product-item .product-content .product-body .product-title a:hover {
  color: var(--global--color-primary);
}
.product-item .product-content .product-body .product-price p {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 20px;
  color: var(--global--color-body);
  line-height: 28px;
  margin-bottom: 0;
}
.product-item .product-content .add-to-cart {
  width: 100%;
  height: 68px;
}
.product-item .product-content .add-to-cart i {
  font-size: 14px;
  margin-left: 10px;
}

/* Shop Options */
.shop-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
@media screen and (max-width:767.98px) {
  .shop-options {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width:767.98px) {
  .shop-options .products-show {
    margin-bottom: 25px;
  }
}
.shop-options .products-show p {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 14px;
  color: var(--global--color-heading-light);
  text-transform: capitalize;
  margin-bottom: 0;
}
.shop-options .products-sort .nice-select {
  border: 2px solid #eaeaea;
  padding: 20px;
  padding-right: 30px;
  height: 55px;
  width: 290px;
  border-radius: 3px;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-options .products-sort .nice-select::after {
  right: 20px;
}
.shop-options .products-sort .nice-select::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-options .products-sort .nice-select:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-options .products-sort .nice-select:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

/* Shop Single */
.single-product {
  padding-top: 70px;
  padding-bottom: 0;
}
.single-product .checkout-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--global--color-white);
  border-radius: 6px;
  margin-bottom: 40px;
  border: 6px solid #eaeaea;
}
@media screen and (max-width:767.98px) {
  .single-product .checkout-banner {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.single-product .checkout-banner p {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 19px;
  line-height: 32px;
  color: var(--global--color-heading-light);
  margin-bottom: 0px;
}
@media screen and (max-width:767.98px) {
  .single-product .checkout-banner p {
    margin-bottom: 20px;
  }
}
.single-product .checkout-banner .btn {
  height: 60px;
  width: 180px;
}
.single-product .product-img {
  border-radius: 8px;
  position: relative;
}
@media screen and (max-width:991.98px) {
  .single-product .product-img {
    margin-bottom: 25px;
  }
}
.single-product .product-img img {
  max-width: 100%;
}
@media screen and (min-width:992px) {
  .single-product .product-img img {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
}
.single-product .product-img a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}
@media screen and (min-width:992px) {
  .single-product .product-content {
    padding-top: 9px;
    padding-left: 30px;
  }
}
@media screen and (max-width:991.98px) {
  .single-product .product-content {
    text-align: center;
  }
}
.single-product .product-content .product-title h3 {
  font-family: var(--global--font-heading);
  font-weight: 700;
  color: var(--global--color-heading-darken);
  font-size: 29px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 14px;
}
.single-product .product-content .product-price {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 26px;
}
.single-product .product-content .product-review {
  margin-bottom: 21px;
}
.single-product .product-content .product-review span {
  color: var(--global--color-body);
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  font-family: var(--global--font-body);
  text-transform: capitalize;
}
.single-product .product-content .product-review span::after {
  content: "/";
  margin: 0 6px;
}
.single-product .product-content .product-review span:last-child::after {
  content: unset;
}
.single-product .product-content .product-review span a {
  color: var(--global--color-body);
}
.single-product .product-content .product-review span a:hover {
  color: var(--global--color-primary);
}
.single-product .product-content .product-review .product-rating {
  margin-right: 30px;
  display: inline-block;
}
.single-product .product-content .product-review .product-rating i {
  color: var(--global--color-body);
  font-size: 15px;
  line-height: 1;
  margin-right: 5px;
}
.single-product .product-content .product-review .product-rating i:last-child {
  margin-right: 0;
}
.single-product .product-content .product-review .product-rating i.active {
  color: var(--global--color-primary);
}
.single-product .product-content .product-desc p {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 32px;
  color: var(--global--color-body);
}
.single-product .product-content .product-action {
  display: flex;
  margin-bottom: 37px;
}
@media screen and (max-width:991.98px) {
  .single-product .product-content .product-action {
    justify-content: center;
  }
}
.single-product .product-content .product-action .product-quantity {
  display: flex;
  border: 2px solid #bcbcbc;
  border-radius: 3px 3px 0 3px;
  width: 80px;
  margin-right: 15px;
}
.single-product .product-content .product-action .product-quantity span {
  display: flex;
  flex-direction: column;
}
.single-product .product-content .product-action .product-quantity span a {
  border-left: 2px solid #bcbcbc;
}
.single-product .product-content .product-action .product-quantity span a:first-child {
  border-bottom: 2px solid #bcbcbc;
}
.single-product .product-content .product-action .product-quantity span a i {
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 25px;
  color: var(--global--color-heading);
  text-align: center;
}
.single-product .product-content .product-action .product-quantity input {
  width: 50px;
  height: 50px;
  color: var(--global--color-heading);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  font-family: var(--global--font-body);
  text-align: center;
  background-color: transparent;
  border-color: transparent;
}
.single-product .product-content .product-action .btn {
  width: 170px;
  height: 54px;
}
.single-product .product-content .product-details {
  margin-bottom: 24px;
}
.single-product .product-content .product-details .table {
  margin-bottom: 0;
}
@media screen and (min-width:992px) {
  .single-product .product-content .product-details .table {
    width: 60%;
  }
}
.single-product .product-content .product-details .table td {
  border-top: 0;
  border-bottom: 0;
  padding-bottom: 9px;
}
.single-product .product-content .product-details .table .name {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 16px;
  color: var(--global--color-heading-light);
  text-transform: capitalize;
}
@media screen and (min-width:992px) {
  .single-product .product-content .product-details .table .name {
    padding-left: 0;
  }
}
.single-product .product-content .product-details .table .value {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 15px;
  color: var(--global--color-body);
  text-transform: capitalize;
}
.single-product .product-content .product-share {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
@media screen and (max-width:991.98px) {
  .single-product .product-content .product-share {
    justify-content: center;
    flex-wrap: wrap;
  }
}
.single-product .product-content .product-share a {
  color: var(--global--color-heading);
  background-color: var(--global--color-primary);
  border-radius: 3px;
  margin-right: 10px;
  transition: 0.3s ease-in-out;
  width: 52px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
}
.single-product .product-content .product-share a:last-child {
  margin-right: 0;
}
.single-product .product-content .product-share a:hover {
  background-color: var(--global--color-heading);
  color: var(--global--color-white);
  transform: translateY(-5px);
}
.single-product .product-content .product-share a.facebook-bg {
  background-color: #3b5999;
  color: var(--global--color-white);
}
.single-product .product-content .product-share a.facebook-bg:hover {
  background-color: var(--global--color-heading);
}
.single-product .product-content .product-share a.twitter-bg {
  background-color: #55acee;
  color: var(--global--color-white);
}
.single-product .product-content .product-share a.twitter-bg:hover {
  background-color: var(--global--color-heading);
}
.single-product .product-content .product-share a.instagram-bg {
  background-color: #0092ff;
  color: var(--global--color-white);
}
.single-product .product-content .product-share a.instagram-bg:hover {
  background-color: var(--global--color-heading);
}
.single-product .product-content .product-share a.pinterest-bg {
  background-color: #ca1f27;
  color: var(--global--color-white);
}
.single-product .product-content .product-share a.pinterest-bg:hover {
  background-color: var(--global--color-heading);
}
.single-product .product-tabs {
  background-color: var(--global--color-white);
  margin-top: 75px;
  margin-bottom: 40px;
}
.single-product .product-tabs .nav-tabs {
  background-color: var(--global--color-white);
  border: 0;
}
.single-product .product-tabs .nav-tabs li {
  margin-right: 30px;
}
.single-product .product-tabs .nav-tabs li:last-child {
  margin-right: 0;
}
.single-product .product-tabs .nav-tabs li a {
  color: var(--global--color-heading);
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: var(--global--font-body);
  text-transform: capitalize;
  border-radius: 0;
  margin-right: 0;
  padding-bottom: 10px;
  display: block;
  border-right: 0;
  position: relative;
}
.single-product .product-tabs .nav-tabs li a::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0%;
  height: 4px;
  background-color: var(--global--color-primary);
  transition: 0.3s ease-in-out;
}
@media (max-width: 420px) {
  .single-product .product-tabs .nav-tabs li a::after {
    content: unset;
  }
}
.single-product .product-tabs .nav-tabs li a:focus, .single-product .product-tabs .nav-tabs li a:hover {
  color: var(--global--color-secondary);
}
.single-product .product-tabs .nav-tabs li > .active,
.single-product .product-tabs .nav-tabs li > .active:focus,
.single-product .product-tabs .nav-tabs li > .active:hover {
  color: var(--global--color-heading);
}
.single-product .product-tabs .nav-tabs li > .active::after,
.single-product .product-tabs .nav-tabs li > .active:focus::after,
.single-product .product-tabs .nav-tabs li > .active:hover::after {
  width: 100%;
}
.single-product .product-tabs .tab-content {
  border-top: 4px solid #e6eaef;
  padding-top: 23px;
}
.single-product .product-tabs .tab-content p {
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  color: var(--global--color-body);
}
.single-product .product-tabs .tab-content h3 {
  font-size: 18px;
}
.single-product .product-tabs .tab-content .review-comment .review-body {
  display: flex;
  margin-bottom: 25px;
}
.single-product .product-tabs .tab-content .review-comment .review-body img {
  width: 60px;
  height: 60px;
  border-radius: 3px;
  margin-right: 30px;
}
.single-product .product-tabs .tab-content .review-comment .review-body h4 {
  font-size: 18px;
  margin-bottom: 5px;
}
.single-product .product-tabs .tab-content .review-comment .review-body p {
  margin-bottom: 7px;
}
.single-product .product-tabs .tab-content .review-comment .review-body .product-rating i.active {
  color: var(--global--color-primary);
}
.single-product .product-tabs .tab-content .review-comment .product-comment {
  margin-bottom: 35px;
}
.single-product .product-tabs .tab-content .review-comment .product-comment p {
  margin-bottom: 0;
}
.single-product .product-tabs .tab-content .form-review .form-control {
  border: 2px solid #eaeaea;
  padding: 30px;
  height: 68px;
  border-radius: 3px;
  margin-bottom: 35px;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
@media screen and (max-width:767.98px) {
  .single-product .product-tabs .tab-content .form-review .form-control {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.single-product .product-tabs .tab-content .form-review .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.single-product .product-tabs .tab-content .form-review .form-control:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.single-product .product-tabs .tab-content .form-review .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.single-product .product-tabs .tab-content .form-review .nice-select::after {
  right: 20px;
}
.single-product .product-tabs .tab-content .form-review textarea.form-control {
  height: 160px;
  margin-bottom: 40px;
}
.single-product .product-tabs .tab-content .form-review .btn {
  width: 240px;
  height: 78px;
}
@media screen and (max-width:767.98px) {
  .single-product .product-tabs .tab-content .form-review .btn {
    width: 100%;
    height: 68px;
  }
}
.single-product .product-tabs .tab-content .form-review .btn:hover i {
  background-color: var(--global--color-white);
  color: var(--global--color-heading);
}
.single-product .product-tabs .tab-content .form-review .btn i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: var(--global--color-heading);
  color: var(--global--color-white);
  border-radius: 50%;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  font-size: 9px;
}

/*Shop Cart*/
.shop-cart {
  padding-top: 70px;
  padding-bottom: 110px;
}
@media screen and (max-width:991.98px) {
  .shop-cart {
    padding-bottom: 70px;
  }
}
.shop-cart .table-wrap {
  border: 1px solid #eaeaea;
  margin-bottom: 53px;
  overflow: hidden;
}
.shop-cart .table-wrap .cart-table {
  margin-bottom: 30px;
}
.shop-cart .table-wrap .cart-table.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}
.shop-cart .table-wrap .cart-table .table {
  background-color: var(--global--color-white);
  border-radius: 0;
  margin-bottom: 0;
  color: var(--global--color-body);
}
.shop-cart .table-wrap .cart-table .table tr:first-child th {
  border-top: 0;
}
.shop-cart .table-wrap .cart-table .table tr:first-child th:first-child {
  border-left: 0;
}
.shop-cart .table-wrap .cart-table .table tr:first-child th:last-child {
  border-right: 0;
}
.shop-cart .table-wrap .cart-table .table tr th {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  color: var(--global--color-body);
  font-family: var(--global--font-body);
  padding: 10px 0;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
.shop-cart .table-wrap .cart-table .table tbody tr td {
  padding: 20px;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  border-top: 0;
  border-left: 0;
}
.shop-cart .table-wrap .cart-table .table tbody tr td:last-child {
  border-right: 0;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-price, .shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-total {
  font-family: var(--global--font-body);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--global--color-body);
  vertical-align: baseline;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-item .cart-product-remove {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  background-color: var(--global--color-heading);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.3s ease-in-out;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-item .cart-product-remove:hover {
  background-color: var(--global--color-primary);
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-item .cart-product-remove:hover svg {
  color: var(--global--color-heading);
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-item .cart-product-remove svg {
  color: var(--global--color-white);
  transition: 0.3s ease-in-out;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-item .cart-product-img {
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  margin-right: 20px;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-item .cart-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-item .cart-product-name h4 {
  font-family: var(--global--font-heading);
  color: var(--global--color-heading);
  font-size: 17px;
  font-weight: 700;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 0px;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-quantity .product-quantity {
  display: flex;
  border: 2px solid #bcbcbc;
  border-radius: 3px 3px 0 3px;
  width: 80px;
  margin: 0 auto;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-quantity .product-quantity span {
  display: flex;
  flex-direction: column;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-quantity .product-quantity span a {
  border-left: 2px solid #bcbcbc;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-quantity .product-quantity span a:first-child {
  border-bottom: 2px solid #bcbcbc;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-quantity .product-quantity span a i {
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 25px;
  color: var(--global--color-heading);
  text-align: center;
}
.shop-cart .table-wrap .cart-table .table tbody tr td.cart-product-quantity .product-quantity input {
  width: 50px;
  height: 50px;
  color: var(--global--color-heading);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  font-family: var(--global--font-body);
  text-align: center;
  background-color: transparent;
  border-color: transparent;
}
.shop-cart .table-wrap .cart-product-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
@media screen and (max-width:767.98px) {
  .shop-cart .table-wrap .cart-product-action {
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.shop-cart .table-wrap .cart-product-action form {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
@media screen and (max-width:767.98px) {
  .shop-cart .table-wrap .cart-product-action form {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
}
.shop-cart .table-wrap .cart-product-action form .form-control {
  border: 1px solid #eaeaea;
  padding: 20px;
  height: 42px;
  width: 250px;
  border-radius: 3px;
  margin-bottom: 0;
  margin-right: 10px;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
@media screen and (max-width:991.98px) {
  .shop-cart .table-wrap .cart-product-action form .form-control {
    width: 200px;
  }
}
@media screen and (max-width:767.98px) {
  .shop-cart .table-wrap .cart-product-action form .form-control {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }
}
.shop-cart .table-wrap .cart-product-action form .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-cart .table-wrap .cart-product-action form .form-control:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-cart .table-wrap .cart-product-action form .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-cart .table-wrap .cart-product-action form .btn {
  margin-top: 0;
  flex-shrink: 0;
}
.shop-cart .table-wrap .cart-product-action .btn {
  width: 134px;
  height: 42px;
  line-height: 39px;
  margin-right: 10px;
  padding: 0 18px;
}
@media screen and (max-width: 575.98px) {
  .shop-cart .table-wrap .cart-product-action .btn {
    margin-top: 20px;
  }
}
.shop-cart .table-wrap .cart-product-action .btn:last-child {
  margin-right: 0;
}
.shop-cart .table-wrap .cart-product-action div {
  display: flex;
  align-items: center;
}
.shop-cart .cart-calculate-shipping h3,
.shop-cart .cart-total-amount h3 {
  color: var(--global--color-heading-darken);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  font-family: var(--global--font-heading);
  margin-bottom: 20px;
  position: relative;
  text-transform: capitalize;
}
.shop-cart .cart-total-amount .table {
  margin-bottom: 30px;
}
.shop-cart .cart-total-amount .table tbody .head {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 17px;
  color: var(--global--color-heading-light);
  text-transform: capitalize;
}
.shop-cart .cart-total-amount .table tbody td {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 17px;
  color: var(--global--color-body);
  border-top: 0;
  border-bottom: 1px solid #eaeaea;
  padding-left: 0;
}
.shop-cart .cart-total-amount .table tbody td.amount {
  font-weight: 700;
}
.shop-cart .cart-total-amount .btn {
  width: 200px;
  height: 60px;
}
@media screen and (max-width:767.98px) {
  .shop-cart .cart-total-amount .btn {
    width: 100%;
  }
}
@media screen and (max-width:991.98px) {
  .shop-cart .cart-calculate-shipping {
    margin-bottom: 50px;
  }
}
.shop-cart .cart-calculate-shipping h3 {
  margin-bottom: 34px;
}
.shop-cart .cart-calculate-shipping form {
  margin-bottom: 0;
}
.shop-cart .cart-calculate-shipping form .form-control {
  border: 2px solid #eaeaea;
  padding: 25px;
  height: 50px;
  border-radius: 3px;
  margin-bottom: 30px;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
@media screen and (max-width:767.98px) {
  .shop-cart .cart-calculate-shipping form .form-control {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.shop-cart .cart-calculate-shipping form .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-cart .cart-calculate-shipping form .form-control:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-cart .cart-calculate-shipping form .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-cart .cart-calculate-shipping form .nice-select::after {
  right: 20px;
}
.shop-cart .cart-calculate-shipping form textarea.form-control {
  height: 160px;
  margin-bottom: 21px;
}
.shop-cart .cart-calculate-shipping .btn {
  width: 200px;
  height: 60px;
}
@media screen and (max-width:767.98px) {
  .shop-cart .cart-calculate-shipping .btn {
    width: 100%;
  }
}

/* Shop Checkout */
.shop-checkout {
  padding: 70px 0 110px;
}
@media screen and (max-width:991.98px) {
  .shop-checkout {
    padding: 70px 0;
  }
}
.shop-checkout h3 {
  color: var(--global--color-heading-darken);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  font-family: var(--global--font-heading);
  margin-bottom: 13px;
  position: relative;
  text-transform: capitalize;
}
.shop-checkout .cart-empty,
.shop-checkout .cart-coupon {
  padding: 30px;
  background-color: #f5f5f5;
  border-top: 3px solid #1e85be;
  font-size: 18px;
  font-weight: 400;
  font-family: var(--global--font-body);
  line-height: 1;
  color: #515151;
  margin-bottom: 35px;
}
.shop-checkout .cart-empty a,
.shop-checkout .cart-coupon a {
  color: var(--global--color-primary);
}
.shop-checkout .checkout-widget {
  margin-bottom: 45px;
}
.shop-checkout .checkout-widget .widget-title {
  margin-bottom: 15px;
  text-transform: capitalize;
}
.shop-checkout .checkout-widget .widget-title h2 {
  font-size: 22px;
}
.shop-checkout .checkout-widget label {
  font-family: var(--global--font-heading);
  font-weight: 500;
  font-size: 16px;
  line-height: 2;
  margin-bottom: 10px;
  text-transform: capitalize;
  color: var(--global--color-heading);
  display: block;
}
.shop-checkout .checkout-widget label .required {
  margin-left: 3px;
  color: red;
}
.shop-checkout .checkout-widget .form-control {
  border: 2px solid #eaeaea;
  padding: 25px;
  height: 50px;
  border-radius: 3px;
  margin-bottom: 30px;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
@media screen and (max-width:767.98px) {
  .shop-checkout .checkout-widget .form-control {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.shop-checkout .checkout-widget .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-checkout .checkout-widget .form-control:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-checkout .checkout-widget .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-checkout .checkout-widget textarea.form-control {
  height: 160px;
  margin-bottom: 21px;
}
.shop-checkout .checkout-widget .nice-select::after {
  right: 20px;
}
.shop-checkout .cart-totals {
  margin-bottom: 53px;
  border-radius: 4px;
  overflow: hidden;
}
@media screen and (max-width:991.98px) {
  .shop-checkout .cart-totals {
    margin-bottom: 40px;
  }
}
.shop-checkout .cart-totals h3 {
  margin-bottom: 26px;
}
.shop-checkout .cart-totals .table-order table {
  width: 100%;
  margin-bottom: 0;
}
.shop-checkout .cart-totals .table-order table tbody tr {
  border-bottom: 1px solid #eaeaea;
}
.shop-checkout .cart-totals .table-order table tbody tr:last-child {
  border-bottom: 0;
}
.shop-checkout .cart-totals .table-order table tbody tr td {
  font-family: var(--global--font-body);
  font-size: 17px;
  color: var(--global--color-heading);
  text-transform: capitalize;
  font-weight: 600;
  padding: 13px;
}
.shop-checkout .cart-totals .table-order table tbody tr td:last-of-type {
  font-weight: 400;
  color: var(--global--color-body);
}
.shop-checkout .cart-totals .table-order table tbody tr td.product-name {
  padding-left: 10px;
}
.shop-checkout .cart-totals .table-order table tbody tr.cart-total td:last-of-type {
  color: var(--global--color-primary);
}
.shop-checkout .your-order h3 {
  margin-bottom: 28px;
}
.shop-checkout .your-order .cart-table {
  margin-bottom: 65px;
}
@media screen and (max-width:991.98px) {
  .shop-checkout .your-order .cart-table {
    margin-bottom: 40px;
  }
}
.shop-checkout .your-order .cart-table.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}
.shop-checkout .your-order .cart-table .table {
  background-color: var(--global--color-white);
  border-radius: 0;
  margin-bottom: 0;
  color: var(--global--color-body);
}
.shop-checkout .your-order .cart-table .table tr:first-child th {
  border-top: 0;
}
.shop-checkout .your-order .cart-table .table tr:first-child th:first-child {
  border-left: 0;
}
.shop-checkout .your-order .cart-table .table tr:first-child th:last-child {
  border-right: 0;
}
.shop-checkout .your-order .cart-table .table tr th {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  color: var(--global--color-heading-darken);
  font-family: var(--global--font-body);
  padding: 10px 0;
  border-bottom: 1px solid #e5e8ed;
}
.shop-checkout .your-order .cart-table .table tbody tr td {
  padding: 20px;
  border-bottom: 1px solid #e5e8ed;
  border-top: 0;
  border-left: 0;
}
.shop-checkout .your-order .cart-table .table tbody tr td:last-child {
  border-right: 0;
}
.shop-checkout .your-order .cart-table .table tbody tr td.cart-product-total, .shop-checkout .your-order .cart-table .table tbody tr td.cart-product-quantity {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--global--color-primary);
  font-family: var(--global--font-body);
  vertical-align: middle;
}
.shop-checkout .your-order .cart-table .table tbody tr td.cart-product-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.shop-checkout .your-order .cart-table .table tbody tr td.cart-product-item .cart-product-img {
  width: 70px;
  height: 70px;
  margin-right: 20px;
}
.shop-checkout .your-order .cart-table .table tbody tr td.cart-product-item .cart-product-img img {
  max-width: 100%;
}
.shop-checkout .your-order .cart-table .table tbody tr td.cart-product-item .cart-product-name h6 {
  font-family: var(--global--font-heading);
  color: var(--global--color-heading-darken);
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 0px;
}
.shop-checkout .payment-wrapper {
  background-color: var(--global--color-gray);
  padding: 33px 15px;
  border-radius: 4px;
}
.shop-checkout .payment-wrapper .payment-method {
  margin: 0 15px;
}
.shop-checkout .payment-wrapper .payment-method .no-payment {
  border-top: 3px solid #1e85be;
  padding: 27px 0;
}
.shop-checkout .payment-wrapper .payment-method .no-payment p {
  font-family: var(--global--font-body);
  padding: 0 30px;
  font-size: 18px;
  line-height: 2;
  font-weight: 500;
  color: var(--global--color-heading);
}
.shop-checkout .payment-wrapper .payment-conditions {
  margin: 0 15px;
}
.shop-checkout .payment-wrapper .payment-conditions .message {
  padding-top: 10px;
  font-family: var(--global--font-body);
  font-size: 17px;
  line-height: 2;
  color: var(--global--color-body);
  font-weight: 400;
  margin-bottom: 30px;
}
.shop-checkout .payment-wrapper .payment-conditions label {
  text-transform: capitalize;
  font-weight: 500;
  color: var(--global--color-heading);
  font-size: 16px;
  margin-bottom: 30px;
}
.shop-checkout .payment-wrapper .payment-conditions label a {
  color: var(--global--color-primary);
}

.shop-login {
  padding: 110px 0;
}
@media screen and (max-width:991.98px) {
  .shop-login {
    padding: 70px 0;
  }
}
.shop-login .login-body {
  background-color: var(--global--color-body-light);
  border-radius: 3px;
  padding: 40px;
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
}
@media screen and (max-width:991.98px) {
  .shop-login .login-body {
    text-align: center;
    padding: 40px 25px;
  }
}
.shop-login .login-body label {
  font-family: var(--global--font-heading);
  font-weight: 500;
  font-size: 16px;
  line-height: 2;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #222222;
}
.shop-login .login-body label .required {
  margin-left: 3px;
  color: red;
}
.shop-login .login-body .form-control {
  border: 2px solid #eaeaea;
  padding: 30px;
  height: 68px;
  border-radius: 3px;
  margin-bottom: 30px;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
@media screen and (max-width:767.98px) {
  .shop-login .login-body .form-control {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.shop-login .login-body .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-login .login-body .form-control:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-login .login-body .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.shop-login .login-body .check {
  margin-top: 10px;
  margin-bottom: 10px;
}
.shop-login .login-body .check label {
  margin-left: 5px;
}
.shop-login .login-body .btn {
  display: inline-flex;
  width: 200px;
  height: 68px;
}
.shop-login .login-body .forget {
  margin-top: 23px;
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 700;
  color: var(--global--color-heading);
}
.shop-login .login-body .forget:hover {
  color: var(--global--color-primary);
}

/* Shop Account */
.shop-account {
  padding: 110px 0;
}
@media screen and (max-width:991.98px) {
  .shop-account {
    padding: 70px 0;
  }
}
.shop-account .shop-account-nav {
  background-color: var(--global--color-body-light);
  padding: 0 30px;
  border: 1px solid var(--global--color-body-light);
  border-radius: 8px;
}
@media screen and (max-width: 991px) {
  .shop-account .shop-account-nav {
    margin-bottom: 30px;
  }
}
.shop-account .shop-account-nav ul li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: var(--global--color-heading);
  font-size: 15px;
  font-family: var(--global--font-body);
  font-weight: 700;
  line-height: 36px;
  text-transform: capitalize;
  position: relative;
  transition: 0.3s ease-in-out;
  border-bottom: 1px solid #eaeaea;
}
@media screen and (max-width: 500px) {
  .shop-account .shop-account-nav ul li a {
    justify-content: center;
  }
}
.shop-account .shop-account-nav ul li a:hover {
  color: var(--global--color-primary);
}
.shop-account .shop-account-nav ul li a:hover i {
  color: var(--global--color-primary);
  transform: translateX(-5px);
}
@media screen and (max-width: 500px) {
  .shop-account .shop-account-nav ul li a span {
    display: none;
  }
}
.shop-account .shop-account-nav ul li a i {
  transition: 0.3s ease-in-out;
  margin-left: 10px;
  font-size: 15px;
}
@media screen and (max-width: 500px) {
  .shop-account .shop-account-nav ul li a i {
    margin-left: 0;
    font-size: 20px;
  }
}
.shop-account .shop-account-nav ul li:first-child a {
  border-top: none;
}
.shop-account .shop-account-nav ul li:last-child a {
  border-bottom: none;
}
.shop-account .shop-account-nav ul li.active a {
  color: var(--global--color-primary);
}
.shop-account .shop-account-nav ul li.active a i {
  color: var(--global--color-primary);
}
.shop-account .shop-account-nav ul li.active:hover a i {
  transform: translateX(0);
}
.shop-account .shop-account-content {
  background-color: var(--global--color-body-light);
  padding: 50px 30px;
  border: 1px solid var(--global--color-body-light);
  border-radius: 8px;
}
@media screen and (max-width:767.98px) {
  .shop-account .shop-account-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.shop-account .shop-account-content h6 {
  font-family: var(--global--font-heading);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0;
}
.shop-account .shop-account-content p {
  font-family: var(--global--font-heading);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
}
.shop-account .shop-account-content p:last-child {
  margin-bottom: 0;
}
.shop-account .shop-account-content p a {
  color: var(--global--color-primary);
}

/*------------------------------------*\
    #Team
\*------------------------------------*/
.team {
  padding-top: 110px;
  padding-bottom: 10px;
}
@media screen and (max-width:991.98px) {
  .team {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}
.team .carousel .owl-stage-outer {
  overflow: visible;
}
.team .carousel .owl-stage-outer .owl-item {
  opacity: 0;
}
.team .carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}
.team .member {
  transition: all 300ms linear 0ms;
  max-width: 370px;
  margin: 0 auto 70px;
  filter: drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.12));
}
@media screen and (max-width:991.98px) {
  .team .member {
    margin: 0 auto 40px;
  }
}
.team .member:hover .member-wrapper .member-content .member-social {
  opacity: 1;
  visibility: visible;
}
.team .member .member-wrapper {
  clip-path: polygon(100% 0, 100% 88%, 85% 100%, 0 100%, 0 0);
}
.team .member .member-wrapper .member-img {
  position: relative;
}
.team .member .member-wrapper .member-img img {
  height: 400px;
  object-fit: cover;
  width: 100%;
}
.team .member .member-wrapper .member-content {
  background-color: var(--global--color-white);
  padding: 32px 40px 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .team .member .member-wrapper .member-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width:767.98px) {
  .team .member .member-wrapper .member-content {
    flex-direction: column;
    text-align: center;
  }
}
.team .member .member-wrapper .member-content .member-social {
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width:767.98px) {
  .team .member .member-wrapper .member-content .member-social {
    opacity: 1;
    visibility: visible;
  }
}
.team .member .member-wrapper .member-content .member-social a {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--global--color-primary);
  width: 38px;
  height: 38px;
  color: var(--global--color-heading);
  font-size: 20px;
  transition: 0.3s ease-in-out;
  margin-right: 3px;
}
.team .member .member-wrapper .member-content .member-social a:last-child {
  margin-right: 0;
}
.team .member .member-wrapper .member-content .member-social a:hover {
  transform: translateY(-5px);
}
@media screen and (max-width:767.98px) {
  .team .member .member-wrapper .member-content .member-info {
    margin-bottom: 25px;
  }
}
.team .member .member-wrapper .member-content .member-info a {
  display: inline-block;
  font-family: var(--global--font-heading);
  font-size: 20px;
  font-weight: 700;
  color: var(--global--color-heading-light);
  margin-bottom: 11px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.team .member .member-wrapper .member-content .member-info a:hover {
  color: var(--global--color-primary);
}
.team .member .member-wrapper .member-content .member-info p {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  margin-bottom: 0;
}
.team .member.member-2 {
  overflow: hidden;
  box-shadow: none;
  filter: unset;
}
.team .member.member-2:hover {
  box-shadow: 0px 5px 83px 0px rgba(3, 43, 86, 0.12);
}
.team .member.member-2:hover .member-wrapper .member-content .member-social {
  opacity: 1;
  visibility: visible;
}
.team .member.member-2 .member-wrapper {
  clip-path: unset;
}
.team .member.member-2 .member-wrapper .member-img img {
  height: 370px;
}
.team .member.member-2 .member-wrapper .member-content {
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 27px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .team .member.member-2 .member-wrapper .member-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.team .member.member-2 .member-wrapper .member-content .member-social {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100% + 40px);
  padding-top: 30px;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--global--color-white);
  z-index: 1;
  transform-origin: bottom;
  visibility: hidden;
  opacity: 0;
}
.team .member.member-2 .member-wrapper .member-content .member-info {
  text-align: center;
  position: relative;
  z-index: 2;
}
.team .member.member-2 .member-wrapper .member-content .member-info a {
  font-size: 18px;
}

/*------------------------------------*\
	#Widgets
\*------------------------------------*/
.sidebar {
  max-width: 370px;
  margin-right: auto;
}
@media screen and (max-width:991.98px) {
  .sidebar {
    margin-right: auto;
    margin-left: auto;
  }
}
.sidebar.sidebar-shop .widget {
  margin-bottom: 52px;
  filter: none;
}
.sidebar.sidebar-shop .widget.widget-search {
  margin-bottom: 42px;
}
.sidebar.sidebar-shop .widget.widget-search .widget-content .form-search .form-control {
  border: 2px solid #eaeaea;
  height: 55px;
}
.sidebar.sidebar-shop .widget.widget-recent-products {
  margin-bottom: 42px;
}
.sidebar.sidebar-shop .widget.widget-filter {
  margin-bottom: 39px;
}

/* Widget */
.widget {
  margin-bottom: 40px;
  filter: drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.12));
}
.widget .widget-wrapper {
  position: relative;
  padding: 43px 50px 50px;
  background-color: var(--global--color-white);
  clip-path: polygon(84% 0, 100% 10%, 100% 100%, 0 100%, 0 0);
}
@media (min-width:992px) and (max-width:1199.98px) {
  .widget .widget-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .widget .widget-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.widget .widget-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  width: calc(100% - 100px);
  background-color: var(--global--color-primary);
}
@media (min-width:992px) and (max-width:1199.98px) {
  .widget .widget-wrapper::after {
    width: calc(100% - 50px);
  }
}
@media screen and (max-width:767.98px) {
  .widget .widget-wrapper::after {
    width: calc(100% - 50px);
  }
}
.widget .widget-title {
  text-align: center;
  margin-bottom: 23px;
}
@media screen and (min-width:992px) {
  .widget .widget-title {
    text-align: left;
  }
}
.widget .widget-title h3 {
  font-family: var(--global--font-heading);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: var(--global--color-heading-darken);
  text-transform: capitalize;
  margin-bottom: 0;
  position: relative;
}

/* Category Widget */
.widget-categories .widget-wrapper {
  padding-top: 41px;
  clip-path: polygon(84% 0, 100% 11%, 100% 100%, 0 100%, 0 0);
}
.widget-categories .widget-content ul {
  margin-bottom: 0;
}
.widget-categories .widget-content ul li {
  margin-bottom: 4px;
}
.widget-categories .widget-content ul li:last-child {
  margin-bottom: 0;
}
.widget-categories .widget-content ul li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 700;
  color: var(--global--color-white);
  text-transform: capitalize;
  background-color: var(--global--color-heading);
  border-radius: 3px;
  z-index: 1;
  transition: 0.3s ease-in-out;
  height: 68px;
  padding: 0 30px;
  position: relative;
  overflow: hidden;
}
.widget-categories .widget-content ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--global--color-primary);
  border-radius: 3px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.24s ease-in-out;
}
.widget-categories .widget-content ul li a:hover {
  color: var(--global--color-heading);
}
.widget-categories .widget-content ul li a:hover::before {
  transform: scaleX(1);
  transform-origin: left center;
}
.widget-categories .widget-content ul li a:hover i {
  background-color: var(--global--color-heading);
  color: var(--global--color-primary);
}
.widget-categories .widget-content ul li a i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: var(--global--color-primary);
  font-style: normal;
  font-family: var(--global--font-body);
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  border-radius: 50%;
}
.widget-categories.widget-categories-2 .widget-content ul li a:hover i {
  color: var(--global--color-heading);
}
.widget-categories.widget-categories-2 .widget-content ul li a i {
  width: auto;
  height: auto;
  background-color: transparent;
  font-size: 10px;
  color: var(--global--color-white);
  border-radius: 0;
}

/* Tags Widget */
.widget-tags .widget-wrapper {
  padding-top: 41px;
  padding-bottom: 40px;
  clip-path: polygon(84% 0, 100% 11%, 100% 100%, 0 100%, 0 0);
}
.widget-tags .widget-content {
  text-align: left;
}
.widget-tags .widget-content a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background-color: transparent;
  font-family: var(--global--font-body);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: var(--global--color-heading);
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
  padding: 0px 10px;
  background-color: var(--global--color-primary);
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}
.widget-tags .widget-content a:hover {
  background-color: var(--global--color-heading);
  color: var(--global--color-white);
}

/* Recent Posts */
.widget-recent-posts .widget-wrapper {
  padding-bottom: 44px;
}
.widget-recent-posts .post {
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  margin-bottom: 30px;
}
.widget-recent-posts .post:last-child {
  margin-bottom: 0;
}
.widget-recent-posts .post .post-img {
  flex-shrink: 0;
  max-width: 100%;
  margin-right: 20px;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.widget-recent-posts .post .post-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.widget-recent-posts .post .post-content {
  position: relative;
}
.widget-recent-posts .post .post-content .post-date {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #616161;
  text-transform: capitalize;
  margin-bottom: 3px;
}
.widget-recent-posts .post .post-content .post-date span:after {
  position: relative;
  content: ",";
  margin-right: 4px;
}
.widget-recent-posts .post .post-content .post-date span:last-child:after {
  content: unset;
}
.widget-recent-posts .post .post-content .post-title a {
  font-family: var(--global--font-heading);
  color: var(--global--color-heading);
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  text-transform: capitalize;
}
.widget-recent-posts .post .post-content .post-title a:hover {
  color: var(--global--color-primary);
}

/* Search Widget */
.widget-search .widget-wrapper {
  background-color: var(--global--color-primary);
  padding-top: 41px;
  clip-path: polygon(84% 0, 100% 27%, 100% 100%, 0 100%, 0 0);
}
.widget-search .widget-wrapper .widget-title h3 {
  color: var(--global--color-heading);
}
.widget-search .form-search {
  position: relative;
  background-color: var(--global--color-white);
  margin-bottom: 0;
}
.widget-search .form-search .form-control {
  border: none;
  box-shadow: none;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1;
  height: 65px;
  padding: 25px 55px 25px 25px;
  background-color: transparent;
  border-radius: 3px;
  margin-bottom: 0;
}
.widget-search .form-search .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.widget-search .form-search .form-control:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.widget-search .form-search .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.widget-search .form-search .btn {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  width: auto;
  height: auto;
  font-size: 16px;
  height: 100%;
  z-index: 4;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  padding: 0;
}
.widget-search .form-search .btn i {
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  font-size: 15px;
  margin-left: 0;
}
.widget-search .form-search .btn:hover i {
  color: var(--global--color-primary);
}

/* Reservation Widget */
.widget-reservation {
  position: relative;
  overflow: visible;
}
.widget-reservation .widget-wrapper {
  clip-path: polygon(84% 0, 100% 12%, 100% 100%, 0 100%, 0 0);
  padding: 42px 50px 46px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .widget-reservation .widget-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .widget-reservation .widget-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .widget-reservation .widget-wrapper {
    text-align: center;
  }
}
.widget-reservation .widget-wrapper:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(254, 208, 0, 0.95);
}
.widget-reservation .widget-wrapper::after {
  content: unset;
}
.widget-reservation .widget-wrapper .widget-content {
  position: relative;
  z-index: 3;
}
.widget-reservation .widget-wrapper .widget-content .widget-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.widget-reservation .widget-wrapper .widget-content .widget-action .btn {
  width: 100%;
  height: 68px;
  margin-bottom: 40px;
}
.widget-reservation .widget-wrapper .widget-content .widget-action .btn:last-child {
  width: auto;
  height: auto;
  padding: 0;
  color: #f9f9f9;
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  justify-content: flex-start;
  margin-bottom: 0;
}
.widget-reservation .widget-wrapper .widget-content .widget-action .btn:last-child svg {
  margin-right: 20px;
}

/* Widget Download */
.widget-download .widget-wrapper {
  clip-path: polygon(84% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
}
.widget-download .widget-wrapper ul {
  margin: 0;
}
.widget-download .widget-wrapper ul li:last-of-type a {
  margin-bottom: 0;
}
.widget-download .widget-wrapper ul li.inversed a {
  background-color: var(--global--color-heading);
  color: var(--global--color-white);
}
.widget-download .widget-wrapper ul li.inversed a .icon-wrapper {
  border-right: 2px solid #393939;
}
.widget-download .widget-wrapper ul li a {
  display: flex;
  align-items: center;
  padding-right: 30px;
  height: 80px;
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  border-radius: 3px;
  margin-bottom: 20px;
  position: relative;
}
@media screen and (max-width:767.98px) {
  .widget-download .widget-wrapper ul li a {
    padding-right: 20px;
    height: 50px;
  }
}
.widget-download .widget-wrapper ul li a .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: transparent;
  margin-right: 25px;
  border-right: 2px solid #d2ad07;
  flex-shrink: 0;
}
.widget-download .widget-wrapper ul li a span {
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}

/* Widget Recent Products */
.widget-recent-products {
  margin-bottom: 42px;
}
.widget-recent-products .product {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.widget-recent-products .product:last-child {
  margin-bottom: 0;
}
.widget-recent-products .product .product-img {
  flex-shrink: 0;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}
.widget-recent-products .product .product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.widget-recent-products .product .product-desc .product-title a {
  text-transform: capitalize;
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: var(--global--color-heading);
  margin-bottom: 5px;
  display: inline-block;
}
.widget-recent-products .product .product-desc .product-title a:hover {
  color: var(--global--color-primary);
}
.widget-recent-products .product .product-desc .product-meta p {
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 700;
  color: var(--global--color-body);
  margin-bottom: 0;
}

/* Widget Services */
.widget-services .widget-content ul {
  margin-bottom: 0;
}
.widget-services .widget-content ul li {
  margin-bottom: 4px;
}
.widget-services .widget-content ul li:last-child {
  margin-bottom: 0;
}
.widget-services .widget-content ul li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 700;
  color: var(--global--color-white);
  text-transform: capitalize;
  background-color: var(--global--color-heading);
  border-radius: 3px;
  z-index: 1;
  transition: 0.3s ease-in-out;
  height: 68px;
  padding: 0 30px;
  position: relative;
  overflow: hidden;
}
.widget-services .widget-content ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--global--color-primary);
  border-radius: 3px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.24s ease-in-out;
}
.widget-services .widget-content ul li a:hover {
  color: var(--global--color-heading);
}
.widget-services .widget-content ul li a:hover::before {
  transform: scaleX(1);
  transform-origin: left center;
}
.widget-services .widget-content ul li a:hover i {
  color: var(--global--color-heading);
}
.widget-services .widget-content ul li a i {
  color: var(--global--color-white);
  transition: 0.3s ease-in-out;
  font-size: 10px;
  margin-left: 5px;
}

/* Widget Filter */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal .ui-slider-handle {
  position: absolute;
  top: -4px;
  margin-left: -0.6em;
}
.ui-slider-horizontal .ui-slider-range {
  position: absolute;
  top: -1px;
  height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: 0.8em;
  height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -0.3em;
  margin-left: 0;
  margin-bottom: -0.6em;
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}
.ui-slider-vertical .ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.widget-filter .widget-title h3 {
  margin-bottom: 26px;
}
.widget-filter .ui-slider {
  position: relative;
  height: 4px;
  background-color: #eaeaea;
  border-radius: 0;
  margin-bottom: 19px;
  margin-top: 5px;
}
.widget-filter .ui-slider-handle {
  /* Style for "Line" */
  width: 10px;
  height: 10px;
  margin-left: 0;
  background-color: var(--global--color-primary);
  border-radius: 3px;
}
.widget-filter .ui-slider-range {
  height: 4px;
  background-color: var(--global--color-primary);
  margin-bottom: 19px;
}
.widget-filter .slider-mount {
  display: flex;
  align-items: center;
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: var(--global--color-body);
  margin-bottom: 0;
}
.widget-filter label {
  color: var(--global--color-body);
  margin: 0;
}
.widget-filter input {
  color: var(--global--color-body);
  border: none;
  background-color: transparent;
}
.widget-filter .btn-filter {
  float: right;
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--global--color-heading);
  flex-grow: 2;
  text-align: right;
}
.widget-filter .btn-filter:hover {
  color: var(--global--color-primary);
}

/*------------------------------------*\
    #About
\*------------------------------------*/
.about {
  padding-top: 130px;
  padding-bottom: 130px;
  position: relative;
  overflow: visible;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .about {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.about .about-img-holder {
  display: flex;
  justify-content: center;
}
.about .about-img-holder .about-img {
  position: relative;
}
@media screen and (max-width:991.98px) {
  .about .about-img-holder .about-img {
    margin: 0 auto 0px;
  }
}
.about .about-img-holder .about-img img {
  border-radius: 0px;
  height: 800px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
@media screen and (max-width:991.98px) {
  .about .about-img-holder .about-img img {
    max-width: 550px;
    height: 650px;
    margin: 0 auto;
  }
}
@media screen and (max-width:767.98px) {
  .about .about-img-holder .about-img img {
    max-width: 100%;
  }
}
.about .about-img-holder .about-img .edge-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 110px;
  height: 110px;
}
.about .about-img-holder .about-img .video-intro {
  display: flex;
  max-width: 400px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 5;
}
@media screen and (max-width: 424.98px) {
  .about .about-img-holder .about-img .video-intro {
    flex-direction: column;
  }
}
.about .about-img-holder .about-img .video-intro .intro-img {
  width: 165px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
@media screen and (max-width: 424.98px) {
  .about .about-img-holder .about-img .video-intro .intro-img {
    width: 220px;
  }
}
.about .about-img-holder .about-img .video-intro .intro-body {
  background-color: var(--global--color-white);
  height: 110px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 6px 30px 0;
  position: relative;
}
@media screen and (max-width: 424.98px) {
  .about .about-img-holder .about-img .video-intro .intro-body {
    width: 220px;
    padding: 6px 20px 0;
  }
}
.about .about-img-holder .about-img .video-intro .intro-body::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background-color: var(--global--color-primary);
}
.about .about-img-holder .about-img .video-intro .intro-body .video-title {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 16px;
  color: var(--global--color-heading-light);
  margin-bottom: 9px;
}
.about .about-img-holder .about-img .video-intro .intro-body .video-desc {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
}
.about .about-img-holder .about-img .cta-banner-holder {
  position: absolute;
  right: -40px;
  bottom: -40px;
  background-color: var(--global--color-white);
  max-width: 300px;
  z-index: 5;
  padding-top: 40px;
  padding-left: 40px;
}
@media (min-width: 992px) and (max-width: 1099.98px) {
  .about .about-img-holder .about-img .cta-banner-holder {
    right: -20px;
    bottom: -20px;
  }
}
@media (min-width: 1200px) and (max-width: 1249.98px) {
  .about .about-img-holder .about-img .cta-banner-holder {
    right: -20px;
    bottom: -20px;
  }
}
.about .about-img-holder .about-img .cta-banner-holder .cta-banner-content {
  background-color: var(--global--color-primary);
  padding: 33px 40px 12px;
}
.about .about-img-holder .about-img .cta-banner-holder .cta-banner-content .banner-title {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: var(--global--color-white);
  margin-bottom: 19px;
}
.about .about-img-holder .about-img .cta-banner-holder .cta-banner-content .banner-subtitle {
  margin-bottom: 35px;
  color: #f9f9f9;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
}
.about .about-img-holder .about-img .cta-banner-holder .cta-banner-content .btn {
  width: 100%;
  margin-bottom: 14px;
}
.about .about-img-holder .about-img .cta-banner-holder .cta-banner-content .banner-note {
  font-size: 13px;
  line-height: 24px;
  color: var(--global--color-heading);
  margin-bottom: 0;
}
.about .advantages-list {
  margin-bottom: 25px;
}
.about .about-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .about .about-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .about .about-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.about .about-action .btn {
  width: 220px;
  height: 68px;
  margin-right: 30px;
}
@media screen and (max-width:767.98px) {
  .about .about-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 200px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .about .about-action .btn {
    width: 200px;
    margin-right: 15px;
  }
}
.about .about-action .btn:last-child {
  margin-right: 0;
  width: 176px;
}
@media screen and (max-width:767.98px) {
  .about .about-action .btn:last-child {
    margin-bottom: 0;
  }
}
.about .signature-block {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
@media screen and (max-width:991.98px) {
  .about .signature-block {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .about .signature-block {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.about .signature-block img {
  width: 68px;
  height: 68px;
  border: 2px solid #49d5fc;
  padding: 8px;
  background-color: var(--global--color-white);
  border-radius: 50%;
  margin-right: 20px;
}
.about .signature-block .signature-body {
  position: relative;
}
.about .signature-block .signature-body h3 {
  font-family: var(--global--font-heading);
  font-weight: 600;
  color: #1e4873;
  font-size: 19px;
  line-height: 29px;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .about .signature-block .signature-body h3 {
    font-size: 12px;
  }
}
.about .signature-block .signature-body p {
  font-family: var(--global--font-body);
  font-weight: 400;
  color: #1d65b9;
  font-size: 14px;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.about .offers-cta {
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width:992px) {
  .about .offers-cta {
    transform: translateY(-187px);
    margin-bottom: -144px;
  }
}
@media screen and (max-width:991.98px) {
  .about .offers-cta {
    margin-bottom: 70px;
    justify-content: center;
  }
}
.about .offers-cta .cta-body {
  position: relative;
  border-radius: 6px;
  background-color: var(--global--color-white);
  max-width: 400px;
  padding: 60px 60px 0px;
}
@media screen and (max-width:1199.98px) {
  .about .offers-cta .cta-body {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .about .offers-cta .cta-body {
    padding: 40px 25px 40px;
    max-width: 370px;
    box-shadow: 0px 5px 83px 0px rgba(3, 43, 86, 0.18);
  }
}
@media screen and (min-width:992px) {
  .about .offers-cta .cta-body img {
    position: absolute;
    bottom: 6px;
    right: 100%;
    width: 200px;
    height: 153px;
    object-fit: cover;
    border-radius: 6px 0 0 0;
  }
}
@media screen and (max-width:991.98px) {
  .about .offers-cta .cta-body img {
    max-width: 100%;
    border-radius: 6px;
  }
}
.about .badge-rate {
  clip-path: polygon(0 0, 50% 0, 100% 0, 100% 100%, 50% 85%, 0 100%);
  background-color: var(--global--color-primary);
  min-height: 130px;
  width: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 7px;
  position: relative;
}
@media screen and (max-width:991.98px) {
  .about .badge-rate {
    margin: 0 auto 40px;
  }
}
.about .badge-rate::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 58px;
  background-color: #1c7bfd;
  clip-path: polygon(0 0, 50% 0, 100% 0, 100% 100%, 50% 70%, 0 100%);
}
.about .badge-rate .num {
  font-weight: 500;
  font-size: 28px;
  font-family: var(--global--font-body);
  color: var(--global--color-white);
  margin-bottom: 18px;
  position: relative;
  z-index: 2;
}
.about .badge-rate .rating {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-bottom: 7px;
}
.about .badge-rate .rating i {
  color: var(--global--color-white);
  font-size: 12px;
  margin-right: 3px;
}
.about .badge-rate .rating i:first-child {
  font-size: 8px;
}
.about .badge-rate .rating i:last-child {
  margin-right: 0;
  font-size: 8px;
}
.about .badge-rate .info {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: var(--global--color-white);
  margin-bottom: 0px;
  text-transform: capitalize;
  position: relative;
  z-index: 2;
}
.about.about-1 {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width:992px) {
  .about.about-1 {
    min-height: 690px;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width:991.98px) {
  .about.about-1 {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}
@media screen and (min-width:992px) {
  .about.about-1 .about-img-holder {
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 50%;
  }
}
.about.about-2 .panels-holder {
  margin-bottom: 70px;
}
@media screen and (max-width:991.98px) {
  .about.about-2 .about-action {
    margin-bottom: 70px;
  }
}
.about.about-2 .about-action .btn:first-child {
  width: 228px;
}
@media screen and (min-width:768px) {
  .about.about-2 .about-action .btn:first-child {
    margin-right: 20px;
  }
}
.about.about-2 .about-img-holder .about-img img {
  height: 757px;
}
.about.about-3 {
  padding-top: 0px;
  padding-bottom: 0px;
}
@media screen and (min-width:992px) {
  .about.about-3 {
    min-height: 690px;
    display: flex;
    align-items: center;
  }
}
.about.about-3 .heading {
  position: relative;
  z-index: 6;
}
@media screen and (min-width:992px) {
  .about.about-3 .about-img-holder {
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
}
@media screen and (min-width:992px) {
  .about.about-3 .about-img-holder .testimonial-wrapper {
    position: absolute;
    bottom: -287px;
    left: 0;
    width: 100%;
  }
}
@media screen and (max-width:991.98px) {
  .about.about-3 .about-img-holder .testimonial-wrapper {
    position: absolute;
    bottom: 0;
    max-width: 550px;
  }
  .about.about-3 .about-img-holder .testimonial-wrapper .container-lg {
    padding: 0;
  }
}
@media screen and (max-width:767.98px) {
  .about.about-3 .about-img-holder .testimonial-wrapper {
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
  }
}
.about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder {
  padding: 78px 90px 80px;
  background-color: var(--global--color-primary);
  position: relative;
  z-index: 5;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media screen and (max-width:991.98px) {
  .about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media screen and (max-width:767.98px) {
  .about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder .testimonials-thumbs-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}
.about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder .testimonials-thumbs-wrapper::before {
  content: unset;
}
.about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder .testimonial-panel {
  margin-bottom: 24px;
}
.about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder .quote-icon {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 5;
}
@media screen and (max-width:767.98px) {
  .about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder .quote-icon {
    bottom: 25px;
    right: 25px;
  }
}
.about.about-3 .about-img-holder .testimonial-wrapper .testimonials-holder::before {
  content: "";
  position: absolute;
  bottom: calc(100% - 1px);
  left: 0;
  width: 35px;
  height: 30px;
  background-color: var(--global--color-primary);
  clip-path: polygon(100% 100%, 0 0, 0 100%);
}
@media screen and (min-width:992px) {
  .about.about-3 .about-img-holder .about-img {
    max-width: 50%;
  }
}
.about.about-3 .about-img-holder .about-img img {
  height: 690px;
}

/*------------------------------------*\
    #Banner
\*------------------------------------*/
.banner {
  position: relative;
  padding-top: 130px;
  padding-bottom: 130px;
}
@media screen and (max-width:991.98px) {
  .banner {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}
.banner .banners-wrapper {
  position: relative;
  margin-bottom: 70px;
}
@media screen and (max-width:991.98px) {
  .banner .banners-wrapper {
    margin-bottom: 50px;
  }
}
@media screen and (min-width:992px) {
  .banner .banners-wrapper .banner-carousel {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 619px;
  }
}
@media screen and (max-width:991.98px) {
  .banner .banners-wrapper .banner-carousel {
    margin-bottom: 70px;
  }
}
@media screen and (min-width:992px) {
  .banner.banner-right .banner-img {
    left: auto;
    right: 0;
    background-position: left bottom;
  }
}
.banner.banner-1 {
  padding-top: 205px;
  padding-bottom: 121px;
}
@media screen and (max-width:991.98px) {
  .banner.banner-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (min-width:992px) {
  .banner.banner-1 .call-to-action .note {
    transform: translateY(-7px);
    margin-bottom: -7px;
  }
}
@media screen and (max-width:991.98px) {
  .banner.banner-1 .call-to-action .note {
    margin-bottom: 40px;
  }
}
.banner.banner-2 {
  padding-top: 121px;
  padding-bottom: 130px;
}
@media screen and (max-width:991.98px) {
  .banner.banner-2 {
    padding-top: 61px;
    padding-bottom: 70px;
  }
}
@media screen and (min-width:992px) {
  .banner.banner-2::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 1px), -50%);
    width: 1px;
    height: calc(100% - 260px);
    background-color: var(--global--color-primary);
  }
}
.banner.banner-2 .panels-holder {
  margin-bottom: 64px;
}
.banner.banner-2 .panels-holder .banner-panel:first-child {
  padding-top: 0;
  border-top: 0;
}
.banner.banner-2 .panels-holder .banner-panel:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.banner.banner-2 .banners-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .banner.banner-2 .banners-action {
    justify-content: center;
    margin-bottom: 63px;
  }
}
@media screen and (max-width:767.98px) {
  .banner.banner-2 .banners-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.banner.banner-2 .banners-action .btn {
  width: 260px;
  height: 68px;
  margin-right: 30px;
  padding: 0 15px;
}
@media screen and (max-width:767.98px) {
  .banner.banner-2 .banners-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 220px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .banner.banner-2 .banners-action .btn {
    width: 220px;
    margin-right: 15px;
  }
}
.banner.banner-2 .banners-action .btn:last-child {
  margin-right: 0;
  width: 154px;
}
@media screen and (max-width:767.98px) {
  .banner.banner-2 .banners-action .btn:last-child {
    margin-bottom: 0;
  }
}
.banner.banner-3 {
  padding-top: 121px;
  padding-bottom: 130px;
  overflow: visible;
}
@media screen and (max-width:991.98px) {
  .banner.banner-3 {
    padding-top: 61px;
    padding-bottom: 70px;
  }
}
@media screen and (min-width:992px) {
  .banner.banner-3::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 1px), -50%);
    width: 1px;
    height: calc(100% - 260px);
    background-color: var(--global--color-primary);
  }
}
.banner.banner-3 .panels-holder {
  margin-bottom: 64px;
}
.banner.banner-3 .panels-holder .banner-panel:first-child {
  padding-top: 0;
  border-top: 0;
}
.banner.banner-3 .panels-holder .banner-panel:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.banner.banner-3 .banners-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .banner.banner-3 .banners-action {
    justify-content: center;
    margin-bottom: 63px;
  }
}
@media screen and (max-width:767.98px) {
  .banner.banner-3 .banners-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.banner.banner-3 .banners-action .btn {
  width: 260px;
  height: 68px;
  margin-right: 30px;
  padding: 0 15px;
}
@media screen and (max-width:767.98px) {
  .banner.banner-3 .banners-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 220px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .banner.banner-3 .banners-action .btn {
    width: 220px;
    margin-right: 15px;
  }
}
.banner.banner-3 .banners-action .btn:last-child {
  margin-right: 0;
  width: 154px;
}
@media screen and (max-width:767.98px) {
  .banner.banner-3 .banners-action .btn:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width:992px) {
  .banner.banner-3 .testimonial-wrapper {
    position: absolute;
    bottom: -109px;
    left: 0;
    width: 100%;
  }
}
@media screen and (max-width:991.98px) {
  .banner.banner-3 .testimonial-wrapper {
    position: relative;
    bottom: auto;
    left: auto;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
  .banner.banner-3 .testimonial-wrapper .container-lg {
    padding: 0;
  }
}
@media screen and (max-width:767.98px) {
  .banner.banner-3 .testimonial-wrapper {
    max-width: 100%;
  }
}
.banner.banner-3 .testimonial-wrapper .testimonials-holder {
  padding: 78px 90px 80px;
  background-color: var(--global--color-primary);
  position: relative;
  z-index: 5;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .banner.banner-3 .testimonial-wrapper .testimonials-holder {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media screen and (max-width:991.98px) {
  .banner.banner-3 .testimonial-wrapper .testimonials-holder {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media screen and (max-width:767.98px) {
  .banner.banner-3 .testimonial-wrapper .testimonials-holder {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.banner.banner-3 .testimonial-wrapper .testimonials-holder .testimonials-thumbs-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}
.banner.banner-3 .testimonial-wrapper .testimonials-holder .testimonials-thumbs-wrapper::before {
  content: unset;
}
.banner.banner-3 .testimonial-wrapper .testimonials-holder .testimonial-panel {
  margin-bottom: 24px;
}
.banner.banner-3 .testimonial-wrapper .testimonials-holder .quote-icon {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 5;
}
@media screen and (max-width:767.98px) {
  .banner.banner-3 .testimonial-wrapper .testimonials-holder .quote-icon {
    bottom: 25px;
    right: 25px;
  }
}
.banner.banner-3 .testimonial-wrapper .testimonials-holder::before {
  content: "";
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  width: 40px;
  height: 30px;
  background-color: var(--global--color-primary);
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}
.banner.banner-4 {
  padding-top: 115px;
  padding-bottom: 121px;
}
@media screen and (max-width:991.98px) {
  .banner.banner-4 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (min-width:992px) {
  .banner.banner-4 .call-to-action .note {
    transform: translateY(-7px);
    margin-bottom: -7px;
  }
}
@media screen and (max-width:991.98px) {
  .banner.banner-4 .call-to-action .note {
    margin-bottom: 40px;
  }
}

.panels-holder .banner-panel {
  display: flex;
  padding-top: 42px;
  padding-bottom: 44px;
  border-bottom: 1px solid #404040;
}
.panels-holder .banner-panel:first-child {
  border-top: 1px solid #404040;
}
@media screen and (max-width:991.98px) {
  .panels-holder .banner-panel {
    max-width: 500px;
    margin: 0 auto 0px;
  }
}
@media screen and (max-width:767.98px) {
  .panels-holder .banner-panel {
    max-width: 370px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.panels-holder .banner-panel:hover .panel-icon i {
  animation: Bounce 0.8s ease-in-out;
}
.panels-holder .banner-panel .panel-icon {
  margin-right: 46px;
}
@media screen and (max-width:1199.98px) {
  .panels-holder .banner-panel .panel-icon {
    margin-right: 30px;
  }
}
@media screen and (max-width:767.98px) {
  .panels-holder .banner-panel .panel-icon {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}
.panels-holder .banner-panel .panel-icon i {
  display: inline-block;
  transition: 0.3s ease-in-out;
}
@media screen and (min-width:768px) {
  .panels-holder .banner-panel .panel-icon i {
    margin-top: 8px;
  }
}
.panels-holder .banner-panel .panel-icon i::before {
  font-size: 65px;
  line-height: 1;
  color: var(--global--color-primary);
}
@media screen and (max-width:767.98px) {
  .panels-holder .banner-panel .panel-icon i::before {
    font-size: 45px;
  }
}
.panels-holder .banner-panel .panel-content h3 {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: var(--global--color-white);
  text-transform: capitalize;
  margin-bottom: 17px;
}
.panels-holder .banner-panel .panel-content p {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: var(--global--color-body);
  margin-bottom: 0px;
}
.panels-holder .banner-panel.banner-dark {
  border-color: #eaeaea;
}
.panels-holder .banner-panel.banner-dark .panel-content h3 {
  color: var(--global--color-heading-light);
}

/*------------------------------------*\
    #Features
\*------------------------------------*/
.features {
  padding-top: 114px;
  padding-bottom: 0;
  overflow: visible;
  position: relative;
  z-index: 4;
}
@media screen and (max-width:991.98px) {
  .features {
    padding-top: 54px;
  }
}
.features .carousel .owl-stage-outer {
  overflow: visible;
}
.features .carousel .owl-stage-outer .owl-item {
  opacity: 0;
}
.features .carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}
@media screen and (max-width:991.98px) {
  .features .carousel .owl-nav {
    justify-content: center;
    margin-bottom: 30px;
  }
}
.features .features-action {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .features .features-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .features .features-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width:992px) {
  .features .features-action {
    justify-content: flex-end;
  }
}
.features .features-action .btn {
  width: 240px;
  height: 68px;
  margin-right: 30px;
  padding: 0 15px;
}
@media screen and (max-width:767.98px) {
  .features .features-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
    height: 60px;
    width: 200px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .features .features-action .btn {
    width: 200px;
    margin-right: 15px;
  }
}
.features .features-action .contact-num {
  display: flex;
  align-items: center;
}
.features .features-action .contact-num .contact-icon-wrapper {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: transparent;
  border: 2px solid var(--global--color-primary);
  border-radius: 50%;
  margin-right: 10px;
}
.features .features-action .contact-num .contact-icon-wrapper .contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: var(--global--color-heading);
  border-radius: 50%;
}
.features .features-action .contact-num .contact-icon-wrapper .contact-icon svg {
  color: var(--global--color-primary);
}
.features .features-action .contact-num .contact-body a {
  display: block;
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 17px;
  color: var(--global--color-heading-light);
  margin-bottom: 10px;
  line-height: 1;
}
.features .features-action .contact-num .contact-body .job-title {
  font-family: var(--global--color-secondary);
  font-weight: 400;
  font-size: 13px;
  color: var(--global--color-body);
  margin-bottom: 0;
  line-height: 1;
}
.features.features-1 {
  padding-top: 128px;
  padding-bottom: 102px;
}
@media screen and (max-width:991.98px) {
  .features.features-1 {
    padding-top: 68px;
    padding-bottom: 62px;
  }
}
.features.features-1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f4f4f4;
  height: 376px;
}
.features.features-1 .owl-carousel .owl-dots {
  margin-bottom: 40px;
}
@media screen and (min-width:992px) {
  .features.features-1 .owl-carousel .owl-dots {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    bottom: -6px;
  }
}
.features.features-1 .owl-carousel .owl-stage-outer {
  overflow: visible;
}
.features.features-1 .owl-carousel .owl-stage-outer .owl-item {
  opacity: 0;
}
.features.features-1 .owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}
.features.features-1 .note {
  position: relative;
}
@media screen and (min-width:992px) {
  .features.features-1 .note {
    transform: translateY(-8px);
    margin-bottom: -8px;
    max-width: 470px;
  }
}
.features.features-2 {
  padding-top: 208px;
  padding-bottom: 110px;
  position: relative;
}
@media screen and (max-width:991.98px) {
  .features.features-2 {
    padding-top: 60px;
    padding-bottom: 62px;
  }
}
.features.features-2 .bottom-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 396px;
}
.features.features-2 .bottom-shape .edge-shape {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 80px;
  height: 80px;
}
.features.features-3 {
  padding-top: 134px;
  padding-bottom: 150px;
}
@media screen and (max-width:991.98px) {
  .features.features-3 {
    padding-top: 64px;
    padding-bottom: 70px;
  }
}
.features.features-3 .advantages-list {
  margin-bottom: 53px;
}
@media screen and (max-width:991.98px) {
  .features.features-3 .features-actions {
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
.features.features-3 .features-actions p {
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  color: var(--global--color-body);
  margin-bottom: 33px;
}
.features.features-3 .features-actions .btn {
  display: inline-flex;
  width: 220px;
  height: 68px;
}
.features.features-4 {
  padding: 0;
}
.features.features-5 {
  padding-top: 20px;
  padding-bottom: 103px;
}
@media screen and (max-width:991.98px) {
  .features.features-5 {
    padding-bottom: 70px;
  }
}
.features.features-5 .divider {
  height: 1px;
  background-color: rgb(234, 234, 234);
  margin-top: 70px;
  margin-bottom: 62px;
}
@media screen and (max-width:991.98px) {
  .features.features-5 .note {
    margin-bottom: 32px;
  }
}
.features.features-6 {
  padding-top: 128px;
  padding-bottom: 90px;
}
@media screen and (max-width:991.98px) {
  .features.features-6 {
    padding-top: 68px;
    padding-bottom: 30px;
  }
}
.features.features-7 {
  padding-top: 116px;
  padding-bottom: 110px;
  position: relative;
}
@media screen and (max-width:991.98px) {
  .features.features-7 {
    padding-top: 60px;
    padding-bottom: 62px;
  }
}
.features.features-7 .bottom-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 396px;
}
.features.features-7 .bottom-shape .edge-shape {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 80px;
  height: 80px;
}
.features.features-8 {
  padding-top: 244px;
  padding-bottom: 150px;
}
@media screen and (max-width:991.98px) {
  .features.features-8 {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}
.features.features-8 .advantages-list {
  margin-bottom: 53px;
}
@media screen and (max-width:991.98px) {
  .features.features-8 .features-actions {
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
.features.features-8 .features-actions p {
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  color: var(--global--color-body);
  margin-bottom: 33px;
}
.features.features-8 .features-actions .btn {
  display: inline-flex;
  width: 220px;
  height: 68px;
}

.feature-panel {
  background-color: var(--global-color-white);
  transition: 0.3s ease-in-out;
  position: relative;
  margin-bottom: 40px;
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
}
@media screen and (max-width:991.98px) {
  .feature-panel {
    max-width: 370px;
    margin: 0 auto 40px;
  }
}
@media screen and (max-width:767.98px) {
  .feature-panel {
    margin: 0 auto 30px;
  }
}
.feature-panel:hover {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.feature-panel:hover .feature-img::before {
  opacity: 1;
  visibility: visible;
}
.feature-panel:hover .feature-img .feature-more::before {
  width: 130px;
  height: 130px;
}
.feature-panel:hover .feature-content .feature-icon i {
  transform: rotateY(360deg);
}
.feature-panel .feature-img {
  position: relative;
}
.feature-panel .feature-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 55%, rgba(0, 0, 0, 0.35) 100%);
  z-index: 2;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.feature-panel .feature-img img {
  width: 100%;
  height: 192px;
  object-fit: cover;
}
.feature-panel .feature-img .feature-more {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: transparent;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  z-index: 5;
}
.feature-panel .feature-img .feature-more::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 2px solid var(--global--color-primary);
  transition: 0.3s ease-in-out;
  border-radius: 50%;
}
.feature-panel .feature-img .feature-more i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: var(--global--color-primary);
  border-radius: 50%;
  color: var(--global--color-heading);
  font-size: 12px;
  transition: 0.3s ease-in-out;
}
.feature-panel .feature-content {
  display: flex;
  flex-direction: column;
  padding: 49px 50px 73px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .feature-panel .feature-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .feature-panel .feature-content {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.feature-panel .feature-content .feature-icon {
  transition: 0.3s ease-in-out;
  margin-bottom: 30px;
}
.feature-panel .feature-content .feature-icon i {
  display: inline-block;
  font-size: 65px;
  transition: 0.3s ease-in-out;
  color: var(--global--color-primary);
}
.feature-panel .feature-content .feature-body .feature-name {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  position: relative;
  margin-bottom: 26px;
}
.feature-panel .feature-content .feature-body .feature-name a {
  color: var(--global--color-heading);
}
.feature-panel .feature-content .feature-body .feature-name a:hover {
  color: var(--global--color-primary);
}
.feature-panel .feature-content .feature-body .feature-desc {
  color: var(--global--color-body);
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 0px;
  transition: 0.3s ease-in-out;
}
.feature-panel.feature-panel-2 {
  box-shadow: none;
  filter: drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.09));
}
.feature-panel.feature-panel-2:hover .feature-img .feature-btn {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}
.feature-panel.feature-panel-2:hover .feature-content .feature-icon i {
  transform: rotateY(0deg);
}
.feature-panel.feature-panel-2 .feature-content {
  clip-path: polygon(84% 0, 100% 12%, 100% 100%, 0 100%, 0 0);
  background-color: var(--global--color-white);
  padding-bottom: 43px;
}
.feature-panel.feature-panel-2 .feature-img {
  overflow: hidden;
}
.feature-panel.feature-panel-2 .feature-img::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 55%, rgba(0, 0, 0, 0.95) 100%);
}
.feature-panel.feature-panel-2 .feature-img img {
  height: 170px;
}
.feature-panel.feature-panel-2 .feature-img .feature-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 150px));
  width: calc(100% - 100px);
  height: 78px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  color: var(--global--color-heading);
}
@media screen and (max-width:1199.98px) {
  .feature-panel.feature-panel-2 .feature-img .feature-btn {
    width: calc(100% - 50px);
  }
}
.feature-panel.feature-panel-2 .feature-img .feature-btn::before {
  content: unset;
}
.feature-panel.feature-panel-2 .feature-img .feature-btn i {
  color: var(--global--color-heading);
}

.features-holder .feature-wrapper {
  margin-bottom: 110px;
}
@media screen and (max-width:991.98px) {
  .features-holder .feature-wrapper {
    margin-bottom: 70px;
    text-align: center;
  }
}
.features-holder .feature-wrapper .feature-img {
  position: relative;
}
@media screen and (min-width:992px) {
  .features-holder .feature-wrapper .feature-img {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
@media screen and (max-width:991.98px) {
  .features-holder .feature-wrapper .feature-img {
    max-width: 550px;
    margin: 0 auto;
  }
}
.features-holder .feature-wrapper .feature-img .edge-shape {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 110px;
  height: 110px;
}
.features-holder .feature-wrapper .feature-img img {
  width: 100%;
  height: 413px;
  object-fit: cover;
}
@media screen and (min-width:992px) {
  .features-holder .feature-wrapper .feature-img img {
    height: 100%;
  }
}
.features-holder .feature-wrapper .btn {
  display: inline-flex;
  width: 218px;
  height: 68px;
  padding: 0 20px;
}
@media screen and (max-width:991.98px) {
  .features-holder .feature-wrapper .heading {
    margin-bottom: 60px;
  }
}
.features-holder .feature-wrapper .quote-card .client-quote {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: var(--global--color-white);
  margin-bottom: 24px;
}
.features-holder .feature-wrapper .quote-card .client-rate {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .features-holder .feature-wrapper .quote-card .client-rate {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .features-holder .feature-wrapper .quote-card .client-rate {
    flex-direction: column;
    align-items: center;
  }
}
.features-holder .feature-wrapper .quote-card .client-rate .rating {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
@media screen and (max-width:767.98px) {
  .features-holder .feature-wrapper .quote-card .client-rate .rating {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.features-holder .feature-wrapper .quote-card .client-rate .rating i {
  font-size: 12px;
  color: var(--global--color-primary);
  margin-right: 5px;
}
.features-holder .feature-wrapper .quote-card .client-rate .rating i:last-child {
  margin-right: 0;
}
.features-holder .feature-wrapper .quote-card .client-rate .client-name {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #f9f9f9;
  margin-bottom: 0;
}
.features-holder .feature-wrapper .advantages-list {
  margin-bottom: 35px;
}
.features-holder .feature-wrapper.feature-right .feature-img .edge-shape {
  left: 0;
}
.features-holder .feature-wrapper.feature-left .feature-img .edge-shape {
  right: 0;
}
.features-holder.features-holder-2 {
  clip-path: polygon(90% 0, 100% 6%, 100% 100%, 0 100%, 0 0);
  background-color: var(--global--color-white);
}
@media screen and (min-width:992px) {
  .features-holder.features-holder-2 {
    transform: translateY(-110px);
  }
}
.features-holder.features-holder-2 .feature-wrapper {
  margin-bottom: 0;
}
.features-holder.features-holder-2 .feature-wrapper .content-wrapper {
  padding: 108px 70px 110px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .features-holder.features-holder-2 .feature-wrapper .content-wrapper {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media screen and (max-width:991.98px) {
  .features-holder.features-holder-2 .feature-wrapper .content-wrapper {
    padding: 68px 40px 70px;
  }
}
@media screen and (max-width:767.98px) {
  .features-holder.features-holder-2 .feature-wrapper .content-wrapper {
    padding: 68px 25px 70px;
  }
}
.features-holder.features-holder-2 .feature-wrapper .content-wrapper .heading {
  margin-bottom: 0;
}
.features-holder.features-holder-2 .feature-wrapper .content-wrapper .heading .heading-subtitle {
  color: var(--global--color-primary);
}
.features-holder.features-holder-2 .feature-wrapper .content-wrapper .quote-card .client-quote {
  color: var(--global--color-heading-darken);
}
.features-holder.features-holder-2 .feature-wrapper .content-wrapper .quote-card .client-rate .client-name {
  color: var(--global--color-heading-light);
}
.features-holder.features-holder-2 .feature-wrapper.feature-wrapper-3 .content-wrapper {
  padding: 104px 70px 103px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .features-holder.features-holder-2 .feature-wrapper.feature-wrapper-3 .content-wrapper {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media screen and (max-width:991.98px) {
  .features-holder.features-holder-2 .feature-wrapper.feature-wrapper-3 .content-wrapper {
    padding: 64px 40px 63px;
  }
}

.features-holder-wrapper {
  filter: drop-shadow(0px 5px 83px rgba(27, 26, 27, 0.12));
}

/*--------------------------------
    #Pricing
---------------------------------*/
.pricing {
  padding-top: 125px;
  padding-bottom: 102px;
}
@media screen and (max-width:991.98px) {
  .pricing {
    padding-top: 65px;
    padding-bottom: 62px;
  }
}

.pricing-table {
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
}
@media screen and (max-width:991.98px) {
  .pricing-table {
    max-width: 370px;
    margin: 0 auto 33px;
  }
}
.pricing-table .pricing-panel {
  background-color: #f4f4f4;
  padding: 43px 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;
  clip-path: polygon(84% 0, 100% 12%, 100% 100%, 0 100%, 0 0);
}
@media screen and (max-width:991.98px) {
  .pricing-table .pricing-panel {
    text-align: center;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .pricing-table .pricing-panel {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .pricing-table .pricing-panel {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.pricing-table .pricing-panel.active {
  background-color: var(--global--color-primary);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.pricing-table .pricing-panel.active:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(254, 208, 0, 0.95);
}
.pricing-table .pricing-panel.active .pricing-body .pricing-heading .pricing-desc {
  color: var(--global--color-heading-light);
}
.pricing-table .pricing-panel.active .pricing-body .pricing-list li i {
  background-color: var(--global--color-white);
}
.pricing-table .pricing-panel.active .pricing-price .btn {
  background-color: var(--global--color-white);
}
.pricing-table .pricing-panel .pricing-tag {
  position: absolute;
  top: -5px;
  left: -5px;
}
.pricing-table .pricing-panel .pricing-body .pricing-heading {
  margin-bottom: 25px;
}
.pricing-table .pricing-panel .pricing-body .pricing-heading .pricing-title {
  font-family: var(--global--font-heading);
  font-size: 23px;
  font-weight: 700px;
  line-height: 32px;
  color: var(--global--color-heading-darken);
  margin-bottom: 15px;
  position: relative;
  text-transform: capitalize;
  transition: 300ms ease-in-out;
}
.pricing-table .pricing-panel .pricing-body .pricing-heading .pricing-desc {
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 400;
  color: var(--global--color-body);
  line-height: 25px;
  margin-bottom: 0px;
}
.pricing-table .pricing-panel .pricing-body .pricing-list {
  margin-bottom: 33px;
}
.pricing-table .pricing-panel .pricing-body .pricing-list li {
  font-family: var(--global--font-body);
  font-size: 15px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 0px;
  color: var(--global--color-heading-light);
  display: flex;
  align-items: center;
}
.pricing-table .pricing-panel .pricing-body .pricing-list li i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--global--color-primary);
  color: var(--global--color-heading-light);
  font-size: 10px;
  margin-right: 15px;
}
.pricing-table .pricing-panel .pricing-price p {
  margin-bottom: 0;
  font-family: var(--global--font-heading);
  font-weight: 700;
  color: var(--global--color-heading-light);
  display: flex;
  align-items: baseline;
  line-height: 30px;
  position: relative;
}
@media screen and (max-width:991.98px) {
  .pricing-table .pricing-panel .pricing-price p {
    justify-content: center;
  }
}
.pricing-table .pricing-panel .pricing-price p span::before {
  content: "/";
}
.pricing-table .pricing-panel .pricing-price p span:first-of-type::before {
  content: unset;
}
.pricing-table .pricing-panel .pricing-price p .currency {
  font-size: 30px;
}
.pricing-table .pricing-panel .pricing-price p .time {
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
}
.pricing-table .pricing-panel .pricing-price .btn {
  height: 68px;
  justify-content: space-between;
  margin-bottom: 37px;
  justify-content: center;
  color: var(--global--color-heading);
}
.pricing-table .pricing-panel .pricing-price .btn span {
  font-size: 10px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  color: var(--global--color-heading);
}
.pricing-table .pricing-panel .pricing-price .btn::before {
  background-color: var(--global--color-white);
}
.pricing-table .pricing-panel .pricing-price .btn:hover span {
  transform: translateX(5px);
}

/*------------------------------------*\
    #FAQs
\*------------------------------------*/
.faqs {
  padding-top: 115px;
  padding-bottom: 103px;
}
@media screen and (max-width:991.98px) {
  .faqs {
    padding-top: 65px;
    padding-bottom: 62px;
  }
}
.faqs.faqs-1 .note {
  margin-top: 33px;
}
.faqs.faqs-2 {
  padding-top: 115px;
}
@media screen and (max-width:991.98px) {
  .faqs.faqs-2 {
    padding-top: 65px;
  }
}
.faqs.faqs-2 .note {
  margin-top: 33px;
}

/*------------------------------------*\
    #Contacts
\*------------------------------------*/
.contact {
  position: relative;
}
.contact .contact-action {
  display: flex;
  align-items: center;
  margin-bottom: 83px;
}
@media screen and (max-width:991.98px) {
  .contact .contact-action {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .contact .contact-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
}
.contact .contact-action .btn {
  width: 190px;
  height: 68px;
  margin-right: 30px;
}
.contact .contact-action .btn:last-child {
  width: 172px;
  padding: 0 20px;
  margin-right: 0;
}
@media screen and (max-width:767.98px) {
  .contact .contact-action .btn {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .contact .contact-action .btn:last-child {
    margin-bottom: 0;
  }
}
.contact .contact-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 97%;
  height: 100%;
  z-index: 1;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .contact .contact-overlay {
    width: 98%;
  }
}
@media screen and (max-width:767.98px) {
  .contact .contact-overlay {
    width: 100%;
  }
}
@media screen and (min-width: 1400px) {
  .contact .contact-overlay {
    width: 95%;
  }
}
.contact .contact-content {
  position: relative;
  z-index: 2;
}
.contact.contact-1 {
  padding-top: 110px;
  padding-bottom: 110px;
  overflow: visible;
  position: relative;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .contact.contact-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width:991.98px) {
  .contact.contact-1 .advantages-list {
    margin-bottom: 70px;
  }
}
.contact.contact-2 {
  padding-top: 110px;
  padding-bottom: 110px;
  overflow: visible;
  position: relative;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .contact.contact-2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.contact.contact-2 .heading .heading-title {
  margin-bottom: 41px;
}
.contact.contact-2 .contact-panel .heading {
  flex-grow: 0;
}
.contact.contact-2 .contact-panel .contact-card {
  flex-grow: 0;
}
@media screen and (max-width:991.98px) {
  .contact.contact-2 .contact-panel .contact-card {
    margin-bottom: 0;
  }
}
.contact.contact-3 {
  padding: 0;
  overflow: visible;
  position: relative;
  z-index: 5;
}

.contact-panel {
  display: flex;
  position: relative;
}
@media screen and (max-width:991.98px) {
  .contact-panel {
    flex-direction: column;
  }
}
@media screen and (min-width:992px) {
  .contact-panel {
    justify-content: space-between;
  }
}
.contact-panel .heading {
  z-index: 4;
}
@mediascreen and (min-width:992px) {
  .contact-panel .heading {
    flex-basis: 41%;
    flex-shrink: 0;
    margin-bottom: 0;
    transform: translateY(-11px);
    margin-bottom: -11px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .contact-panel .heading {
    flex-basis: 46%;
  }
}
.contact-panel .contact-card {
  z-index: 4;
}
@media screen and (min-width:992px) {
  .contact-panel .contact-card {
    flex-basis: 53%;
    flex-shrink: 0;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .contact-panel .contact-card {
    flex-basis: 50%;
  }
}
@media screen and (max-width:991.98px) {
  .contact-panel .contact-card {
    margin-bottom: 50px;
  }
}
.contact-panel.contact-panel-2 .contact-card {
  clip-path: none;
}
.contact-panel.contact-panel-2 .contact-card.contact-card-left {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.contact-panel.contact-panel-2 .contact-card.contact-card-left .contact-body {
  padding: 57px 30px 60px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .contact-panel.contact-panel-2 .contact-card.contact-card-left .contact-body {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (min-width: 1400px) {
  .contact-panel.contact-panel-2 .contact-card.contact-card-left .contact-body {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (max-width:767.98px) {
  .contact-panel.contact-panel-2 .contact-card.contact-card-left .contact-body {
    padding: 37px 25px 30px;
  }
}
.contact-panel.contact-panel-2 .contact-card.contact-card-left .contact-body .pricing-table {
  margin-bottom: 0;
}
.contact-panel.contact-panel-2 .contact-card.contact-card-right {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.contact-panel.contact-panel-2 .contact-card.contact-card-right .contact-body {
  padding: 57px 50px 60px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .contact-panel.contact-panel-2 .contact-card.contact-card-right .contact-body {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 1400px) {
  .contact-panel.contact-panel-2 .contact-card.contact-card-right .contact-body {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (max-width:767.98px) {
  .contact-panel.contact-panel-2 .contact-card.contact-card-right .contact-body {
    padding: 37px 25px 30px;
  }
}
.contact-panel.contact-panel-2 .contact-card.contact-card-right .contact-body .custom-radio-group {
  margin-bottom: 14px;
}
.contact-panel.contact-panel-2 .contact-card .contact-body .card-desc {
  margin-bottom: 34px;
}
.contact-panel.contact-panel-2 .contact-card .contact-body .btn i {
  background-color: var(--global--color-white);
  color: var(--global--color-heading);
}
.contact-panel.contact-panel-3 {
  transform: translateY(-145px);
  margin-bottom: -145px;
  padding: 60px 70px 60px;
  background-color: var(--global--color-white);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
  z-index: 5;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .contact-panel.contact-panel-3 {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width:767.98px) {
  .contact-panel.contact-panel-3 {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.contact-panel.contact-panel-3 .contact-card {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 70px;
  box-shadow: none;
  clip-path: none;
}
.contact-panel.contact-panel-3 .contact-card::after {
  content: unset;
}
@media screen and (max-width:991.98px) {
  .contact-panel.contact-panel-3 .contact-card {
    padding-left: 0;
    margin-bottom: 0;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .contact-panel.contact-panel-3 .contact-card {
    padding-left: 0;
  }
}
.contact-panel.contact-panel-3 .contact-card .contact-body {
  padding: 0;
  box-shadow: none;
}
.contact-panel.contact-panel-3 .contact-card .contact-body .card-desc {
  margin-bottom: 33px;
}
.contact-panel.contact-panel-3 .contact-card .contact-body .form-control {
  border: 2px solid #eaeaea;
  padding: 20px;
  height: 55px;
  border-radius: 3px;
  margin-bottom: 30px;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.contact-panel.contact-panel-3 .contact-card .contact-body .form-control::-webkit-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.contact-panel.contact-panel-3 .contact-card .contact-body .form-control:-moz-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.contact-panel.contact-panel-3 .contact-card .contact-body .form-control:-ms-input-placeholder {
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.contact-panel.contact-panel-3 .contact-card .contact-body textarea.form-control {
  height: 120px;
  margin-bottom: 40px;
}
.contact-panel.contact-panel-3 .contact-card .contact-body .nice-select::after {
  right: 20px;
}
.contact-panel.contact-panel-3 .contact-card .contact-body .btn {
  height: 68px;
  width: 190px;
  padding: 0 20px;
  box-shadow: 0px 3px 63px 0px rgba(6, 52, 100, 0.11);
}
@media screen and (max-width:991.98px) {
  .contact-panel.contact-panel-3 .contact-card .contact-body .btn {
    width: 100%;
  }
}
.contact-panel.contact-panel-3 .contact-card .contact-result {
  margin-top: 0;
}

.contact-card {
  background-color: var(--global--color-white);
  position: relative;
  clip-path: polygon(82% 0, 100% 14%, 100% 100%, 0 100%, 0 0);
}
.contact-card .contact-body {
  background-color: var(--global--color-white);
  height: max-content;
  padding: 47px 50px 0px;
  box-shadow: 0px 5px 83px 0px rgba(3, 43, 86, 0.12);
  position: relative;
  z-index: 2;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .contact-card .contact-body {
    padding: 37px 25px 30px;
  }
}
@media screen and (max-width:767.98px) {
  .contact-card .contact-body {
    padding: 37px 25px 30px;
  }
}
.contact-card .contact-body .card-heading {
  font-family: var(--global--font-heading);
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  text-transform: capitalize;
  color: #1b1a1a;
  margin-bottom: 20px;
}
@media screen and (max-width:991.98px) {
  .contact-card .contact-body .card-heading {
    text-align: center;
    line-height: 1.4;
  }
}
.contact-card .contact-body .card-desc {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: var(--global--color-body);
  margin-bottom: 34px;
}
@media screen and (max-width:991.98px) {
  .contact-card .contact-body .card-desc {
    text-align: center;
  }
}
.contact-card .contact-body .card-desc a {
  position: relative;
  font-weight: 700;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
}
.contact-card .contact-body .card-desc a:hover::before {
  width: 80%;
}
.contact-card .contact-body .card-desc a::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  height: 2px;
  width: 100%;
}
.contact-card .contact-body form {
  margin-bottom: 0;
}
.contact-card .contact-body .custom-radio-group {
  margin-bottom: 24px;
}
.contact-card .contact-body .btn {
  height: 60px;
  justify-content: space-between;
  border-radius: 3px 3px 0 0;
}
@media screen and (min-width:768px) {
  .contact-card .contact-body .btn {
    height: 78px;
    padding: 0 40px;
  }
}
.contact-card .contact-body .btn:hover i {
  background-color: var(--global--color-white);
  color: var(--global--color-heading);
}
.contact-card .contact-body .btn i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: var(--global--color-heading);
  color: var(--global--color-white);
  border-radius: 50%;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  font-size: 9px;
}
.contact-card .contact-body .contact-result {
  margin-top: 10px;
  text-align: center;
}
.contact-card.contact-card-2 {
  filter: drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.12));
  clip-path: none;
  background-color: transparent;
}
.contact-card.contact-card-2::after {
  content: unset;
}
@media screen and (max-width:991.98px) {
  .contact-card.contact-card-2 {
    margin-bottom: 70px;
  }
}
.contact-card.contact-card-2 .contact-body {
  clip-path: polygon(85% 0, 100% 10%, 100% 100%, 0 100%, 0 0);
  padding-bottom: 32px;
  background-color: var(--global--color-white);
}
.contact-card.contact-card-2 .custom-radio-group {
  flex-wrap: wrap;
}
.contact-card.contact-card-2 .contact-note {
  margin-top: 24px;
}
.contact-card.contact-card-2 .contact-note p {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 0;
}
.contact-card.contact-card-2 .block-info:first-child {
  border-bottom: 2px solid #eaeaea;
  margin-bottom: 36px;
}
.contact-card.contact-card-2 .block-info .card-desc {
  font-size: 14px;
  font-weight: 700;
  color: var(--global--color-heading-light);
  margin-bottom: 23px;
}

.img-card-holder {
  display: flex;
  flex-direction: column;
}
.img-card-holder .img-card-wrapper {
  position: relative;
  overflow: visible;
  filter: drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.12));
  display: flex;
  flex-grow: 1;
}
.img-card-holder .img-card-wrapper .img-card {
  clip-path: polygon(86% 0, 100% 11%, 100% 100%, 0 100%, 0 0);
  flex-shrink: 0;
  height: auto;
  padding: 42px 50px 47px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media screen and (min-width:992px) {
  .img-card-holder .img-card-wrapper .img-card {
    text-align: left;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .img-card-holder .img-card-wrapper .img-card {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .img-card-holder .img-card-wrapper .img-card {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width:991.98px) {
  .img-card-holder .img-card-wrapper .img-card {
    margin-bottom: 43px;
  }
}
.img-card-holder .img-card-wrapper .img-card:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: rgba(254, 208, 0, 0.95);
}
.img-card-holder .img-card-wrapper .img-card .card-content {
  max-width: 100%;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}
@media screen and (max-width:991.98px) {
  .img-card-holder .img-card-wrapper .img-card .card-content .content-top {
    margin-bottom: 30px;
  }
}
.img-card-holder .img-card-wrapper .img-card .card-content .content-top .heading {
  transform: translate(0);
}
.img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .contact-infos {
  margin-bottom: 27px;
}
.img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .contact-infos .contact-info {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .contact-infos .contact-info:last-child {
  margin-bottom: 0;
}
.img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .contact-infos .contact-info svg {
  color: var(--global--color-heading-light);
  font-size: 16px;
  margin-right: 15px;
}
.img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .contact-infos .contact-info a,
.img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .contact-infos .contact-info p {
  font-family: var(--global--font-body);
  line-height: 22px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--global--color-heading-light);
  text-transform: capitalize;
}
.img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .contact-infos .contact-info a:hover {
  color: var(--global--color-heading);
}
.img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .btn {
  width: 100%;
  height: 68px;
}
@media screen and (max-width:991.98px) {
  .img-card-holder .img-card-wrapper .img-card .card-content .content-bottom .btn {
    display: inline-flex;
  }
}

.map {
  position: relative;
  height: 560px;
}
.map iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.map iframe.map-gray {
  filter: brightness(104%) contrast(99%) saturate(0%) blur(0px) hue-rotate(0deg);
}

.request-quote {
  padding: 110px 0 130px;
}
@media screen and (max-width:991.98px) {
  .request-quote {
    padding: 70px 0;
  }
}

/*------------------------------------*\
    #Clients
\*------------------------------------*/
.clients {
  padding-top: 0;
  padding-bottom: 0;
}
.clients .clients-note {
  text-align: center;
}
@media screen and (min-width:992px) {
  .clients .clients-note {
    text-align: left;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .clients .clients-note {
    max-width: 100%;
  }
}
@media screen and (max-width:991.98px) {
  .clients .clients-note {
    margin: 0 auto 40px;
    max-width: 80%;
  }
}
@media screen and (max-width:767.98px) {
  .clients .clients-note {
    max-width: 100%;
  }
}
.clients .clients-note p {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 14px;
  color: #1e4873;
  line-height: 25px;
  margin-bottom: 0;
}
.clients .clients-note p a {
  position: relative;
  font-weight: 700;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
  padding-bottom: 6px;
}
.clients .clients-note p a:hover::before {
  width: 80%;
}
.clients .clients-note p a::before {
  content: "";
  transition: 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: var(--global--color-primary);
}
.clients .client {
  cursor: pointer;
  max-width: 150px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  height: 194px;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.clients .client:hover {
  opacity: 0.5;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .clients .client {
    width: 130px;
  }
}
@media screen and (max-width:991.98px) {
  .clients .client {
    height: 140px;
  }
}
.clients .client a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.clients .client img {
  max-width: 100%;
  width: auto;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.clients.clients-1 {
  background-color: transparent;
}
.clients.clients-2 {
  background-color: transparent;
}
.clients.clients-2 .client {
  height: 176px;
}

/*------------------------------------*\
    #Global Locations
\*------------------------------------*/
.global-locations {
  padding-top: 105px;
  padding-bottom: 48px;
}
@media screen and (max-width:991.98px) {
  .global-locations {
    padding-top: 65px;
    padding-bottom: 16px;
  }
}
.global-locations .continent {
  margin-bottom: 70px;
}
@media screen and (max-width:991.98px) {
  .global-locations .continent {
    margin-bottom: 40px;
  }
}
.global-locations .continent .continent-name {
  margin-bottom: 27px;
  border-bottom: 2px solid #e6eaef;
  padding-bottom: 23px;
}
@media screen and (max-width:991.98px) {
  .global-locations .continent .continent-name {
    text-align: center;
  }
}
.global-locations .continent .continent-name h2 {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-transform: capitalize;
  color: var(--global--color-heading-darken);
  margin-bottom: 0;
}
.global-locations .continent .continent-offices {
  margin-bottom: 0;
}
@media screen and (max-width:991.98px) {
  .global-locations .continent .continent-offices {
    text-align: center;
  }
}
.global-locations .continent .continent-offices ul {
  margin: 0;
}
.global-locations .continent .continent-offices li a {
  font-family: var(--global--font-body);
  font-size: 14px;
  line-height: 40px;
  font-weight: 700;
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.global-locations .continent .continent-offices li a:hover {
  color: var(--global--color-primary);
}
.global-locations .continent .continent-offices li a:hover i {
  color: var(--global--color-primary);
}
.global-locations .continent .continent-offices li a i {
  margin-right: 15px;
  transition: 0.3s ease-in-out;
  color: var(--global--color-heading);
}

/*------------------------------------*\
    #Carrers
\*------------------------------------*/
.careers {
  padding-top: 128px;
  padding-bottom: 110px;
}
@media screen and (max-width:991.98px) {
  .careers {
    padding-top: 68px;
    padding-bottom: 70px;
  }
}
.careers .career-item {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
  background-color: var(--global--color-white);
  padding: 40px 40px 40px;
  position: relative;
  transition: 0.3s ease-in-out;
  margin-bottom: 40px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .careers .career-item {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width:767.98px) {
  .careers .career-item {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width:991.98px) {
  .careers .career-item {
    max-width: 370px;
    margin: 0 auto 40px;
  }
}
.careers .career-item:hover {
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
}
.careers .career-item:hover .career-content .career-more {
  color: var(--global--color-white);
  background-color: var(--global--color-heading);
  border-color: var(--global--color-heading);
}
.careers .career-item:hover .career-content .career-more i {
  color: var(--global--color-white);
}
.careers .career-item .career-meta {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
}
.careers .career-item .career-meta .career-type,
.careers .career-item .career-meta .career-place {
  font-size: 13px;
  line-height: 28px;
  font-family: var(--global--font-body);
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
}
.careers .career-item .career-meta .career-type {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 30px;
  border-radius: 3px;
  background-color: var(--global--color-primary);
  margin-right: 15px;
  color: var(--global--color-heading);
}
.careers .career-item .career-meta .career-place {
  color: #616161;
}
.careers .career-item .career-content .career-title {
  font-family: var(--global--font-heading);
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  color: var(--global--color-heading);
  margin-bottom: 17px;
  text-transform: capitalize;
}
.careers .career-item .career-content .career-desc {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: var(--global--color-body);
  margin-bottom: 23px;
}
.careers .career-item .career-content .career-more {
  width: 150px;
  height: 48px;
  padding: 0 15px;
}
.careers .career-item .career-content .career-more i {
  color: var(--global--color-heading);
}
.careers .carousel-dots .owl-stage-outer {
  overflow: visible;
}
.careers .carousel-dots .owl-stage-outer .owl-item {
  opacity: 0;
  visibility: hidden;
}
.careers .carousel-dots .owl-stage-outer .owl-item.active {
  opacity: 1;
  visibility: visible;
}

/*------------------------------------*\
    #Testimonial
\*------------------------------------*/
.testimonial {
  padding-top: 93px;
  padding-bottom: 96px;
}
@media screen and (max-width:991.98px) {
  .testimonial {
    padding-top: 53px;
    padding-bottom: 70px;
  }
}
.testimonial.testimonial-1 {
  padding: 0;
  background-color: transparent;
  position: relative;
  overflow: visible;
  z-index: 5;
}
@media screen and (max-width:991.98px) {
  .testimonial.testimonial-1 {
    background-color: var(--global--color-primary);
  }
}
.testimonial.testimonial-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--global--color-primary);
  height: 100%;
  width: 50%;
}
.testimonial.testimonial-1 .testimonial-wrapper {
  background-color: var(--global--color-primary);
  padding-top: 122px;
  padding-bottom: 117px;
  position: relative;
}
@media screen and (max-width:991.98px) {
  .testimonial.testimonial-1 .testimonial-wrapper {
    padding-top: 70px;
    padding-bottom: 67px;
  }
}
.testimonial.testimonial-1 .testimonial-wrapper .quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  transform: translateY(-28px);
}
.testimonial.testimonial-1 .testimonial-wrapper .edge-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  width: 110px;
  height: 110px;
}
@media screen and (max-width:991.98px) {
  .testimonial.testimonial-1 .testimonial-wrapper .edge-shape {
    display: none;
  }
}

.testimonial-panel {
  position: relative;
}
@media screen and (max-width:991.98px) {
  .testimonial-panel .testimonial-body {
    text-align: center;
  }
}
.testimonial-panel .testimonial-body .testimonial-content p {
  font-family: var(--global--font-heading);
  color: var(--global--color-heading-darken);
  font-style: italic;
  font-size: 22px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 0;
}
@media screen and (max-width:767.98px) {
  .testimonial-panel .testimonial-body .testimonial-content p {
    font-size: 19px;
    line-height: 1.5;
  }
}

.testimonials-thumbs-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 37px;
  margin-bottom: 27px;
}
@media screen and (max-width:991.98px) {
  .testimonials-thumbs-wrapper {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .testimonials-thumbs-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.testimonials-thumbs-wrapper::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 440px;
  height: 4px;
  background-color: #161616;
}
@media screen and (max-width:991.98px) {
  .testimonials-thumbs-wrapper::before {
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
  }
}
.testimonials-thumbs-wrapper .testimonial-thumbs {
  display: flex;
  margin-right: 23px;
  position: relative;
  z-index: 55;
}
@media screen and (max-width:767.98px) {
  .testimonials-thumbs-wrapper .testimonial-thumbs {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.testimonials-thumbs-wrapper .testimonial-thumbs-info .testimonial-thumb {
  margin-right: 0;
}

.testimonial-thumb {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-right: 11px;
}
.testimonial-thumb:last-child {
  margin-right: 0;
}
@media screen and (max-width:991.98px) {
  .testimonial-thumb {
    justify-content: center;
  }
}
.testimonial-thumb.active .thumb-img {
  border-color: #282828;
}
.testimonial-thumb .thumb-img {
  padding: 5px;
  box-shadow: 0px 0px 0px 2px rgba(40, 40, 40, 0.004);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid transparent;
  position: relative;
  transition: 0.3s ease-in-out;
  padding: 5px;
}
.testimonial-thumb .thumb-img img {
  border-radius: 50%;
  max-width: 100%;
}
.testimonial-thumb .thumb-body {
  text-transform: capitalize;
}
.testimonial-thumb .thumb-body h3 {
  font-family: var(--global--font-body);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 3px;
  color: var(--global--color-heading-light);
  text-transform: capitalize;
}
.testimonial-thumb .thumb-body p {
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  color: var(--global--color-heading-light);
  margin-bottom: 0;
  text-transform: capitalize;
}

.testimonial-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width:767.98px) {
  .testimonial-actions {
    flex-direction: column;
    justify-content: center;
  }
}
.testimonial-actions .custom-navs {
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
}
@media screen and (max-width:767.98px) {
  .testimonial-actions .custom-navs {
    margin-left: 0;
  }
}
.testimonial-actions .custom-navs a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background: var(--global--color-heading);
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.testimonial-actions .custom-navs a:hover {
  background-color: #e6eaef;
}
.testimonial-actions .custom-navs a:hover i {
  color: var(--global--color-heading);
}
.testimonial-actions .custom-navs a i {
  font-size: 12px;
  color: var(--global--color-white);
  transition: 0.3s ease-in-out;
}
.testimonial-actions .custom-navs a.prev {
  margin-right: 10px;
}

.section-head {
  font-family: var(--global--font-secondary);
  font-weight: 400;
  font-size: 30px;
  line-height: 1;
  color: var(--global--color-primary);
  text-transform: capitalize;
  margin-bottom: 23px;
}
@media screen and (max-width:991.98px) {
  .section-head {
    text-align: center;
  }
}

/*------------------------------------*\
    #Video
\*------------------------------------*/
.video {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 560px;
}
.video .btn--video {
  width: 128px;
  height: 128px;
  background-color: var(--global--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
  z-index: 5;
}
@media screen and (max-width:767.98px) {
  .video .btn--video {
    width: 68px;
    height: 68px;
  }
}
.video.video-2 {
  height: 853px;
  margin-bottom: 63px;
}
@media screen and (max-width:767.98px) {
  .video.video-2 {
    height: 560px;
  }
}

/* Custom, iPhone Retina */
/*------------------------------------*\
    #404
\*------------------------------------*/
.page-404 h3 {
  font-size: 280px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0;
}
.page-404 p {
  color: #a5a5a5;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .page-404 h3 {
    font-size: 150px;
  }
  .page-404 .btn {
    width: 125px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .page-404 h3 {
    font-size: 180px;
  }
}
/*------------------------------------*\
    #Services
\*------------------------------------*/
.services {
  padding-top: 128px;
  padding-bottom: 103px;
}
@media screen and (max-width:991.98px) {
  .services {
    padding-top: 68px;
    padding-bottom: 63px;
  }
}
.services .owl-carousel .owl-dots {
  margin-bottom: 40px;
}
@media screen and (min-width:992px) {
  .services .owl-carousel .owl-dots {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    bottom: -13px;
  }
}
.services .owl-carousel .owl-stage-outer {
  overflow: visible;
}
.services .owl-carousel .owl-stage-outer .owl-item {
  opacity: 0;
}
.services .owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}
.services .service-panel {
  filter: drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.12));
}
.services .service-panel:hover .service-wrapper .service-img {
  opacity: 1;
  visibility: visible;
}
.services .service-panel:hover .service-wrapper .service-content .service-body .service-title h3 a {
  color: var(--global--color-white);
}
.services .service-panel:hover .service-wrapper .service-content .service-body .service-desc p {
  color: #f9f9f9;
}
.services .service-panel:hover .service-wrapper .service-content .service-more .btn {
  border-color: var(--global--color-primary);
  background-color: var(--global--color-primary);
  color: var(--global--color-heading);
}
.services .service-panel .service-wrapper {
  position: relative;
  clip-path: polygon(84% 0, 100% 11%, 100% 100%, 0 100%, 0 0);
  background-color: var(--global--color-white);
  box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
  transition: 0.3s ease-in-out;
  margin-bottom: 40px;
}
@media screen and (max-width:991.98px) {
  .services .service-panel .service-wrapper {
    max-width: 370px;
    margin: 0 auto 30px;
  }
}
.services .service-panel .service-wrapper .service-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}
.services .service-panel .service-wrapper .service-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 55%, rgb(0, 0, 0) 100%);
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.services .service-panel .service-wrapper .service-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}
.services .service-panel .service-wrapper .service-content {
  position: relative;
  z-index: 3;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
}
@media screen and (min-width:992px) {
  .services .service-panel .service-wrapper .service-content {
    text-align: left;
  }
}
.services .service-panel .service-wrapper .service-content .service-body {
  padding: 60px 50px 42px;
}
@media screen and (max-width:991.98px) {
  .services .service-panel .service-wrapper .service-content .service-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .services .service-panel .service-wrapper .service-content .service-body {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.services .service-panel .service-wrapper .service-content .service-body .service-icon {
  position: relative;
  margin-bottom: 30px;
}
.services .service-panel .service-wrapper .service-content .service-body .service-icon:hover i {
  transform: translateY(-5px);
}
.services .service-panel .service-wrapper .service-content .service-body .service-icon i {
  display: inline-block;
  color: var(--global--color-primary);
  font-size: 65px;
  transition: 0.3s ease-in-out;
}
.services .service-panel .service-wrapper .service-content .service-body .service-title h3 {
  font-family: var(--global--font-heading);
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 26px;
  text-transform: capitalize;
}
.services .service-panel .service-wrapper .service-content .service-body .service-title h3 a {
  color: var(--global--color-heading);
  transition: 0.3s ease-in-out;
}
.services .service-panel .service-wrapper .service-content .service-body .service-title h3 a:hover {
  color: var(--global--color-white);
}
.services .service-panel .service-wrapper .service-content .service-body .service-desc {
  margin-bottom: 0px;
}
.services .service-panel .service-wrapper .service-content .service-body .service-desc p {
  font-family: var(--global--font-body);
  color: var(--global--color-body);
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 0;
  transition: 0.3s ease-in-out;
}
.services .service-panel .service-wrapper .service-content .service-more {
  transition: 0.3s ease-in-out;
  border-top: 1px solid #eaeaea;
  padding: 40px 50px;
}
@media screen and (max-width:991.98px) {
  .services .service-panel .service-wrapper .service-content .service-more {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width:992px) and (max-width:1199.98px) {
  .services .service-panel .service-wrapper .service-content .service-more {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.services .service-panel .service-wrapper .service-content .service-more .btn {
  width: 100%;
  height: 58px;
  transition: 0.3s ease-in-out;
}
.services .service-panel .service-wrapper .service-content .service-more .btn:hover i {
  transform: translateX(5px);
}
.services .service-panel .service-wrapper .service-content .service-more .btn i {
  color: var(--global--color-heading);
  font-size: 10px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width:991.98px) {
  .services.services-1 {
    padding-top: 70px;
  }
}
.services.services-1 .service-panel {
  margin-bottom: 33px;
}

.service-single {
  padding-top: 110px;
  padding-bottom: 122px;
}
@media screen and (max-width:991.98px) {
  .service-single {
    padding-top: 70px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width:991.98px) {
  .service-single .sidebar-service {
    margin-top: 70px;
  }
}
.service-single .service-entry .entry-content .entry-infos {
  margin-bottom: 66px;
}
@media screen and (max-width:991.98px) {
  .service-single .service-entry .entry-content .entry-infos {
    text-align: center;
  }
}
.service-single .service-entry .entry-content .entry-infos .entry-heading {
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 21px;
  color: var(--global--color-heading-darken);
  text-transform: capitalize;
}
.service-single .service-entry .entry-content .entry-infos .entry-desc {
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 25px;
}
.service-single .service-entry .entry-content .entry-infos .entry-desc:last-child {
  margin-bottom: 0;
}
.service-single .service-entry .entry-content .entry-infos.entry-introduction {
  margin-bottom: 32px;
}
.service-single .service-entry .entry-content .entry-infos.entry-introduction .entry-heading {
  margin-bottom: 17px;
}
@media screen and (min-width:992px) {
  .service-single .service-entry .entry-content .entry-infos.entry-introduction .entry-heading {
    transform: translateY(-3px);
  }
}
.service-single .service-entry .entry-content .entry-infos.entry-stats {
  margin-bottom: 0;
}
.service-single .service-entry .entry-content .entry-infos.entry-stats img {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
}
@media screen and (max-width:991.98px) {
  .service-single .service-entry .entry-content .entry-infos.entry-stats img {
    margin-top: 40px;
  }
}
@media screen and (min-width:992px) {
  .service-single .service-entry .entry-content .entry-infos.entry-stats img {
    margin-top: 7px;
  }
}
@media screen and (max-width:767.98px) {
  .service-single .service-entry .entry-content .entry-infos.entry-video {
    margin-bottom: 41px;
  }
}
.service-single .service-entry .entry-content .entry-infos.entry-video .video {
  margin-bottom: 43px;
}
.service-single .service-entry .entry-content .entry-infos.entry-video .entry-desc {
  margin-bottom: 26px;
}
.service-single .service-entry .entry-content .entry-infos.entry-video .entry-desc:last-of-type {
  margin-bottom: 32px;
}
.service-single .service-entry .entry-content .entry-infos.entry-video .entry-images img {
  width: 100%;
  object-fit: cover;
  height: 400px;
}
@media screen and (max-width:767.98px) {
  .service-single .service-entry .entry-content .entry-infos.entry-video .entry-images img {
    margin-bottom: 25px;
  }
}
.service-single .service-entry .entry-content .entry-infos.entry-benefits {
  margin-bottom: 66px;
}
.service-single .service-entry .entry-content .entry-infos.entry-benefits .entry-desc {
  margin-bottom: 32px;
}

/*------------------------------------*\
    #Industries
\*------------------------------------*/
.industries {
  position: relative;
  padding-top: 130px;
  padding-bottom: 0px;
}
@media screen and (max-width:991.98px) {
  .industries {
    padding-top: 65px;
    padding-bottom: 0px;
  }
}
.industries .industries-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 97%;
  border-radius: 12px;
  height: 100%;
  z-index: 1;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .industries .industries-overlay {
    width: 98%;
  }
}
@media screen and (max-width:767.98px) {
  .industries .industries-overlay {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
}
@media screen and (min-width: 1400px) {
  .industries .industries-overlay {
    width: 95%;
  }
}
.industries .industries-content {
  position: relative;
  z-index: 2;
}
.industries .actions-holder {
  display: flex;
  align-items: center;
}
@media screen and (max-width:991.98px) {
  .industries .actions-holder {
    justify-content: center;
  }
}
@media screen and (max-width:767.98px) {
  .industries .actions-holder {
    flex-direction: column;
  }
}
.industries .actions-holder .btn {
  width: 202px;
  height: 68px;
  box-shadow: 0px 3px 63px 0px rgba(6, 52, 100, 0.11);
  padding: 0 25px;
}
.industries .industries-note-wrapper .industries-note {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width:991.98px) {
  .industries .industries-note-wrapper .industries-note {
    justify-content: center;
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width:767.98px) {
  .industries .industries-note-wrapper .industries-note {
    flex-direction: column;
    align-items: center;
  }
}
.industries .industries-note-wrapper .industries-note .rating {
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-top: 7px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .industries .industries-note-wrapper .industries-note .rating {
    margin-right: 20px;
  }
}
@media screen and (max-width:767.98px) {
  .industries .industries-note-wrapper .industries-note .rating {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }
}
.industries .industries-note-wrapper .industries-note .rating i {
  font-size: 12px;
  color: var(--global--color-primary);
  margin-right: 5px;
}
.industries .industries-note-wrapper .industries-note .rating i:last-child {
  margin-right: 0;
}
@media screen and (max-width:991.98px) {
  .industries .industries-note-wrapper .industries-note .note {
    max-width: unset;
    margin: 0;
  }
}
.industries.industries-1 {
  padding-bottom: 73px;
}
@media screen and (max-width:991.98px) {
  .industries.industries-1 {
    padding-bottom: 15px;
  }
}
@media screen and (min-width:992px) {
  .industries.industries-1 .heading-wrapper {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media screen and (max-width:991.98px) {
  .industries.industries-1 .heading-wrapper {
    margin-bottom: 70px;
  }
}
.industries.industries-1 .industries-wrapper {
  position: relative;
  margin-bottom: 62px;
}
@media screen and (min-width:992px) {
  .industries.industries-1 .industries-wrapper {
    margin-bottom: 102px;
    height: 500px;
  }
}
@media screen and (min-width:992px) {
  .industries.industries-1 .industries-wrapper .industries-holder {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 60%;
  }
}
.industries.industries-2 {
  padding-top: 129px;
  padding-bottom: 90px;
}
@media screen and (max-width:991.98px) {
  .industries.industries-2 {
    padding-top: 63px;
    padding-bottom: 30px;
  }
}
.industries.industries-2 .industry-panel {
  margin-bottom: 40px;
}

.industry-panel {
  position: relative;
}
@media screen and (max-width:991.98px) {
  .industry-panel {
    max-width: 370px;
    margin: 0 auto;
  }
}
.industry-panel:hover .industry-content .industry-more {
  width: 170px;
  height: 170px;
  border-color: rgba(255, 255, 255, 0.5);
}
.industry-panel:hover .industry-content .industry-more i {
  transform: scale(0);
}
.industry-panel:hover .industry-content .industry-body .industry-name {
  bottom: 43px;
}
.industry-panel:hover .industry-content .industry-body .industry-desc {
  bottom: 50%;
  transform: translateY(50%);
}
.industry-panel .industry-img {
  position: relative;
}
.industry-panel .industry-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 55%, rgba(0, 0, 0, 0.85) 100%);
  z-index: 2;
  transition: 0.35s ease-in-out;
}
.industry-panel .industry-img img {
  object-fit: cover;
  width: 100%;
  height: 619px;
}
.industry-panel .industry-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: flex-end;
  padding: 42px 50px;
  z-index: 5;
  direction: ltr;
}
.industry-panel .industry-content .industry-more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid var(--global--color-white);
  transition: 0.3s ease-in-out;
}
.industry-panel .industry-content .industry-more i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  background: var(--global--color-primary);
  border-radius: 50%;
  color: var(--global--color-heading);
  font-size: 12px;
  transition: 0.3s ease-in-out;
}
.industry-panel .industry-content .industry-body {
  text-align: left;
}
.industry-panel .industry-content .industry-body .industry-name {
  position: absolute;
  left: 0px;
  bottom: 161px;
  font-family: var(--global--font-heading);
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 0px;
  text-transform: capitalize;
  max-width: 90%;
  transition: 0.3s ease-in-out;
  padding: 0 50px;
  z-index: 6;
}
@media screen and (max-width:1199.98px) {
  .industry-panel .industry-content .industry-body .industry-name {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.industry-panel .industry-content .industry-body .industry-name a {
  color: var(--global--color-white);
}
.industry-panel .industry-content .industry-body .industry-desc {
  transition: 0.3s ease-in-out;
  position: absolute;
  left: 0px;
  bottom: 43px;
  transform: translateY(0);
  padding: 0 50px;
  z-index: 6;
}
@media screen and (max-width:1199.98px) {
  .industry-panel .industry-content .industry-body .industry-desc {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (min-width: 1400px) {
  .industry-panel .industry-content .industry-body .industry-desc {
    max-width: 85%;
  }
}
.industry-panel .industry-content .industry-body .industry-desc p {
  font-family: var(--global--font-body);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #f9f9f9;
  margin-bottom: 0;
}
.industry-panel.industry-panel-2:hover .industry-content .industry-body .industry-desc {
  opacity: 1;
  visibility: visible;
  transform: translateY(50%);
}
.industry-panel.industry-panel-2 .industry-img img {
  height: 500px;
}
.industry-panel.industry-panel-2 .industry-content .industry-more {
  width: auto;
  height: auto;
  border: 0;
}
.industry-panel.industry-panel-2 .industry-content .industry-more i {
  width: 68px;
  height: 68px;
}
.industry-panel.industry-panel-2 .industry-content .industry-body .industry-name {
  bottom: 43px;
}
@media (min-width:992px) and (max-width:1199.98px) {
  .industry-panel.industry-panel-2 .industry-content .industry-body .industry-name {
    padding-right: 60px;
  }
}
@media screen and (min-width:1200px) {
  .industry-panel.industry-panel-2 .industry-content .industry-body .industry-name {
    padding-right: 75px;
  }
}
.industry-panel.industry-panel-2 .industry-content .industry-body .industry-desc {
  opacity: 0;
  visibility: hidden;
  bottom: 50%;
  transform: translateY(calc(50% + 30px));
}
.industry-panel.industry-panel-2 .industry-content .industry-body .industry-desc::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translate(0%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  transition: 0.3s ease-in-out;
}
@media screen and (max-width:1199.98px) {
  .industry-panel.industry-panel-2 .industry-content .industry-body .industry-desc::before {
    right: 25px;
  }
}

.industry-single {
  padding-top: 110px;
  padding-bottom: 110px;
}
@media screen and (max-width:991.98px) {
  .industry-single {
    padding-top: 70px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width:991.98px) {
  .industry-single .sidebar-industries {
    margin-top: 70px;
  }
}
.industry-single .industry-entry .entry-content .entry-infos {
  margin-bottom: 66px;
}
@media screen and (max-width:991.98px) {
  .industry-single .industry-entry .entry-content .entry-infos {
    text-align: center;
  }
}
.industry-single .industry-entry .entry-content .entry-infos .entry-heading {
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 21px;
  color: var(--global--color-heading-darken);
  text-transform: capitalize;
}
.industry-single .industry-entry .entry-content .entry-infos .entry-desc {
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 25px;
}
.industry-single .industry-entry .entry-content .entry-infos .entry-desc:last-child {
  margin-bottom: 0;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-introduction {
  margin-bottom: 32px;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-introduction .entry-img {
  margin-bottom: 33px;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-introduction .entry-img img {
  width: 100%;
  object-fit: cover;
  height: 560px;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-banners {
  margin-bottom: 57px;
}
@media screen and (max-width:767.98px) {
  .industry-single .industry-entry .entry-content .entry-infos.entry-banners .entry-img {
    margin-bottom: 43px;
  }
}
.industry-single .industry-entry .entry-content .entry-infos.entry-banners .entry-img img {
  width: 100%;
  object-fit: cover;
  height: 480px;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-banners .panels-holder .banner-panel:first-child {
  padding-top: 0;
  border-top: 0;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-banners .panels-holder .banner-panel:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
@media screen and (min-width:768px) {
  .industry-single .industry-entry .entry-content .entry-infos.entry-banners .panels-holder .banner-panel {
    text-align: left;
  }
}
.industry-single .industry-entry .entry-content .entry-infos.entry-why .entry-desc {
  margin-bottom: 32px;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-benefits {
  margin-bottom: 66px;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-benefits .entry-desc {
  margin-bottom: 32px;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-cases {
  margin-bottom: 0;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-cases .entry-heading {
  margin-bottom: 29px;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-cases .owl-stage-outer {
  overflow: visible;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-cases .owl-stage-outer .owl-item {
  opacity: 0;
}
.industry-single .industry-entry .entry-content .entry-infos.entry-cases .owl-stage-outer .owl-item.active {
  opacity: 1;
}
@media screen and (min-width:992px) {
  .industry-single .industry-entry .entry-content .entry-infos.entry-cases .owl-dots {
    justify-content: flex-start;
  }
}

/*------------------------------------*\
    #Footer
\*------------------------------------*/
/* Parallax Effect */
#wrapperParallax {
  position: relative;
  z-index: 1060;
  min-height: 100vh;
}

#footerParallax {
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 991px) {
  #wrapperParallax {
    margin-bottom: 0 !important;
  }
  #footerParallax {
    position: static;
    text-align: center;
  }
}
.footer {
  background-color: #161616;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  /* Footer Widgets */
}
.footer .footer-top {
  padding-top: 120px;
  padding-bottom: 140px;
}
@media screen and (max-width:991.98px) {
  .footer .footer-top {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.footer .footer-bottom {
  background-color: #161616;
}
@media screen and (max-width:991.98px) {
  .footer .footer-bottom {
    padding-top: 70px;
  }
}
.footer .footer-widget {
  text-align: center;
}
@media screen and (min-width:768px) {
  .footer .footer-widget {
    text-align: left;
  }
}
@media screen and (max-width:991.98px) {
  .footer .footer-widget {
    padding-top: 50px;
  }
}
.footer .footer-widget .footer-widget-title h3 {
  font-family: var(--global--font-heading);
  color: #eaeaea;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
  margin-bottom: 37px;
}
.footer .footer-widget .widget-content p {
  color: var(--global--color-body);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 29px;
}
.footer .footer-widget.widget-about .brand {
  display: flex;
}
@media screen and (min-width:768px) {
  .footer .footer-widget.widget-about .brand {
    margin-top: 9px;
  }
}
@media screen and (max-width:767.98px) {
  .footer .footer-widget.widget-about .brand {
    justify-content: center;
  }
}
.footer .footer-widget.widget-contact .widget-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width:768px) {
  .footer .footer-widget.widget-contact .widget-content {
    align-items: flex-start;
  }
}
.footer .footer-widget.widget-contact .widget-content .contact-infos {
  display: flex;
  flex-direction: column;
}
.footer .footer-widget.widget-contact .widget-content .contact-infos .contact-info {
  margin-bottom: 22px;
}
.footer .footer-widget.widget-contact .widget-content .contact-infos .contact-info:last-child {
  margin-bottom: 0;
}
.footer .footer-widget.widget-contact .widget-content .contact-infos .contact-info .contact-label {
  display: block;
  font-family: var(--global-font-body);
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.footer .footer-widget.widget-contact .widget-content .contact-infos .contact-info a {
  display: flex;
  align-items: center;
}
.footer .footer-widget.widget-contact .widget-content .contact-infos .contact-info a svg {
  color: var(--global--color-primary);
  margin-right: 20px;
}
.footer .footer-widget.widget-contact .widget-content .contact-infos .contact-info a span {
  font-family: var(--global--font-heading);
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: var(--global--color-primary);
}
.footer .footer-widget.widget-links ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  overflow: hidden;
}
@media screen and (min-width:768px) {
  .footer .footer-widget.widget-links ul {
    transform: translateY(-5px);
  }
}
.footer .footer-widget.widget-links ul li {
  display: block;
}
.footer .footer-widget.widget-links ul li a {
  color: var(--global-color-white);
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-transform: capitalize;
  font-family: var(--global--font-body);
}
.footer .footer-widget.widget-links ul li a:hover {
  color: var(--global--color-primary);
}
.footer .footer-copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 57px;
  position: relative;
}
@media screen and (max-width:991.98px) {
  .footer .footer-copyright {
    flex-direction: column;
    justify-content: space-between;
  }
}
.footer .footer-copyright::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--global--color-primary);
  height: 12px;
}
@media screen and (max-width:991.98px) {
  .footer .footer-copyright .copyright-holder {
    margin-bottom: 15px;
    text-align: center;
  }
}
.footer .footer-copyright .copyright-holder .copyright {
  font-family: var(--global--font-body);
  font-weight: 400;
  font-size: 14px;
  color: var(--global--color-body);
  text-transform: capitalize;
  margin-bottom: 0;
}
.footer .footer-copyright .copyright-holder .copyright a {
  color: var(--global--color-primary);
}
.footer .footer-copyright .copyright-holder .copyright a:hover {
  color: var(--global--color-primary);
}
.footer .footer-copyright .copyright-actions {
  display: flex;
  align-items: center;
}
@media screen and (max-width:767.98px) {
  .footer .footer-copyright .copyright-actions {
    flex-direction: column;
    justify-content: center;
  }
}
.footer .footer-copyright .copyright-actions .social-icons {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
@media screen and (min-width:768px) {
  .footer .footer-copyright .copyright-actions .social-icons {
    margin-right: 30px;
  }
}
@media screen and (min-width:1200px) {
  .footer .footer-copyright .copyright-actions .social-icons {
    margin-right: 60px;
  }
}
@media screen and (max-width:767.98px) {
  .footer .footer-copyright .copyright-actions .social-icons {
    margin-bottom: 25px;
  }
}
.footer .footer-copyright .copyright-actions .social-icons li {
  margin-right: 30px;
}
.footer .footer-copyright .copyright-actions .social-icons li:last-child {
  margin-right: 0;
}
.footer .footer-copyright .copyright-actions .social-icons li a {
  display: flex;
  align-items: center;
}
.footer .footer-copyright .copyright-actions .social-icons li a:hover {
  color: var(--global--color-primary);
}
.footer .footer-copyright .copyright-actions .social-icons li a:hover span {
  transform: translateX(-3px);
}
.footer .footer-copyright .copyright-actions .social-icons li a i {
  color: var(--global--color-primary);
  font-size: 20px;
  margin-right: 10px;
}
.footer .footer-copyright .copyright-actions .social-icons li a span {
  font-family: var(--global--font-body);
  font-weight: 700;
  font-size: 14px;
  color: var(--global--color-white);
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
@media screen and (min-width:992px) {
  .footer.footer-2 .footer-top {
    padding-top: 230px;
  }
}