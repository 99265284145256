/*------------------------------------*\
    #PAGINATION
\*------------------------------------*/
.pagination {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin         : 0;
    border-radius  : 0;

    li {
        margin-right: 10px;
        display     : inline-block;

        &:last-child {
            margin-right: 0px;
        }

        a,
        span {
            display         : inline-flex;
            justify-content : center;
            align-items     : center;
            background-color: var(--global--color-white);
            color           : var(--global--color-heading);
            font-family     : var(--global--font-body);
            font-size       : 20px;
            font-weight     : 700;
            text-transform  : uppercase;
            padding         : 0;
            width           : 58px;
            height          : 58px;
            border-radius   : 3px;
            border          : 2px solid #bcbcbc;
            transition      : 300ms ease-in-out;

            &:hover {
                background-color: var(--global--color-primary);
                border-color    : var(--global--color-primary);
            }

            i {
                font-size: 11px;
            }

            &.current {
                background-color: var(--global--color-primary);
                border-color    : var(--global--color-primary);
            }
        }
    }
}