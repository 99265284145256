/*------------------------------------*\
    #Reviews
\*------------------------------------*/
.reviews {
    padding-top   : 0;
    padding-bottom: 0;
    overflow      : visible;
    z-index       : 2;

    .social-rates-wrapper {
        background-color: #f9f9f9;

        .social-rates {
            display        : flex;
            align-items    : center;
            justify-content: center;
            padding        : 60px 0;

            @media #{$maxSmall} {
                flex-direction: column;
            }

            .rate-panel-wrapper {
                border-right: 2px solid #eaeaea;
                position    : relative;

                &:last-child {
                    border-right: 0;
                }

                padding  : 0 35px;
                max-width: 280px;

                @media #{$maxTablet} {
                    padding: 0 15px;
                }

                @media #{$maxSmall} {
                    padding      : 25px 0;
                    border-right : 0;
                    border-bottom: 2px solid #eaeaea;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        border-bottom : 0;
                        padding-bottom: 0;
                    }
                }

                .rate-panel {
                    display    : flex;
                    align-items: center;
                    position   : relative;

                    img {
                        max-width   : 100%;
                        margin-right: 13px;

                        @media #{$maxSmall} {
                            margin-right: 20px
                        }
                    }

                    .rating-link {
                        @include overlay(transparent);
                        z-index: 2;
                    }

                    .rate-body {
                        .rate-title {
                            @include text(var(--global--font-body), 14px, 20px, 700, #616161, 9px);
                        }

                        .rating {
                            display    : flex;
                            align-items: center;

                            i {
                                font-size   : 12px;
                                color       : var(--global--color-body);
                                margin-right: 5px;

                                &:last-child {
                                    margin-right: 0;
                                }

                                &.active {
                                    color: var(--global--color-primary);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .clients-reviews {
        padding-top   : 90px;
        padding-bottom: 127px;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 67px;
        }

        .review-panel {
            display       : flex;
            padding-top   : 50px;
            padding-bottom: 47px;
            border-bottom : 2px solid #eaeaea;

            &:first-child {
                padding-top: 0
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom : 0;
            }

            @media #{$maxSmall} {
                flex-direction : column;
                justify-content: center;
                text-align     : center;
            }

            .review-icon {
                margin-right: 40px;
                width       : 65px;
                flex-shrink : 0;

                @media #{$maxSmall} {
                    margin-right : auto;
                    margin-left  : auto;
                    margin-bottom: 30px;
                }

                img {
                    max-width: 100%;
                }
            }

            .review-body {
                @media #{$minTablet} {
                    transform    : translateY(-8px);
                    margin-bottom: -8px;
                }

                p {
                    @include text(var(--global--font-heading), 21px, 32px, 700, var(--global--color-heading-light), 19px);
                }

                .review-content {
                    display    : flex;
                    align-items: center;

                    @media #{$maxSmall} {
                        flex-direction : column;
                        justify-content: center;
                        text-align     : center;
                    }

                    .rating {
                        display     : flex;
                        align-items : center;
                        margin-right: 20px;

                        @media #{$maxSmall} {
                            margin-right : 0;
                            margin-bottom: 20px;
                        }

                        i {
                            font-size   : 12px;
                            color       : var(--global--color-body);
                            margin-right: 5px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &.active {
                                color: var(--global--color-primary);
                            }
                        }
                    }

                    .review-meta {
                        display    : flex;
                        align-items: center;

                        .review-author {
                            display    : flex;
                            align-items: center;
                            @include text(var(--global--font-body), 16px, 1, 700, var(--global--color-heading), 0px);
                            margin-right  : 6px;
                            text-transform: capitalize;

                            &::after {
                                content: ',';
                            }
                        }

                        .review-title {
                            @include text(var(--global--font-body), 13px, 1, 400, var(--global--color-body), 0px);
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }
}