/*------------------------------------*\
    #Shop
\*------------------------------------*/
.shop {
    padding-top   : 110px;
    padding-bottom: 110px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    &.shop-1 {
        .pagination {
            margin-top: 10px;
        }
    }

    &.shop-2 {
        padding-top   : 25px;
        padding-bottom: 70px;

        @media #{$maxTablet} {
            padding-top   : 20px;
            padding-bottom: 28px;
        }

        h2 {
            font-family   : var(--global--font-heading);
            font-weight   : 700;
            font-size     : 20px;
            line-height   : 30px;
            color         : var(--global--color-heading-darken);
            text-transform: capitalize;
            margin-bottom : 23px;

            @media #{$maxTablet} {
                text-align: center;
            }
        }
    }

    .pagination {
        margin-top: 10px;
    }

    .sidebar-shop {
        @media #{$minLarge} {
            transform: translateY(-8px);
        }
    }
}



/* Product Item */

.product-item {
    margin-bottom: 40px;
    overflow     : hidden;

    @media #{$maxTablet} {
        max-width: 370px;
        margin   : 0 auto 40px;
    }

    &:hover {
        .product-content {
            transform: translateY(0);
        }
    }

    .product-img {
        border-radius: 4px;
        position     : relative;
        overflow     : hidden;

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        img {
            width     : 100%;
            height    : 290px;
            object-fit: cover;
            transition: 0.4s ease-in-out;
            transform : scale(1);
        }

        .badge {
            position       : absolute;
            top            : 20px;
            right          : 20px;
            color          : #f9f9f9;
            border-radius  : 3px;
            font-family    : var(--global--font-body);
            font-size      : 14px;
            font-weight    : 700;
            line-height    : 1;
            display        : flex;
            align-items    : center;
            justify-content: center;
            height         : 30px;
            ;
            padding       : 0px 15px;
            text-transform: capitalize;

            &.badge-sale {
                background-color: #ff0000;
            }

            &.badge-new {
                background-color: var(--global--color-primary);
                color           : var(--global--color-heading)
            }
        }

    }

    .product-content {
        transform       : translateY(98px);
        margin-top      : -98px;
        transition      : all 0.3s ease-in-out;
        background-color: var(--global--color-white);
        position        : relative;
        z-index         : 5;

        .product-body {
            padding-top   : 21px;
            padding-bottom: 23px;
            text-align    : center;

            .product-title {
                margin-bottom: 6px;

                a {
                    text-transform: capitalize;
                    font-family   : var(--global--font-heading);
                    font-size     : 19px;
                    line-height   : 32px;
                    font-weight   : 700;
                    color         : var(--global--color-heading);

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }

            .product-price {
                p {
                    font-family  : var(--global--font-body);
                    font-weight  : 700;
                    font-size    : 20px;
                    color        : var(--global--color-body);
                    line-height  : 28px;
                    margin-bottom: 0;
                }
            }
        }

        .add-to-cart {
            width : 100%;
            height: 68px;

            i {
                font-size  : 14px;
                margin-left: 10px;
            }
        }
    }
}

/* Shop Options */

.shop-options {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-bottom  : 40px;

    @media #{$maxSmall} {
        flex-direction: column;
        align-items   : center;
    }

    .products-show {
        @media #{$maxSmall} {
            margin-bottom: 25px;
        }

        p {
            font-family   : var(--global--font-body);
            font-weight   : 700;
            font-size     : 14px;
            color         : var(--global--color-heading-light);
            text-transform: capitalize;
            margin-bottom : 0;
        }
    }

    .products-sort {
        .nice-select {
            border        : 2px solid #eaeaea;
            padding       : 20px;
            padding-right : 30px;
            height        : 55px;
            width         : 290px;
            border-radius : 3px;
            color         : #616161;
            font-size     : 14px;
            font-weight   : 400;
            text-transform: capitalize;

            &::after {
                right: 20px;
            }

            &::-webkit-input-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }

            &:-moz-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }

            &:-ms-input-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }
        }
    }
}



/* Shop Single */
.single-product {
    padding-top   : 70px;
    padding-bottom: 0;

    .checkout-banner {
        display         : flex;
        align-items     : center;
        justify-content : space-between;
        padding         : 20px;
        background-color: var(--global--color-white);
        border-radius   : 6px;
        margin-bottom   : 40px;
        border          : 6px solid #eaeaea;

        @media #{$maxSmall} {
            flex-direction: column;
            align-items   : center;
            text-align    : center;
        }

        p {
            font-family  : var(--global--font-heading);
            font-weight  : 700;
            font-size    : 19px;
            line-height  : 32px;
            color        : var(--global--color-heading-light);
            margin-bottom: 0px;

            @media #{$maxSmall} {
                margin-bottom: 20px;
            }
        }

        .btn {
            height: 60px;
            width : 180px;
        }
    }

    .product-img {
        border-radius: 8px;
        position     : relative;

        @media #{$maxTablet} {
            margin-bottom: 25px;
        }

        img {
            max-width: 100%;

            @media #{$minLarge} {
                width     : 100%;
                height    : 600px;
                object-fit: cover;
            }
        }

        a {
            position: absolute;
            top     : 0;
            left    : 0;
            right   : 0;
            width   : 100%;
            height  : 100%;
            cursor  : zoom-in;
        }
    }

    .product-content {
        @media #{$minLarge} {
            padding-top : 9px;
            padding-left: 30px;
        }

        @media #{$maxTablet} {
            text-align: center;
        }

        .product-title {
            h3 {
                font-family   : var(--global--font-heading);
                font-weight   : 700;
                color         : var(--global--color-heading-darken);
                font-size     : 29px;
                line-height   : 1;
                text-transform: capitalize;
                margin-bottom : 14px;
            }
        }

        .product-price {
            font-family  : var(--global--font-body);
            color        : var(--global--color-body);
            font-size    : 22px;
            font-weight  : 700;
            line-height  : 28px;
            margin-bottom: 26px;
        }

        .product-review {
            margin-bottom: 21px;

            span {
                color         : var(--global--color-body);
                font-size     : 13px;
                line-height   : 1;
                font-weight   : 400;
                font-family   : var(--global--font-body);
                text-transform: capitalize;

                &::after {
                    content: '/';
                    margin : 0 6px;
                }

                &:last-child {
                    &::after {
                        content: unset;
                    }
                }

                a {
                    color: var(--global--color-body);

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }

            .product-rating {
                margin-right: 30px;
                display     : inline-block;

                i {
                    color       : var(--global--color-body);
                    font-size   : 15px;
                    line-height : 1;
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.active {
                        color: var(--global--color-primary);
                    }
                }

            }
        }

        .product-desc {
            p {
                font-family  : var(--global--font-body);
                font-weight  : 400;
                font-size    : 17px;
                line-height  : 27px;
                margin-bottom: 32px;
                color        : var(--global--color-body);
            }
        }

        .product-action {
            display      : flex;
            margin-bottom: 37px;

            @media #{$maxTablet} {
                justify-content: center;
            }

            .product-quantity {
                display      : flex;
                border       : 2px solid #bcbcbc;
                border-radius: 3px 3px 0 3px;
                width        : 80px;
                margin-right : 15px;

                span {
                    display       : flex;
                    flex-direction: column;

                    a {
                        border-left: 2px solid #bcbcbc;

                        &:first-child {
                            border-bottom: 2px solid #bcbcbc;
                        }

                        i {
                            width      : 24px;
                            height     : 24px;
                            font-size  : 10px;
                            line-height: 25px;
                            color      : var(--global--color-heading);
                            text-align : center;
                        }
                    }
                }

                input {
                    width           : 50px;
                    height          : 50px;
                    color           : var(--global--color-heading);
                    font-size       : 20px;
                    font-weight     : 700;
                    line-height     : 22px;
                    font-family     : var(--global--font-body);
                    text-align      : center;
                    background-color: transparent;
                    border-color    : transparent;
                }
            }

            .btn {
                width : 170px;
                height: 54px;
            }
        }

        .product-details {
            margin-bottom: 24px;

            .table {
                margin-bottom: 0;

                @media #{$minLarge} {
                    width: 60%;
                }

                td {
                    border-top    : 0;
                    border-bottom : 0;
                    padding-bottom: 9px;
                }

                .name {
                    font-family   : var(--global--font-body);
                    font-weight   : 700;
                    font-size     : 16px;
                    color         : var(--global--color-heading-light);
                    text-transform: capitalize;

                    @media #{$minLarge} {
                        padding-left: 0;
                    }
                }

                .value {
                    font-family   : var(--global--font-body);
                    font-weight   : 400;
                    font-size     : 15px;
                    color         : var(--global--color-body);
                    text-transform: capitalize;
                }
            }
        }

        .product-share {
            display    : flex;
            align-items: center;
            flex-wrap  : nowrap;

            @media #{$maxTablet} {
                justify-content: center;
                flex-wrap      : wrap;
            }

            a {
                color           : var(--global--color-heading);
                background-color: var(--global--color-primary);
                border-radius   : 3px;
                margin-right    : 10px;
                transition      : 0.3s ease-in-out;
                width           : 52px;
                height          : 40px;
                display         : flex;
                justify-content : center;
                align-items     : center;
                transition      : 0.3s ease-in-out;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    background-color: var(--global--color-heading);
                    color           : var(--global--color-white);
                    transform       : translateY(-5px);
                }

                &.facebook-bg {
                    background-color: #3b5999;
                    color           : var(--global--color-white);

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }

                &.twitter-bg {
                    background-color: #55acee;
                    color           : var(--global--color-white);

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }

                &.instagram-bg {
                    background-color: #0092ff;
                    color           : var(--global--color-white);

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }

                &.pinterest-bg {
                    background-color: #ca1f27;
                    color           : var(--global--color-white);

                    &:hover {
                        background-color: var(--global--color-heading);
                    }
                }
            }
        }

    }

    .product-tabs {
        background-color: var(--global--color-white);
        margin-top      : 75px;
        margin-bottom   : 40px;

        .nav-tabs {
            background-color: var(--global--color-white);
            border          : 0;

            li {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color         : var(--global--color-heading);
                    font-size     : 16px;
                    font-weight   : 700;
                    text-transform: capitalize;
                    font-family   : var(--global--font-body);
                    text-transform: capitalize;
                    border-radius : 0;
                    margin-right  : 0;
                    padding-bottom: 10px;
                    display       : block;
                    border-right  : 0;
                    position      : relative;

                    &::after {
                        content         : '';
                        position        : absolute;
                        bottom          : -4px;
                        left            : 0;
                        width           : 0%;
                        height          : 4px;
                        background-color: var(--global--color-primary);
                        transition      : 0.3s ease-in-out;

                        @media (max-width:420px) {
                            content: unset;
                        }
                    }

                    &:focus,
                    &:hover {
                        color: var(--global--color-secondary);
                    }
                }
            }

            li>.active,
            li>.active:focus,
            li>.active:hover {
                color: var(--global--color-heading);

                &::after {
                    width: 100%;
                }
            }
        }

        .tab-content {
            border-top : 4px solid #e6eaef;
            padding-top: 23px;

            p {
                font-family: var(--global--font-body);
                font-size  : 15px;
                font-weight: 400;
                line-height: 25px;
                color      : var(--global--color-body);
            }

            h3 {
                font-size: 18px;
            }

            .review-comment {
                .review-body {
                    display      : flex;
                    margin-bottom: 25px;

                    img {
                        width        : 60px;
                        height       : 60px;
                        border-radius: 3px;
                        margin-right : 30px;
                    }

                    h4 {
                        font-size    : 18px;
                        margin-bottom: 5px;
                    }

                    p {
                        margin-bottom: 7px;
                    }

                    .product-rating {
                        i {
                            &.active {
                                color: var(--global--color-primary);
                            }
                        }
                    }
                }

                .product-comment {
                    margin-bottom: 35px;

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            .form-review {
                .form-control {
                    border        : 2px solid #eaeaea;
                    padding       : 30px;
                    height        : 68px;
                    border-radius : 3px;
                    margin-bottom : 35px;
                    color         : #616161;
                    font-size     : 14px;
                    font-weight   : 400;
                    text-transform: capitalize;

                    @media #{$maxSmall} {
                        padding-left : 25px;
                        padding-right: 25px;
                    }

                    &::-webkit-input-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }

                    &:-moz-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }

                    &:-ms-input-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }
                }

                .nice-select {
                    &::after {
                        right: 20px;
                    }
                }

                textarea {
                    &.form-control {
                        height       : 160px;
                        margin-bottom: 40px;
                    }
                }

                .btn {
                    width : 240px;
                    height: 78px;

                    @media #{$maxSmall} {
                        width : 100%;
                        height: 68px;
                    }

                    &:hover {
                        i {
                            background-color: var(--global--color-white);
                            color           : var(--global--color-heading);
                        }
                    }

                    i {
                        @include centerd(32px, 32px, var(--global--color-heading));
                        color        : var(--global--color-white);
                        border-radius: 50%;
                        margin-left  : 10px;
                        transition   : 0.3s ease-in-out;
                        font-size    : 9px;
                    }
                }
            }
        }
    }

}

/*Shop Cart*/
.shop-cart {
    padding-top   : 70px;
    padding-bottom: 110px;

    @media #{$maxTablet} {
        padding-bottom: 70px;
    }

    .table-wrap {
        border       : 1px solid #eaeaea;
        margin-bottom: 53px;
        overflow     : hidden;

        .cart-table {
            margin-bottom: 30px;

            &.table-responsive {
                min-height: 0.01%;
                overflow-x: auto;
            }

            .table {
                background-color: var(--global--color-white);
                border-radius   : 0;
                margin-bottom   : 0;
                color           : var(--global--color-body);

                tr {
                    &:first-child {
                        th {
                            border-top: 0;

                            &:first-child {
                                border-left: 0;
                            }

                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }

                    th {
                        font-size     : 16px;
                        font-weight   : 700;
                        text-align    : center;
                        text-transform: capitalize;
                        color         : var(--global--color-body);
                        font-family   : var(--global--font-body);
                        padding       : 10px 0;
                        border-right  : 1px solid #eaeaea;
                        border-bottom : 1px solid #eaeaea;
                    }
                }

                tbody {
                    tr {
                        td {
                            padding      : 20px;
                            border-right : 1px solid #eaeaea;
                            border-bottom: 1px solid #eaeaea;
                            border-top   : 0;
                            border-left  : 0;

                            &:last-child {
                                border-right: 0;
                            }

                            &.cart-product-price,
                            &.cart-product-total {
                                font-family   : var(--global--font-body);
                                text-align    : center;
                                font-size     : 16px;
                                font-weight   : 700;
                                line-height   : 20px;
                                color         : var(--global--color-body);
                                vertical-align: baseline;
                            }

                            &.cart-product-item {
                                display        : flex;
                                justify-content: flex-start;
                                align-items    : center;

                                .cart-product-remove {
                                    flex-shrink     : 0;
                                    display         : flex;
                                    justify-content : center;
                                    align-items     : center;
                                    justify-content : center;
                                    width           : 19px;
                                    height          : 19px;
                                    background-color: var(--global--color-heading);
                                    border-radius   : 50%;
                                    text-align      : center;
                                    cursor          : pointer;
                                    margin-right    : 20px;
                                    transition      : 0.3s ease-in-out;

                                    &:hover {
                                        background-color: var(--global--color-primary);

                                        svg {
                                            color: var(--global--color-heading);
                                        }
                                    }

                                    svg {
                                        color     : var(--global--color-white);
                                        transition: 0.3s ease-in-out;
                                    }
                                }

                                .cart-product-img {
                                    flex-shrink : 0;
                                    width       : 70px;
                                    height      : 70px;
                                    margin-right: 20px;

                                    img {
                                        width     : 100%;
                                        height    : 100%;
                                        object-fit: cover;
                                    }
                                }

                                .cart-product-name {
                                    h4 {
                                        font-family   : var(--global--font-heading);
                                        color         : var(--global--color-heading);
                                        font-size     : 17px;
                                        font-weight   : 700;
                                        line-height   : 27px;
                                        text-transform: capitalize;
                                        margin-bottom : 0px;
                                    }
                                }
                            }

                            &.cart-product-quantity {
                                .product-quantity {
                                    display      : flex;
                                    border       : 2px solid #bcbcbc;
                                    border-radius: 3px 3px 0 3px;
                                    width        : 80px;
                                    margin       : 0 auto;

                                    span {
                                        display       : flex;
                                        flex-direction: column;

                                        a {
                                            border-left: 2px solid #bcbcbc;

                                            &:first-child {
                                                border-bottom: 2px solid #bcbcbc;
                                            }

                                            i {
                                                width      : 24px;
                                                height     : 24px;
                                                font-size  : 10px;
                                                line-height: 25px;
                                                color      : var(--global--color-heading);
                                                text-align : center;
                                            }
                                        }
                                    }

                                    input {
                                        width           : 50px;
                                        height          : 50px;
                                        color           : var(--global--color-heading);
                                        font-size       : 20px;
                                        font-weight     : 700;
                                        line-height     : 22px;
                                        font-family     : var(--global--font-body);
                                        text-align      : center;
                                        background-color: transparent;
                                        border-color    : transparent;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cart-product-action {
            display        : flex;
            align-items    : center;
            justify-content: space-between;
            margin-bottom  : 30px;
            padding-left   : 30px;
            padding-right  : 30px;

            @media #{$maxSmall} {
                flex-direction: column;
                align-items   : center;
                padding-left  : 20px;
                padding-right : 20px;
            }

            form {
                display      : flex;
                align-items  : center;
                margin-bottom: 0;

                @media #{$maxSmall} {
                    flex-direction: column;
                    align-items   : center;
                    margin-bottom : 20px;
                }

                .form-control {
                    border        : 1px solid #eaeaea;
                    padding       : 20px;
                    height        : 42px;
                    width         : 250px;
                    border-radius : 3px;
                    margin-bottom : 0;
                    margin-right  : 10px;
                    color         : #616161;
                    font-size     : 14px;
                    font-weight   : 400;
                    text-transform: capitalize;

                    @media #{$maxTablet} {
                        width: 200px;
                    }

                    @media #{$maxSmall} {
                        padding-left : 25px;
                        padding-right: 25px;
                        width        : 100%;
                        margin-bottom: 15px;
                        margin-right : 0;
                    }

                    &::-webkit-input-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }

                    &:-moz-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }

                    &:-ms-input-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }
                }

                .btn {
                    margin-top : 0;
                    flex-shrink: 0;
                }
            }

            .btn {
                width       : 134px;
                height      : 42px;
                line-height : 39px;
                margin-right: 10px;
                padding     : 0 18px;

                @media screen and (max-width: 575.98px) {
                    margin-top: 20px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            div {
                display    : flex;
                align-items: center;
            }
        }
    }

    .cart-calculate-shipping,
    .cart-total-amount {
        h3 {
            color         : var(--global--color-heading-darken);
            font-size     : 20px;
            font-weight   : 700;
            line-height   : 30px;
            font-family   : var(--global--font-heading);
            margin-bottom : 20px;
            position      : relative;
            text-transform: capitalize;
        }
    }

    .cart-total-amount {
        .table {
            margin-bottom: 30px;

            tbody {
                .head {
                    font-family   : var(--global--font-body);
                    font-weight   : 700;
                    font-size     : 17px;
                    color         : var(--global--color-heading-light);
                    text-transform: capitalize;
                }

                td {
                    font-family  : var(--global--font-body);
                    font-weight  : 400;
                    font-size    : 17px;
                    color        : var(--global--color-body);
                    border-top   : 0;
                    border-bottom: 1px solid #eaeaea;
                    padding-left : 0;

                    &.amount {
                        font-weight: 700;
                    }
                }
            }
        }

        .btn {
            width : 200px;
            height: 60px;

            @media #{$maxSmall} {
                width: 100%;
            }
        }
    }

    .cart-calculate-shipping {
        @media #{$maxTablet} {
            margin-bottom: 50px;
        }

        h3 {
            margin-bottom: 34px;
        }

        form {
            margin-bottom: 0;

            .form-control {
                border        : 2px solid #eaeaea;
                padding       : 25px;
                height        : 50px;
                border-radius : 3px;
                margin-bottom : 30px;
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;

                @media #{$maxSmall} {
                    padding-left : 25px;
                    padding-right: 25px;
                }

                &::-webkit-input-placeholder {
                    color         : #616161;
                    font-size     : 14px;
                    font-weight   : 400;
                    text-transform: capitalize;
                }

                &:-moz-placeholder {
                    color         : #616161;
                    font-size     : 14px;
                    font-weight   : 400;
                    text-transform: capitalize;
                }

                &:-ms-input-placeholder {
                    color         : #616161;
                    font-size     : 14px;
                    font-weight   : 400;
                    text-transform: capitalize;
                }
            }

            .nice-select {
                &::after {
                    right: 20px;
                }
            }

            textarea {
                &.form-control {
                    height       : 160px;
                    margin-bottom: 21px;
                }
            }
        }

        .btn {
            width : 200px;
            height: 60px;

            @media #{$maxSmall} {
                width: 100%;
            }
        }
    }
}



/* Shop Checkout */
.shop-checkout {
    padding: 70px 0 110px;

    @media #{$maxTablet} {
        padding: 70px 0;
    }

    h3 {
        color         : var(--global--color-heading-darken);
        font-size     : 24px;
        font-weight   : 700;
        line-height   : 30px;
        font-family   : var(--global--font-heading);
        margin-bottom : 13px;
        position      : relative;
        text-transform: capitalize;
    }

    .cart-empty,
    .cart-coupon {
        padding         : 30px;
        background-color: #f5f5f5;
        border-top      : 3px solid #1e85be;
        font-size       : 18px;
        font-weight     : 400;
        font-family     : var(--global--font-body);
        line-height     : 1;
        color           : #515151;
        margin-bottom   : 35px;

        a {
            color: var(--global--color-primary);
        }
    }

    .checkout-widget {
        margin-bottom: 45px;

        .widget-title {
            margin-bottom : 15px;
            text-transform: capitalize;

            h2 {
                font-size: 22px;
            }
        }

        label {
            font-family  : var(--global--font-heading);
            font-weight  : 500;
            font-size    : 16px;
            line-height  : 2;
            margin-bottom: 10px;
            ;
            text-transform: capitalize;
            color         : var(--global--color-heading);
            display       : block;

            .required {
                margin-left: 3px;
                color      : red;
            }
        }

        .form-control {
            border        : 2px solid #eaeaea;
            padding       : 25px;
            height        : 50px;
            border-radius : 3px;
            margin-bottom : 30px;
            color         : #616161;
            font-size     : 14px;
            font-weight   : 400;
            text-transform: capitalize;

            @media #{$maxSmall} {
                padding-left : 25px;
                padding-right: 25px;
            }

            &::-webkit-input-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }

            &:-moz-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }

            &:-ms-input-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }
        }

        textarea {
            &.form-control {
                height       : 160px;
                margin-bottom: 21px;
            }
        }

        .nice-select {
            &::after {
                right: 20px;
            }
        }
    }

    .cart-totals {
        margin-bottom: 53px;
        border-radius: 4px;
        overflow     : hidden;

        @media #{$maxTablet} {
            margin-bottom: 40px;
        }

        h3 {
            margin-bottom: 26px
        }

        .table-order {

            table {
                width        : 100%;
                margin-bottom: 0;

                tbody {
                    tr {
                        border-bottom: 1px solid #eaeaea;

                        &:last-child {
                            border-bottom: 0;
                        }

                        td {
                            font-family   : var(--global--font-body);
                            font-size     : 17px;
                            color         : var(--global--color-heading);
                            text-transform: capitalize;
                            font-weight   : 600;
                            padding       : 13px;

                            &:last-of-type {
                                font-weight: 400;
                                color      : var(--global--color-body)
                            }

                            &.product-name {
                                padding-left: 10px;
                            }
                        }

                        &.cart-total {
                            td {
                                &:last-of-type {
                                    color: var(--global--color-primary);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .your-order {
        h3 {
            margin-bottom: 28px
        }

        .cart-table {
            margin-bottom: 65px;

            @media #{$maxTablet} {
                margin-bottom: 40px
            }

            &.table-responsive {
                min-height: 0.01%;
                overflow-x: auto;
            }

            .table {
                background-color: var(--global--color-white);
                border-radius   : 0;
                margin-bottom   : 0;
                color           : var(--global--color-body);

                tr {
                    &:first-child {
                        th {
                            border-top: 0;

                            &:first-child {
                                border-left: 0;
                            }

                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }

                    th {
                        font-size     : 16px;
                        font-weight   : 700;
                        text-align    : center;
                        text-transform: capitalize;
                        color         : var(--global--color-heading-darken);
                        font-family   : var(--global--font-body);
                        padding       : 10px 0;
                        border-bottom : 1px solid #e5e8ed;
                    }
                }

                tbody {
                    tr {
                        td {
                            padding      : 20px;
                            border-bottom: 1px solid #e5e8ed;
                            border-top   : 0;
                            border-left  : 0;

                            &:last-child {
                                border-right: 0;
                            }

                            &.cart-product-total,
                            &.cart-product-quantity {
                                text-align    : center;
                                font-size     : 16px;
                                font-weight   : 700;
                                line-height   : 20px;
                                color         : var(--global--color-primary);
                                font-family   : var(--global--font-body);
                                vertical-align: middle;
                            }

                            &.cart-product-item {
                                display        : flex;
                                justify-content: flex-start;
                                align-items    : center;

                                .cart-product-img {
                                    width       : 70px;
                                    height      : 70px;
                                    margin-right: 20px;

                                    img {
                                        max-width: 100%;
                                    }
                                }

                                .cart-product-name {
                                    h6 {
                                        font-family   : var(--global--font-heading);
                                        color         : var(--global--color-heading-darken);
                                        font-size     : 16px;
                                        font-weight   : 700;
                                        line-height   : 27px;
                                        text-transform: capitalize;
                                        margin-bottom : 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .payment-wrapper {
        background-color: var(--global--color-gray);
        padding         : 33px 15px;
        border-radius   : 4px;

        .payment-method {
            margin: 0 15px;

            .no-payment {
                border-top: 3px solid #1e85be;
                padding   : 27px 0;

                p {
                    font-family: var(--global--font-body);
                    padding    : 0 30px;
                    font-size  : 18px;
                    line-height: 2;
                    font-weight: 500;
                    color      : var(--global--color-heading);
                }
            }
        }

        .payment-conditions {
            margin: 0 15px;

            .message {
                padding-top  : 10px;
                font-family  : var(--global--font-body);
                font-size    : 17px;
                line-height  : 2;
                color        : var(--global--color-body);
                font-weight  : 400;
                margin-bottom: 30px;
            }

            label {
                text-transform: capitalize;
                font-weight   : 500;
                color         : var(--global--color-heading);
                font-size     : 16px;
                margin-bottom : 30px;

                a {
                    color: var(--global--color-primary);
                }
            }
        }
    }
}



.shop-login {
    padding: 110px 0;

    @media #{$maxTablet} {
        padding: 70px 0;
    }

    .login-body {
        background-color: var(--global--color-body-light);
        border-radius   : 3px;
        padding         : 40px;
        box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.09);

        @media #{$maxTablet} {
            text-align: center;
            padding   : 40px 25px
        }

        label {
            font-family  : var(--global--font-heading);
            font-weight  : 500;
            font-size    : 16px;
            line-height  : 2;
            margin-bottom: 5px;
            ;
            text-transform: capitalize;
            color         : #222222;

            .required {
                margin-left: 3px;
                color      : red;
            }
        }

        .form-control {
            border        : 2px solid #eaeaea;
            padding       : 30px;
            height        : 68px;
            border-radius : 3px;
            margin-bottom : 30px;
            color         : #616161;
            font-size     : 14px;
            font-weight   : 400;
            text-transform: capitalize;

            @media #{$maxSmall} {
                padding-left : 25px;
                padding-right: 25px;
            }

            &::-webkit-input-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }

            &:-moz-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }

            &:-ms-input-placeholder {
                color         : #616161;
                font-size     : 14px;
                font-weight   : 400;
                text-transform: capitalize;
            }
        }

        .check {
            margin-top   : 10px;
            margin-bottom: 10px;

            label {
                margin-left: 5px;
            }
        }

        .btn {
            display: inline-flex;
            width  : 200px;
            height : 68px;
        }

        .forget {
            margin-top : 23px;
            font-family: var(--global--font-body);
            font-size  : 15px;
            font-weight: 700;
            color      : var(--global--color-heading);

            &:hover {
                color: var(--global--color-primary);
            }
        }
    }
}


/* Shop Account */
.shop-account {
    padding: 110px 0;

    @media #{$maxTablet} {
        padding: 70px 0;
    }

    .shop-account-nav {
        background-color: var(--global--color-body-light);
        padding         : 0 30px;
        border          : 1px solid var(--global--color-body-light);
        border-radius   : 8px;

        @media screen and (max-width:991px) {
            margin-bottom: 30px;
        }

        ul {
            li {
                a {
                    display        : flex;
                    justify-content: space-between;
                    align-items    : center;
                    height         : 60px;
                    color          : var(--global--color-heading);
                    font-size      : 15px;
                    font-family    : var(--global--font-body);
                    font-weight    : 700;
                    line-height    : 36px;
                    text-transform : capitalize;
                    position       : relative;
                    transition     : 0.3s ease-in-out;
                    border-bottom  : 1px solid #eaeaea;

                    @media screen and (max-width: 500px) {
                        justify-content: center;
                    }

                    &:hover {
                        color: var(--global--color-primary);

                        i {
                            color    : var(--global--color-primary);
                            transform: translateX(-5px);
                        }
                    }

                    span {
                        @media screen and (max-width: 500px) {
                            display: none;
                        }
                    }

                    i {
                        transition : 0.3s ease-in-out;
                        margin-left: 10px;
                        font-size  : 15px;

                        @media screen and (max-width: 500px) {
                            margin-left: 0;
                            font-size  : 20px;
                        }
                    }
                }

                &:first-child {

                    a {
                        border-top: none;
                    }
                }

                &:last-child {
                    a {
                        border-bottom: none;
                    }
                }

                &.active {

                    a {
                        color: var(--global--color-primary);

                        i {
                            color: var(--global--color-primary);
                        }
                    }

                    &:hover {
                        a {
                            i {
                                transform: translateX(0);
                            }
                        }
                    }

                }
            }
        }
    }

    .shop-account-content {
        background-color: var(--global--color-body-light);
        padding         : 50px 30px;
        border          : 1px solid var(--global--color-body-light);
        border-radius   : 8px;

        @media #{$maxSmall} {
            padding-left : 20px;
            padding-right: 20px;
        }

        h6 {
            font-family  : var(--global--font-heading);
            font-size    : 18px;
            font-weight  : 600;
            line-height  : 28px;
            margin-bottom: 0;
        }

        p {
            font-family  : var(--global--font-heading);
            font-size    : 18px;
            font-weight  : 600;
            line-height  : 28px;
            margin-bottom: 0;
            color        : var(--global--color-heading);
            transition   : 0.3s ease-in-out;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: var(--global--color-primary);
            }
        }
    }
}