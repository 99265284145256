/*------------------------------------*\
    #Team
\*------------------------------------*/
.team {
    padding-top   : 110px;
    padding-bottom: 10px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 0px;
    }

    .carousel {
        .owl-stage-outer {
            overflow: visible;

            .owl-item {
                opacity: 0;

                &.active {
                    opacity: 1;
                }
            }
        }
    }

    .member {
        transition: all 300ms linear 0ms;
        max-width : 370px;
        margin    : 0 auto 70px;
        filter    : drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.12));

        @media #{$maxTablet} {
            margin: 0 auto 40px;
        }

        &:hover {
            .member-wrapper {
                .member-content {
                    .member-social {
                        opacity   : 1;
                        visibility: visible;
                    }
                }
            }
        }

        .member-wrapper {
            clip-path: polygon(100% 0, 100% 88%, 85% 100%, 0 100%, 0 0);

            .member-img {
                position: relative;

                img {
                    height    : 400px;
                    object-fit: cover;
                    width     : 100%;
                }

            }

            .member-content {
                background-color: var(--global--color-white);
                padding         : 32px 40px 38px;
                display         : flex;
                align-items     : center;
                justify-content : space-between;
                position        : relative;

                @media #{$noteBook} {
                    padding-left : 30px;
                    padding-right: 30px;
                }

                @media #{$maxSmall} {
                    flex-direction: column;
                    text-align    : center;
                }

                .member-social {
                    display    : flex;
                    align-items: center;
                    transition : 0.3s ease-in-out;
                    opacity    : 0;
                    visibility : hidden;

                    @media #{$maxSmall} {
                        opacity   : 1;
                        visibility: visible;
                    }

                    a {
                        border-radius   : 3px;
                        display         : flex;
                        align-items     : center;
                        justify-content : center;
                        background-color: var(--global--color-primary);
                        width           : 38px;
                        height          : 38px;
                        color           : var(--global--color-heading);
                        font-size       : 20px;
                        transition      : 0.3s ease-in-out;
                        margin-right    : 3px;

                        &:last-child {
                            margin-right: 0;
                        }

                        &:hover {
                            transform: translateY(-5px);
                        }
                    }
                }

                .member-info {
                    @media #{$maxSmall} {
                        margin-bottom: 25px;
                    }

                    a {
                        display       : inline-block;
                        font-family   : var(--global--font-heading);
                        font-size     : 20px;
                        font-weight   : 700;
                        color         : var(--global--color-heading-light);
                        margin-bottom : 11px;
                        text-transform: capitalize;
                        transition    : 0.3s ease-in-out;

                        &:hover {
                            color: var(--global--color-primary);
                        }
                    }

                    p {
                        font-family   : var(--global--font-body);
                        color         : var(--global--color-body);
                        font-size     : 15px;
                        font-weight   : 400;
                        line-height   : 1;
                        text-transform: capitalize;
                        transition    : 0.3s ease-in-out;
                        margin-bottom : 0;
                    }
                }
            }
        }

        &.member-2 {
            overflow  : hidden;
            box-shadow: none;
            filter    : unset;

            &:hover {
                box-shadow: 0px 5px 83px 0px rgba(3, 43, 86, 0.12);

                .member-wrapper {
                    .member-content {
                        .member-social {
                            opacity   : 1;
                            visibility: visible;
                        }
                    }
                }
            }

            .member-wrapper {
                clip-path: unset;

                .member-img {
                    img {
                        height: 370px;
                    }
                }

                .member-content {
                    justify-content: center;
                    padding-top    : 22px;
                    padding-bottom : 27px;

                    @media #{$noteBook} {
                        padding-left : 25px;
                        padding-right: 25px;
                    }

                    .member-social {
                        position        : absolute;
                        top             : -60px;
                        left            : 0;
                        right           : 0;
                        width           : 100%;
                        height          : calc(100% + 40px);
                        padding-top     : 30px;
                        justify-content : center;
                        align-items     : flex-start;
                        background-color: var(--global--color-white);
                        z-index         : 1;
                        transform-origin: bottom;
                        visibility      : hidden;
                        opacity         : 0;
                    }

                    .member-info {
                        text-align: center;
                        position  : relative;
                        z-index   : 2;

                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}