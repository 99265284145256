/*------------------------------------*\
    #TopBar
\*------------------------------------*/
.top-bar {
	padding         : 0 40px;
	display         : flex;
	height          : 50px;
	position        : relative;
	z-index         : 8888;
	background-color: var(--global--color-heading-light);

	@media (min-width:992px) and (max-width:1400px) {
		padding: 0px 20px;
	}

	.block-left {
		display    : flex;
		align-items: center;
		flex-basis : 50%;

		@media screen and (min-width:1350px) {
			flex-basis: 60%
		}
	}

	.block-right {
		display    : flex;
		align-items: center;
		flex-basis : 50%;

		@media screen and (min-width:1350px) {
			flex-basis: 40%
		}

		@media #{$minLarge} {
			justify-content: flex-end;
		}

		@media (min-width:992px) and (max-width:1185px) {
			margin-bottom: 0px;
		}

		.module-language {
			margin-left: 30px;

			@media (min-width:992px) and (max-width:1399.98px) {
				margin-left: 15px;
			}
		}

		.module-social {
			margin-left: 30px;

			@media (min-width:992px) and (max-width:1399.98px) {
				margin-left: 15px;
			}
		}

		.top-bar-links-wrapper {
			.top-bar-link {
				display    : inline-flex;
				align-items: center;
				height     : 100%;
				@include text(var(--global--font-body), 13px, 21px, 400, var(--global--color-white), 0px);
				margin-right  : 20px;
				text-transform: capitalize;

				@media (min-width:992px) and (max-width:1199.98px) {
					margin-right: 10px;
				}

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					color: var(--global--color-primary);
				}
			}
		}
	}

	.top-contact {
		display: flex;

		.contact-info {
			display     : flex;
			align-items : center;
			margin-right: 30px;

			@media (min-width:992px) and (max-width:1399.98px) {
				margin-right: 15px;
			}

			&:last-child {
				margin-right: 0;

				@media (min-width:992px) and (max-width:1349.98px) {
					display: none;
				}
			}

			svg {
				color       : var(--global--color-primary);
				margin-right: 10px;
			}

			a,
			p {
				@include text(var(--global--font-body), 13px, 21px, 400, var(--global--color-body), 0px);
				text-transform: capitalize;
			}

			a {
				&:hover {
					color: var(--global--color-primary);
				}
			}
		}
	}
}