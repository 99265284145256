/*------------------------------------*\
    #Heading
\*------------------------------------*/

.heading {
    margin-bottom: 48px;

    @media #{$maxTablet} {
        text-align  : center;
        max-width   : 500px;
        margin-left : auto;
        margin-right: auto;
    }

    @media #{$maxSmall} {
        max-width: unset
    }

    .heading-subtitle {
        font-family   : var(--global--font-body);
        text-transform: capitalize;
        color         : var(--global--color-body);
        font-size     : 16px;
        font-weight   : 700;
        line-height   : 1;
        margin-bottom : 14px;

        @media #{$maxTablet} {
            line-height: 1.5;
        }
    }

    .heading-title {
        font-family   : var(--global--font-heading);
        text-transform: capitalize;
        font-size     : 37px;
        line-height   : 54px;
        margin-bottom : 0px;
        font-weight   : 700;
        color         : var(--global--color-heading-darken);

        @media #{$noteBook} {
            font-size  : 30px;
            line-height: 1.4;
        }

        @media #{$maxTablet} {
            font-size  : 30px;
            line-height: 1.4;
        }
    }

    .heading-desc {
        font-family  : var(--global--font-body);
        color        : var(--global--color-body);
        font-size    : 17px;
        font-weight  : 400;
        line-height  : 27px;
        margin-bottom: 32px;
    }

    .paragraph {
        font-family  : var(--global--font-body);
        font-weight  : 700;
        font-size    : 17px;
        line-height  : 27px;
        color        : #1e4873;
        margin-bottom: 0px;
    }

    &.heading-light {
        .heading-subtitle {
            color: var(--global--color-primary);
        }

        .heading-title {
            color: var(--global--color-white);
        }

        .heading-desc {
            color: #c6c6c6;
        }

        .paragraph {
            color: #c6c6c6;
        }
    }

    &.heading-2 {
        margin-bottom: 70px;

        @media #{$minLarge} {
            margin-bottom: 0px;
        }

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 31px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .heading-desc {
            margin-bottom: 25px;

            &:last-of-type {
                margin-bottom: 32px;
            }
        }
    }

    &.heading-3 {
        margin-bottom: 70px;

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 31px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .heading-desc {
            font-size    : 18px;
            margin-bottom: 0px;
        }
    }

    &.heading-5 {
        margin-bottom: 0;

        @media #{$minLarge} {
            transform    : translateY(-6px);
            margin-bottom: -6px;
        }

        .heading-desc {
            margin-bottom: 24px;
        }
    }

    &.heading-6 {
        margin-bottom: 104px;

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 23px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .heading-desc {
            margin-bottom: 0px;
            font-size    : 18px;
            line-height  : 29px;
            ;
            font-weight: 700;
        }
    }

    &.heading-7 {
        margin-bottom: 0;

        .heading-title {
            margin-bottom: 21px;
        }

        .heading-desc {
            margin-bottom: 34px;
        }
    }

    &.heading-8 {
        margin-bottom: 0;

        .heading-title {
            margin-bottom: 20px;
        }

        .heading-desc {
            margin-bottom: 42px;
        }
    }

    &.heading-9 {
        margin-bottom: 0;

        .heading-title {
            margin-bottom: 20px;
        }

        .heading-desc {
            margin-bottom: 37px;
        }
    }

    &.heading-9 {
        margin-bottom: 0;

        .heading-title {
            margin-bottom: 20px;
        }

        .heading-desc {
            margin-bottom: 37px;
        }
    }

    &.heading-11 {
        margin-bottom: 60px;

        .heading-title {
            margin-bottom: 23px;

            @media #{$minLarge} {
                transform    : translateY(-10px);
                margin-bottom: -10px;
            }
        }
    }

    &.heading-12 {
        margin-bottom: 32px;

        .heading-title {
            font-size  : 24px;
            line-height: 1.4;
        }
    }

    &.heading-13 {
        margin-bottom: 0px;

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 23px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .heading-desc {
            font-size  : 16px;
            font-weight: 700;

            &:last-of-type {
                font-size    : 15px;
                margin-bottom: 33px;
            }
        }
    }

    &.heading-15 {
        margin-bottom: 63px;

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 24px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .heading-desc {
            margin-bottom: 0px;
        }
    }

    &.heading-16 {
        .heading-title {
            margin-bottom: 34px;
        }
    }

    &.heading-18 {
        margin-bottom: 62px;

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 22px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }
    }

    &.heading-19 {
        margin-bottom: 0;

        @media #{$minLarge} {
            padding-top: 117px;
        }

        @media #{$maxTablet} {
            margin-bottom: 70px;
        }

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 32px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .heading-desc {
            margin-bottom: 25px;

            &:last-of-type {
                margin-bottom: 32px;
            }
        }
    }

    &.heading-20 {
        margin-bottom: 83px;

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 59px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .block-desc {
            position   : relative;
            padding-top: 28px;

            @media #{$maxTablet} {
                padding-bottom: 43px;
            }

            &::before {
                content         : '';
                position        : absolute;
                top             : 0;
                left            : 0;
                width           : 290px;
                height          : 4px;
                background-color: var(--global--color-primary);

                @media #{$maxTablet} {
                    left     : 50%;
                    transform: translateX(-50%);
                    max-width: 100%;
                }
            }

            .heading-desc {
                color        : #f9f9f9;
                line-height  : 28px;
                margin-bottom: 27px;

                &:last-of-type {
                    margin-bottom: 33px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .btn {
                display: inline-flex;
                width  : 218px;
                height : 68px;
            }
        }
    }

    &.heading-21 {
        @media #{$minLarge} {
            transform    : translateY(-8px);
            margin-bottom: -8px;
        }
    }

    &.heading-22 {
        margin-bottom: 0px;

        @media #{$minLarge} {
            transform    : translateY(-14px);
            margin-bottom: -14px
        }

        @media #{$maxTablet} {
            margin-bottom: 62px;
        }

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 31px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .heading-desc {
            margin-bottom: 26px;
        }
    }

    &.heading-23 {
        margin-bottom: 0;

        @media #{$minLarge} {
            margin-top: 287px;
        }

        .heading-title {
            color        : var(--global--color-white);
            font-size    : 24px;
            line-height  : 1.4;
            margin-bottom: 27px;
        }

        .heading-desc {
            margin-bottom: 24px;
        }

        .advantages-list {
            margin-bottom: 33px;
        }

        .btn {
            display: inline-flex;
            width  : 228px;
            height : 68px
        }
    }

    &.heading-25 {
        margin-bottom: 0px;

        .heading-title {
            font-size    : 40px;
            line-height  : 55px;
            margin-bottom: 33px;

            @media #{$noteBook} {
                font-size  : 30px;
                line-height: 1.4;
            }

            @media #{$maxTablet} {
                font-size  : 30px;
                line-height: 1.4;
            }
        }

        .heading-desc {
            margin-bottom: 26px;
            font-weight  : 700;
            color        : #f9f9f9;

            &:last-of-type {
                font-weight  : 400;
                margin-bottom: 33px;
            }
        }
    }

    &.heading-26 {
        margin-bottom: 62px;

        .heading-title {
            margin-bottom: 20px;
        }

        .heading-desc {
            font-size  : 18px;
            line-height: 29px;
            ;
            font-weight: 700;
        }
    }

    &.heading-27 {
        @media #{$maxTablet} {
            margin-bottom: 70px;
        }

        .heading-title {
            color        : var(--global--color-white);
            font-size    : 24px;
            line-height  : 1.4;
            margin-bottom: 27px;
        }

        .heading-desc {
            margin-bottom: 24px;
        }

        .advantages-list {
            margin-bottom: 33px;
        }

        .btn {
            display: inline-flex;
            width  : 228px;
            height : 68px
        }
    }

    &.heading-28 {
        margin-bottom: 53px;

        .heading-title {
            margin-bottom: 21px;
        }
    }

    &.heading-29 {
        margin-bottom: 62px;

        .heading-title {
            font-size    : 22px;
            line-height  : 33px;
            color        : var(--global--color-heading-darken);
            margin-bottom: 24px;
        }

        .heading-desc {
            font-size: 16px;
            color    : var(--global--color-heading-light);
        }
    }

    &.heading-31 {
        margin-bottom: 0;

        .heading-title {
            font-size    : 22px;
            line-height  : 33px;
            color        : var(--global--color-heading-darken);
            margin-bottom: 23px;
        }

        .heading-desc {
            font-weight  : 400;
            font-size    : 16px;
            line-height  : 27px;
            color        : var(--global--color-heading-light);
            margin-bottom: 0;
        }
    }

    &.heading-32 {
        margin-bottom: 0;

        .heading-subtitle {
            font-size: 17px;
            color    : var(--global--color-heading-light);
        }

        .heading-subtitle {
            margin-bottom: 11px;
        }

        .heading-title {
            font-size     : 75px;
            line-height   : 1.1;
            text-transform: capitalize;

            @media #{$noteBook} {
                font-size  : 35px;
                line-height: 1.4;
            }

            @media #{$maxSmall} {
                font-size  : 32px;
                line-height: 1.4;
            }
        }
    }
}