/*------------------------------------*\
#CTA
\*------------------------------------*/
.cta {
    padding-top   : 130px;
    padding-bottom: 110px;
    position      : relative;
    overflow      : visible;
    z-index       : 5;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    .cta-wrapper {
        position: relative;
    }

    .advantages-list {
        margin-bottom: 32px;
    }

    .cta-action {
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            justify-content: center;
        }

        @media #{$maxSmall} {
            display       : flex;
            flex-direction: column;
            align-items   : center;
        }

        .btn {
            width       : 228px;
            height      : 68px;
            margin-right: 30px;
            padding     : 0 15px;

            @media #{$maxSmall} {
                margin-right : 0;
                margin-bottom: 25px;
                height       : 60px;
                width        : 220px;
            }

            @media #{$noteBook} {
                width       : 220px;
                margin-right: 15px;
            }

            &:last-child {
                margin-right: 0;

                @media #{$maxSmall} {
                    margin-bottom: 0;
                }
            }
        }
    }

    .cta-img-holder {
        display        : flex;
        justify-content: center;

        @media #{$minLarge} {
            justify-content: flex-end;
            position       : absolute;
            top            : 0;
            left           : 0;
            max-width      : 50%;
            z-index        : 7;
        }

        .cta-img {
            position: relative;

            @media #{$maxTablet} {
                margin: 0 auto 50px;
            }

            img {
                width        : 100%;
                border-radius: 0px;
                height       : 529px;
                object-fit   : cover;
                position     : relative;
                z-index      : 1;

                @media #{$maxTablet} {
                    max-width: 550px;
                    height   : 550px;
                    margin   : 0 auto
                }

                @media #{$maxSmall} {
                    max-width: 100%;
                }
            }

            .edge-shape {
                position: absolute;
                right   : 0;
                top     : 0;
                z-index : 5;
                width   : 110px;
                height  : 110px;
            }

            .cta-banner-holder {
                position        : absolute;
                right           : 0;
                bottom          : 0;
                background-color: var(--global--color-primary);
                max-width       : 250px;
                z-index         : 5;
                padding         : 33px 40px 12px;

                .banner-title {
                    font-family  : var(--global--font-heading);
                    font-weight  : 700;
                    font-size    : 20px;
                    line-height  : 29px;
                    color        : var(--global--color-white);
                    margin-bottom: 19px;
                }

                .banner-desc {
                    margin-bottom: 35px;
                    color        : #f9f9f9;
                    font-size    : 14px;
                    line-height  : 22px;
                }

                .btn {
                    width        : 100%;
                    margin-bottom: 14px
                }

                .banner-note {
                    font-size    : 13px;
                    line-height  : 24px;
                    color        : var(--global--color-heading);
                    margin-bottom: 0;
                }
            }
        }
    }

    &.cta-2 {
        padding         : 0;
        position        : relative;
        background-color: var(--global--color-primary);

        @media #{$minLarge} {
            transform       : translateY(109px);
            margin-top      : -109px;
            background-color: transparent;
        }

        &::before {
            content         : '';
            position        : absolute;
            top             : 0;
            left            : 0;
            background-color: var(--global--color-primary);
            height          : 100%;
            width           : 50%;
        }

        .cta-wrapper {
            background-color: var(--global--color-primary);
            padding-top     : 122px;
            padding-bottom  : 120px;
            position        : relative;
            clip-path       : polygon(90% 0, 100% 18%, 100% 100%, 0 100%, 0 0);

            @media #{$maxTablet} {
                padding-top   : 70px;
                padding-bottom: 70px;
            }

            .cta-newsletter {
                margin-top: 46px;

                @media #{$maxTablet} {
                    margin-bottom: 40px;
                }

                .form-newsletter {
                    display      : flex;
                    align-items  : center;
                    margin-bottom: 22px;

                    @media #{$maxSmall} {
                        flex-direction : column;
                        justify-content: center;
                    }

                    .form-control {
                        border        : none;
                        box-shadow    : none;
                        color         : #616161;
                        font-size     : 15px;
                        font-weight   : 700;
                        text-transform: capitalize;
                        line-height   : 1;
                        width         : 290px;
                        ;
                        height          : 68px;
                        padding         : 25px 25px 25px 40px;
                        background-color: var(--global--color-white);
                        border-radius   : 3px;
                        margin-bottom   : 0;
                        margin-right    : 30px;

                        @media #{$maxTablet} {
                            padding-left: 25px;
                        }

                        @media #{$maxSmall} {
                            margin-bottom: 25px;
                            margin-right : 0px;
                        }

                        &::-webkit-input-placeholder {
                            color         : #616161;
                            font-size     : 15px;
                            font-weight   : 700;
                            text-transform: capitalize;
                        }

                        &:-moz-placeholder {
                            color         : #616161;
                            font-size     : 15px;
                            font-weight   : 700;
                            text-transform: capitalize;
                        }

                        &:-ms-input-placeholder {
                            color         : #616161;
                            font-size     : 15px;
                            font-weight   : 700;
                            text-transform: capitalize;
                        }
                    }

                    .btn {
                        width : 214px;
                        height: 68px;

                        &::before {
                            background-color: var(--global--color-white);
                        }
                    }
                }

                p {
                    font-weight  : 700;
                    font-size    : 15px;
                    color        : var(--global--color-heading-light);
                    margin-bottom: 0;
                }
            }

            .video-btn-wrapper {
                display        : flex;
                align-items    : center;
                justify-content: center;
                flex-grow      : 1;

                .btn--video {
                    background-color: var(--global--color-white);
                    width           : 128px;
                    height          : 128px;

                    @media #{$maxSmall} {
                        width : 80px;
                        height: 80px;
                    }
                }
            }
        }
    }
}

.call-to-action {
    .cta-action {
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            justify-content: center;
        }

        @media #{$maxSmall} {
            display       : flex;
            flex-direction: column;
            align-items   : center;
        }

        .btn {
            width       : 260px;
            height      : 68px;
            margin-right: 30px;
            padding     : 0 15px;

            @media #{$maxSmall} {
                margin-right : 0;
                margin-bottom: 25px;
                height       : 60px;
                width        : 220px;
            }

            @media #{$noteBook} {
                width       : 220px;
                margin-right: 15px;
            }

            &:last-child {
                margin-right: 0;
                width       : 154px;

                @media #{$maxSmall} {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.cta-banner {
    position        : absolute;
    bottom          : 0;
    right           : 0;
    background-color: #161616;
    max-width       : 440px;
    z-index         : 5;
    padding         : 52px 50px 60px;

    .cta-body {
        margin-bottom: 22px;

        .cta-name {
            font-size     : 20px;
            line-height   : 29px;
            color         : var(--global--color-white);
            text-transform: capitalize;
            margin-bottom : 16px;
        }

        .cta-desc {
            font-size    : 15px;
            line-height  : 25px;
            margin-bottom: 0;
        }
    }

    .carousel-dots {
        .owl-dots {
            @media #{$minLarge} {
                justify-content: flex-start;
            }

            .owl-dot {
                span {
                    background-color: #505050;
                    position        : relative;

                    &::before {
                        content         : '';
                        position        : absolute;
                        top             : 0;
                        left            : 0;
                        border-radius   : 2px;
                        width           : 0px;
                        height          : 6px;
                        background-color: var(--global--color-primary);
                        transition      : 0.3s ease-in-out;
                    }
                }

                &.active {
                    span {
                        background-color: #505050;

                        &::before {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}