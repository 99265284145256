/*------------------------------------*\
    #BACK TO TOP
\*------------------------------------*/

#back-to-top {
    z-index         : 999;
    display         : flex;
    align-items     : center;
    cursor          : pointer;
    transition      : 300ms ease-in-out;
    span {
        font-family   : var(--global--font-body);
        font-weight: 700;
        font-size: 14px;
        color: var(--global--color-white);
        text-transform: capitalize;
        margin-right: 20px;
        transition: 0.3s ease-in-out;
    }
    &:hover {
        &::before {
            width: 100%;
            height: 100%;
        }
    }
}