/*------------------------------------*\
#Counters
\*------------------------------------*/
.counters {
    padding-top   : 260px;
    padding-bottom: 122px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    .counter-img-holder {
        display        : flex;
        justify-content: center;

        .counter-img {
            position: relative;

            @media #{$maxTablet} {
                margin: 0 auto 0px;
            }

            img {
                border-radius: 0px;
                height       : 520px;
                object-fit   : cover;
                position     : relative;
                z-index      : 1;

                @media #{$maxTablet} {
                    max-width: 550px;
                    margin   : 0 auto
                }

                @media #{$maxSmall} {
                    max-width: 100%;
                }
            }

            .edge-shape {
                position: absolute;
                left    : 0;
                top     : 0;
                z-index : 5;
                width   : 110px;
                height  : 110px;
            }

            .counters-holder {
                position        : absolute;
                left            : 40px;
                bottom          : 0;
                background-color: var(--global--color-primary);
                max-width       : 210px;
                padding         : 32px 30px 40px;
                z-index         : 5;

                &::before {
                    content         : '';
                    position        : absolute;
                    top             : 100%;
                    left            : 0;
                    width           : 40px;
                    height          : 30px;
                    background-color: var(--global--color-primary);
                    clip-path       : polygon(100% 0, 0 0, 0 100%);
                }

                .counter {
                    .counter-num {
                        font-family  : var(--global--font-heading);
                        font-weight  : 600;
                        font-size    : 50px;
                        color        : var(--global--color-heading-darken);
                        margin-bottom: 19px;
                    }

                    .counter-name {
                        h3 {
                            font-family  : var(--global--font-body);
                            font-weight  : 700;
                            font-size    : 15px;
                            line-height  : 25px;
                            color        : var(--global--color-heading-light);
                            margin-bottom: 0;
                        }
                    }
                }

                .owl-dots {
                    justify-content: flex-start;
                    margin-top     : 23px;
                }
            }
        }
    }

    &.counters-1 {
        .advantages-list {
            margin-bottom: 34px;
        }
    }

    &.counters-2 {
        padding-top: 130px;

        @media #{$maxTablet} {
            padding-top: 70px;
        }

        .advantages-list {
            margin-bottom: 34px;
        }
    }
}