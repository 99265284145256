/*------------------------------------*\
    #MODULE
\*------------------------------------*/
.module-holder {
    margin-left: 20px;
    display    : flex;
    align-items: center;
    height     : 110px;

    @media #{$maxTablet} {
        margin-top : 30px;
        margin-left: 0;
        height     : auto;
    }

    div {
        &:not(.module-contact) {
            @media #{$maxTablet} {
                display: none;
            }
        }
    }

}

.module-holder-phone {
    display: none;

    @media #{$maxTablet} {
        display    : flex;
        align-items: center;
        height     : 80px;
        margin-top : 0;
    }

    .navbar-toggler {
        display        : flex;
        justify-content: center;
        align-items    : center;
        width          : 30px;
        height         : 30px;
        transition     : 0.3s ease-in-out;
        border         : 0;
        padding        : 0;
        outline        : 0;
        cursor         : pointer;
        border-radius  : 0;

        &:focus {
            box-shadow: none;
        }

        @media #{$minXlarge} {
            display: none;
        }

        &:not(.collapsed) {
            .navbar-toggler-icon {
                background-color: transparent;
                transform       : rotate(180deg);

                &:before,
                &:after {
                    top: 0;
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }

        .navbar-toggler-icon {
            background-color: var(--global--color-heading);
            width           : 100%;
            height          : 2px;
            position        : relative;
            transition      : 0.3s ease-in-out;

            &::before,
            &::after {
                content         : '';
                position        : absolute;
                left            : 0;
                height          : 2px;
                width           : 100%;
                background-color: var(--global--color-heading);
                transition      : 0.3s ease-in-out;
            }

            &::before {
                top: -10px;
            }

            &::after {
                top: 10px;
            }
        }
    }

    .module-language {
        display     : inline-flex;
        margin-right: 15px;

        @media (min-width:992px) and (max-width:1200px) {
            top: 140%;
        }

        @media #{$minLarge} {
            display: none;
        }

        .selected {
            img {
                margin-right: 0;
            }

            span,
            i {
                display: none;
            }

            .lang-list {
                left: -45px;
            }
        }
    }

    .module-search {
        display      : inline-flex;
        border-bottom: 0;
        padding      : 0;
        margin-right : 20px;
    }

    .module-cart {
        display: inline-flex;
    }
}

.module {
    display : inline-block;
    position: relative;

    span.title {
        display: none;
    }

    .module-icon {
        cursor: pointer;

        i {
            font-size  : 14px;
            line-height: 24px;
            color      : var(--global--color-white);
        }
    }

    &:last-of-type {
        .module-icon {
            margin-right: 0;
        }
    }

    .module-label {
        width           : 16px;
        height          : 16px;
        line-height     : 16px;
        border-radius   : 50%;
        background-color: var(--global--color-primary);
        color           : var(--global--color-white);
        font-size       : 10px;
        font-weight     : 400;
        text-align      : center;
        position        : absolute;
        top             : -4px;
        left            : 10px;
    }
}

/* Module Fullscreen */
.module-fullscreen {
    left            : 0;
    top             : 0;
    right           : 0;
    z-index         : 9999;
    display         : block;
    height          : 100%;
    width           : 100%;
    opacity         : 0;
    visibility      : hidden;
    background-color: var(--global--color-white);
    position        : fixed;

    .module-cancel {
        top             : 40px;
        right           : 40px;
        border-radius   : 8px 8px 0 8px;
        position        : absolute;
        width           : 50px;
        height          : 50px;
        font-size       : 14px;
        font-weight     : 700;
        text-transform  : uppercase;
        color           : var(--global--color-white);
        background-color: var(--global--color-primary);
        display         : flex;
        justify-content : center;
        align-items     : center;
    }

    &.module-active {
        visibility: visible;
        opacity   : 1;
        transition: opacity .5s ease-in-out;
    }
}


/* Module Search */
.module-search {
    position    : relative;
    margin-right: 20px;
    display     : flex;
    align-items : center;
    height      : 80px;

    @media #{$minLarge} {
        height: 110px;
    }

    .module-icon {
        &:hover {

            i,
            span {
                color: var(--global--color-primary);
            }
        }

        i {
            transition: 0.3s ease-in-out;
            color     : var(--global--color-heading);
        }
    }
}

.module-search-warp {
    left            : 0;
    top             : 0;
    right           : 0;
    z-index         : 9999;
    display         : block;
    height          : 100%;
    width           : 100%;
    opacity         : 0;
    visibility      : hidden;
    background-color: var(--global--color-white);
    position        : fixed;

    &.search-warp-active {
        visibility: visible;
        opacity   : 1;
        transition: opacity .5s ease-in-out;
    }

    .module-cancel {
        top             : 40px;
        right           : 40px;
        border-radius   : 3px;
        position        : absolute;
        width           : 64px;
        height          : 64px;
        font-size       : 20px;
        font-weight     : 700;
        text-transform  : uppercase;
        color           : var(--global--color-white);
        background-color: var(--global--color-primary);
        display         : flex;
        justify-content : center;
        align-items     : center;

        @media #{$maxSmall} {
            top      : 30px;
            right    : 20px;
            font-size: 14px;
            width    : 50px;
            height   : 50px;
        }
    }

    .form-search {
        margin-bottom: 0;

        button {
            &::before {
                position   : absolute;
                left       : 0;
                top        : 0;
                content    : "\e906";
                color      : var(--global--color-heading);
                font-family: "icomoon";
                font-size  : 28px;
                font-weight: 400;
                line-height: 70px;
                z-index    : 999;
                cursor     : pointer;
                transform  : translateY(-2px);

                @media #{$maxSmall} {
                    font-size: 16px;
                }
            }
        }
    }

    .form-control {
        position        : relative;
        font-family     : var(--global--font-heading);
        font-size       : 35px;
        font-weight     : 500;
        line-height     : 70px;
        height          : 70px;
        color           : var(--global--color-body);
        border          : none;
        background-color: transparent;
        width           : 100%;
        border-bottom   : 2px solid #eaeaea;
        padding         : 17px 30px 25px 46px;
        margin-bottom   : 0;
        transition      : 400ms linear;
        text-transform  : capitalize;
        border-radius   : 0;

        @media #{$maxSmall} {
            font-size     : 18px;
            padding-left  : 30px;
            padding-bottom: 17px;
        }

        &:focus,
        &:active {
            border-color: var(--global--color-primary);
        }

        &::-webkit-input-placeholder {
            color      : var(--global--color-body);
            font-family: var(--global--font-heading);
            font-size  : 35px;
            font-weight: 500;

            @media #{$maxSmall} {
                font-size: 18px;
            }
        }

        &::-moz-placeholder {
            color      : var(--global--color-body);
            font-family: var(--global--font-heading);
            font-size  : 35px;
            font-weight: 500;

            @media #{$maxSmall} {
                font-size: 18px;
            }
        }

        &::-moz-placeholder {
            color      : var(--global--color-body);
            font-family: var(--global--font-heading);
            font-size  : 35px;
            font-weight: 500;

            @media #{$maxSmall} {
                font-size: 18px;
            }
        }

        &:-ms-input-placeholder {
            color      : var(--global--color-body);
            font-family: var(--global--font-heading);
            font-size  : 35px;
            font-weight: 500;

            @media #{$maxSmall} {
                font-size: 18px;
            }
        }
    }

    .btn {
        background-color: transparent;
        border-radius   : 0;
        width           : 35px;
        line-height     : 57px;
        color           : var(--global--color-white);
        font-size       : 28px;
        font-weight     : 400;
        text-align      : right;
        margin-left     : 0;
        padding         : 0;
    }
}

/* Module SideNav */
.module-sidenav {
    .module-icon {
        @include centerd(110px, 80px, var(--global--color-primary));
        border-right: 1px solid #404040;

        @media (min-width: 992px) and (max-width: 1399.98px) {
            width: 60px;
        }

        @media #{$minLarge} {
            height: 110px;
        }

        @media #{$maxTablet} {
            width: 80px;
        }
    }
}

/* Module Cart */
.module-cart {
    height       : 80px;
    display      : flex;
    align-items  : center;
    padding-right: 40px;
    margin-right : 40px;

    @media (min-width:992px) and (max-width:1400px) {
        margin-right : 20px;
        padding-right: 20px;
    }

    @media #{$minLarge} {
        height      : 110px;
        border-right: 1px solid rgba(234, 234, 234, 0.149);
    }

    @media #{$maxTablet} {
        height       : 80px;
        margin-right : 30px;
        padding-right: 0;
    }

    .module-icon {
        position: relative;

        i {
            color     : var(--global--color-heading);
            transition: 0.3s ease-in-out;
            font-size : 16px;
            position  : relative;
            cursor    : pointer;
        }
    }

    .module-cart-warp {
        position        : absolute;
        box-shadow      : 0px 5px 83px 0px rgba(3, 43, 86, 0.13);
        top             : 100%;
        left            : -150px;
        width           : 290px;
        background-color: var(--global--color-white);
        padding         : 40px;
        border-radius   : 0 0 6px 6px;
        opacity         : 0;
        visibility      : hidden;
        transform       : translateY(40px);
        transition      : 500ms;

        @media #{$maxTablet} {
            left: -250px;
        }

        @media #{$maxSmall} {
            left   : -160px;
            padding: 30px;
        }

        @media #{$minTablet} {
            width: 330px;
        }

        .cart-overview {
            margin-bottom: 0;
            width        : 100%;

            li {
                margin-bottom  : 30px;
                position       : relative;
                display        : flex;
                align-items    : center;
                justify-content: space-between;

                &:last-child {
                    margin-bottom: 25px;
                }

                .cart-cancel {
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    color           : var(--global--color-white);
                    font-size       : 7px;
                    flex-shrink     : 0;
                    border-radius   : 50%;
                    width           : 20px;
                    height          : 20px;
                    background-color: var(--global--color-heading);
                    margin-left     : 15px;

                    &:hover {
                        background-color: var(--global--color-primary);
                    }
                }

                img {
                    border-radius: 4px;
                    flex-shrink  : 0;
                    width        : 60px;
                    height       : 60px;
                    margin-right : 15px;
                }

                .product-meta {
                    flex-grow: 1;

                    h5 {
                        color         : var(--global--color-heading);
                        font-family   : var(--global--font-heading);
                        font-size     : 15px;
                        text-transform: capitalize;
                        line-height   : 1;
                        font-weight   : 700;
                        margin-bottom : 8px;
                    }

                    p {
                        font-family  : var(--global--font-body);
                        color        : var(--global--color-primary);
                        font-size    : 15px;
                        line-height  : 20px;
                        font-weight  : 700;
                        margin-bottom: 0;
                    }
                }
            }

        }

        span {
            font-family   : var(--global--font-heading);
            color         : #9aaabb;
            font-size     : 17px;
            font-weight   : 500;
            line-height   : 22px;
            display       : inline-block;
            text-transform: capitalize;
            margin-bottom : 24px;

            .total-price {
                font-style: normal;
            }
        }

        .cart--control {
            .btn {
                height: 60px;
                width : 100%;
            }
        }
    }

    &.module-active {

        .module-cart-warp {
            opacity   : 1;
            visibility: visible;
            transform : translateY(0);
        }
    }

}

/* Module Contact */
.module-contact {
    display    : flex;
    align-items: center;

    @media #{$maxLarge} {
        flex-direction: column;
        width         : 100%;
    }

    .btn {
        width  : 162px;
        height : 50px;
        padding: 0 10px;

        @media #{$maxLarge} {
            display: flex;
            width  : 100%;
        }
    }
}

/* Module Phone */
.module-phone {
    display     : flex;
    align-items : center;
    position    : relative;
    margin-right: 40px;

    @media (min-width:992px) and (max-width:1400px) {
        margin-right: 20px;
    }

    @media (min-width:992px) and (max-width:1150px) {
        display: none;
    }

    .module-icon {
        margin-right: 10px;

        svg {
            color     : var(--global--color-heading);
            transition: 0.3s ease-in-out;
            display   : inline-block;
        }
    }

    .module-body {
        flex-shrink: 0;

        span {
            font-family   : var(--global--font-body);
            font-size     : 13px;
            font-weight   : 400;
            color         : var(--global--color-gray-darken);
            text-transform: capitalize;
        }

        a {
            display    : flex;
            align-items: center;
            color      : var(--global--color-heading);
            font-family: var(--global--font-body);
            font-size  : 18px;
            line-height: 22px;
            font-weight: 500;
            transition : 0.3s ease-in-out;

            &:hover {
                color: var(--global--color-primary);
            }
        }
    }

    &:hover {
        .module-icon {
            svg {
                color: var(--global--color-primary);
            }
        }

        .module-body {
            a {
                color: var(--global--color-primary);
            }
        }
    }

    &.module-phone-2 {
        @media (min-width:992px) and (max-width:1199.98px) {
            display: none;
        }

        .module-icon {
            @include centerd(53px, 53px, transparent);
            border       : 2px solid var(--global--color-white);
            border-radius: 50%;
            margin-right : 15px;
            transition   : 0.3s ease-in-out;
        }

        &:hover {
            .module-icon {
                background-color: var(--global--color-white);

                svg {
                    color: var(--global--color-heading);
                }
            }
        }
    }
}

/* Module Language */
.module-language {
    display    : inline-flex;
    align-items: center;
    position   : relative;
    height     : 100%;

    &.module-active,
    &:hover {
        .lang-list {
            opacity   : 1;
            visibility: visible;
        }
    }

    .selected {
        display    : flex;
        align-items: center;

        img {
            margin-right: 10px;
        }

        span {
            @include text(var(--global--font-body), 13px, 21px, 400, var(--global--color-body), 0px);
            text-transform: capitalize;
            margin-right  : 5px;
            cursor        : pointer;
        }

        i {
            font-size: 11px;
            color    : var(--global--color-body);
        }
    }

    .lang-list {
        background-color: var(--global--color-white);
        color           : var(--global--color-heading);
        position        : absolute;
        top             : 100%;
        padding         : 6px 22px;
        box-shadow      : 0px 5px 41.5px rgba(9, 29, 62, 0.11);
        z-index         : 5555;
        left            : -22px;
        visibility      : hidden;
        opacity         : 0;
        transition      : 0.3s ease-in-out;
        border-radius   : 0 0 8px 8px;

        @media (min-width:992px) and (max-width:1200px) {
            top: 140%;
        }

        ul {
            margin-bottom: 0;

            li {
                display    : flex;
                align-items: center;

                &:last-of-type {
                    a {
                        border-bottom: none;

                        &::after {
                            content: none;
                        }
                    }
                }

                img {
                    margin-right: 10px;
                }

                a {
                    font-size     : 14px;
                    display       : block;
                    color         : var(--global--color-body);
                    text-transform: capitalize;
                    border-bottom : 1px solid #eaeaea;
                    padding       : 8px 0px;
                    cursor        : pointer;
                    transition    : 0.3s ease;
                    position      : relative;

                    &::after {
                        content         : '';
                        position        : absolute;
                        bottom          : 0;
                        left            : 0;
                        height          : 1px;
                        width           : 0px;
                        transition      : 0.3s ease;
                        background-color: transparent;
                    }

                    &:hover {
                        color: var(--global--color-primary);

                        &::after {
                            width           : 100%;
                            background-color: var(--global--color-primary);
                        }
                    }
                }
            }
        }
    }
}

/* Fixed Nav */
.navbar-fixed {
    .module-language {

        span,
        i {
            color: var(--global--color-heading);
        }
    }
}

/* Module Social */
.module-social {
    line-height: 50px;
    height     : 50px;
    display    : flex;
    align-items: center;

    a {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        i {
            @include centerd(20px, 20px, var(--global--color-primary));
            color        : var(--global--color-heading);
            font-size    : 12px;
            border-radius: 50%;
            transition   : 300ms ease-in-out;

            &:hover {
                transform: translateY(-5px);
            }
        }
    }

    &.module-social-2 {
        line-height : 70px;
        height      : 70px;
        border-right: 2px solid #292929;
        margin-right: 0;

        &:last-child {
            margin-right: 0;
        }

        a {
            color           : var(--global--color-white);
            background-color: transparent;
            margin-right    : 20px;
            width           : auto;
            height          : auto;
            border-radius   : 0;

            &:hover {
                color    : var(--global--color-primary);
                transform: scale(1);
            }
        }
    }
}