/*------------------------------------*\
    #Industries
\*------------------------------------*/
.industries {
    position      : relative;
    padding-top   : 130px;
    padding-bottom: 0px;

    @media #{$maxTablet} {
        padding-top   : 65px;
        padding-bottom: 0px;
    }

    .industries-overlay {
        position     : absolute;
        top          : 50%;
        left         : 50%;
        transform    : translate(-50%, -50%);
        width        : calc(100% - 3%);
        border-radius: 12px;
        height       : 100%;
        z-index      : 1;

        @media #{$noteBook} {
            width: calc(100% - 2%);
        }

        @media #{$maxSmall} {
            width        : 100%;
            border-radius: 8px 8px 0 0;
        }

        @media screen and (min-width: 1400px) {
            width: calc(100% - 5%);
        }
    }

    .industries-content {
        position: relative;
        z-index : 2;
    }

    .actions-holder {
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            justify-content: center;
        }

        @media #{$maxSmall} {
            flex-direction: column;
        }

        .btn {
            width     : 202px;
            height    : 68px;
            box-shadow: 0px 3px 63px 0px rgba(6, 52, 100, 0.11);
            padding   : 0 25px;
        }
    }

    .industries-note-wrapper {
        .industries-note {
            display    : flex;
            align-items: flex-start;

            @media #{$maxTablet} {
                justify-content: center;
                text-align     : center;
                max-width      : 500px;
                margin-left    : auto;
                margin-right   : auto;
            }

            @media #{$maxSmall} {
                flex-direction: column;
                align-items   : center
            }

            .rating {
                display     : flex;
                align-items : center;
                margin-right: 40px;
                margin-top  : 7px;

                @media #{$noteBook} {
                    margin-right: 20px;
                }

                @media #{$maxSmall} {
                    margin-right : 0;
                    margin-top   : 0;
                    margin-bottom: 20px;
                }

                i {
                    font-size   : 12px;
                    color       : var(--global--color-primary);
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .note {
                @media #{$maxTablet} {
                    max-width: unset;
                    margin   : 0;
                }
            }
        }
    }

    &.industries-1 {
        padding-bottom: 73px;

        @media #{$maxTablet} {
            padding-bottom: 15px;
        }

        .heading-wrapper {
            @media #{$minLarge} {
                height         : 500px;
                display        : flex;
                flex-direction : column;
                justify-content: space-between;
            }

            @media #{$maxTablet} {
                margin-bottom: 70px;
            }
        }

        .industries-wrapper {
            position     : relative;
            margin-bottom: 62px;

            @media #{$minLarge} {
                margin-bottom: 102px;
                height       : 500px;
            }

            .industries-holder {
                @media #{$minLarge} {
                    position : absolute;
                    right    : 0;
                    top      : 0;
                    max-width: 60%;
                }
            }
        }
    }

    &.industries-2 {
        padding-top   : 129px;
        padding-bottom: 90px;

        @media #{$maxTablet} {
            padding-top   : 63px;
            padding-bottom: 30px;
        }

        .industry-panel {
            margin-bottom: 40px;
        }
    }
}

.industry-panel {
    position: relative;

    @media #{$maxTablet} {
        max-width: 370px;
        margin   : 0 auto;
    }

    &:hover {
        .industry-content {
            .industry-more {
                width       : 170px;
                height      : 170px;
                border-color: rgba(255, 255, 255, 0.5);

                i {
                    transform: scale(0);
                }
            }

            .industry-body {
                .industry-name {
                    bottom: 43px;
                }

                .industry-desc {
                    bottom   : 50%;
                    transform: translateY(50%);
                }
            }
        }
    }

    .industry-img {
        position: relative;

        &::before {
            content: '';
            @include overlay(linear-gradient(180deg, rgba(0, 0, 0, 0.25) 55%, rgba(0, 0, 0, 0.85) 100%));
            z-index   : 2;
            transition: 0.35s ease-in-out;
        }

        img {
            object-fit: cover;
            width     : 100%;
            height    : 619px;
        }
    }

    .industry-content {
        @include overlay(transparent);
        display    : flex;
        align-items: flex-end;
        padding    : 42px 50px;
        z-index    : 5;
        direction  : ltr;

        .industry-more {
            position : absolute;
            top      : 50%;
            left     : 50%;
            transform: translate(-50%, -50%);
            @include centerd(80px, 80px, transparent);
            border-radius: 50%;
            border       : 2px solid var(--global--color-white);
            transition   : 0.3s ease-in-out;

            i {
                @include centerd(58px, 58px, var(--global--color-primary));
                border-radius: 50%;
                color        : var(--global--color-heading);
                font-size    : 12px;
                transition   : 0.3s ease-in-out;
            }
        }

        .industry-body {
            text-align: left;

            .industry-name {
                position      : absolute;
                left          : 0px;
                bottom        : 161px;
                font-family   : var(--global--font-heading);
                font-size     : 22px;
                line-height   : 30px;
                font-weight   : 700;
                margin-bottom : 0px;
                text-transform: capitalize;
                max-width     : 90%;
                transition    : 0.3s ease-in-out;
                padding       : 0 50px;
                z-index       : 6;

                @media #{$maxLarge} {
                    padding-left : 25px;
                    padding-right: 25px;
                }

                a {
                    color: var(--global--color-white);
                }
            }

            .industry-desc {
                transition: 0.3s ease-in-out;
                position  : absolute;
                left      : 0px;
                bottom    : 43px;
                transform : translateY(0);
                padding   : 0 50px;
                z-index   : 6;

                @media #{$maxLarge} {
                    padding-left : 25px;
                    padding-right: 25px;
                }

                @media screen and (min-width:1400px) {
                    max-width: 85%;
                }

                p {
                    font-family  : var(--global--font-body);
                    font-size    : 16px;
                    line-height  : 26px;
                    font-weight  : 400;
                    color        : #f9f9f9;
                    margin-bottom: 0;
                }
            }
        }
    }

    &.industry-panel-2 {
        &:hover {
            .industry-content {
                .industry-body {
                    .industry-desc {
                        opacity   : 1;
                        visibility: visible;
                        transform : translateY(50%);
                    }
                }
            }
        }

        .industry-img {
            img {
                height: 500px;
            }
        }

        .industry-content {
            .industry-more {
                width : auto;
                height: auto;
                border: 0;

                i {
                    width : 68px;
                    height: 68px;
                }
            }

            .industry-body {
                .industry-name {
                    bottom: 43px;

                    @media #{$noteBook} {
                        padding-right: 60px;
                    }

                    @media #{$minXlarge} {
                        padding-right: 75px
                    }
                }

                .industry-desc {
                    opacity   : 0;
                    visibility: hidden;
                    bottom    : 50%;
                    transform : translateY(calc(50% + 30px));

                    &::before {
                        content  : '';
                        position : absolute;
                        top      : 50%;
                        right    : 50px;
                        transform: translate(0%, -50%);
                        @include centerd(170px, 170px, transparent);
                        border-radius: 50%;
                        border       : 2px solid rgba(255, 255, 255, 0.5);
                        transition   : 0.3s ease-in-out;

                        @media #{$maxLarge} {
                            right: 25px;
                        }
                    }
                }
            }
        }
    }
}

.industry-single {
    padding-top   : 110px;
    padding-bottom: 110px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 30px;
    }

    .sidebar-industries {
        @media #{$maxTablet} {
            margin-top: 70px;
        }
    }

    .industry-entry {
        .entry-content {
            .entry-infos {
                margin-bottom: 66px;

                @media #{$maxTablet} {
                    text-align: center;
                }

                .entry-heading {
                    font-size     : 24px;
                    line-height   : 1;
                    font-weight   : 700;
                    margin-bottom : 21px;
                    color         : var(--global--color-heading-darken);
                    text-transform: capitalize;
                }

                .entry-desc {
                    font-size    : 17px;
                    line-height  : 27px;
                    margin-bottom: 25px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &.entry-introduction {
                    margin-bottom: 32px;

                    .entry-img {
                        margin-bottom: 33px;

                        img {
                            width     : 100%;
                            object-fit: cover;
                            height    : 560px;
                        }
                    }
                }

                &.entry-banners {
                    margin-bottom: 57px;

                    .entry-img {
                        @media #{$maxSmall} {
                            margin-bottom: 43px;
                        }

                        img {
                            width     : 100%;
                            object-fit: cover;
                            height    : 480px;
                        }
                    }

                    .panels-holder {
                        .banner-panel {
                            &:first-child {
                                padding-top: 0;
                                border-top : 0;
                            }

                            &:last-child {
                                padding-bottom: 0;
                                border-bottom : 0;
                            }

                            @media #{$minTablet} {
                                text-align: left;
                            }
                        }
                    }
                }

                &.entry-why {

                    .entry-desc {
                        margin-bottom: 32px;
                    }
                }

                &.entry-benefits {
                    margin-bottom: 66px;

                    .entry-desc {
                        margin-bottom: 32px;
                    }
                }

                &.entry-cases {
                    margin-bottom: 0;

                    .entry-heading {
                        margin-bottom: 29px;
                    }

                    .owl-stage-outer {
                        overflow: visible;

                        .owl-item {
                            opacity: 0;

                            &.active {
                                opacity: 1;
                            }
                        }
                    }

                    .owl-dots {
                        @media #{$minLarge} {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}