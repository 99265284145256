/*------------------------------------*\
    #Mixins
\*------------------------------------*/
@mixin bp-large {
    @media only screen and (max-width: 60em) {
        @content;
    }
}

@mixin bp-medium {
    @media only screen and (max-width: 40em) {
        @content;
    }
}

@mixin bp-small {
    @media only screen and (max-width: 30em) {
        @content;
    }
}

@mixin transition($trans) {
    -webkit-transition: $trans;
    -moz-transition   : $trans;
    -ms-transition    : $trans;
    -o-transition     : $trans;
    transition        : $trans;
}

@mixin rounded ($r) {
    -webkit-border-radius: $r;
    -moz-border-radius   : $r;
    -o-border-radius     : $r;
    border-radius        : $r;
}

// Add overlay  over elements
@mixin overlay($background) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $background;
}

@mixin centerd($width, $height, $background) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $width;
    height: $height;
    background: $background;
}
@mixin text($fontFamily, $fontSize, $lineHeight, $fontWeight, $color, $marginBottom) {
    font-family: $fontFamily;
    font-size: $fontSize;
    line-height: $lineHeight;
    font-weight: $fontWeight;
    color: $color;
    margin-bottom: $marginBottom;
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}