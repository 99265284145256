/*------------------------------------*\
    #Contacts
\*------------------------------------*/

.contact {
    position: relative;

    .contact-action {
        display      : flex;
        align-items  : center;
        margin-bottom: 83px;

        @media #{$maxTablet} {
            justify-content: center;
        }

        @media #{$maxSmall} {
            display       : flex;
            flex-direction: column;
            align-items   : center;
            margin-bottom : 50px;
        }

        .btn {
            width       : 190px;
            height      : 68px;
            margin-right: 30px;

            &:last-child {
                width       : 172px;
                padding     : 0 20px;
                margin-right: 0;
            }

            @media #{$maxSmall} {
                margin-right : 0;
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .contact-overlay {
        position : absolute;
        top      : 50%;
        left     : 50%;
        transform: translate(-50%, -50%);
        width    : calc(100% - 3%);
        height   : 100%;
        z-index  : 1;

        @media #{$noteBook} {
            width: calc(100% - 2%);
        }

        @media #{$maxSmall} {
            width: 100%;
        }

        @media screen and (min-width: 1400px) {
            width: calc(100% - 5%);
        }
    }

    .contact-content {
        position: relative;
        z-index : 2;
    }

    &.contact-1 {
        padding-top   : 110px;
        padding-bottom: 110px;
        overflow      : visible;
        position      : relative;
        z-index       : 5;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }

        .advantages-list {
            @media #{$maxTablet} {
                margin-bottom: 70px;
            }
        }
    }

    &.contact-2 {
        padding-top   : 110px;
        padding-bottom: 110px;
        overflow      : visible;
        position      : relative;
        z-index       : 5;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }

        .heading {
            .heading-title {
                margin-bottom: 41px;
            }
        }

        .contact-panel {
            .heading {
                flex-grow: 0;
            }

            .contact-card {
                flex-grow: 0;

                @media #{$maxTablet} {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.contact-3 {
        padding : 0;
        overflow: visible;
        position: relative;
        z-index : 5;
    }
}

.contact-panel {
    display : flex;
    position: relative;

    @media #{$maxTablet} {
        flex-direction: column;
    }

    @media #{$minLarge} {
        justify-content: space-between;
    }

    .heading {
        z-index: 4;

        @media#{$minLarge} {
            flex-basis   : 41%;
            flex-shrink  : 0;
            margin-bottom: 0;
            transform    : translateY(-11px);
            margin-bottom: -11px;
        }

        @media #{$noteBook} {
            flex-basis: 46%;
        }
    }

    .contact-card {
        z-index: 4;

        @media #{$minLarge} {
            flex-basis : 53%;
            flex-shrink: 0;
        }

        @media #{$noteBook} {
            flex-basis: 50%;
        }

        @media #{$maxTablet} {
            margin-bottom: 50px;
        }
    }

    &.contact-panel-2 {
        .contact-card {
            clip-path: none;

            &.contact-card-left {
                box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);

                .contact-body {
                    padding: 57px 30px 60px;

                    @media #{$noteBook} {
                        padding-left : 25px;
                        padding-right: 25px;
                    }

                    @media screen and (min-width: 1400px) {
                        padding-left : 60px;
                        padding-right: 60px;
                    }

                    @media #{$maxSmall} {
                        padding: 37px 25px 30px;
                    }

                    .pricing-table {
                        margin-bottom: 0;
                    }
                }
            }

            &.contact-card-right {
                box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);

                .contact-body {
                    padding: 57px 50px 60px;

                    @media #{$noteBook} {
                        padding-left : 40px;
                        padding-right: 40px;
                    }

                    @media screen and (min-width: 1400px) {
                        padding-left : 100px;
                        padding-right: 100px;
                    }

                    @media #{$maxSmall} {
                        padding: 37px 25px 30px;
                    }

                    .custom-radio-group {
                        margin-bottom: 14px;
                    }
                }
            }

            .contact-body {
                .card-desc {
                    margin-bottom: 34px;
                }

                .btn {
                    i {
                        background-color: var(--global--color-white);
                        color           : var(--global--color-heading);
                    }
                }
            }
        }
    }

    &.contact-panel-3 {
        transform       : translateY(-145px);
        margin-bottom   : -145px;
        padding         : 60px 70px 60px;
        background-color: var(--global--color-white);
        box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
        z-index         : 5;

        @media #{$noteBook} {
            padding-left : 40px;
            padding-right: 40px;
        }

        @media #{$maxSmall} {
            padding-left : 25px;
            padding-right: 25px;
        }

        .contact-card {
            padding-top   : 0;
            padding-bottom: 0;
            padding-right : 0;
            padding-left  : 70px;
            box-shadow    : none;
            clip-path     : none;

            &::after {
                content: unset;
            }

            @media #{$maxTablet} {
                padding-left : 0;
                margin-bottom: 0;
            }

            @media #{$noteBook} {
                padding-left: 0;
            }

            .contact-body {
                padding   : 0;
                box-shadow: none;

                .card-desc {
                    margin-bottom: 33px;
                }

                .form-control {
                    border        : 2px solid #eaeaea;
                    padding       : 20px;
                    height        : 55px;
                    border-radius : 3px;
                    margin-bottom : 30px;
                    color         : #616161;
                    font-size     : 14px;
                    font-weight   : 400;
                    text-transform: capitalize;

                    &::-webkit-input-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }

                    &:-moz-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }

                    &:-ms-input-placeholder {
                        color         : #616161;
                        font-size     : 14px;
                        font-weight   : 400;
                        text-transform: capitalize;
                    }
                }

                textarea {
                    &.form-control {
                        height       : 120px;
                        margin-bottom: 40px;
                    }
                }

                .nice-select {
                    &::after {
                        right: 20px;
                    }
                }

                .btn {
                    height    : 68px;
                    width     : 190px;
                    padding   : 0 20px;
                    box-shadow: 0px 3px 63px 0px rgba(6, 52, 100, 0.11);

                    @media #{$maxTablet} {
                        width: 100%;
                    }
                }
            }

            .contact-result {
                margin-top: 0;
            }
        }
    }
}

.contact-card {
    background-color: var(--global--color-white);
    position        : relative;
    clip-path       : polygon(82% 0, 100% 14%, 100% 100%, 0 100%, 0 0);

    .contact-body {
        background-color: var(--global--color-white);
        height          : max-content;
        padding         : 47px 50px 0px;
        box-shadow      : 0px 5px 83px 0px rgba(3, 43, 86, 0.12);
        position        : relative;
        z-index         : 2;

        @media #{$noteBook} {
            padding: 37px 25px 30px;
        }

        @media #{$maxSmall} {
            padding: 37px 25px 30px;
        }

        .card-heading {
            font-family   : var(--global--font-heading);
            font-size     : 24px;
            line-height   : 1;
            font-weight   : 700;
            text-transform: capitalize;
            color         : #1b1a1a;
            margin-bottom : 20px;

            @media #{$maxTablet} {
                text-align : center;
                line-height: 1.4;
            }
        }

        .card-desc {
            font-family  : var(--global--font-body);
            font-weight  : 400;
            font-size    : 15px;
            line-height  : 25px;
            color        : var(--global--color-body);
            margin-bottom: 34px;

            @media #{$maxTablet} {
                text-align: center;
            }

            a {
                position      : relative;
                font-weight   : 700;
                color         : var(--global--color-heading);
                transition    : 0.3s ease-in-out;
                text-transform: capitalize;
                display       : inline-flex;
                align-items   : center;

                &:hover {
                    &::before {
                        width: 80%;
                    }
                }

                &::before {
                    content         : '';
                    position        : absolute;
                    bottom          : 1px;
                    left            : 50%;
                    transform       : translateX(-50%);
                    background-color: var(--global--color-heading);
                    transition      : 0.3s ease-in-out;
                    height          : 2px;
                    width           : 100%;
                    ;
                }
            }
        }

        form {
            margin-bottom: 0;
        }

        .custom-radio-group {
            margin-bottom: 24px;
        }

        .btn {
            height         : 60px;
            justify-content: space-between;
            border-radius  : 3px 3px 0 0;

            @media #{$minTablet} {
                height : 78px;
                padding: 0 40px;
            }

            &:hover {
                i {
                    background-color: var(--global--color-white);
                    color           : var(--global--color-heading);
                }
            }

            i {
                @include centerd(32px, 32px, var(--global--color-heading));
                color        : var(--global--color-white);
                border-radius: 50%;
                margin-left  : 10px;
                transition   : 0.3s ease-in-out;
                font-size    : 9px;
            }
        }

        .contact-result {
            margin-top: 10px;
            text-align: center;
        }
    }

    &.contact-card-2 {
        filter          : drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.12));
        clip-path       : none;
        background-color: transparent;

        &::after {
            content: unset;
        }

        @media #{$maxTablet} {
            margin-bottom: 70px;
        }

        .contact-body {
            clip-path       : polygon(85% 0, 100% 10%, 100% 100%, 0 100%, 0 0);
            padding-bottom  : 32px;
            background-color: var(--global--color-white);
        }

        .custom-radio-group {
            flex-wrap: wrap;
        }

        .contact-note {
            margin-top: 24px;

            p {
                font-size    : 14px;
                line-height  : 25px;
                margin-bottom: 0;
            }
        }

        .block-info {
            &:first-child {
                border-bottom: 2px solid #eaeaea;
                margin-bottom: 36px;
            }

            .card-desc {
                font-size    : 14px;
                font-weight  : 700;
                color        : var(--global--color-heading-light);
                margin-bottom: 23px;
            }
        }
    }
}


.img-card-holder {
    display       : flex;
    flex-direction: column;

    .img-card-wrapper {
        position : relative;
        overflow : visible;
        filter   : drop-shadow(0px 5px 83px rgba(27, 26, 26, 0.12));
        display  : flex;
        flex-grow: 1;

        .img-card {
            clip-path     : polygon(86% 0, 100% 11%, 100% 100%, 0 100%, 0 0);
            flex-shrink   : 0;
            height        : auto;
            padding       : 42px 50px 47px;
            display       : flex;
            align-items   : flex-end;
            text-align    : center;
            display       : flex;
            flex-direction: column;
            flex-grow     : 1;

            @media #{$minLarge} {
                text-align: left;
            }

            @media #{$noteBook} {
                padding-left : 25px;
                padding-right: 25px;
            }

            @media #{$maxSmall} {
                padding-left : 20px;
                padding-right: 20px;
            }

            @media #{$maxTablet} {
                margin-bottom: 43px
            }

            &:before {
                content   : "";
                display   : inline-block;
                height    : 100%;
                left      : 0;
                position  : absolute;
                top       : 0;
                width     : 100%;
                z-index   : -1;
                background: rgba(254, 208, 0, 0.95);
            }

            .card-content {
                max-width      : 100%;
                flex-direction : column;
                display        : flex;
                flex-grow      : 1;
                justify-content: space-between;
                position       : relative;
                z-index        : 3;

                .content-top {
                    @media #{$maxTablet} {
                        margin-bottom: 30px;
                    }

                    .heading {
                        transform: translate(0);
                    }
                }

                .content-bottom {
                    .contact-infos {
                        margin-bottom: 27px;

                        .contact-info {
                            display      : flex;
                            align-items  : center;
                            margin-bottom: 14px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            svg {
                                color       : var(--global--color-heading-light);
                                font-size   : 16px;
                                margin-right: 15px;
                            }

                            a,
                            p {
                                font-family   : var(--global--font-body);
                                line-height   : 22px;
                                font-size     : 14px;
                                font-weight   : 700;
                                margin-bottom : 0;
                                color         : var(--global--color-heading-light);
                                text-transform: capitalize;
                            }

                            a {
                                &:hover {
                                    color: var(--global--color-heading);
                                }
                            }
                        }
                    }

                    .btn {
                        width : 100%;
                        height: 68px;

                        @media #{$maxTablet} {
                            display: inline-flex;
                        }
                    }
                }
            }
        }
    }
}



.map {
    position: relative;
    height  : 560px;

    iframe {
        position: absolute;
        top     : 0;
        left    : 0;
        right   : 0;
        height  : 100%;
        width   : 100%;

        &.map-gray {
            filter: brightness(104%) contrast(99%) saturate(0%) blur(0px) hue-rotate(0deg);
        }
    }
}

.request-quote {
    padding: 110px 0 130px;

    @media #{$maxTablet} {
        padding: 70px 0;
    }
}