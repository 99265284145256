/*------------------------------------*\
    #Projects Gallery
\*------------------------------------*/
.projects-gallery {
    padding: 110px 0;

    @media #{$maxTablet} {
        padding: 70px 0;
    }

    .project-panel {
        position     : relative;
        margin-bottom: 40px;
        transition   : 0.3s ease-in-out;

        @media #{$maxTablet} {
            max-width: 370px;
            margin   : 0 auto 40px;
        }

        &:hover {
            .project-img {
                &::before {
                    opacity   : 1;
                    visibility: visible;
                }

                .project-zoom {
                    opacity   : 1;
                    visibility: visible;
                    transform : translate(-50%, -50%) scale(1);
                }
            }
        }

        .project-img {
            &::before {
                content: '';
                @include overlay(linear-gradient(180deg, rgba(0, 0, 0, 0.1) 55%, rgba(0, 0, 0, 0.35) 100%));
                z-index   : 2;
                transition: 0.3s ease-in-out;
                opacity   : 0;
                visibility: hidden;
            }

            img {
                border-radius: 6px;
                object-fit   : cover;
                height       : 267px;
                width        : 100%;
            }

            .project-zoom {
                position       : absolute;
                top            : 50%;
                left           : 50%;
                transform      : translate(-50%, -50%) scale(0);
                z-index        : 2;
                display        : flex;
                justify-content: center;
                align-items    : center;
                opacity        : 0;
                visibility     : hidden;
                transition     : 0.4s ease-in-out;

                i {
                    @include centerd(68px, 68px, var(--global--color-white));
                    border-radius: 50%;
                    color        : var(--global--color-heading);
                    font-size    : 12px;
                    transition   : 0.3s ease-in-out;
                }

                a {
                    position: absolute;
                    top     : 0;
                    left    : 0;
                    right   : 0;
                    width   : 100%;
                    height  : 100%;
                    z-index : 2;
                }
            }
        }
    }

    .btn {
        display: inline-flex;
        width  : 150px;
        height : 68px;
    }
}