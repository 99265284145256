/*------------------------------------*\
#How Works
\*------------------------------------*/
.how-works {
    padding-top   : 0px;
    padding-bottom: 250px;
    position      : relative;
    overflow      : visible;
    z-index       : 5;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 70px;
    }

    .how-works-wrapper {
        position: relative;
    }

    .how-works-action {
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            justify-content: center;
        }

        @media #{$maxSmall} {
            display       : flex;
            flex-direction: column;
            align-items   : center;
        }

        .btn {
            width       : 240px;
            height      : 68px;
            margin-right: 30px;
            padding     : 0 15px;

            @media #{$maxSmall} {
                margin-right : 0;
                margin-bottom: 25px;
                height       : 60px;
                width        : 220px;
            }

            @media #{$noteBook} {
                width       : 220px;
                margin-right: 15px;
            }

            &:last-child {
                width       : 238px;
                margin-right: 0;

                @media #{$noteBook} {
                    width: 220px;
                }

                @media #{$maxSmall} {
                    margin-bottom: 0;
                }
            }
        }
    }

    .how-works-img-holder {
        display        : flex;
        justify-content: center;

        @media #{$minLarge} {
            justify-content: flex-end;
            position       : absolute;
            top            : 0;
            left           : 0;
            max-width      : 41%;
            z-index        : 7;
        }

        .how-works-img {
            position: relative;

            @media #{$maxTablet} {
                margin: 0 auto 50px;
            }

            img {
                width        : 100%;
                border-radius: 0px;
                height       : 890px;
                object-fit   : cover;
                position     : relative;
                z-index      : 1;

                @media #{$maxTablet} {
                    max-width: 550px;
                    height   : 550px;
                    margin   : 0 auto
                }

                @media #{$maxSmall} {
                    max-width: 100%;
                }
            }

            .edge-shape {
                position: absolute;
                right   : 0;
                top     : 0;
                z-index : 5;
                width   : 110px;
                height  : 110px;
            }

            .btn--video {
                width           : 128px;
                height          : 128px;
                background-color: var(--global--color-white);
                position        : absolute;
                top             : 50%;
                left            : 50%;
                transform       : translate(-50%, -50%);
                box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
                z-index         : 5;
            }
        }
    }

    .work-steps-wrapper {
        @media #{$minLarge} {
            transform    : translate(-79px, 74px);
            margin-bottom: -100px;
            position     : relative;
            z-index      : 10;
        }

        @media #{$noteBook} {
            transform: translate(-107px, 83px);
        }

        .work-step {
            display: flex;

            @media #{$maxSmall} {
                flex-direction: column;
            }

            &:first-child {
                .step-num {
                    border-top: 1px solid var(--global--color-primary);
                }

                .step-info {
                    border-top: 1px solid #eaeaea
                }
            }

            .step-num {
                width           : 150px;
                min-height      : 221px;
                background-color: var(--global--color-primary);
                flex-shrink     : 0;
                display         : flex;
                align-items     : center;
                justify-content : center;
                border-bottom   : 1px solid #eaeaea;

                @media #{$maxSmall} {
                    width        : 100%;
                    border-bottom: 0;
                    min-height   : 70px;
                }

                h3 {
                    font-family  : var(--global--font-heading);
                    font-weight  : 500;
                    font-size    : 60px;
                    color        : var(--global--color-white);
                    margin-bottom: 0;

                    @media #{$maxSmall} {
                        font-size: 40px
                    }
                }
            }

            .step-info {
                border-bottom  : 1px solid #eaeaea;
                display        : flex;
                flex-direction : column;
                justify-content: center;
                padding-left   : 118px;
                padding-right  : 30px;

                @media #{$minLarge} {
                    height: 221px;
                }

                @media #{$noteBook} {
                    padding-left : 117px;
                    padding-right: 0px;
                }

                @media #{$maxTablet} {
                    padding: 40px;
                }

                @media #{$maxSmall} {
                    padding   : 40px 25px;
                    text-align: center;
                }

                .step-title {
                    font-family  : var(--global--font-heading);
                    font-weight  : 700;
                    font-size    : 22px;
                    line-height  : 29px;
                    color        : var(--global--color-heading-light);
                    margin-bottom: 28px;
                }

                .step-desc {
                    font-family  : var(--global--font-body);
                    font-size    : 16px;
                    line-height  : 26px;
                    color        : var(--global--color-body);
                    margin-bottom: 0;
                }
            }
        }
    }

    &.how-works-2 {
        padding-top: 130px;

        @media #{$maxTablet} {
            padding-top: 70px;
        }
    }

    &.how-works-3 {
        padding-top: 260px;
        z-index    : 4;

        @media #{$maxTablet} {
            padding-top: 70px;
        }
    }
}