/*------------------------------------*\
#Highlights
\*------------------------------------*/
.highlights {
    &.highlights-1 {
        padding-top: 28px;

        .carousel-navs {
            .owl-nav {
                @media #{$minLarge} {
                    position: absolute;
                    right   : 0;
                    top     : -103px;
                }
            }
        }

        .note {
            position: relative;
            z-index : 5;

            @media #{$minLarge} {
                margin-top: 4px;
            }
        }
    }
}

.highlight-panel {
    background-color: var(--global-color-white);
    transition      : 0.3s ease-in-out;
    position        : relative;
    margin-bottom   : 40px;
    box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.09);

    @media #{$maxTablet} {
        max-width: 370px;
        margin   : 0 auto 40px;
    }

    @media #{$maxSmall} {
        margin: 0 auto 30px;
    }

    &:hover {
        box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);

        .highlight-img {
            &::before {
                opacity   : 1;
                visibility: visible;
            }

            .highlight-more {
                opacity   : 1;
                visibility: visible;
            }
        }
    }

    .highlight-img {
        position: relative;

        &::before {
            content: '';
            @include overlay(linear-gradient(225deg, rgba(0, 0, 0, 0.2) 55%, rgba(0, 0, 0, 0.35) 100%));
            z-index   : 2;
            transition: 0.3s ease-in-out;
            opacity   : 0;
            visibility: hidden;
        }

        img {
            width     : 100%;
            height    : 192px;
            object-fit: cover
        }

        .highlight-more {
            position : absolute;
            top      : 50%;
            left     : 50%;
            transform: translate(-50%, -50%);
            @include centerd(68px, 68px, transparent);
            border-radius: 50%;
            transition   : 0.3s ease-in-out;
            z-index      : 5;
            opacity      : 0;
            visibility   : hidden;

            i {
                @include centerd(68px, 68px, var(--global--color-white));
                border-radius: 50%;
                color        : var(--global--color-heading);
                font-size    : 11px;
                transition   : 0.3s ease-in-out;
            }
        }
    }

    .highlight-content {
        display         : flex;
        flex-direction  : column;
        padding         : 33px 40px 33px;
        background-color: var(--global--color-white);

        @media #{$noteBook} {
            padding-left : 25px;
            padding-right: 25px;
        }

        @media #{$maxTablet} {
            text-align: center;
        }

        .highlight-body {
            .highlight-name {
                font-family   : var(--global--font-heading);
                font-weight   : 700;
                font-size     : 20px;
                line-height   : 29px;
                text-transform: capitalize;
                transition    : 0.3s ease-in-out;
                position      : relative;
                margin-bottom : 16px;

                a {
                    color: var(--global--color-heading-light);

                    &:hover {
                        color: var(--global--color-primary);
                    }
                }
            }

            .highlight-desc {
                color        : var(--global--color-body);
                font-size    : 15px;
                line-height  : 25px;
                margin-bottom: 0px;
                transition   : 0.3s ease-in-out;
            }
        }
    }
}