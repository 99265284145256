/*------------------------------------*\
    #Accordion
\*------------------------------------*/
/* Accordion Base */
.accordion {
    .accordion-holder {
        border-top: 6px solid var(--global--color-primary);
    }

    .card,
    .card:first-of-type,
    .card:not(:first-of-type):not(:last-of-type) {
        padding      : 21px 0px 18px;
        box-shadow   : none;
        border       : 0;
        border-bottom: 2px solid #eaeaea;
        margin-bottom: 0px;
        border-radius: 0px;
        transition   : 0.3s ease-in-out;
        text-align   : left;

        &.active-acc {
            .card-heading {
                .card-link {
                    color: var(--global--color-heading);
                }
            }
        }

        .card-heading {
            padding-bottom: 4px;

            .card-link {
                font-family    : var(--global--font-body);
                font-size      : 19px;
                font-weight    : 700;
                color          : var(--global--color-heading);
                text-transform : capitalize;
                position       : relative;
                transition     : 0.3s ease-in-out;
                display        : flex;
                align-items    : center;
                justify-content: flex-start;
                padding-left   : 0;

                &:hover {
                    color: var(--global--color-heading);
                }

                &::before {
                    /* symbol for "opening" cards */
                    content    : "\f078";
                    flex-shrink: 0;
                    @include centerd(22px, 22px, var(--global--color-secondary));
                    border-radius   : 50%;
                    transition      : 0.3s ease-in-out;
                    font-family     : "Font Awesome 5 Free";
                    font-weight     : 700;
                    font-size       : 11px;
                    margin-right    : 20px;
                    color           : var(--global--color-heading);
                    background-color: var(--global--color-primary);
                }

                &.collapsed {
                    &::before {
                        /* symbol for "collapsed" cards */
                        transform       : rotate(-90deg);
                        color           : var(--global--color-white);
                        background-color: var(--global--color-heading);
                    }
                }
            }
        }

        .card-body {
            font-family: var(--global--font-body);
            color      : var(--global--color-body);
            padding    : 10px 40px 1px;
            font-size  : 15px;
            font-weight: 400;
            line-height: 25px;
        }
    }
}