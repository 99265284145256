/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

.btn {
    display        : flex;
    justify-content: center;
    align-items    : center;
    font-family    : var(--global--font-body);
    position       : relative;
    z-index        : 2;
    font-size      : 15px;
    font-weight    : 700;
    text-transform : capitalize;
    border         : none;
    width          : 170px;
    height         : 50px;
    transition     : 0.3s ease-in-out;
    border-radius  : 3px;
    color          : var(--global--color-white);
    padding        : 0 25px;

    &.no-shadow {
        box-shadow: none;
    }

    &:focus,
    &.active,
    &:active {
        box-shadow: none;
        outline   : none;
    }

    &::before {
        content         : '';
        position        : absolute;
        width           : calc(100%);
        height          : calc(100%);
        top             : 0px;
        left            : 0px;
        z-index         : -1;
        transform       : scaleX(0);
        transform-origin: right center;
        transition      : transform .24s cubic-bezier(.37, .31, .31, .9);
        border-radius   : inherit;
    }

    i {
        margin-left: 10px;
        font-size  : 10px;
        transition : 0.3s ease-in-out;
    }

    &:hover {
        &::before {
            transform       : scaleX(1);
            transform-origin: left center;
        }
    }
}

/* Button Primary */
.btn--primary {
    background-color: var(--global--color-primary);
    color           : var(--global--color-heading);

    i {
        color: var(--global--color-heading);
    }

    &::before {
        background-color: var(--global--color-heading);
    }

    &:active,
    &:focus,
    &:hover {
        color: var(--global--color-white);

        i {
            color: var(--global--color-white);
        }
    }

    &.btn--inversed {
        &::before {
            background-color: var(--global--color-white);
        }

        &:active,
        &:focus,
        &:hover {
            color: var(--global--color-heading);

            i {
                color: var(--global--color-heading);
            }
        }
    }
}

/* Button Secondary*/
.btn--secondary {
    background-color: var(--global--color-heading);
    color           : var(--global--color-white);

    i {
        color: var(--global--color-white);
    }

    &::before {
        background-color: var(--global--color-primary);
    }

    &:active,
    &:focus,
    &:hover {
        color: var(--global--color-heading);

        i {
            color: var(--global--color-heading);
        }
    }

    &.btn--inversed {
        &::before {
            background-color: var(--global--color-heading-darken);
        }

        &:active,
        &:focus,
        &:hover {
            color: var(--global--color-white);

            i {
                color: var(--global--color-heading);
            }
        }
    }
}

/* Button White */
.btn--white {
    background-color: var(--global--color-white);
    color           : var(--global--color-heading);

    &::before {
        background-color: var(--global--color-heading);
    }

    i {
        color: var(--global--color-heading);
    }

    &:active,
    &:focus,
    &:hover {
        color: var(--global--color-white);

        i {
            color: var(--global--color-white);
        }
    }
}

/* Button Transparent */
.btn--transparent {
    background-color: transparent;
    color           : var(--global--color-white);
    border          : 2px solid transparent;

    &::before {
        content: unset;
    }

    &:active,
    &:focus,
    &:hover {
        color: var(--global--color-white);
    }

    &.btn--primary {
        border-color: var(--global--color-primary);
        color       : var(--global--color-primary);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--global--color-primary);
            color           : var(--global--color-heading);
        }
    }

    &.btn--white {
        border-color: var(--global--color-white);

        i {
            color: var(--global--color-white);
        }

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-heading);
            border-color    : var(--global--color-primary);
            background-color: var(--global--color-primary);

            i {
                color: var(--global--color-heading-darken);
            }
        }
    }
}

/* Buttons Custom */
.btn--danger {
    background-color: #dd3333;
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-secondary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-secondary);
    }
}

.btn--success {
    background-color: #81d742;
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-secondary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-secondary);
    }
}

.btn--prime {
    background-color: #1e73be;
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-secondary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-secondary);
    }
}

.btn--purble {
    background-color: #8224e3;
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-secondary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-secondary);
    }
}

/* Button Fluid */
.btn--block {
    width: 100%;
}

/*Button Auto */

.btn--auto {
    width  : auto;
    padding: 0 20px;
}

/* Button Rounded */
.btn--rounded {
    border-radius: 50px;
}

/* Buttons Shadow */
.btn-shadow {
    box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
}

.btn-shadow-hover {

    &:hover,
    &:active,
    &:focus {
        box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
    }
}

/* Button Bordered */
.btn--bordered {
    background-color: var(--global--color-white);
    color           : var(--global--color-white);
    border          : 2px solid transparent;

    &::before {
        content: unset;
    }

    &.btn--primary {
        color       : var(--global--color-primary);
        border-color: var(--global--color-primary);

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-white);
            background-color: var(--global--color-primary);
            border-color    : var(--global--color-primary);
        }
    }

    &.btn--secondary {
        color       : var(--global--color-heading);
        border-color: #bcbcbc;

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-white);
            background-color: var(--global--color-heading);
            border-color    : var(--global--color-heading);
        }
    }

    &.btn--secondary-dark {
        color       : var(--global--color-heading);
        border-color: var(--global--color-heading);

        i {
            color: var(--global--color-heading);
        }

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-white);
            background-color: var(--global--color-heading);
            border-color    : var(--global--color-heading);

            i {
                color: var(--global--color-white);
            }
        }
    }

    &.btn--white {
        color       : var(--global--color-white);
        border-color: var(--global--color-white);
        background  : transparent;

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-primary);
            background-color: var(--global--color-white);
            border-color    : var(--global--color-white);
        }
    }
}

.btn--slide {
    :last-of-type {
        margin-left: -6px;
    }
}

.btn--slide-wide {
    width: 270px;

    @media screen and (max-width: 360px) {
        width: 240px;
    }
}

.btn--video {
    @include centerd(60px, 60px, transparent);
    border-radius: 50%;
    border       : 2px solid var(--global--color-white);

    i {
        color: var(--global--color-white);
    }
}

.btn--video-text {
    display    : flex;
    align-items: center;

    .icon-wrapper {
        @include centerd(68px, 68px, var(--global--color-white));
        border-radius: 50%;
        margin-right : 30px;
        position     : relative;

        &::after {
            content         : '';
            height          : 100%;
            width           : 100%;
            position        : absolute;
            top             : 0;
            left            : 0;
            right           : 0;
            background-color: transparent;
            border          : 1px solid var(--global--color-white);
            border-radius   : 100%;
            transform       : scale(1);
            opacity         : 1;
            animation       : pulse 1s ease-out 0s infinite;
            z-index         : -1;
        }
    }

    span {
        font-family   : var(--global--font-body);
        font-size     : 15px;
        font-weight   : 700;
        color         : var(--global--color-white);
        text-transform: capitalize;
    }
}


/* Buttons Sizes */
.btn-small {
    width      : 170px;
    height     : 41px;
    line-height: 41px;
}

.btn-super {
    width      : 170px;
    height     : 61px;
    line-height: 61px;
}