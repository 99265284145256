/*------------------------------------*\
    #Clients
\*------------------------------------*/

.clients {
    padding-top   : 0;
    padding-bottom: 0;

    .clients-note {
        text-align: center;

        @media #{$minLarge} {
            text-align: left;
        }

        @media #{$noteBook} {
            max-width: 100%;
        }

        @media #{$maxTablet} {
            margin   : 0 auto 40px;
            max-width: 80%;
        }

        @media #{$maxSmall} {
            max-width: 100%;
        }

        p {
            font-family  : var(--global--font-body);
            font-weight  : 700;
            font-size    : 14px;
            color        : #1e4873;
            line-height  : 25px;
            margin-bottom: 0;

            a {
                position      : relative;
                font-weight   : 700;
                color         : var(--global--color-heading);
                transition    : 0.3s ease-in-out;
                text-transform: capitalize;
                padding-bottom: 6px;

                &:hover {
                    &::before {
                        width: calc(100% - 20%);
                    }
                }

                &::before {
                    content         : '';
                    transition      : 0.3s ease-in-out;
                    position        : absolute;
                    bottom          : 0;
                    left            : 50%;
                    transform       : translateX(-50%);
                    width           : 100%;
                    height          : 2px;
                    background-color: var(--global--color-primary);
                }
            }
        }
    }

    .client {
        cursor      : pointer;
        max-width   : 150px;
        margin-right: auto;
        margin-left : auto;
        position    : relative;
        display     : flex;
        align-items : center;
        height      : 194px;
        opacity     : 1;
        transition  : 0.3s ease-in-out;

        &:hover {
            opacity: 0.5;
        }

        @media #{$noteBook} {
            width: 130px;
        }

        @media #{$maxTablet} {
            height: 140px;
        }

        a {
            position: absolute;
            width   : 100%;
            height  : 100%;
            top     : 0;
            left    : 0;
            right   : 0;
            z-index : 2;
        }

        img {
            max-width   : 100%;
            width       : auto;
            display     : block;
            margin-right: auto;
            margin-left : auto;
        }
    }

    &.clients-1 {
        background-color: transparent;
    }

    &.clients-2 {
        background-color: transparent;

        .client {
            height: 176px;
        }
    }
}