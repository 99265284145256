/*--------------------------------
    #Pricing
---------------------------------*/
.pricing {
    padding-top   : 125px;
    padding-bottom: 102px;

    @media #{$maxTablet} {
        padding-top   : 65px;
        padding-bottom: 62px;
    }
}

.pricing-table {
    display       : flex;
    flex-direction: column;
    margin-bottom : 33px;

    @media #{$maxTablet} {
        max-width: 370px;
        margin   : 0 auto 33px;
    }

    .pricing-panel {
        background-color: #f4f4f4;
        padding         : 43px 50px 40px;
        display         : flex;
        flex-direction  : column;
        justify-content : space-between;
        flex-grow       : 1;
        position        : relative;
        clip-path       : polygon(84% 0, 100% 12%, 100% 100%, 0 100%, 0 0);

        @media #{$maxTablet} {
            text-align: center;
        }

        @media #{$noteBook} {
            padding-left : 25px;
            padding-right: 25px;
        }

        @media #{$maxSmall} {
            padding-left : 25px;
            padding-right: 25px;
        }

        &.active {
            background-color: var(--global--color-primary);
            box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.12);

            &:before {
                content   : "";
                display   : inline-block;
                height    : 100%;
                left      : 0;
                position  : absolute;
                top       : 0;
                width     : 100%;
                z-index   : -1;
                background: rgba(254, 208, 0, 0.95);
            }

            .pricing-body {
                .pricing-heading {
                    .pricing-desc {
                        color: var(--global--color-heading-light);
                    }
                }

                .pricing-list {
                    li {
                        i {
                            background-color: var(--global--color-white);
                        }
                    }
                }
            }

            .pricing-price {
                .btn {
                    background-color: var(--global--color-white);
                }
            }
        }


        .pricing-tag {
            position: absolute;
            top     : -5px;
            left    : -5px;
        }

        .pricing-body {
            .pricing-heading {
                margin-bottom: 25px;

                .pricing-title {
                    font-family   : var(--global--font-heading);
                    font-size     : 23px;
                    font-weight   : 700px;
                    line-height   : 32px;
                    color         : var(--global--color-heading-darken);
                    margin-bottom : 15px;
                    position      : relative;
                    text-transform: capitalize;
                    transition    : 300ms ease-in-out;
                }

                .pricing-desc {
                    font-family  : var(--global--font-body);
                    font-size    : 15px;
                    font-weight  : 400;
                    color        : var(--global--color-body);
                    line-height  : 25px;
                    margin-bottom: 0px;
                }
            }

            .pricing-list {
                margin-bottom: 33px;

                li {
                    font-family  : var(--global--font-body);
                    font-size    : 15px;
                    font-weight  : 700;
                    line-height  : 35px;
                    margin-bottom: 0px;
                    color        : var(--global--color-heading-light);
                    display      : flex;
                    align-items  : center;

                    i {
                        display         : inline-flex;
                        align-items     : center;
                        justify-content : center;
                        width           : 20px;
                        height          : 20px;
                        border-radius   : 50%;
                        background-color: var(--global--color-primary);
                        color           : var(--global--color-heading-light);
                        font-size       : 10px;
                        margin-right    : 15px;
                    }
                }
            }
        }

        .pricing-price {
            p {
                margin-bottom: 0;
                font-family  : var(--global--font-heading);
                font-weight  : 700;
                color        : var(--global--color-heading-light);
                display      : flex;
                align-items  : baseline;
                line-height  : 30px;
                position     : relative;

                @media #{$maxTablet} {
                    justify-content: center;
                }

                span {
                    &::before {
                        content: '/';
                    }

                    &:first-of-type {
                        &::before {
                            content: unset;
                        }
                    }
                }

                .currency {
                    font-size: 30px;
                }

                .time {
                    font-weight   : 700;
                    font-size     : 14px;
                    text-transform: capitalize;
                }
            }

            .btn {
                height         : 68px;
                justify-content: space-between;
                margin-bottom  : 37px;
                justify-content: center;
                color          : var(--global--color-heading);

                span {
                    font-size  : 10px;
                    margin-left: 10px;
                    transition : 0.3s ease-in-out;
                    color      : var(--global--color-heading);
                }

                &::before {
                    background-color: var(--global--color-white);
                }

                &:hover {
                    span {
                        transform: translateX(5px)
                    }
                }
            }
        }
    }
}