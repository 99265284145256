/*------------------------------------*\
    #Header
\*------------------------------------*/

.header {
    height: 80px;

    .navbar-brand {
        padding     : 0;
        margin      : 0;
        margin-right: 80px;
        display     : flex;
        align-items : center;

        @media (min-width: 992px) and (max-width: 1200px) {
            margin-right: 20px;
        }

        @media (min-width: 1200px) and (max-width: 1400px) {
            margin-right: 30px;
        }

        @media #{$minLarge} {
            height: 110px;
        }

        @media #{$maxTablet} {
            height: 80px;
        }

        .logo {
            max-width: 100%;
            width    : 150px;
            height   : 40px;

            &.logo-mobile {
                width : auto;
                height: auto;
            }
        }
    }

    &.header-light {
        background-color: var(--global--color-white);

        .navbar {
            background-color: var(--global--color-white);

            .navbar-brand {
                .logo-dark {
                    display: inline-block;

                    @media #{$maxSmall} {
                        display: none;
                    }
                }

                .logo-light {
                    display: none;
                }

                .logo-mobile {
                    display: none;

                    @media #{$maxSmall} {
                        display: inline-block;
                    }

                    &.logo-mobile-white {
                        @media #{$maxSmall} {
                            display: none;
                        }
                    }
                }
            }

            .navbar-nav {
                >li {
                    >a {
                        color: var(--global--color-heading);

                        &::before {
                            background-color: var(--global--color-primary);
                        }
                    }
                }
            }
        }
    }

    &.header-white {
        background-color: var(--global--color-white);
        height          : 110px;

        @media #{$maxTablet} {
            height: 80px;
        }

        .navbar {
            background-color: var(--global--color-white);
            box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.12);

            .navbar-brand {
                .logo-dark {
                    display: inline-block;

                    @media #{$maxSmall} {
                        display: none;
                    }
                }

                .logo-light {
                    display: none;
                }

                .logo-mobile {
                    display: none;

                    @media #{$maxSmall} {
                        display: inline-block;
                    }

                    &.logo-mobile-white {
                        @media #{$maxSmall} {
                            display: none;
                        }
                    }
                }
            }

            .navbar-nav {
                >li {
                    >a {
                        color: var(--global--color-heading);

                        &::before {
                            background-color: var(--global--color-primary);
                        }
                    }
                }
            }

            .module {
                &.module-cart {
                    border-color: #eaeaea;
                }
            }
        }
    }

    &.header-transparent {
        background-color: transparent;
        position        : absolute;
        top             : 0;
        left            : 0;
        width           : 100%;

        @media #{$minLarge} {
            height: 110px;
        }

        .navbar {
            background-color: transparent;

            .navbar-brand {
                .logo-dark {
                    display: none;
                }

                .logo-light {
                    display: inline-block;

                    @media #{$maxSmall} {
                        display: none;
                    }
                }

                .logo-mobile {
                    display: none;

                    @media #{$maxSmall} {
                        display: inline-block;
                    }

                    &.logo-mobile-dark {
                        @media #{$maxSmall} {
                            display: none;
                        }
                    }
                }
            }

            .navbar-nav {
                >li {
                    >a {
                        @media #{$minLarge} {
                            color: var(--global--color-white);
                        }

                        &::before {
                            background: var(--global--color-primary);
                        }
                    }

                    &.has-dropdown {
                        >a {
                            &::after {
                                color: var(--global--color-white);
                            }
                        }
                    }
                }
            }

            .module {
                .module-icon {

                    i,
                    svg {
                        color: var(--global--color-white);

                        &:hover {
                            color: var(--global--color-primary);
                        }
                    }
                }

                &.module-phone {
                    .module-body {
                        a {
                            color: var(--global--color-white);
                        }
                    }

                    &:hover {
                        .module-icon {
                            svg {
                                color: var(--global--color-primary);
                            }
                        }

                        .module-body {
                            a {
                                color: var(--global--color-primary);
                            }
                        }
                    }
                }
            }

            .module-holder-phone {
                .navbar-toggler {
                    .navbar-toggler-icon {
                        background-color: var(--global--color-white);

                        &::before,
                        &::after {
                            background-color: var(--global--color-white);
                        }
                    }

                    &:not(.collapsed) {
                        .navbar-toggler-icon {
                            background-color: transparent;
                        }
                    }
                }
            }

            &.navbar-fixed {
                .navbar-brand {
                    .logo-dark {
                        display: none;

                        @media #{$minTablet} {
                            display: inline-block;
                        }
                    }

                    .logo-mobile {
                        @media #{$maxSmall} {
                            &.logo-mobile-white {
                                display: none;
                            }

                            &.logo-mobile-dark {
                                display: inline-block;
                            }
                        }
                    }
                }

                .navbar-nav {
                    >li {
                        &.has-dropdown {
                            >a {
                                &::after {
                                    color: var(--global--color-heading);
                                }
                            }

                            &.active,
                            &:hover {
                                >a {
                                    &::after {
                                        color: var(--global--color-primary)
                                    }
                                }
                            }
                        }
                    }
                }

                .module {
                    .module-icon {

                        i,
                        svg {
                            color: var(--global--color-heading);

                            &:hover {
                                color: var(--global--color-primary);
                            }
                        }
                    }

                    &.module-phone {
                        .module-body {
                            a {
                                color: var(--global--color-heading);
                            }
                        }

                        &:hover {
                            .module-icon {
                                svg {
                                    color: var(--global--color-primary);
                                }
                            }

                            .module-body {
                                a {
                                    color: var(--global--color-primary);
                                }
                            }
                        }
                    }
                }

                .module-holder-phone {
                    .navbar-toggler {
                        .navbar-toggler-icon {
                            background-color: var(--global--color-heading);

                            &::before,
                            &::after {
                                background-color: var(--global--color-heading);
                            }
                        }

                        &:not(.collapsed) {
                            .navbar-toggler-icon {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    &.header-transparent-2 {
        background-color: transparent;
        position        : absolute;
        top             : 0;
        left            : 0;
        width           : 100%;

        @media #{$minLarge} {
            height: 110px;
        }

        .navbar {
            background-color: transparent;
            padding-left    : 0;

            @media #{$maxTablet} {
                padding-right: 0;
                position     : relative;
            }

            .navbar-brand {
                width           : 230px;
                background-color: var(--global--color-white);
                justify-content : center;

                @media (min-width: 992px) and (max-width: 1399.98px) {
                    width: 200px;
                }

                @media #{$maxTablet} {
                    position : absolute;
                    top      : 0;
                    left     : 50%;
                    transform: translateX(-50%);
                }

                @media #{$maxSmall} {
                    width: 80px;
                }

                .logo-dark {
                    display: none;

                    @media #{$minTablet} {
                        display: inline-block;
                    }
                }

                .logo-light {
                    display: none;
                }

                .logo-mobile {
                    display: none;

                    @media #{$maxSmall} {
                        display: inline-block;
                    }

                    &.logo-mobile-white {
                        display: none;
                    }

                    &.logo-mobile-dark {
                        @media #{$maxSmall} {
                            display: block;
                        }
                    }
                }
            }

            .navbar-nav {
                >li {
                    @media #{$minLarge} {
                        margin-right: 20px;
                    }

                    @media screen and (min-width: 1400px) {
                        margin-right: 20px;
                    }

                    >a {
                        @media #{$minLarge} {
                            color: var(--global--color-white);
                        }

                        &::before {
                            bottom    : auto;
                            top       : 0;
                            background: var(--global--color-primary);
                        }
                    }

                    &.has-dropdown {
                        >a {
                            &::after {
                                color: var(--global--color-white);
                            }
                        }
                    }
                }
            }

            .module {
                .module-icon {

                    i,
                    svg {
                        color: var(--global--color-white);

                        &:hover {
                            color: var(--global--color-primary);
                        }
                    }
                }

                &.module-phone {
                    .module-body {
                        a {
                            color: var(--global--color-white);
                        }
                    }

                    &:hover {
                        .module-icon {
                            svg {
                                color: var(--global--color-heading);
                            }
                        }

                        .module-body {
                            a {
                                color: var(--global--color-white);
                            }
                        }
                    }
                }
            }

            .module-holder-phone {
                @media #{$maxTablet} {
                    padding-right: 40px;
                }

                @media #{$maxSmall} {
                    padding-right: 20px;
                }

                .navbar-toggler {
                    .navbar-toggler-icon {
                        background-color: var(--global--color-white);

                        &::before,
                        &::after {
                            background-color: var(--global--color-white);
                        }
                    }

                    &:not(.collapsed) {
                        .navbar-toggler-icon {
                            background-color: transparent;
                        }
                    }
                }

                .module-search,
                .module-cart {
                    @media screen and (max-width:400px) {
                        display: none;
                    }
                }
            }

            &.navbar-fixed {
                .navbar-nav {
                    >li {
                        &.has-dropdown {
                            >a {
                                &::after {
                                    color: var(--global--color-heading);
                                }
                            }

                            &.active,
                            &:hover {
                                >a {
                                    &::after {
                                        color: var(--global--color-primary)
                                    }
                                }
                            }
                        }
                    }
                }

                .module {
                    .module-icon {

                        i,
                        svg {
                            color: var(--global--color-heading);

                            &:hover {
                                color: var(--global--color-primary);
                            }
                        }
                    }

                    &.module-phone {
                        .module-icon {
                            border-color: var(--global--color-heading);
                        }

                        .module-body {
                            a {
                                color: var(--global--color-heading);
                            }
                        }

                        &:hover {
                            .module-icon {
                                background-color: var(--global--color-heading);

                                svg {
                                    color: var(--global--color-white);
                                }
                            }

                            .module-body {
                                a {
                                    color: var(--global--color-heading);
                                }
                            }
                        }
                    }
                }

                .module-holder-phone {
                    .navbar-toggler {
                        .navbar-toggler-icon {
                            background-color: var(--global--color-heading);

                            &::before,
                            &::after {
                                background-color: var(--global--color-heading);
                            }
                        }

                        &:not(.collapsed) {
                            .navbar-toggler-icon {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    &.header-topbar {
        height: 80px;

        @media #{$minLarge} {
            height: 160px;
        }

        .top-bar {
            @media #{$maxTablet} {
                display: none;
            }
        }

        &.header-shadow {
            .navbar {
                box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11);
            }
        }

        .navbar {
            .navbar-nav {
                >li {
                    @media #{$minXlarge} {
                        margin-right: 35px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    &.has-dropdown {
                        >a {
                            &::after {
                                color: var(--global--color-heading);
                            }
                        }

                        &.active,
                        &:hover {
                            >a {
                                &::after {
                                    color: var(--global--color-primary)
                                }
                            }
                        }
                    }
                }
            }

            .module-holder {
                .module-search {
                    margin-right: 30px;
                }

                .module-contact {
                    @media #{$minLarge} {
                        margin-right: 30px;
                    }
                }

                .module-cart {
                    padding-right: 0;
                    margin-right : 0;

                    .module-cart-warp {
                        left: -300px;
                    }
                }
            }
        }
    }
}