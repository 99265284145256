/*------------------------------------*\
    #Lists
\*------------------------------------*/

ul.list-icons {
    padding        : 0;
    font-size      : 14px;
    list-style-type: none;

    li {
        font-family: var(--global--font-body);
        color      : var(--global--color-body);
        font-size  : 16px;
        font-weight: 400;
        line-height: 29px;
        position   : relative;

        i {
            font-size   : 14px;
            color       : var(--global--color-heading);
            margin-right: 10px;
        }
    }
}

.advantages-list-wrapper {
    @media #{$maxTablet} {
        text-align: center;

        .advantages-list {
            @media #{$maxTablet} {
                display: inline-grid;
            }
        }
    }
}

.advantages-list {
    text-align: left;
    margin    : 0;
    padding   : 0;
    list-style: none;

    li {
        display       : flex;
        align-items   : center;
        font-family   : var(--global--font-body);
        font-weight   : 700;
        font-size     : 15px;
        line-height   : 36px;
        color         : var(--global--color-body);
        text-transform: capitalize;
        position      : relative;

        @media #{$noteBook} {
            font-size: 14px;
        }

        i {
            position: relative;
            @include centerd(20px, 20px, var(--global--color-primary));
            flex-shrink  : 0;
            border-radius: 50%;
            margin-right : 15px;
            font-size    : 10px;
            color        : var(--global--color-white);
        }
    }

    &.advantages-list-white {
        li {
            color: #f9f9f9;

            i {
                color: var(--global--color-heading);
            }
        }
    }

    &.advantages-list-white-2 {
        li {
            color: #9b9b9b;

            i {
                color: var(--global--color-white);
            }
        }
    }

    &.advantages-list-2 {
        li {
            font-size     : 16px;
            padding-top   : 15px;
            padding-bottom: 17px;
            border-bottom : 1px solid #404040;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom : 0;
            }

            i {
                width       : 26px;
                height      : 26px;
                margin-right: 20px;
                ;
            }
        }

        &.advantages-list-dark {
            li {
                color       : var(--global--color-heading-light);
                border-color: #eaeaea;

                i {
                    color: var(--global--color-heading);
                }
            }
        }
    }

    &.advantages-list-3 {
        display              : grid;
        grid-template-columns: repeat(3, 1fr);
        gap                  : 1px;

        @media #{$maxTablet} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{$maxSmall} {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            background-color: #f4f4f4;
            border          : 1px solid transparent;
            transition      : 0.3s ease-in-out;
            font-size       : 16px;
            color           : var(--global--color-heading-light);
            padding         : 24px;

            &:hover {
                background-color: var(--global--color-white);
                box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.19);
                border-color    : #282828;
            }

            i {
                width       : 36px;
                height      : 36px;
                margin-right: 15px;
                ;
                color: var(--global--color-heading);
            }
        }
    }

    &.advantages-list-4 {
        padding-top          : 40px;
        border-top           : 2px solid #404040;
        display              : grid;
        grid-template-columns: repeat(2, 1fr);
        gap                  : 30px;

        @media #{$maxSmall} {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            display       : flex;
            align-items   : flex-start;
            flex-direction: column;

            @media #{$maxSmall} {
                flex-direction: row;
            }

            &:last-child {
                .list-body {
                    border-bottom : 0;
                    padding-bottom: 0;
                    margin-bottom : 0;
                }
            }

            i {
                @include centerd(46px, 46px, var(--global--color-primary));
                flex-shrink  : 0;
                border-radius: 50%;
                color        : var(--global--color-heading);
                margin-bottom: 23px;

                @media screen and (max-width: 424.98px) {
                    width : 40px;
                    height: 40px;
                }
            }

            .list-body {
                h3 {
                    font-family  : var(--global--font-heading);
                    font-weight  : 700;
                    font-size    : 20px;
                    line-height  : 29px;
                    color        : var(--global--color-white);
                    margin-bottom: 16px;
                }

                p {
                    font-family  : var(--global--font-body);
                    font-weight  : 400;
                    font-size    : 15px;
                    line-height  : 25px;
                    color        : #f9f9f9;
                    margin-bottom: 0;
                }
            }
        }
    }

    &.advantages-list-5 {
        li {
            font-size  : 14px;
            font-weight: 400;
            color      : #f9f9f9;

            i {
                width           : auto;
                height          : auto;
                margin-right    : 10px;
                color           : var(--global--color-heading-light);
                background-color: transparent;
            }
        }
    }

    &.advantages-list-6 {
        display              : grid;
        grid-template-columns: repeat(2, 1fr);
        gap                  : 1px;

        @media #{$maxTablet} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{$maxSmall} {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            background-color: #f4f4f4;
            border          : 1px solid transparent;
            transition      : 0.3s ease-in-out;
            font-size       : 16px;
            color           : var(--global--color-heading-light);
            padding         : 24px;

            &:hover {
                background-color: var(--global--color-white);
                box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.19);
                border-color    : #282828;
            }

            i {
                width       : 36px;
                height      : 36px;
                margin-right: 15px;
                ;
                color: var(--global--color-heading);
            }
        }
    }
}