/*------------------------------------*\
	#Slider
\*------------------------------------*/

.slider {
	padding-top   : 0;
	padding-bottom: 0;
	position      : relative;

	.slide {
		padding-top   : 0;
		padding-bottom: 0;
		display       : flex;
		align-items   : center;
		height        : 900px;

		@media #{$maxTablet} {
			height    : auto;
			min-height: 120vh;
		}

		.slide-content {
			position: relative;
			z-index : 5;

			@media #{$maxTablet} {
				text-align: center;
			}

			.slide-subtitle {
				font-family   : var(--global--font-body);
				text-transform: capitalize;
				color         : #f9f9f9;
				font-size     : 17px;
				font-weight   : 700;
				line-height   : 1.5;
				margin-bottom : 8px;
			}

			.slide-title {
				font-family   : var(--global--font-heading);
				color         : var(--global--color-white);
				font-weight   : 700;
				font-size     : 75px;
				line-height   : 1.1;
				margin-bottom : 22px;
				text-transform: capitalize;

				@media #{$noteBook} {
					font-size  : 35px;
					line-height: 1.4;
				}

				@media #{$maxSmall} {
					font-size  : 32px;
					line-height: 1.4;
				}
			}

			.slide-desc {
				font-family  : var(--global--font-body);
				color        : #f9f9f9;
				font-size    : 17px;
				font-weight  : 700;
				line-height  : 27px;
				margin-bottom: 32px;
			}

			.slide-action {
				display    : flex;
				align-items: center;

				@media #{$maxTablet} {
					justify-content: center;
				}

				@media #{$maxSmall} {
					flex-direction: column;
				}

				.btn {
					width       : 184px;
					height      : 68px;
					margin-right: 30px;

					@media #{$maxSmall} {
						margin-right : 0;
						margin-bottom: 25px;
						height       : 60px;
						width        : 200px;
					}

					@media #{$noteBook} {
						width       : 200px;
						margin-right: 15px;
					}

					&:last-child {
						margin-right: 0;

						@media #{$maxSmall} {
							margin-bottom: 0;
						}
					}
				}
			}

			.slide-list {
				display    : flex;
				align-items: center;

				@media #{$maxTablet} {
					justify-content: center;
				}

				@media #{$maxSmall} {
					flex-wrap      : wrap;
					justify-content: space-evenly;
				}

				.icon {
					position        : relative;
					margin-right    : 30px;
					display         : inline-flex;
					justify-content : center;
					align-items     : center;
					flex-direction  : column;
					text-align      : center;
					border-radius   : 3px;
					width           : 110px;
					height          : 135px;
					background-color: var(--global--color-white);
					transition      : 0.3s ease-in-out;

					&:last-child {
						margin-right: 0;
					}

					@media #{$noteBook} {
						margin-right: 15px;
					}

					@media #{$maxSmall} {
						margin-right: 0;
						margin      : 0 15px 25px;

						&:last-child {
							margin-right: 15px;
						}
					}

					&:hover {
						background-color: var(--global--color-primary);
						animation       : Bounce 0.8s ease-in-out;
					}

					a {
						@include overlay(transparent);
						z-index: 5;
					}

					i {
						display      : inline-block;
						font-size    : 48px;
						color        : var(--global--color-heading);
						transition   : 0.3s ease-in-out;
						margin-bottom: 13px;
					}

					span {
						font-family   : var(--global--font-body);
						font-weight   : 700;
						font-size     : 15px;
						color         : var(--global--color-heading);
						text-transform: capitalize;
					}
				}
			}
		}
	}

	.carousel-navs-slider {
		.owl-nav {
			@media #{$maxTablet} {
				display: none;
			}

			span {
				font-size: 0;
			}

			right   : auto;
			top     : auto;
			position: initial;

			.owl-next:before,
			.owl-prev:before {
				color      : rgba(255, 255, 255, 0.5);
				font-family: "carousel" !important;
				font-size  : 44px;
				transition : 0.3s ease-in-out;
			}

			.owl-prev:before {
				content: "\e990";
			}

			.owl-next:before {
				content: "\e991";
			}

			.owl-next,
			.owl-prev {
				position : absolute;
				top      : 50%;
				transform: translateY(-50%);

				&:hover {
					&::before {
						color: white;
					}
				}
			}

			.owl-next {
				right: 40px;

				@media #{$noteBook} {
					right: 20px;
				}
			}

			.owl-prev {
				margin-right: 0;
				left        : 40px;

				@media #{$noteBook} {
					left: 20px;
				}
			}
		}
	}

	&.slider-1 {
		.slide {
			@media #{$maxTablet} {
				padding-bottom: 135px;
				height        : 130vh;
			}

			@media screen and (max-width:400px) {
				height: 140vh;
			}

			@media #{$minLarge} {
				padding-top: 111px;
			}

			.slide-content {
				.slide-desc {
					@media #{$minLarge} {
						max-width: 90%;
					}
				}

				.slide-action {
					@media #{$maxTablet} {
						margin-bottom: 40px;
					}

					.btn {
						&:first-child {
							width: 240px;
						}

						&:last-child {
							padding: 0 20px;
						}
					}
				}
			}
		}

		.carousel-navs-2 {
			.owl-nav {
				position: absolute;
				right   : calc(440px + 50px);
				bottom  : 60px;
			}
		}
	}

	&.slider-2 {
		.slide {
			@media #{$minLarge} {
				padding-top: 110px;
			}

			@media #{$maxSmall} {
				height: 130vh;
			}

			.slide-content {
				.slide-action {
					.btn {
						&:first-child {
							width: 260px;
						}

						&:last-child {
							width: 196px;
						}
					}
				}
			}
		}
	}

	&.slider-3 {
		.slide {
			@media #{$minLarge} {
				height        : 740px;
				padding-bottom: 110px;
			}
		}
	}
}

.hero {
	padding-top   : 0;
	padding-bottom: 0;
	display       : flex;
	align-items   : center;
	height        : 680px;
	position      : relative;
	padding-top   : 110px;

	@media #{$maxTablet} {
		height     : 80vh;
		padding-top: 80px;
	}

	@media #{$maxSmall} {
		height: 90vh
	}

	.hero-content {
		@media #{$maxTablet} {
			text-align: center;
		}

		.hero-subtitle {
			font-family   : var(--global--font-secondary);
			text-transform: capitalize;
			color         : var(--global--color-primary);
			font-size     : 30px;
			font-weight   : 400;
			line-height   : 1;
			margin-bottom : 18px;

			@media #{$maxTablet} {
				line-height: 1.5;
			}
		}

		.hero-title {
			font-family   : var(--global--font-heading);
			color         : var(--global--color-white);
			font-weight   : 700;
			font-size     : 75px;
			line-height   : 1;
			margin-bottom : 27px;
			text-transform: capitalize;

			@media #{$noteBook} {
				font-size  : 35px;
				line-height: 1.4;
			}

			@media #{$maxSmall} {
				font-size  : 32px;
				line-height: 1.4;
			}
		}

		.hero-desc {
			font-family  : var(--global--font-body);
			color        : #f9f9f9;
			font-size    : 17px;
			font-weight  : 700;
			line-height  : 27px;
			margin-bottom: 32px;
		}

		.hero-action {
			display    : flex;
			align-items: center;

			@media #{$maxTablet} {
				justify-content: center;
			}

			@media #{$maxSmall} {
				flex-direction: column;
			}

			.btn {
				width       : 218px;
				height      : 68px;
				margin-right: 30px;

				&:last-child {
					width       : 176px;
					margin-right: 0;
				}

				@media #{$noteBook} {
					width       : 225px;
					margin-right: 15px;
				}

				@media #{$maxSmall} {
					margin-right : 0;
					margin-bottom: 25px;
					height       : 60px;
					width        : 225px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.contact-num {
				display    : flex;
				align-items: center;

				.contact-icon-wrapper {
					flex-shrink: 0;
					@include centerd(68px, 68px, transparent);
					border       : 2px solid var(--global--color-primary);
					border-radius: 50%;
					margin-right : 10px;

					.contact-icon {
						@include centerd(46px, 46px, var(--global--color-white));
						border-radius: 50%;

						svg {
							color: var(--global--color-primary)
						}
					}
				}

				.contact-body {
					a {
						display      : block;
						font-family  : var(--global--font-heading);
						font-weight  : 700;
						font-size    : 17px;
						color        : var(--global--color-white);
						margin-bottom: 10px;
						line-height  : 1
					}

					.job-title {
						font-family  : var(--global--color-secondary);
						font-weight  : 400;
						font-size    : 13px;
						color        : #f4f4f4;
						margin-bottom: 0;
						line-height  : 1
					}
				}
			}
		}

		.hero-list {
			display    : flex;
			align-items: center;

			@media #{$maxTablet} {
				justify-content: center;
			}

			@media #{$maxSmall} {
				flex-wrap      : wrap;
				justify-content: space-evenly;
			}

			.icon {
				position        : relative;
				margin-right    : 30px;
				display         : inline-flex;
				justify-content : center;
				align-items     : center;
				flex-direction  : column;
				text-align      : center;
				border-radius   : 3px;
				width           : 110px;
				height          : 135px;
				background-color: var(--global--color-white);
				transition      : 0.3s ease-in-out;

				&:last-child {
					margin-right: 0;
				}

				@media #{$noteBook} {
					margin-right: 15px;
				}

				@media #{$maxSmall} {
					margin-right: 0;
					margin      : 0 15px 25px;

					&:last-child {
						margin-right: 15px;
					}
				}

				&:hover {
					background-color: var(--global--color-primary);
					animation       : Bounce 0.8s ease-in-out;
				}

				a {
					@include overlay(transparent);
					z-index: 5;
				}

				i {
					display      : inline-block;
					font-size    : 48px;
					color        : var(--global--color-heading);
					transition   : 0.3s ease-in-out;
					margin-bottom: 13px;
				}

				span {
					font-family   : var(--global--font-body);
					font-weight   : 700;
					font-size     : 15px;
					color         : var(--global--color-heading);
					text-transform: capitalize;
				}
			}
		}
	}

	&.hero-1 {
		.hero-content {
			.hero-action {
				.btn {
					&:first-child {
						width: 220px;

						@media #{$minLarge} {
							margin-right: 40px;
						}
					}
				}
			}
		}
	}

	&.hero-3 {
		.hero-content {
			.hero-action {
				.btn {
					width: 244px;
				}
			}
		}
	}

	&.hero-4 {
		.hero-content {
			.hero-action {
				.btn {
					width: 260px;
				}
			}
		}
	}

	&.hero-5 {
		.hero-content {
			.hero-action {
				.btn {
					width: 228px;
				}
			}
		}
	}

	&.hero-6 {
		.hero-content {
			.hero-desc {
				@media #{$minLarge} {
					max-width: 80%;
				}
			}

			.hero-action {
				.btn {
					width: 240px;
				}
			}
		}
	}

	&.hero-7 {
		.hero-content {
			.hero-action {
				.btn {
					width: 184px;

					&:last-child {
						width: 154px;
					}
				}
			}
		}
	}

	&.hero-8 {
		.hero-content {
			.hero-action {
				.btn {
					width: 218px;

					&:last-child {
						width: 184px;
					}
				}
			}
		}
	}

	&.hero-9 {
		.hero-content {
			.hero-action {
				.btn {
					width: 190px;

					&:last-child {
						width: 172px;
					}
				}
			}
		}
	}

	&.hero-10 {
		@media #{$minLarge} {
			height: 832px
		}

		@media #{$maxTablet} {
			height: 100vh;
		}

		@media #{$maxSmall} {
			height: 120vh;
		}
	}

	&.hero-11 {
		@media #{$minLarge} {
			height: 765px
		}

		@media #{$maxTablet} {
			height: 85vh;
		}

		@media #{$maxSmall} {
			height: 100vh;
		}
	}

	&.hero-12 {
		height: 572px;

		.hero-content {
			.hero-desc {
				margin-bottom: 0;
			}
		}
	}

	&.hero-13 {
		height: 550px;

		.hero-content {
			.hero-desc {
				margin-bottom: 0;
			}
		}
	}

	&.hero-14 {
		height: 600px;

		@media #{$maxSmall} {
			height: 100vh
		}

		.hero-content {
			.hero-action {
				justify-content: center;

				.btn {
					&:first-child {
						width: 218px;
					}

					&:last-child {
						width: 176px;
					}
				}
			}
		}
	}

	&.hero-15 {
		.hero-content {
			.hero-action {
				.btn {
					&:first-child {
						width: 190px;
					}

					&:last-child {
						width: 172px;
					}
				}
			}
		}
	}

	&.hero-16 {
		.hero-content {
			.hero-action {
				.btn {
					width: 260px;
				}
			}
		}
	}
}