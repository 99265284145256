/*------------------------------------*\
	#Blog Section
\*------------------------------------*/
.blog {
	padding: 127px 0 70px;

	.more-blogs {
		text-align: center;

		p {
			font-family  : var(--global--font-body);
			font-weight  : 700;
			font-size    : 14px;
			color        : var(--global--color-body);
			line-height  : 33px;
			margin-bottom: 0;

			a {
				position      : relative;
				font-weight   : 700;
				color         : var(--global--color-heading);
				transition    : 0.3s ease-in-out;
				text-transform: capitalize;
				padding-bottom: 6px;

				&:hover {
					&::before {
						width: calc(100% - 20%);
					}
				}

				&::before {
					content         : '';
					transition      : 0.3s ease-in-out;
					position        : absolute;
					bottom          : 0;
					left            : 50%;
					transform       : translateX(-50%);
					width           : 100%;
					height          : 2px;
					background-color: var(--global--color-primary);
				}
			}
		}
	}

	.owl-carousel {
		.owl-stage-outer {
			overflow: visible;

			.owl-item {
				opacity: 0;

				&.active {
					opacity: 1;
				}
			}
		}
	}

	&.blog-2 {
		padding-top   : 114px;
		padding-bottom: 90px;

		@media #{$maxTablet} {
			padding-top   : 57px;
			padding-bottom: 22px;
		}
	}
}

.blog-entry {
	margin-bottom   : 33px;
	position        : relative;
	background-color: var(--global--color-white);
	border-radius   : 8px;
	transition      : 0.3s ease-in-out;
	box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.09);

	@media #{$maxTablet} {
		max-width : 370px;
		margin    : 0 auto 33px;
		text-align: center;
	}

	&:hover {
		box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);

		.entry-img {
			.entry-img-holder {
				img {
					transform: scale3d(1, 1, 1);
				}
			}

			&::before {
				opacity   : 1;
				visibility: visible;
			}
		}

		.entry-content {

			.entry-more {
				bottom: 0;
			}
		}
	}

	.entry-img {
		position: relative;

		&::before {
			content: '';
			@include overlay(linear-gradient(180deg, rgba(0, 0, 0, 0.1) 55%, rgba(0, 0, 0, 0.85) 100%));
			z-index   : 2;
			transition: 0.3s ease-in-out;
			opacity   : 0;
			visibility: hidden;
		}

		.entry-img-holder {
			overflow: hidden;

			img {
				transition: transform 0.35s ease-in-out;
				width     : 100%;
				transform : scale3d(1.05, 1.05, 1);
				height    : 267px;
				object-fit: cover;
			}
		}

		a {
			@include overlay(transparent);
			z-index: 4;
		}

	}

	.entry-category {
		display         : inline-flex;
		align-items     : center;
		margin-bottom   : 0px;
		text-transform  : capitalize;
		height          : 30px;
		padding         : 0 10px;
		background-color: var(--global--color-primary);
		border-radius   : 3px;
		margin-bottom   : 24px;

		a {
			position      : relative;
			color         : var(--global--color-heading);
			font-family   : var(--global--font-body);
			font-size     : 13px;
			font-weight   : 400;
			line-height   : 26px;
			text-transform: capitalize;
			transition    : 0.3s ease-in-out;

			&::after {
				content     : ", ";
				position    : relative;
				margin-right: 4px;
			}

			&:last-of-type {
				&::after {
					content: "";
				}
			}

			&:hover {
				color: var(--global--color-heading);
			}
		}
	}

	.entry-content {
		position: relative;
		z-index : 6;
		overflow: hidden;

		.entry-body {
			padding: 30px 40px 0px;

			@media #{$noteBook} {
				padding-left : 25px;
				padding-right: 25px;
			}

			@media #{$maxTablet} {
				padding-left : 25px;
				padding-right: 25px;
			}

			.entry-title {
				font-size     : 20px;
				font-weight   : 700;
				line-height   : 29px;
				text-transform: capitalize;
				margin-bottom : 16px;

				a {
					color: var(--global--color-heading);

					&:hover {
						color: var(--global--color-primary);
					}
				}
			}

			.entry-bio {
				font-family  : var(--global--font-body);
				font-size    : 15px;
				font-weight  : 400;
				line-height  : 25px;
				color        : var(--global--color-body);
				margin-bottom: 33px;
			}
		}

		.entry-meta {
			display        : flex;
			align-items    : center;
			justify-content: space-evenly;
			height         : 67px;
			border-top     : 1px solid #eaeaea;

			.entry-date {
				margin-bottom  : 0px;
				text-transform : capitalize;
				display        : flex;
				align-items    : center;
				justify-content: center;

				span {
					position   : relative;
					color      : #616161;
					font-family: var(--global--font-body);
					font-size  : 13px;
					font-weight: 400;
					line-height: 16px;
					transition : all 0.3s ease-in-out;

					&::after {
						content : ", ";
						position: relative;
					}

					&:last-of-type {
						&::after {
							content: "";
						}
					}

					&:hover {
						color: var(--global--color-primary);
					}
				}
			}

			.divider {
				width           : 1px;
				height          : 67px;
				background-color: #eaeaea;
			}

			.entry-author {
				text-transform: capitalize;

				p {
					font-family  : var(--global--font-body);
					font-size    : 13px;
					font-weight  : 400;
					line-height  : 26px;
					margin-bottom: 0;
					color        : var(--global--color-heading);
				}
			}
		}

		.entry-more {
			position  : absolute;
			bottom    : -61px;
			left      : 0;
			right     : 0;
			transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

			a {
				display         : flex;
				width           : 100%;
				height          : 67px;
				padding         : 0 15px;
				position        : relative;
				transition      : all 300ms ease-in-out;
				background-color: var(--global--color-primary);
				color           : var(--global--color-heading);
				border-radius   : 0;

				&::before {
					content: unset;
				}
			}
		}
	}

	&.blog-standard {
		box-shadow: none;

		.entry-img {
			&::before {
				content: unset;
			}

			.entry-img-holder {
				img {
					height: 358px;
				}
			}

			.entry-category {
				position     : absolute;
				top          : 0;
				left         : 0;
				margin-bottom: 0;
				z-index      : 2;
				border-radius: 0 0 3px 0;
			}
		}

		.entry-content {
			padding: 23px 40px 0px;

			.entry-body {
				padding: 0;

				.entry-meta {
					height         : auto;
					align-items    : center;
					justify-content: flex-start;
					border-top     : 0;
					margin-bottom  : 6px;

					@media #{$maxTablet} {
						justify-content: center;
					}

					.entry-date {
						margin-right: 20px;
					}
				}

				.entry-title {
					font-size  : 24px;
					line-height: 33px;
				}

				.entry-bio {
					margin-bottom: 18px;
				}
			}

			.entry-more {
				position: relative;
				bottom  : auto;
				left    : auto;
				right   : auto;

				a {
					width           : auto;
					height          : auto;
					padding         : 0;
					background-color: transparent;
					display         : inline-block;
				}
			}
		}
	}

	&.blog-thumb {
		box-shadow   : none;
		display      : flex;
		margin-bottom: 40px;

		@media #{$maxTablet} {
			flex-direction: column;
		}

		.entry-img {
			&::before {
				content: unset;
			}

			.entry-category {
				position     : absolute;
				top          : 0;
				left         : 0;
				margin-bottom: 0;
				z-index      : 2;
				border-radius: 0 0 3px 0;
			}

			.entry-img-holder {
				img {
					height: 120px;
					width : 180px;

					@media #{$maxTablet} {
						width : 100%;
						height: 200px
					}
				}
			}
		}

		.entry-content {
			flex-grow   : 1;
			padding-left: 40px;
			display     : flex;
			align-items : center;

			@media #{$maxTablet} {
				padding-left: 0;
				padding-top : 32px;
			}

			.entry-body {
				padding: 0;

				.entry-meta {
					height         : auto;
					align-items    : center;
					justify-content: flex-start;
					border-top     : 0;
					margin-bottom  : 6px;

					@media #{$maxTablet} {
						justify-content: center;
					}

					.entry-date {
						margin-right: 20px;
					}
				}

				.entry-title {
					margin-bottom: 0;
				}
			}
		}
	}
}

/* Blog Grid */
.blog-grid {
	&.blog-grid-1 {
		padding: 128px 0 101px;

		@media #{$maxTablet} {
			padding: 69px 0 61px
		}
	}

	&.blog-grid-2 {
		padding: 238px 0 101px;

		@media #{$maxTablet} {
			padding: 69px 0 61px
		}
	}

	&.blog-grid-3 {
		padding: 110px 0;

		@media #{$maxTablet} {
			padding: 70px 0
		}

		.blog-entry {
			margin-bottom: 40px;
		}
	}
}

/* Blog Single */
.blog-single {
	padding-top   : 70px;
	padding-bottom: 110px;

	@media #{$maxTablet} {
		padding-bottom: 70px;
	}

	.blog-entry-wrapper {
		background-color: var(--global--color-white);
		box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.12);
		margin-bottom   : 0;

		@media #{$maxTablet} {
			max-width: unset;
		}

		&:last-of-type {
			margin-bottom: 0px;
		}

		&:hover {}

		.entry-img {
			img {
				border-radius: 0px;
				width        : 100%;
				height       : 562px;
				object-fit   : cover;
			}
		}

		.entry-holder {
			display        : flex;
			justify-content: space-between;
			border-top     : 2px solid #eaeaea;
			padding-top    : 35px;

			@media #{$maxSmall} {
				flex-direction : column;
				justify-content: center;
				align-items    : center;
				text-align     : center;
			}

			.entry-share,
			.entry-tags {
				display       : flex;
				flex-direction: column;

				@media #{$maxSmall} {
					align-items: center;
				}

				span {
					display       : block;
					font-family   : var(--global--font-body);
					font-weight   : 700;
					font-size     : 14px;
					color         : var(--global--color-heading-light);
					text-transform: capitalize;
					margin-bottom : 9px;
				}

				div {
					display    : flex;
					align-items: center;
					flex-wrap  : wrap;
				}
			}

			.entry-share {
				div {
					height     : auto;
					line-height: normal;
					flex-wrap  : nowrap;

					a {
						background-color: var(--global--color-white);
						border-radius   : 50%;
						transition      : 0.3s ease-in-out;
						width           : 32px;
						height          : 32px;
						display         : flex;
						justify-content : center;
						align-items     : center;
						transition      : 0.3s ease-in-out;
						border          : 2px solid #eaeaea;
						margin-right    : 10px;
						margin-bottom   : 10px;
						;

						&:last-child {
							margin-right: 0;
						}

						i {
							color     : var(--global--color-heading);
							font-size : 15px;
							transition: 0.3s ease-in-out;
						}

						&:last-child {
							margin-right: 0;
						}

						&:hover {
							background-color: var(--global--color-heading);
							border-color    : var(--global--color-heading);
							transform       : translateY(-5px);

							i {
								color: var(--global--color-white);
							}
						}
					}
				}
			}

			.entry-tags {
				@media #{$maxSmall} {
					margin-bottom: 20px;
				}

				div {
					@media #{$maxSmall} {
						justify-content: center;
					}
				}

				a {
					display         : inline-flex;
					align-items     : center;
					height          : 32px;
					margin-right    : 10px;
					margin-bottom   : 10px;
					font-family     : var(--global--font-body);
					font-size       : 12px;
					font-weight     : 400;
					text-transform  : capitalize;
					transition      : 0.3s ease-in-out;
					color           : var(--global--color-heading);
					background-color: var(--global--color-primary);
					border-radius   : 3px;
					padding         : 0 14px;

					&:hover {
						background-color: var(--global--color-heading);
						color           : var(--global--color-white);
					}
				}
			}
		}

		.entry-content {
			position: relative;
			padding : 38px 50px 40px;

			@media #{$noteBook} {
				padding-left : 25px;
				padding-right: 25px;
			}

			@media #{$maxTablet} {
				padding-left : 25px;
				padding-right: 25px;
			}

			@media #{$maxSmall} {
				text-align: center;
			}

			&::after {
				content         : '';
				position        : absolute;
				bottom          : 0;
				left            : 50%;
				transform       : translateX(-50%);
				width           : calc(100% - 100px);
				height          : 4px;
				background-color: var(--global--color-primary);

				@media #{$noteBook} {
					width: calc(100% - 50px);
				}

				@media #{$maxTablet} {
					width: calc(100% - 50px);
				}
			}

			.entry-meta-wrapper {
				display      : flex;
				align-items  : center;
				margin-bottom: 33px;

				@media #{$maxSmall} {
					flex-direction : column;
					justify-content: center;
				}

				.entry-category {
					display         : inline-flex;
					align-items     : center;
					text-transform  : capitalize;
					height          : 30px;
					padding         : 0 10px;
					background-color: var(--global--color-primary);
					border-radius   : 3px;

					@media #{$maxSmall} {
						margin-bottom: 20px;
					}

					a {
						position      : relative;
						color         : var(--global--color-heading);
						font-family   : var(--global--font-body);
						font-size     : 13px;
						font-weight   : 400;
						line-height   : 26px;
						text-transform: capitalize;
						transition    : 0.3s ease-in-out;

						&::after {
							content     : ", ";
							position    : relative;
							margin-right: 4px;
						}

						&:last-of-type {
							&::after {
								content: "";
							}
						}

						&:hover {
							color: var(--global--color-heading);
						}
					}
				}

				.entry-meta {
					display    : flex;
					align-items: center;
					margin-left: 20px;

					@media #{$maxSmall} {
						margin-left    : 0;
						flex-wrap      : wrap;
						justify-content: space-around;
					}

					div {
						margin-right: 20px;

						&:last-child {
							margin-right: 0;
						}
					}

					.entry-date {
						margin-bottom  : 0px;
						text-transform : capitalize;
						display        : flex;
						align-items    : center;
						justify-content: center;

						span {
							position   : relative;
							color      : #616161;
							font-family: var(--global--font-body);
							font-size  : 13px;
							font-weight: 400;
							line-height: 16px;
							transition : all 0.3s ease-in-out;

							&::after {
								content : ", ";
								position: relative;
							}

							&:last-of-type {
								&::after {
									content: "";
								}
							}
						}
					}

					.entry-author {
						text-transform: capitalize;

						p {
							font-family  : var(--global--font-body);
							font-size    : 13px;
							font-weight  : 400;
							line-height  : 26px;
							margin-bottom: 0;

							a {
								color: var(--global--color-heading);

								&:hover {
									color: var(--global--color-primary);
								}
							}
						}
					}

					.entry-comments {
						text-transform: capitalize;
						font-family   : var(--global--font-body);
						font-size     : 13px;
						font-weight   : 400;
						line-height   : 26px;
						color         : var(--global--color-body);

						.num {
							color: var(--global--color-heading);
						}
					}
				}
			}

			.entry-title {
				margin-bottom: 19px;

				h2 {
					font-family  : var(--global--font-heading);
					font-size    : 40px;
					line-height  : 50px;
					font-weight  : 700;
					margin-bottom: 0;

					@media #{$maxSmall} {
						font-size  : 30px;
						line-height: 1.4;
					}

					@media #{$noteBook} {
						font-size  : 32px;
						line-height: 1.4;
					}
				}
			}

			.entry-images {
				margin-bottom: 44px;

				@media #{$maxSmall} {
					margin-bottom: 19px;
				}

				img {
					width     : 100%;
					object-fit: cover;
					height    : 350px;

					@media #{$maxSmall} {
						margin-bottom: 25px;
					}
				}
			}

			.entry-bio {
				margin-bottom: 32px;

				p {
					font-size    : 17px;
					line-height  : 27px;
					margin-bottom: 27px;
					font-weight  : 400;
					margin-bottom: 25px;

					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
		}
	}


	.nav-posts {
		display        : flex;
		align-items    : center;
		justify-content: space-between;
		padding        : 40px 0;

		@media #{$maxSmall} {
			justify-content: center;
		}

		.btn {
			height     : 60px;
			color      : var(--global--color-heading);
			transition : 0.3s ease-in-out;
			width      : auto;
			font-size  : 15px;
			font-weight: 700;
			padding    : 0;

			i {
				@include centerd(60px, 60px, var(--global--color-primary));
				color        : var(--global--color-heading);
				border-radius: 3px;
				transition   : 0.3s ease-in-out;
				margin-left  : 20px;
				font-size    : 11px;
			}

			@media #{$maxSmall} {
				span {
					display: none;
				}
			}
		}

		.prev-post {
			.btn {
				i {
					background-color: var(--global--color-heading);
					color           : var(--global--color-white);
					margin-left     : 0;
					margin-right    : 20px;
					transform       : rotate(180deg);
				}
			}
		}
	}

	.entry-widget {
		margin-top: 62px;

		.entry-widget-title {
			position     : relative;
			margin-bottom: 35px;

			h3 {
				font-family   : var(--global--font-heading);
				font-size     : 24px;
				font-weight   : 700;
				line-height   : 30px;
				margin-bottom : 0;
				position      : relative;
				text-transform: capitalize;
				color         : var(--global--color-heading-darken);
			}
		}
	}

	.entry-widget-bio {
		padding : 45px 45px 45px;
		border  : 6px solid #eaeaea;
		position: relative;

		@media #{$maxSmall} {
			padding-right: 20px;
			padding-left : 20px;
		}

		@media #{$noteBook} {
			padding-right: 25px;
			padding-left : 25px;
		}

		.entry-widget-content {
			position: relative;
			display : flex;

			@media #{$maxSmall} {
				flex-direction: column;
				align-items   : center;
			}

			img {
				width       : 120px;
				height      : 120px;
				object-fit  : cover;
				margin-right: 30px;

				@media #{$maxSmall} {
					margin-right : 0;
					margin-bottom: 25px;
				}
			}

			.entry-bio-desc {
				@media #{$maxSmall} {
					text-align: center;
				}

				@media #{$minTablet} {
					transform    : translateY(-5px);
					margin-bottom: -5px
				}

				h3 {
					font-family   : var(--global--font-body);
					color         : var(--global--color-heading);
					font-size     : 20px;
					font-weight   : 600;
					line-height   : 30px;
					margin-bottom : 8px;
					text-transform: capitalize;
				}

				p {
					color        : var(--global--color-body);
					font-size    : 15px;
					font-family  : var(--global--font-body);
					font-weight  : 400;
					line-height  : 23px;
					margin-bottom: 13px;
				}

				.social-icons {
					display    : flex;
					align-items: center;

					@media #{$maxSmall} {
						justify-content: center;
					}

					a {
						background-color: var(--global--color-white);
						border-radius   : 50%;
						transition      : 0.3s ease-in-out;
						width           : 32px;
						height          : 32px;
						display         : flex;
						justify-content : center;
						align-items     : center;
						transition      : 0.3s ease-in-out;
						border          : 2px solid #eaeaea;
						margin-right    : 10px;

						&:last-child {
							margin-right: 0;
						}

						i {
							color     : var(--global--color-primary);
							font-size : 15px;
							transition: 0.3s ease-in-out;
						}

						&:last-child {
							margin-right: 0;
						}

						&:hover {
							background-color: var(--global--color-heading);
							border-color    : var(--global--color-heading);
							transform       : translateY(-5px);

							i {
								color: var(--global--color-white);
							}
						}
					}
				}
			}
		}
	}

	.entry-comments {
		.entry-widget-content {
			.comments-list {
				width        : 100%;
				list-style   : none;
				overflow     : hidden;
				padding-left : 0;
				margin-bottom: 0;

				.comment-body {
					position     : relative;
					margin-bottom: 34px;
					display      : flex;

					@media #{$maxSmall} {
						flex-direction: column;
					}

					&:last-child {
						margin-bottom: 0;
					}

					.replies-list {
						margin-top: 34px;

						li {
							position     : relative;
							margin-bottom: 30px;

							&:last-child {
								margin-bottom: 0px;
							}
						}
					}

					.avatar {
						flex-shrink  : 0;
						width        : 80px;
						height       : 80px;
						overflow     : hidden;
						display      : flex;
						border-radius: 50%;

						@media #{$maxSmall} {
							margin-left  : 20px;
							margin-bottom: 30px;
						}
					}

					.comment {
						margin-left: 40px;

						@media #{$maxSmall} {
							margin-left: 20px;
						}

						.comment-head {
							display      : flex;
							align-items  : center;
							margin-bottom: 17px;

							h3 {
								font-family   : var(--global--font-body);
								color         : var(--global--color-heading);
								font-size     : 18px;
								font-weight   : 700;
								text-transform: capitalize;
								margin-bottom : 12px;
								line-height   : 18px;
								margin-bottom : 0;
								margin-right  : 20px;
							}

							.date {
								color      : #616161;
								font-family: var(--global--font-body);
								font-size  : 12px;
								font-weight: 400;
								line-height: 18px;
							}
						}

						.comment-content {
							background-color: #f4f4f4;
							padding         : 32px 40px 32px;
							position        : relative;
							margin-bottom   : 16px;

							@media #{$noteBook} {
								padding-left : 25px;
								padding-right: 25px;
							}

							@media #{$maxSmall} {
								padding-left : 25px;
								padding-right: 25px;
							}

							&::before {
								content         : '';
								width           : 20px;
								height          : 15px;
								background-color: #f4f4f4;
								position        : absolute;
								right           : calc(100% - 1px);
								top             : 0;
								clip-path       : polygon(100% 0, 0 0, 100% 100%);
							}

							p {
								font-family  : var(--global--font-body);
								color        : var(--global--color-body);
								font-size    : 15px;
								font-weight  : 400;
								line-height  : 24px;
								margin-bottom: 0px;
							}
						}

						a.reply {
							font-family   : var(--global--font-body);
							font-size     : 14px;
							font-weight   : 700;
							text-transform: capitalize;
							color         : var(--global--color-heading);
							line-height   : 20px;
							display       : inline-flex;
							align-items   : center;
							transition    : 0.3s ease-in-out;
							position      : relative;

							i {
								font-size   : 10px;
								margin-right: 10px
							}
						}
					}
				}
			}
		}
	}

	.entry-add-comment {
		padding-top: 0;
		margin-top : 57px;

		.custom-radio-group {
			.custom-control {
				margin-right : 0;
				margin-bottom: 39px;

				@media #{$maxSmall} {
					display    : flex;
					align-items: center;
				}

				label {
					@media #{$maxSmall} {
						white-space  : nowrap;
						text-overflow: ellipsis;

						&::After {
							left: 6px;
							top : 4px;
						}
					}
				}
			}
		}

		.form-control {
			border        : 2px solid #eaeaea;
			padding       : 30px;
			height        : 68px;
			border-radius : 3px;
			margin-bottom : 35px;
			color         : #616161;
			font-size     : 14px;
			font-weight   : 400;
			text-transform: capitalize;

			@media #{$maxSmall} {
				padding-left : 25px;
				padding-right: 25px;
			}

			&::-webkit-input-placeholder {
				color         : #616161;
				font-size     : 14px;
				font-weight   : 400;
				text-transform: capitalize;
			}

			&:-moz-placeholder {
				color         : #616161;
				font-size     : 14px;
				font-weight   : 400;
				text-transform: capitalize;
			}

			&:-ms-input-placeholder {
				color         : #616161;
				font-size     : 14px;
				font-weight   : 400;
				text-transform: capitalize;
			}
		}

		textarea {
			&.form-control {
				height       : 160px;
				margin-bottom: 21px;
			}
		}

		.btn {
			width : 240px;
			height: 78px;

			@media #{$maxSmall} {
				width : 100%;
				height: 68px;
			}

			&:hover {
				i {
					background-color: var(--global--color-white);
					color           : var(--global--color-heading);
				}
			}

			i {
				@include centerd(32px, 32px, var(--global--color-heading));
				color        : var(--global--color-white);
				border-radius: 50%;
				margin-left  : 10px;
				transition   : 0.3s ease-in-out;
				font-size    : 9px;
			}
		}
	}

	.sidebar-blog {
		@media #{$maxTablet} {
			margin-top: 70px;
		}
	}
}