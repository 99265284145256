/*------------------------------------*\
    #Menu
\*------------------------------------*/
@media only #{$minLarge} {
    .navbar-collapse {
        padding-right: 0;
        padding-left : 0;
    }

    /* Menu with Dropdown */
    .navbar-nav {
        >li.has-dropdown {
            >ul.dropdown-menu {
                box-shadow      : 0px 5px 41.5px rgba(40, 40, 40, 0.11);
                transition      : all 300ms linear;
                opacity         : 0;
                visibility      : hidden;
                position        : absolute;
                left            : 0px;
                right           : auto;
                transform-origin: left top;
                transform       : translateY(20px);
                display         : block;
                width           : 235px;
                padding         : 24px 40px 25px;
                background-color: var(--global--color-white);
                border-radius   : 0px;
                box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.12);

                >li {
                    padding-right : 0;
                    position      : relative;
                    vertical-align: top;
                }

                li {
                    a {
                        transition    : 0.3s ease-in-out;
                        position      : relative;
                        font-family   : var(--global--font-body);
                        color         : var(--global--color-body);
                        padding       : 10px 0px 9px 0;
                        display       : block;
                        font-size     : 14px;
                        font-weight   : 400;
                        transition    : all 300ms ease-in-out;
                        text-transform: capitalize;
                        border-radius : 0;
                        border-bottom : 1px solid #eaeaea;
                        position      : relative;

                        &::after {
                            content         : '';
                            position        : absolute;
                            bottom          : -1px;
                            left            : 0;
                            height          : 1px;
                            width           : 0px;
                            transition      : 0.3s ease;
                            background-color: var(--global--color-heading);
                        }

                        &:active,
                        &:focus,
                        &:hover {
                            color: var(--global--color-heading);

                            &::after {
                                width: 100%;
                            }
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 0;

                            &::after {
                                content: unset;
                            }
                        }
                    }

                    &.current {
                        >a {
                            border-color: var(--global--color-heading);
                            color       : var(--global--color-heading);
                        }
                    }
                }
            }

            &:hover {
                >ul.dropdown-menu {
                    opacity   : 1;
                    visibility: visible;
                    transform : translateY(0);
                    z-index   : 100;
                    display   : block;
                }
            }
        }

        li {
            ul.dropdown-menu {
                &.left {
                    left       : -650px !important;
                    margin-left: 0 !important;
                }
            }
        }

        /* Mega Menu Content */
        li.has-dropdown {
            &.mega-dropdown {
                >.dropdown-menu {
                    background-color: var(--global--color-white);
                    padding         : 0;
                    width           : 480px;

                    li {
                        padding-left : 0;
                        padding-right: 0;
                    }

                    .row {
                        background-color: var(--global--color-white);
                        margin-right    : 0;
                        margin-left     : 0;
                        padding         : 38px 23px 25px;

                        .menu-head {
                            font-size     : 16px;
                            font-family   : var(--global--font-heading);
                            font-weight   : 700;
                            color         : var(--global--color-heading-light);
                            text-transform: capitalize;
                            margin-bottom : 13px;
                        }

                        ul {
                            margin : 0;
                            padding: 0;

                            li {
                                a {
                                    transition    : 0.3s ease-in-out;
                                    position      : relative;
                                    font-family   : var(--global--font-body);
                                    color         : var(--global--color-body);
                                    padding       : 10px 0px 9px 0;
                                    display       : block;
                                    font-size     : 14px;
                                    font-weight   : 400;
                                    transition    : all 300ms ease-in-out;
                                    text-transform: capitalize;
                                    border-radius : 0;
                                    border-bottom : 1px solid #eaeaea;
                                    position      : relative;

                                    &::after {
                                        content         : '';
                                        position        : absolute;
                                        bottom          : -1px;
                                        left            : 0;
                                        height          : 1px;
                                        width           : 0px;
                                        transition      : 0.3s ease;
                                        background-color: var(--global--color-heading);
                                    }

                                    &:active,
                                    &:focus,
                                    &:hover {
                                        color: var(--global--color-heading);

                                        &::after {
                                            width: 100%;
                                        }
                                    }
                                }

                                &:last-child {
                                    a {
                                        border-bottom: 0;

                                        &::after {
                                            content: unset;
                                        }
                                    }
                                }

                                &.current {
                                    >a {
                                        border-color: var(--global--color-heading);
                                        color       : var(--global--color-heading);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.ms-auto.me-auto {
            li.has-dropdown {
                &.mega-dropdown {
                    position: absolute
                }
            }
        }

        &.ms-auto:not(.me-auto) {
            li.has-dropdown {
                &.mega-dropdown {
                    position: relative
                }
            }
        }
    }
}