/*------------------------------------*\
    #Banner
\*------------------------------------*/
.banner {
    position      : relative;
    padding-top   : 130px;
    padding-bottom: 130px;

    @media #{$maxTablet} {
        padding-top   : 70px;
        padding-bottom: 0px;
    }

    .banners-wrapper {
        position     : relative;
        margin-bottom: 70px;

        @media #{$maxTablet} {
            margin-bottom: 50px;
            ;
        }

        .banner-carousel {
            @media #{$minLarge} {
                position: absolute;
                left    : 0;
                top     : 0;
                width   : 50%;
                height  : 619px;
            }

            @media #{$maxTablet} {
                margin-bottom: 70px;
                ;
            }
        }
    }

    &.banner-right {
        .banner-img {
            @media #{$minLarge} {
                left               : auto;
                right              : 0;
                background-position: left bottom;
            }
        }
    }

    &.banner-1 {
        padding-top   : 205px;
        padding-bottom: 121px;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }

        .call-to-action {
            .note {
                @media #{$minLarge} {
                    transform    : translateY(-7px);
                    margin-bottom: -7px;
                }

                @media #{$maxTablet} {
                    margin-bottom: 40px;
                    ;
                }
            }
        }
    }

    &.banner-2 {
        padding-top   : 121px;
        padding-bottom: 130px;

        @media #{$maxTablet} {
            padding-top   : 61px;
            padding-bottom: 70px;
        }

        &::before {
            @media #{$minLarge} {
                content         : '';
                position        : absolute;
                top             : 50%;
                left            : 50%;
                transform       : translate(calc(-50% + 1px), -50%);
                width           : 1px;
                height          : calc(100% - 260px);
                background-color: var(--global--color-primary);
            }
        }

        .panels-holder {
            margin-bottom: 64px;

            .banner-panel {
                &:first-child {
                    padding-top: 0;
                    border-top : 0;
                }

                &:last-child {
                    padding-bottom: 0;
                    border-bottom : 0;
                }
            }
        }

        .banners-action {
            display    : flex;
            align-items: center;

            @media #{$maxTablet} {
                justify-content: center;
                margin-bottom  : 63px;
            }

            @media #{$maxSmall} {
                display       : flex;
                flex-direction: column;
                align-items   : center;
            }

            .btn {
                width       : 260px;
                height      : 68px;
                margin-right: 30px;
                padding     : 0 15px;

                @media #{$maxSmall} {
                    margin-right : 0;
                    margin-bottom: 25px;
                    height       : 60px;
                    width        : 220px;
                }

                @media #{$noteBook} {
                    width       : 220px;
                    margin-right: 15px;
                }

                &:last-child {
                    margin-right: 0;
                    width       : 154px;

                    @media #{$maxSmall} {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &.banner-3 {
        padding-top   : 121px;
        padding-bottom: 130px;
        overflow      : visible;

        @media #{$maxTablet} {
            padding-top   : 61px;
            padding-bottom: 70px;
        }

        &::before {
            @media #{$minLarge} {
                content         : '';
                position        : absolute;
                top             : 50%;
                left            : 50%;
                transform       : translate(calc(-50% + 1px), -50%);
                width           : 1px;
                height          : calc(100% - 260px);
                background-color: var(--global--color-primary);
            }
        }

        .panels-holder {
            margin-bottom: 64px;

            .banner-panel {
                &:first-child {
                    padding-top: 0;
                    border-top : 0;
                }

                &:last-child {
                    padding-bottom: 0;
                    border-bottom : 0;
                }
            }
        }

        .banners-action {
            display    : flex;
            align-items: center;

            @media #{$maxTablet} {
                justify-content: center;
                margin-bottom  : 63px;
            }

            @media #{$maxSmall} {
                display       : flex;
                flex-direction: column;
                align-items   : center;
            }

            .btn {
                width       : 260px;
                height      : 68px;
                margin-right: 30px;
                padding     : 0 15px;

                @media #{$maxSmall} {
                    margin-right : 0;
                    margin-bottom: 25px;
                    height       : 60px;
                    width        : 220px;
                }

                @media #{$noteBook} {
                    width       : 220px;
                    margin-right: 15px;
                }

                &:last-child {
                    margin-right: 0;
                    width       : 154px;

                    @media #{$maxSmall} {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .testimonial-wrapper {
            @media #{$minLarge} {
                position: absolute;
                bottom  : -109px;
                left    : 0;
                width   : 100%;
            }

            @media #{$maxTablet} {
                position    : relative;
                bottom      : auto;
                left        : auto;
                max-width   : 550px;
                margin-left : auto;
                margin-right: auto;

                .container-lg {
                    padding: 0;
                }
            }

            @media #{$maxSmall} {
                max-width: 100%;
            }

            .testimonials-holder {
                padding         : 78px 90px 80px;
                background-color: var(--global--color-primary);
                position        : relative;
                z-index         : 5;

                @media #{$noteBook} {
                    padding-left : 35px;
                    padding-right: 35px;
                }

                @media #{$maxTablet} {
                    padding-left : 35px;
                    padding-right: 35px;
                }

                @media #{$maxSmall} {
                    padding-left : 15px;
                    padding-right: 15px;
                }

                .testimonials-thumbs-wrapper {
                    margin-bottom : 0;
                    padding-bottom: 0;

                    &::before {
                        content: unset;
                    }
                }

                .testimonial-panel {
                    margin-bottom: 24px;
                }

                .quote-icon {
                    position: absolute;
                    bottom  : 40px;
                    right   : 40px;
                    z-index : 5;

                    @media #{$maxSmall} {
                        bottom: 25px;
                        right : 25px;
                    }
                }

                &::before {
                    content         : '';
                    position        : absolute;
                    top             : calc(100% - 1px);
                    right           : 0;
                    width           : 40px;
                    height          : 30px;
                    background-color: var(--global--color-primary);
                    clip-path       : polygon(100% 0, 0 0, 100% 100%);
                }
            }
        }
    }

    &.banner-4 {
        padding-top   : 115px;
        padding-bottom: 121px;

        @media #{$maxTablet} {
            padding-top   : 70px;
            padding-bottom: 70px;
        }

        .call-to-action {
            .note {
                @media #{$minLarge} {
                    transform    : translateY(-7px);
                    margin-bottom: -7px;
                }

                @media #{$maxTablet} {
                    margin-bottom: 40px;
                    ;
                }
            }
        }
    }
}

.panels-holder {
    .banner-panel {
        display       : flex;
        padding-top   : 42px;
        padding-bottom: 44px;
        border-bottom : 1px solid #404040;

        &:first-child {
            border-top: 1px solid #404040;
        }

        @media #{$maxTablet} {
            max-width: 500px;
            margin   : 0 auto 0px;
        }

        @media #{$maxSmall} {
            max-width     : 370px;
            flex-direction: column;
            align-items   : center;
            text-align    : center;
        }

        &:hover {
            .panel-icon {
                i {
                    animation: Bounce 0.8s ease-in-out;
                }
            }
        }

        .panel-icon {
            margin-right: 46px;

            @media #{$maxLarge} {
                margin-right: 30px;
            }

            @media #{$maxSmall} {
                margin-right : 0px;
                margin-bottom: 30px;
            }

            i {
                display   : inline-block;
                transition: 0.3s ease-in-out;

                @media #{$minTablet} {
                    margin-top: 8px;
                }

                &::before {
                    font-size  : 65px;
                    line-height: 1;
                    color      : var(--global--color-primary);

                    @media #{$maxSmall} {
                        font-size: 45px;
                    }
                }
            }
        }

        .panel-content {
            h3 {
                font-family   : var(--global--font-heading);
                font-weight   : 700;
                font-size     : 20px;
                line-height   : 29px;
                color         : var(--global--color-white);
                text-transform: capitalize;
                margin-bottom : 17px;
            }

            p {
                font-family  : var(--global--font-body);
                font-weight  : 400;
                font-size    : 15px;
                line-height  : 25px;
                color        : var(--global--color-body);
                margin-bottom: 0px;
            }
        }

        &.banner-dark {
            border-color: #eaeaea;

            .panel-content {
                h3 {
                    color: var(--global--color-heading-light)
                }
            }
        }
    }
}