/*------------------------------------*\
    #Carrers
\*------------------------------------*/
.careers {
    padding-top   : 128px;
    padding-bottom: 110px;

    @media #{$maxTablet} {
        padding-top   : 68px;
        padding-bottom: 70px;
    }

    .career-item {
        box-shadow      : 0px 5px 83px 0px rgba(27, 26, 26, 0.09);
        background-color: var(--global--color-white);
        padding         : 40px 40px 40px;
        position        : relative;
        transition      : 0.3s ease-in-out;
        margin-bottom   : 40px;

        @media #{$noteBook} {
            padding-right: 25px;
            padding-left : 25px;
        }

        @media #{$maxSmall} {
            padding-right: 25px;
            padding-left : 25px;
        }

        @media #{$maxTablet} {
            max-width: 370px;
            margin   : 0 auto 40px;
        }

        &:hover {
            box-shadow: 0px 5px 83px 0px rgba(27, 26, 26, 0.12);

            .career-content {
                .career-more {
                    color           : var(--global--color-white);
                    background-color: var(--global--color-heading);
                    border-color    : var(--global--color-heading);

                    i {
                        color: var(--global--color-white);
                    }
                }
            }
        }

        .career-meta {
            display      : flex;
            align-items  : center;
            margin-bottom: 23px;

            .career-type,
            .career-place {
                font-size     : 13px;
                line-height   : 28px;
                font-family   : var(--global--font-body);
                font-weight   : 400;
                text-transform: capitalize;
                margin-bottom : 0;
            }

            .career-type {
                position        : relative;
                display         : flex;
                align-items     : center;
                justify-content : center;
                padding         : 0 10px;
                height          : 30px;
                border-radius   : 3px;
                background-color: var(--global--color-primary);
                margin-right    : 15px;
                color           : var(--global--color-heading);
            }

            .career-place {
                color: #616161;
            }
        }

        .career-content {
            .career-title {
                font-family   : var(--global--font-heading);
                font-size     : 22px;
                font-weight   : 600;
                line-height   : 29px;
                color         : var(--global--color-heading);
                margin-bottom : 17px;
                text-transform: capitalize;
            }

            .career-desc {
                font-family  : var(--global--font-body);
                font-weight  : 400;
                font-size    : 15px;
                line-height  : 25px;
                color        : var(--global--color-body);
                margin-bottom: 23px;
            }

            .career-more {
                width  : 150px;
                height : 48px;
                padding: 0 15px;

                i {
                    color: var(--global--color-heading);
                }
            }
        }
    }

    .carousel-dots {
        .owl-stage-outer {
            overflow: visible;

            .owl-item {
                opacity   : 0;
                visibility: hidden;

                &.active {
                    opacity   : 1;
                    visibility: visible;
                }
            }
        }
    }
}